import React, { useEffect, useRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { LinkButton } from '@/components/common/buttons'
import { Typo, TypoVariant } from '@/components/common/typography'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { useCustomRouter } from '@/containers/hooks'
import { appendQueryStringToPathname } from '@/utils/appendQueryStringToPathname'
import { FitpetMallEventEnum } from '@/utils/event-tracker/events'

type NavigationItemType = {
  title: string
  link: string
  isBadge?: boolean
  labelText?: string
}

type HomeNavigationBarItemProps = Omit<NavigationItemType, 'isShow'> & {
  scrollCenter: (offset: number, width: number) => void
}

const HomeNavigationBarItem = ({ title, link, scrollCenter, isBadge, labelText }: HomeNavigationBarItemProps) => {
  const itemRef = useRef<HTMLLIElement | null>(null)
  const { asPath } = useCustomRouter()
  const { triggerCustomEvent } = useTracker()
  const { color } = useTheme()

  const isSelected = () => {
    if (asPath.startsWith('/home/bestseller') && link.startsWith('/home/bestseller')) return true
    const asPathPathname = asPath.split('?')[0]
    const linkPathname = link.split('?')[0]

    return asPathPathname === linkPathname
  }

  useEffect(() => {
    if (isSelected()) {
      scrollCenter(itemRef.current!.offsetLeft, itemRef.current!.getBoundingClientRect().width)
    }
  })

  return (
    <NavigationItem
      ref={(ref) => {
        itemRef.current = ref
      }}
    >
      <LinkButton
        routeType={link.startsWith('/home') || link === '/' ? 'replace' : 'push'}
        url={appendQueryStringToPathname({
          pathname: link,
          param: 'eventsale_location',
          value: 'topmenu',
        })}
        onClick={() => {
          triggerCustomEvent(FitpetMallEventEnum.ClickHomeTopmenuTab, { homeTopmenuName: title })
        }}
      >
        <StyledGnbItem isActiveTab={isSelected()}>
          <Typo
            as={isSelected() ? 'h1' : 'div'}
            variant={TypoVariant.Body1Bold}
            color={isSelected() ? color.gray900 : color.gray400}
          >
            {title}
          </Typo>
          {labelText && (
            <StyledLabel>
              <Typo variant={TypoVariant.Caption2Bold} color={color.grayWhite}>
                {labelText}
              </Typo>
            </StyledLabel>
          )}
        </StyledGnbItem>
      </LinkButton>
      {!labelText && isBadge && <StyledBadge />}
    </NavigationItem>
  )
}

export default HomeNavigationBarItem

const NavigationItem = styled.li`
  position: relative;
  height: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0;
`

const StyledGnbItem = styled.div<{ isActiveTab: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.2rem 1.2rem 1.2rem;
  gap: 0.4rem;

  ${({ isActiveTab, theme: { color } }) =>
    isActiveTab &&
    `
      ::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.2rem;
        background-color: ${color.gray900};
        border-radius: 2px;
      }
  `}
`

const StyledBadge = styled.div`
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.red500};
`

const StyledLabel = styled.span`
  display: flex;
  padding: 0.2rem 0.4rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem 0.8rem 0.8rem 0.1rem;
  background: ${(props) => props.theme.color.red500};
`
