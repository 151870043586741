import { SktPaperCouponTitle } from '@/constants/coupon.const'
import { DateFormatEnum } from '@/constants/date-type.enum'
import { UserCouponsQuery } from '@/graphql/generated/operations'
import * as Types from '@/graphql/generated/schemas'
import {
  BrandTypeConnection,
  CategoryTypeConnection,
  CouponGroupDiscountType,
  CouponGroupIssueType,
  ProductTypeConnection,
} from '@/graphql/generated/schemas'
import { currencyText } from '@/utils/utilNumber'
import { convertDateWithFormat, getDiffDays } from './utilDate'

type GetBenefitText = (params: {
  discountType: string
  issueType: string
  discountAmount: number
  discountRate: number
  name: string
}) => string

type GetBenefitTextNew = (params: {
  discountType: string
  issueType: string
  discountAmount: number
  appliedDiscountAmount: number
  discountRate: number
  name: string
  isAvailable?: boolean
}) => string

type GetConditionText = (params: {
  hasMinOrderAmount: boolean
  minOrderAmount?: number
  maxDiscountAmount?: number
}) => string

type GetDatePeriodText = (params: {
  hasUsingPeriod: boolean
  usingStartedAt?: Date | undefined
  usingEndedAt: Date | undefined
  expiredAt?: Date | undefined
  usedStatus?: UsedStatus
}) => string

type GetDatePeriodTextNew = (params: {
  hasUsingPeriod: boolean
  startedAt?: Date | undefined
  expiredAt?: Date | undefined
  hasTime?: boolean
}) => string

type UsedStatus = 'EXPIRED' | 'USED' | 'UNUSED'

const usedStatusText = {
  EXPIRED: '사용불가',
  USED: '사용완료',
  TODAYEXPIRED: '오늘만료',
}

export type IncludedCouponTypes = {
  includedBrands: Omit<BrandTypeConnection, 'pageInfo'>
  includedCategories: Omit<CategoryTypeConnection, 'pageInfo'>
  includedProducts: Omit<ProductTypeConnection, 'pageInfo'>
}

export type ExcludedCouponTypes = {
  excludedBrands: Omit<BrandTypeConnection, 'pageInfo'>
  excludedCategories: Omit<CategoryTypeConnection, 'pageInfo'>
  excludedProducts: Omit<ProductTypeConnection, 'pageInfo'>
}

type CheckIncludedCoupon = (params: IncludedCouponTypes) => string
type CheckExcludedCoupon = (params: ExcludedCouponTypes) => boolean

const getBenefitText: GetBenefitText = ({ discountType, issueType, discountAmount, discountRate, name }) => {
  const oneHundreds = 100
  if (issueType === CouponGroupIssueType.PaperSkt) {
    return SktPaperCouponTitle
  }
  if (discountType === CouponGroupDiscountType.Amount && discountAmount > 0) {
    return `${Number(discountAmount).toLocaleString('ko-KR')}원 할인`
  } else {
    return `${Math.floor(discountRate * oneHundreds)}% 할인`
  }
}

export const getBenefitTextNew: GetBenefitTextNew = ({
  discountType,
  issueType,
  discountAmount,
  appliedDiscountAmount,
  discountRate,
  isAvailable = true,
}) => {
  const oneHundreds = 100
  if (issueType === CouponGroupIssueType.PaperSkt) {
    return SktPaperCouponTitle
  }
  if (discountType === CouponGroupDiscountType.Amount) {
    return `${Number(discountAmount).toLocaleString('ko-KR')}원 할인`
  } else {
    return isAvailable
      ? `${currencyText(appliedDiscountAmount)} 할인(${Math.floor(discountRate * oneHundreds)}%)`
      : `${Math.floor(discountRate * oneHundreds)}% 할인`
  }
}

export const getAmountText: GetConditionText = ({ hasMinOrderAmount, minOrderAmount = 0, maxDiscountAmount = 0 }) => {
  const amountTextList: string[] = []
  if (hasMinOrderAmount && minOrderAmount > 0) {
    amountTextList.push(`최소주문금액 ${Number(minOrderAmount).toLocaleString('ko-KR')}원`)
  }
  if (maxDiscountAmount && maxDiscountAmount > 0) {
    amountTextList.push(`최대 ${Number(maxDiscountAmount).toLocaleString('ko-KR')}원 할인`)
  }
  return amountTextList.join(' / ')
}

const getDday = (diffDays: number) => {
  const periodDays = 60
  switch (true) {
    case diffDays < 0:
      return usedStatusText.EXPIRED
    case diffDays === 0:
      return usedStatusText.TODAYEXPIRED
    case diffDays <= periodDays:
      return `D-${diffDays}`
    case diffDays > periodDays:
      return ''
    default:
      return ''
  }
}

/**
 *
 * 결제 페이지(주문 쿠폰, 상품 쿠폰, 배송비 쿠폰)에서 사용, 마이페이지 쿠폰함에서 사용
 * hasusingPeriod: 발급일 기간 설정 여부(ex. 발급일로부터 30일전)
 * expiredAt: 사용만료일
 * usingPeriod: 발급일 기간
 * usingEndedAt: 사용 종료일
 * usedStatus: 사용여부 - 마이페이지/쿠폰함 페이지에서 사용여부에 따라 텍스트 변경
 *
 * 1. 발급일 기간 설정 && 사용만료일 : hasUsingPeriod && expiredAt
 * 2. 발급일 기간 설정 && 발급 종료일 :  hasUsingPeriod && issueEndedAt
 * 3. 사용일 기간 설정: !hasUsingPeriod
 *
 */
const getExpiredText: GetDatePeriodText = ({ hasUsingPeriod, usingEndedAt, expiredAt, usedStatus }) => {
  if (usedStatus === 'EXPIRED' || usedStatus === 'USED') {
    return usedStatusText[usedStatus]
  }
  const targetDate = hasUsingPeriod ? expiredAt : usingEndedAt
  const diffDays = getDiffDays({
    endDate: targetDate as Date,
    startDate: new Date(),
  })
  return getDday(diffDays)
}

/**
 *
 * 결제 페이지(주문 쿠폰, 상품 쿠폰, 배송비 쿠폰)에서 사용, 마이페이지 쿠폰함에서 사용
 * hasusingPeriod: 발급일 기간 설정 여부(ex. 발급일로부터 30일전)
 * expiredAt: 사용만료일
 * usingPeriod: 발급일 기간
 * usingEndedAt: 사용 종료일
 *
 * 1. 발급일 기간 설정 && 사용만료일 : hasUsingPeriod && expiredAt
 * 2. 사용일 기간 설정: !hasUsingPeriod
 *
 */

const getDatePeriodText: GetDatePeriodText = ({ hasUsingPeriod, usingStartedAt, usingEndedAt, expiredAt }) => {
  if (hasUsingPeriod && expiredAt) {
    return `${convertDateWithFormat({ date: expiredAt, format: DateFormatEnum['YY.MM.DD HH:mm'] })}까지`
  }
  return `${convertDateWithFormat({
    date: usingStartedAt,
    format: DateFormatEnum['YY.MM.DD HH:mm'],
  })} - ${convertDateWithFormat({ date: usingEndedAt, format: DateFormatEnum['YY.MM.DD HH:mm'] })}`
}

/**
 * 쿠폰 사용 기간
 *
 * 결제 페이지(주문 쿠폰, 상품 쿠폰, 배송비 쿠폰)에서 사용, 마이페이지 쿠폰함에는 적용하지 않음 (추후 적용 예정)
 *
 * getDatePeriodText 와 달라진 점
 * - usingStartedAt, usingEndedAt 대신 startedAt, expiredAt 사용
 *
 * params
 * - hasUsingPeriod: 발급일 기간 설정 여부(ex. 발급일로부터 30일전)
 * - startedAt: 사용시작일
 * - expiredAt: 사용만료일
 */
export const getDatePeriodTextNew: GetDatePeriodTextNew = ({ hasUsingPeriod, startedAt, expiredAt, hasTime }) => {
  if (hasUsingPeriod && expiredAt) {
    return `${convertDateWithFormat({ date: expiredAt, format: DateFormatEnum['YY.MM.DD HH:mm'] })}까지`
  }

  const dateFormat = hasTime ? DateFormatEnum['YY.MM.DD HH:mm'] : DateFormatEnum['YY.MM.DD']
  return `${convertDateWithFormat({
    date: startedAt,
    format: dateFormat,
  })} - ${convertDateWithFormat({ date: expiredAt, format: dateFormat })}`
}

/**
 *
 * 쿠폰 다운로드 시 쿠폰 적용 대상(ex 이벤트 페이지)
 * issueEndedAt: 발급 종료일
 *
 */
const getDatePeriodTextForDownload = ({ issueEndedAt }: { issueEndedAt?: Date }) => {
  if (issueEndedAt) {
    return `${convertDateWithFormat({ date: issueEndedAt, format: DateFormatEnum['YY.MM.DD HH:mm'] })}까지`
  }
  return '-'
}

/**
 *  쿠폰 적용 대상(이벤트 페이지)
 *  3개 프로퍼티(브랜드, 카테고리, 제품) 받아 edges length값 체크 하나라도 있으면
 *  해당 edges 배열 node.name을 join(', ')으로 리턴
 */

export const makeCouponIncludeTargetString: CheckIncludedCoupon = ({
  includedBrands,
  includedCategories,
  includedProducts,
}) => {
  const included = [includedBrands, includedCategories, includedProducts]
  const includedText = included
    .filter((item) => item.edges.length > 0)
    .map((item) => item.edges.map((item) => item.node?.name).join(', '))
    .join(', ')
  return includedText
}

/**
 * 쿠폰 적용 제외 대상(이벤트 페이지)
 * 3개 프로퍼티(브랜드, 카테고리, 제품) 받아 edges length값 체크 하나라도 있으면 return true
 */

export const checkExcludedCoupon: CheckExcludedCoupon = ({ excludedBrands, excludedCategories, excludedProducts }) => {
  const excluded = [excludedBrands, excludedCategories, excludedProducts]
  const isExcluded = excluded.some((item) => item.edges.length > 0)
  return isExcluded
}

export interface CouponGroupType {
  id: string
  name: string
  description?: string
  discountType: Types.CouponGroupDiscountType
  discountRate: number
  discountAmount: number
  maxDiscountAmount?: number
  hasMinOrderAmount?: boolean
  minOrderAmount?: number
  coverageOsType: Types.CouponGroupCoverageOsType
  coverageType: Types.CouponGroupCoverageType
  canConjunctUse: boolean
  couponType: Types.CouponGroupCouponType
  hasUsingPeriod?: boolean
  issueEndedAt?: Date
  isMembershipCoupon?: boolean
  usingPeriod?: number
  usingStartedAt?: Date
  usingEndedAt?: Date
  canIssue?: boolean
  issueType: Types.CouponGroupIssueType
  expiredAt?: Date
  usedStatus?: UsedStatus
}

export const makeCouponDisplayData = ({
  discountType,
  issueType,
  discountAmount,
  discountRate,
  name,
  hasMinOrderAmount,
  minOrderAmount,
  maxDiscountAmount,
  hasUsingPeriod,
  issueEndedAt,
  usingStartedAt,
  usingEndedAt,
  expiredAt,
  usedStatus,
}: CouponGroupType) => {
  const title = getBenefitText({
    discountType,
    issueType,
    discountAmount,
    discountRate,
    name,
  })

  const amount = getAmountText({
    hasMinOrderAmount: !!hasMinOrderAmount,
    minOrderAmount,
    maxDiscountAmount,
  })

  const dDayDate = getExpiredText({
    hasUsingPeriod: !!hasUsingPeriod,
    expiredAt,
    usingEndedAt,
    usedStatus,
  })

  const datePeriod = getDatePeriodText({
    hasUsingPeriod: !!hasUsingPeriod,
    usingStartedAt,
    usingEndedAt,
    expiredAt,
  })

  const datePeriodForDownload = getDatePeriodTextForDownload({
    issueEndedAt,
  })

  return { title, amount, dDayDate, datePeriod, datePeriodForDownload }
}

export const createCouponList = (data?: UserCouponsQuery) => {
  return (
    data?.coupons?.edges.map((edge) => ({
      ...edge.node,
      ...edge.node?.couponGroup,
    })) ?? []
  )
}
