export type Maybe<T> = T
export type InputMaybe<T> = T
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  DateTime: Date
  Decimal: any
  GenericScalar: any
  ImageUpload: any
  JSONString: any
  Upload: any
}

/** 교환 요청 -> 교환수거중 */
export type AcceptExchangeReturnInfosMutation = {
  __typename?: 'AcceptExchangeReturnInfosMutation'
  newOrderItems?: Maybe<Array<Maybe<OrderItemType>>>
  returnInfos?: Maybe<Array<Maybe<ReturnInfoType>>>
}

/** 반품 진행, 요청 -> 반품수거중 */
export type AcceptReturnInfosMutation = {
  __typename?: 'AcceptReturnInfosMutation'
  returnInfos?: Maybe<Array<Maybe<ReturnInfoType>>>
}

/** An enumeration. */
export enum ActionPowerOrderPetType {
  /** ALL */
  All = 'ALL',
  /** CAT */
  Cat = 'CAT',
  /** DOG */
  Dog = 'DOG',
}

/** An enumeration. */
export enum ActionPowerOrderStatus {
  /** 유저 업로드 전 */
  BeforeUpload = 'BEFORE_UPLOAD',
  /** 액션파워 이미지 처리 완료 */
  Done = 'DONE',
  /** 액션파워 이미지 처리 실패 */
  Failed = 'FAILED',
  /** 액션파워 이미지 처리 중 */
  InProcess = 'IN_PROCESS',
  /** 유저 액션파워 업로드 완료 */
  Uploaded = 'UPLOADED',
}

export type ActionPowerOrderType = Node & {
  __typename?: 'ActionPowerOrderType'
  /** 취소 일시 */
  canceledAt?: Maybe<Scalars['DateTime']>
  /** 이미지 처리 완료 일시 */
  completedAt?: Maybe<Scalars['DateTime']>
  /** 컨셉 */
  concept: Scalars['String']
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 이미지 처리 실패 일시 */
  failedAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 주문 상품 */
  orderItem?: Maybe<OrderItemType>
  /** 액션파워 URL */
  orderUrl?: Maybe<Scalars['String']>
  /** 펫 타입 */
  petType: ActionPowerOrderPetType
  /** 이미지 처리 시작 일시 */
  processedAt?: Maybe<Scalars['DateTime']>
  /** 액션파워 API 요청 일시 */
  requestedAt?: Maybe<Scalars['DateTime']>
  /** 주문 이미지 처리 상태 */
  status: ActionPowerOrderStatus
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 이미지 업로드 일시 */
  uploadedAt?: Maybe<Scalars['DateTime']>
}

export type ActivateCustomerGradeInput = {
  /** 적용 상태 */
  isActivated?: InputMaybe<Scalars['Boolean']>
}

/** 회원등급 적용 유무 */
export type ActivateCustomerGradeMutation = {
  __typename?: 'ActivateCustomerGradeMutation'
  customerGrade?: Maybe<CustomerGradeType>
}

/** An enumeration. */
export enum AdAdType {
  /** DEAL_100 */
  Deal_100 = 'DEAL_100',
  /** DEAL_990 */
  Deal_990 = 'DEAL_990',
  /** NORMAL */
  Normal = 'NORMAL',
}

/** An enumeration. */
export enum AdImageDisplayType {
  /** ANIMATED_MAIN */
  AnimatedMain = 'ANIMATED_MAIN',
  /** IN_EDIT */
  InEdit = 'IN_EDIT',
  /** MAIN */
  Main = 'MAIN',
  /** SUB */
  Sub = 'SUB',
}

export type AdImageInput = {
  /** 이미지 노출 위치 */
  displayType?: InputMaybe<Scalars['String']>
  image: Scalars['ImageUpload']
}

export type AdImageType = Node & {
  __typename?: 'AdImageType'
  /** 프로모션 */
  ad?: Maybe<AdType>
  /** 이미지 타입 */
  displayType: AdImageDisplayType
  /** The ID of the object. */
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
}

export type AdImageTypeConnection = {
  __typename?: 'AdImageTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AdImageTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `AdImageType` and its cursor. */
export type AdImageTypeEdge = {
  __typename?: 'AdImageTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<AdImageType>
}

/** An enumeration. */
export enum AdPetType {
  /** ALL */
  All = 'ALL',
  /** CAT */
  Cat = 'CAT',
  /** DOG */
  Dog = 'DOG',
}

export type AdType = Node & {
  __typename?: 'AdType'
  /** 프로모션 */
  adCouponGroups: CouponGroupTypeConnection
  adDisplayCollections?: Maybe<DisplayCollectionTypeConnection>
  /** 이벤트 */
  adGnbs: GnbTypeConnection
  /** 프로모션 */
  adImages: AdImageTypeConnection
  /** 프로모션 종류, NORMAL: 일반, DEAL_100: 100원딜, DEAL_990: 990원딜 */
  adType: AdAdType
  couponGroups?: Maybe<CouponGroupTypeConnection>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 설명 */
  description?: Maybe<Scalars['String']>
  /** 디스플레이 순서 */
  displayOrder: Scalars['Int']
  /** 종료일 */
  endedAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 모든 쿠폰들을 받았는지 여부 */
  isAllCouponsDownloaded?: Maybe<Scalars['Boolean']>
  /**
   * 쿠폰 이미 받았는지 여부
   * @deprecated 사용하지 않는 필드
   */
  isCouponDownloaded?: Maybe<Scalars['Boolean']>
  /** 이벤트 종료 여부 */
  isFinished?: Maybe<Scalars['Boolean']>
  /** 노출 여부 */
  isShown: Scalars['Boolean']
  mainImage?: Maybe<AdImageType>
  /** 프로모션명 */
  name: Scalars['String']
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType: AdPetType
  /** 프로모션 Url 주소 slug */
  slug?: Maybe<Scalars['String']>
  /** 시작일 */
  startedAt?: Maybe<Scalars['DateTime']>
  /** 배경색 등 스타일 정보 */
  style?: Maybe<Scalars['JSONString']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type AdTypeAdCouponGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroupStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  coverageOsType?: InputMaybe<Scalars['String']>
  coverageType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  eventType?: InputMaybe<Scalars['String']>
  excludeIssueType?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDiscountVisibleBeforeDownload?: InputMaybe<Scalars['Boolean']>
  isIssuing?: InputMaybe<Scalars['Boolean']>
  issueStatus?: InputMaybe<Scalars['String']>
  issueType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  usingEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  usingPeriod?: InputMaybe<Scalars['Float']>
  usingStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingStartedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type AdTypeAdDisplayCollectionsArgs = {
  ad?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  collectionType?: InputMaybe<Scalars['String']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  isHome?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type AdTypeAdGnbsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type AdTypeAdImagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type AdTypeCouponGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroupStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  coverageOsType?: InputMaybe<Scalars['String']>
  coverageType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  eventType?: InputMaybe<Scalars['String']>
  excludeIssueType?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDiscountVisibleBeforeDownload?: InputMaybe<Scalars['Boolean']>
  isIssuing?: InputMaybe<Scalars['Boolean']>
  issueStatus?: InputMaybe<Scalars['String']>
  issueType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  usingEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  usingPeriod?: InputMaybe<Scalars['Float']>
  usingStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingStartedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type AdTypeConnection = {
  __typename?: 'AdTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AdTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `AdType` and its cursor. */
export type AdTypeEdge = {
  __typename?: 'AdTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<AdType>
}

/** 관리자 권한 추가 */
export type AddAdminPermissionMutation = {
  __typename?: 'AddAdminPermissionMutation'
  user?: Maybe<UserType>
}

/** 배너 노출 허용 */
export type AllowBannerMutation = {
  __typename?: 'AllowBannerMutation'
  banner?: Maybe<BannerType>
}

/** 상품 문의 답변 */
export type AnswerProductQuestionMutation = {
  __typename?: 'AnswerProductQuestionMutation'
  productQna?: Maybe<ProductQnaType>
}

export type AnswerQuestionInput = {
  /** 답변 내용 */
  answerBody: Scalars['String']
}

/** 1:1 문의 답변 */
export type AnswerServiceQuestionMutation = {
  __typename?: 'AnswerServiceQuestionMutation'
  serviceQna?: Maybe<ServiceQnaType>
}

export type ApproveProductsSellingInput = {
  /** 승인 여부 */
  isApproved: Scalars['Boolean']
  products: Array<InputMaybe<Scalars['ID']>>
  /** 반려 사유 */
  rejectedMessage?: InputMaybe<Scalars['String']>
}

/** 상품 판매, 노출 일괄 승인 */
export type ApproveProductsSellingMutation = {
  __typename?: 'ApproveProductsSellingMutation'
  products?: Maybe<Array<Maybe<ProductType>>>
}

/** 요청 승인 */
export type ApproveRequestMutation = {
  __typename?: 'ApproveRequestMutation'
  status?: Maybe<StatusType>
}

/** 관리자에 의해 다중의 요청을 승인 */
export type ApproveRequestsMutation = {
  __typename?: 'ApproveRequestsMutation'
  statuses?: Maybe<Array<Maybe<StatusType>>>
}

export type ApproveTransactionInput = {
  /** 바로 구매 여부 */
  isDirect?: InputMaybe<Scalars['Boolean']>
}

/** 결제 승인 */
export type ApproveTransactionMutation = {
  __typename?: 'ApproveTransactionMutation'
  transaction?: Maybe<TransactionType>
}

/** 정보변경 요청 승인 */
export type ApproveUpdateRequestMutation = {
  __typename?: 'ApproveUpdateRequestMutation'
  status?: Maybe<StatusType>
}

export type AsInfoType = Node & {
  __typename?: 'AsInfoType'
  /** The ID of the object. */
  id: Scalars['ID']
  /** AS 전화번호 이름 */
  name?: Maybe<Scalars['String']>
  /** AS 관련 정보 */
  productSet: ProductTypeConnection
  /** 셀러 */
  seller?: Maybe<SellerType>
  /** AS 전화번호 */
  telephoneNumber?: Maybe<Scalars['String']>
}

export type AsInfoTypeProductSetArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type AsInfoTypeConnection = {
  __typename?: 'AsInfoTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AsInfoTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `AsInfoType` and its cursor. */
export type AsInfoTypeEdge = {
  __typename?: 'AsInfoTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<AsInfoType>
}

export type AskProductQuestionInput = {
  /** 비밀글 여부 */
  isSecret: Scalars['Boolean']
  /** QnA관련 상품 */
  product: Scalars['ID']
  /** QnA관련 상품 옵션 */
  productOption?: InputMaybe<Scalars['ID']>
  /** 질문 내용 */
  questionBody: Scalars['String']
}

/** 상품 문의 생성 */
export type AskProductQuestionMutation = {
  __typename?: 'AskProductQuestionMutation'
  productQna?: Maybe<ProductQnaType>
}

export type AskServiceQuestionInput = {
  /** 상품 옵션 타입 */
  category: Scalars['String']
  /** 수신 email */
  incomingEmail: Scalars['String']
  /** 답변 연락처 핸드폰 번호 */
  mobileNumber: Scalars['String']
  /** QnA관련 주문 */
  orderItem?: InputMaybe<Scalars['ID']>
  /** 질문 내용 */
  questionBody: Scalars['String']
  questionImages?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>
  /** 상품 옵션 타입 */
  questionType: Scalars['String']
}

/** 주문 / 1:1 문의 생성 */
export type AskServiceQuestionMutation = {
  __typename?: 'AskServiceQuestionMutation'
  serviceQna?: Maybe<ServiceQnaType>
}

/** 검색사전 OpenSearch 연동 */
export type AssociateSearchTokenMutation = {
  __typename?: 'AssociateSearchTokenMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

export type AttachmentInput = {
  /** 첨부파일 */
  file: Scalars['Upload']
}

export type AuditRequestExcelFileInput = {
  /** 필터 */
  filter?: InputMaybe<Scalars['GenericScalar']>
  /** 정렬방식 */
  orderBy?: InputMaybe<Scalars['String']>
  /** 페이지 정보 */
  pageInfo?: InputMaybe<PageInfoInputType>
  /** 압축파일 암호화 패스워드 */
  password: Scalars['String']
  /** 감사 타입 */
  requestType?: InputMaybe<Scalars['String']>
  /** 템플릿이 필요할 경우 사용 */
  requireTemplate?: InputMaybe<Scalars['Boolean']>
}

/** 요청 스테이터스 금지 */
export type BanRequestMutation = {
  __typename?: 'BanRequestMutation'
  status?: Maybe<StatusType>
}

/** 관리자에 의해서 권한이 금지 */
export type BanRequestsMutation = {
  __typename?: 'BanRequestsMutation'
  statuses?: Maybe<Array<Maybe<StatusType>>>
}

export type BankType = {
  __typename?: 'BankType'
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

/** An enumeration. */
export enum BannerBannerType {
  /** BANNER_HOME_BOTTOM */
  BannerHomeBottom = 'BANNER_HOME_BOTTOM',
  /** BANNER_HOME_MIDDLE */
  BannerHomeMiddle = 'BANNER_HOME_MIDDLE',
  /** BANNER_HOME_MULTI_LINE */
  BannerHomeMultiLine = 'BANNER_HOME_MULTI_LINE',
  /** BANNER_PRODUCT_TOP */
  BannerProductTop = 'BANNER_PRODUCT_TOP',
  /** BANNER_REVIEW */
  BannerReview = 'BANNER_REVIEW',
  /** BANNER_SLIM */
  BannerSlim = 'BANNER_SLIM',
  /** BANNER_TOP_ROLL */
  BannerTopRoll = 'BANNER_TOP_ROLL',
  /** BANNER_TOP_SLIM */
  BannerTopSlim = 'BANNER_TOP_SLIM',
}

/** An enumeration. */
export enum BannerDisplayDevice {
  /** ALL */
  All = 'ALL',
  /** MOBILE */
  Mobile = 'MOBILE',
  /** PC */
  Pc = 'PC',
}

/** An enumeration. */
export enum BannerImageDisplayDevice {
  /** ALL */
  All = 'ALL',
  /** MOBILE */
  Mobile = 'MOBILE',
  /** PC */
  Pc = 'PC',
}

export type BannerImageType = Node & {
  __typename?: 'BannerImageType'
  /** 배너 */
  banner?: Maybe<BannerType>
  /** 노출 디바이스 */
  displayDevice: BannerImageDisplayDevice
  /** The ID of the object. */
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  /** list 대응 index string */
  index?: Maybe<Scalars['String']>
  /** 배너 링크 URL */
  link?: Maybe<Scalars['String']>
}

export type BannerImageTypeConnection = {
  __typename?: 'BannerImageTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BannerImageTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `BannerImageType` and its cursor. */
export type BannerImageTypeEdge = {
  __typename?: 'BannerImageTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<BannerImageType>
}

export type BannerInfoType = {
  __typename?: 'BannerInfoType'
  adId?: Maybe<Scalars['ID']>
  adName?: Maybe<Scalars['String']>
  adSlug?: Maybe<Scalars['String']>
  mobileImage?: Maybe<BannerImageType>
}

export type BannerInput = {
  /** 노출 디바이스 */
  displayDevice?: InputMaybe<Scalars['String']>
  /** 디스플레이 순서 */
  displayOrder?: InputMaybe<Scalars['Int']>
  /** 노출 종료일 */
  endedAt?: InputMaybe<Scalars['DateTime']>
  /** 상시 노출 */
  isAlways?: InputMaybe<Scalars['Boolean']>
  link: Scalars['String']
  mobileImage: Scalars['Upload']
  /** 배너명 */
  name: Scalars['String']
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType?: InputMaybe<Scalars['String']>
  /** 노출 시작일 */
  startedAt?: InputMaybe<Scalars['DateTime']>
  /** 배경색 등 스타일 정보 */
  style?: InputMaybe<Scalars['JSONString']>
}

export type BannerType = Node & {
  __typename?: 'BannerType'
  accessoriesCoverflowBannerInfo?: Maybe<CoverflowBannerInfoType>
  accessoriesRollBannerInfo?: Maybe<SubRollBannerInfoType>
  /** 노출 허용 여부 */
  allowDisplay: Scalars['Boolean']
  apparelCoverflowBannerInfo?: Maybe<CoverflowBannerInfoType>
  apparelRollBannerInfo?: Maybe<SubRollBannerInfoType>
  /** 배너 */
  bannerImages: BannerImageTypeConnection
  /** 배너 종류 */
  bannerType: BannerBannerType
  /** 배너 타입별 추가 정보 */
  bannerTypeInfo?: Maybe<Scalars['JSONString']>
  brandRollBannerInfo?: Maybe<SubRollBannerInfoType>
  coverflowBannerInfo?: Maybe<CoverflowBannerInfoType>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  designersBannerInfo?: Maybe<DesignersBannerInfoType>
  /** 노출 디바이스 */
  displayDevice: BannerDisplayDevice
  /** 디스플레이 순서 */
  displayOrder: Scalars['Int']
  /** 노출 종료일 */
  endedAt?: Maybe<Scalars['DateTime']>
  homeBottomBannerInfo?: Maybe<BannerInfoType>
  homeMiddleBannerInfo?: Maybe<BannerInfoType>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 상시 노출 */
  isAlways: Scalars['Boolean']
  /** 복수 노출 여부 */
  isPluralDisplay: Scalars['Boolean']
  /** 배너명 */
  name: Scalars['String']
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType: AdPetType
  popupBannerInfo?: Maybe<PopupBannerInfoType>
  productTopBannerInfo?: Maybe<BannerInfoType>
  slimBannerInfo?: Maybe<SlimBannerInfoType>
  /** 노출 시작일 */
  startedAt?: Maybe<Scalars['DateTime']>
  /** 배경색 등 스타일 정보 */
  style?: Maybe<Scalars['JSONString']>
  topRollBannerInfo?: Maybe<TopRollBannerInfoType>
  topSlimBannerInfo?: Maybe<SlimBannerInfoType>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  weeklyBestBannerInfo?: Maybe<WeeklyBestBannerInfoType>
}

export type BannerTypeBannerImagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type BannerTypeConnection = {
  __typename?: 'BannerTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BannerTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `BannerType` and its cursor. */
export type BannerTypeEdge = {
  __typename?: 'BannerTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<BannerType>
}

export type BaseImportByExcelInput = {
  excel: Scalars['Upload']
}

/** An enumeration. */
export enum BillingItemBillingType {
  /** BILLING_ITEM_TYPE_CONFIRM_MILEAGE */
  BillingItemTypeConfirmMileage = 'BILLING_ITEM_TYPE_CONFIRM_MILEAGE',
  /** BILLING_ITEM_TYPE_ORDER_ITEM */
  BillingItemTypeOrderItem = 'BILLING_ITEM_TYPE_ORDER_ITEM',
  /** BILLING_ITEM_TYPE_REFUND_ORDER_ITEM */
  BillingItemTypeRefundOrderItem = 'BILLING_ITEM_TYPE_REFUND_ORDER_ITEM',
  /** BILLING_ITEM_TYPE_REFUND_SHIPPING_FEE */
  BillingItemTypeRefundShippingFee = 'BILLING_ITEM_TYPE_REFUND_SHIPPING_FEE',
  /** BILLING_ITEM_TYPE_RETURN_SHIPPING_FEE */
  BillingItemTypeReturnShippingFee = 'BILLING_ITEM_TYPE_RETURN_SHIPPING_FEE',
  /** BILLING_ITEM_TYPE_SHIPPING_FEE */
  BillingItemTypeShippingFee = 'BILLING_ITEM_TYPE_SHIPPING_FEE',
  /** BILLING_ITEM_TYPE_WRITE_REVIEW_MILEAGE */
  BillingItemTypeWriteReviewMileage = 'BILLING_ITEM_TYPE_WRITE_REVIEW_MILEAGE',
}

export type BillingItemType = Node & {
  __typename?: 'BillingItemType'
  /** 상품금액: BILLING_ITEM_TYPE_ORDER_ITEM, 환불상품금액: BILLING_ITEM_TYPE_REFUND_ORDER_ITEM, 배송비: BILLING_ITEM_TYPE_SHIPPING_FEE, 환불배송비: BILLING_ITEM_TYPE_REFUND_SHIPPING_FEE, 반품수수료: BILLING_ITEM_TYPE_RETURN_SHIPPING_FEE, 리뷰 작성 적립금: BILLING_ITEM_TYPE_WRITE_REVIEW_MILEAGE, 구매 확정 적립금: BILLING_ITEM_TYPE_CONFIRM_MILEAGE */
  billingType: BillingItemBillingType
  /** 쿠폰 할인액, billling_type이 OrderItem 관련일 때 OrderItem의 상품 쿠폰 할인액, Shipping 관련일 때 배송비 쿠폰 할인액 */
  couponDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** billling_type이 OrderItem 관련일 때 상품 기본 할인액, Shipping 관련일 때 기본 배송비 할인액 (무료배송용) */
  discountPrice?: Maybe<Scalars['Decimal']>
  /** billling_type이 Shipping 관련일 때 도서 산간비 */
  extraPrice?: Maybe<Scalars['Decimal']>
  /** fitpet 수수료 금액 */
  fitpetFee?: Maybe<Scalars['Decimal']>
  /** 핏펫 수수료 */
  fitpetFeeRate?: Maybe<Scalars['Float']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 셀러 정산 완료 여부 */
  isSellerBilled?: Maybe<Scalars['Boolean']>
  /** 멤버쉽 할인액 */
  membershipDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 메모 */
  memo?: Maybe<Scalars['String']>
  /** 적립금 할인액, billling_type이 OrderItem 관련일 때 OrderItem의 상품 적립금 할인액, Shipping 관련일 때 배송비 적립금 할인액 */
  mileageDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 적립율, 0.xx로 표기 */
  mileageRewardRate: Scalars['Float']
  /** 금액 비율로 쪼개진 주문 쿠폰 할인액, billling_type이 OrderItem 관련일 때만 사용 */
  orderCouponDiscountPrice: Scalars['Decimal']
  /** 정산 대상 주문 아이템 */
  orderItem?: Maybe<OrderItemType>
  /** billling_type이 OrderItem 관련일 때 상품 원가, Shipping 관련일 때 기본 배송비 */
  originalPrice: Scalars['Decimal']
  /** {('LPAY', 'LPAY'), ('KAKAOPAY', '카카오페이'), ('SAMSUNG', '삼성페이'), ('ADMIN', '관리자 결제처리'), ('HAPPYMONEY', '해피머니'), ('POINT', '포인트 결제'), ('TOSSPAY', '토스간편결제'), ('SSGPAY', 'SSG페이'), ('PHONE', '휴대폰소액결제'), ('PAYCO', '페이코'), ('ZERO_PRICE', '할인 등으로 0원제 결제'), ('BOOKNLIFE', '도서문화상품권'), ('CARD', '신용카드'), ('ETC', '기타 결제 (마이그레이션 등)'), ('SMARTCULTURE', '스마트문상'), ('VBANK', '가상계좌'), ('KBPAY', '케이비페이'), ('KPAY', 'KPay'), ('TRANS', '실시간계좌이체'), ('NAVERPAY', '네이버페이'), ('CULTURELAND', '문화상품권')} */
  payMethodType?: Maybe<Scalars['String']>
  /** 이벤트 할인액, billling_type이 OrderItem 관련일 때만 사용 */
  productPromotionDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 핏펫 판매촉진비 */
  promotionAmount?: Maybe<Scalars['Decimal']>
  /** 주문 갯수 */
  quantity: Scalars['Int']
  /** 배송비 및 환불 수수료 정보 */
  refundShippingInfo?: Maybe<RefundShippingInfoType>
  /** 정산 대상 반품 배송 정보 */
  returnInfo?: Maybe<ReturnInfoType>
  /** 셀러 정산 */
  sellerBilling?: Maybe<SellerBillingType>
  /** 셀러 수수료 금액 */
  sellerFee?: Maybe<Scalars['Decimal']>
  /** 셀러 판매촉진비 */
  sellerPromotionAmount?: Maybe<Scalars['Decimal']>
  /** 셀러 매출 */
  sellerSupplyAmount?: Maybe<Scalars['Decimal']>
  /** 정산 대상 배송 정보 */
  shipping?: Maybe<ShippingType>
  /** 핏펫 공급대가 */
  supplyAmount?: Maybe<Scalars['Decimal']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type BillingItemTypeConnection = {
  __typename?: 'BillingItemTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BillingItemTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `BillingItemType` and its cursor. */
export type BillingItemTypeEdge = {
  __typename?: 'BillingItemTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<BillingItemType>
}

export type BlockUserInput = {
  /** 차단 여부 */
  isBlock: Scalars['Boolean']
}

/** 사용자 차단/차단 해제 */
export type BlockUserMutation = {
  __typename?: 'BlockUserMutation'
  user?: Maybe<UserType>
}

export type BrandByFitpetAdminInput = {
  /** 배경 이미지 */
  backgroundImage?: InputMaybe<Scalars['ImageUpload']>
  /** 브랜드코드 */
  code?: InputMaybe<Scalars['String']>
  /** 브랜드 홈 메인 이미지 */
  homeImage?: InputMaybe<Scalars['ImageUpload']>
  /** 브랜드 목록 이미지 */
  listImage?: InputMaybe<Scalars['ImageUpload']>
  /** 브랜드명 */
  name: Scalars['String']
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType?: InputMaybe<Scalars['String']>
  /** 등록할 셀러 */
  seller?: InputMaybe<Scalars['ID']>
}

export type BrandBySellerInput = {
  /** 배경 이미지 */
  backgroundImage?: InputMaybe<Scalars['ImageUpload']>
  /** 브랜드코드 */
  code?: InputMaybe<Scalars['String']>
  /** 브랜드 홈 메인 이미지 */
  homeImage?: InputMaybe<Scalars['ImageUpload']>
  /** 브랜드 목록 이미지 */
  listImage?: InputMaybe<Scalars['ImageUpload']>
  /** 브랜드명 */
  name: Scalars['String']
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType?: InputMaybe<Scalars['String']>
  /** 요청 셀러 */
  seller: Scalars['ID']
}

export type BrandType = Node & {
  __typename?: 'BrandType'
  /** 승인일 */
  approvedAt?: Maybe<Scalars['DateTime']>
  /** 배경 이미지 */
  backgroundImage?: Maybe<Scalars['String']>
  /** 브랜드 */
  brandProducts: ProductTypeConnection
  /** 브랜드코드 */
  code?: Maybe<Scalars['String']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 디스플레이 순서 */
  displayOrder: Scalars['Int']
  /** 쿠폰 제외 브랜드 범위 */
  excludedBrandsCouponGroups: CouponGroupTypeConnection
  /** 브랜드 홈 메인 이미지 */
  homeImage?: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 쿠폰 포함 브랜드 범위 */
  includedBrandsCouponGroups: CouponGroupTypeConnection
  /** 사용자가 찜한 여부 */
  isWished?: Maybe<Scalars['Boolean']>
  /** 브랜드 목록 이미지 */
  listImage?: Maybe<Scalars['String']>
  /** 브랜드명 */
  name: Scalars['String']
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType: AdPetType
  /** 브랜드에 속한 상품의 개수 */
  productCount?: Maybe<Scalars['Int']>
  /** 최종등록신청일 */
  requestedAt?: Maybe<Scalars['DateTime']>
  sellers?: Maybe<SellerTypeConnection>
  /** 브랜드 상태, REQUEST(요청)/APPROVED(승인)/REJECTED(거부)/SUSPEND(중단) */
  status?: Maybe<StatusType>
  /** 정보 변경 요청 승인일 */
  updateApprovedAt?: Maybe<Scalars['DateTime']>
  updateInfo?: Maybe<UpdatedBrandType>
  /** 수정 상태, REQUEST(요청)/APPROVED(승인)/REJECTED(거부) */
  updateStatus?: Maybe<StatusType>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  wishedBrandUsers?: Maybe<UserTypeConnection>
  /** 사용자들이 찜한 갯수 */
  wishedCount?: Maybe<Scalars['Int']>
}

export type BrandTypeBrandProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type BrandTypeExcludedBrandsCouponGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroupStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  coverageOsType?: InputMaybe<Scalars['String']>
  coverageType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  eventType?: InputMaybe<Scalars['String']>
  excludeIssueType?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDiscountVisibleBeforeDownload?: InputMaybe<Scalars['Boolean']>
  isIssuing?: InputMaybe<Scalars['Boolean']>
  issueStatus?: InputMaybe<Scalars['String']>
  issueType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  usingEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  usingPeriod?: InputMaybe<Scalars['Float']>
  usingStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingStartedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type BrandTypeIncludedBrandsCouponGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroupStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  coverageOsType?: InputMaybe<Scalars['String']>
  coverageType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  eventType?: InputMaybe<Scalars['String']>
  excludeIssueType?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDiscountVisibleBeforeDownload?: InputMaybe<Scalars['Boolean']>
  isIssuing?: InputMaybe<Scalars['Boolean']>
  issueStatus?: InputMaybe<Scalars['String']>
  issueType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  usingEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  usingPeriod?: InputMaybe<Scalars['Float']>
  usingStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingStartedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type BrandTypeSellersArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  chiefManagerName?: InputMaybe<Scalars['String']>
  chiefManagerUsername?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeSellers?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  followerInfluencer?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  shopName?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
}

export type BrandTypeWishedBrandUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  agreeMarketing?: InputMaybe<Scalars['Boolean']>
  agreeMarketingHospital?: InputMaybe<Scalars['Boolean']>
  agreeMarketingPlay?: InputMaybe<Scalars['Boolean']>
  agreeNightMarketing?: InputMaybe<Scalars['Boolean']>
  agreeServicePushAllowed?: InputMaybe<Scalars['Boolean']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  dateJoinedAfter?: InputMaybe<Scalars['DateTime']>
  dateJoinedBefore?: InputMaybe<Scalars['DateTime']>
  email?: InputMaybe<Scalars['String']>
  excludeUsers?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  grades?: InputMaybe<Scalars['String']>
  inactivatedAtAfter?: InputMaybe<Scalars['DateTime']>
  inactivatedAtBefore?: InputMaybe<Scalars['DateTime']>
  inactiveUserEmail?: InputMaybe<Scalars['String']>
  inactiveUserMobileNumber?: InputMaybe<Scalars['String']>
  inactiveUserName?: InputMaybe<Scalars['String']>
  inactiveUserUsername?: InputMaybe<Scalars['String']>
  isBuyer?: InputMaybe<Scalars['Boolean']>
  isInfluencer?: InputMaybe<Scalars['Boolean']>
  isLeft?: InputMaybe<Scalars['Boolean']>
  isSeller?: InputMaybe<Scalars['Boolean']>
  isStaff?: InputMaybe<Scalars['Boolean']>
  joinedPlatform?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  lastLoginAfter?: InputMaybe<Scalars['DateTime']>
  lastLoginBefore?: InputMaybe<Scalars['DateTime']>
  leftAtAfter?: InputMaybe<Scalars['DateTime']>
  leftAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipRefundStatus?: InputMaybe<Scalars['String']>
  membershipStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipStartedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipStatus?: InputMaybe<Scalars['String']>
  mileagePointLess?: InputMaybe<Scalars['Float']>
  mileagePointMore?: InputMaybe<Scalars['Float']>
  mobileNumber?: InputMaybe<Scalars['String']>
  mobileNumbers?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderAmountLess?: InputMaybe<Scalars['Float']>
  orderAmountMore?: InputMaybe<Scalars['Float']>
  orderBy?: InputMaybe<Scalars['String']>
  orderCountLess?: InputMaybe<Scalars['Float']>
  orderCountMore?: InputMaybe<Scalars['Float']>
  requiredUsername?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  socialType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userNumber?: InputMaybe<Scalars['String']>
  userStatus?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type BrandTypeConnection = {
  __typename?: 'BrandTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `BrandType` and its cursor. */
export type BrandTypeEdge = {
  __typename?: 'BrandTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<BrandType>
}

export type BundledProductConnection = {
  __typename?: 'BundledProductConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BundledProductEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `BundledProduct` and its cursor. */
export type BundledProductEdge = {
  __typename?: 'BundledProductEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProductType>
}

export type CacheProductType = {
  __typename?: 'CacheProductType'
  edges?: Maybe<Scalars['JSONString']>
  pageInfo?: Maybe<Scalars['JSONString']>
  totalCount?: Maybe<Scalars['Int']>
}

export type CachedBannerImageType = {
  __typename?: 'CachedBannerImageType'
  displayDevice?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  index?: Maybe<Scalars['Int']>
  link?: Maybe<Scalars['String']>
  rawId?: Maybe<Scalars['Int']>
  thumbnail?: Maybe<Scalars['String']>
}

export type CachedProductMainImage = {
  __typename?: 'CachedProductMainImage'
  id?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
}

/** 엑셀 파일로 쿠폰 수동 생성 취소 */
export type CancelIssuingManualCoupons = {
  __typename?: 'CancelIssuingManualCoupons'
  excelImportRequest?: Maybe<ExcelImportRequestType>
}

export type CancelOrderItemInput = {
  orderItem: Scalars['ID']
}

export type CancelOrderItemsInput = {
  /** 취소 안내 메시지 */
  cancelGuideMessage?: InputMaybe<Scalars['String']>
  orderItems: Array<InputMaybe<CancelOrderItemInput>>
  /** cancel_request_type이 ETC일 때 기타 사유 입력필드 */
  requestMessage?: InputMaybe<Scalars['String']>
  /** 단순 변심 (CHANGE_MIND), 상품 추가 또는 옵션 변경 후 재주문 (MISCHOICE_PRODUCT_OPTION), 상품 불량 및 파손 (DEFECTIVE_PRODUCT), 오배송 (WRONG_DELIVERY), 상품이 설명과 다름 (DIFFERENT_PRODUCT_INFO), 서비스 불만족 (DISSATISFACTION), 가상계좌 미입금 자동취소(AUTO_VBANK), 가상계좌 미입금 취소(UNCONFIRMED_VBANK), 기타 (ETC), */
  requestType?: InputMaybe<Scalars['String']>
}

/** 관리자/셀러/사용자 주문 취소 */
export type CancelOrderItemsMutation = {
  __typename?: 'CancelOrderItemsMutation'
  orderItems?: Maybe<Array<Maybe<OrderItemType>>>
}

export type CancelReasonType = {
  __typename?: 'CancelReasonType'
  isUserVisible?: Maybe<Scalars['Boolean']>
  requestMessage?: Maybe<Scalars['String']>
  requestType?: Maybe<Scalars['String']>
}

/** An enumeration. */
export enum CancelTransactionCancelTransactionStatus {
  /** CONFIRMED */
  Confirmed = 'CONFIRMED',
  /** READY */
  Ready = 'READY',
}

export type CancelTransactionType = Node & {
  __typename?: 'CancelTransactionType'
  /** 예금주명 */
  accountHolderName?: Maybe<Scalars['String']>
  /** 계좌번호 */
  accountNumber?: Maybe<Scalars['String']>
  /** 은행 코드 */
  bankCode?: Maybe<Scalars['String']>
  /** 은행 이름 */
  bankName?: Maybe<Scalars['String']>
  /** 취소 요청 금액 */
  cancelAmount?: Maybe<Scalars['Decimal']>
  /** READY(대기), CONFIRMED(취소승인됨) */
  cancelTransactionStatus: CancelTransactionCancelTransactionStatus
  /** 취소 가능 잔액 (취소 요청 전 금액) */
  cancelableAmount?: Maybe<Scalars['Decimal']>
  /** 취소 승인일시 */
  canceledAt?: Maybe<Scalars['DateTime']>
  canceledTransactionOrder?: Maybe<MembershipHistoryType>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 취소 오류코드 */
  errorCode?: Maybe<Scalars['String']>
  /** 취소 오류 메세지 */
  errorMessage?: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 멤버쉽 히스토리 */
  membershipHistory?: Maybe<MembershipHistoryType>
  /** 취소 요청 사유 */
  message: Scalars['String']
  /** 취소 대상 Order */
  order?: Maybe<OrderType>
  /** 취소 대상 OrderItem */
  orderItem?: Maybe<OrderItemType>
  /** 취소 후 잔액 */
  remainAmount?: Maybe<Scalars['Decimal']>
  /** 결제 취소 응답 데이터 */
  responseData?: Maybe<Scalars['JSONString']>
  /** 취소할 원 트랜잭션 */
  transaction?: Maybe<TransactionType>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type CancelTransactionTypeConnection = {
  __typename?: 'CancelTransactionTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CancelTransactionTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `CancelTransactionType` and its cursor. */
export type CancelTransactionTypeEdge = {
  __typename?: 'CancelTransactionTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CancelTransactionType>
}

export type CartItemHistoryType = Node & {
  __typename?: 'CartItemHistoryType'
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 상품옵션 */
  productOption?: Maybe<ProductOptionType>
  /** 상품 프로모션 */
  productPromotion?: Maybe<ProductPromotionType>
  /** 수량 */
  quantity: Scalars['Int']
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 회원 */
  user?: Maybe<UserType>
}

export type CartItemHistoryTypeConnection = {
  __typename?: 'CartItemHistoryTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CartItemHistoryTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `CartItemHistoryType` and its cursor. */
export type CartItemHistoryTypeEdge = {
  __typename?: 'CartItemHistoryTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CartItemHistoryType>
}

export type CartItemType = Node & {
  __typename?: 'CartItemType'
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 상품옵션 */
  productOption?: Maybe<ProductOptionType>
  /** 카트에 담았을 당시 상품 가격 */
  productPrice?: Maybe<Scalars['Decimal']>
  /** 상품 프로모션 */
  productPromotion?: Maybe<ProductPromotionType>
  /** 수량 */
  quantity: Scalars['Int']
  /** 카트에 담았을 당시 상품 가격(상품 쿠폰 할인가 반영) */
  salePriceAfterCoupon?: Maybe<Scalars['Decimal']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 회원 */
  user?: Maybe<UserType>
}

export type CartItemTypeConnection = {
  __typename?: 'CartItemTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CartItemTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `CartItemType` and its cursor. */
export type CartItemTypeEdge = {
  __typename?: 'CartItemTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CartItemType>
}

export type CategoryImageInput = {
  listImage: Scalars['ImageUpload']
}

export type CategoryInput = {
  /** 설명 */
  description?: InputMaybe<Scalars['String']>
  /** 노출여부 */
  isVisible?: InputMaybe<Scalars['Boolean']>
  /** 카테고리명 */
  name?: InputMaybe<Scalars['String']>
  parentCategory?: InputMaybe<Scalars['ID']>
  /** 슬러그 */
  slug?: InputMaybe<Scalars['String']>
}

export type CategorySlugInput = {
  slug: Scalars['String']
}

export type CategoryType = Node & {
  __typename?: 'CategoryType'
  categoryChildren?: Maybe<CategoryTypeConnection>
  /** 카테고리 */
  categoryGnbs: GnbTypeConnection
  /** 카테고리 */
  categoryProductCategories: ProductCategoryTypeConnection
  /** 카테고리코드 */
  code?: Maybe<Scalars['String']>
  coupons?: Maybe<CouponTypeConnection>
  /** 뎁스 */
  depth?: Maybe<Scalars['Int']>
  /** 설명 */
  description?: Maybe<Scalars['String']>
  /** 디스플레이 순서 */
  displayOrder: Scalars['Int']
  /** 쿠폰 제외 카테고리 범위 */
  excludedCategoriesCouponGroups: CouponGroupTypeConnection
  /** The ID of the object. */
  id: Scalars['ID']
  /** 카테고리에 포함된 상품을 가지고 있는 브랜드 */
  includedBrands?: Maybe<BrandTypeConnection>
  /** 쿠폰 포함 카테고리 범위 */
  includedCategoriesCouponGroups: CouponGroupTypeConnection
  /** 노출여부 */
  isVisible: Scalars['Boolean']
  /** 카테고리 목록 이미지 */
  listImage?: Maybe<Scalars['String']>
  /** 카테고리명 */
  name: Scalars['String']
  /** 부모 카테고리 */
  parentCategory?: Maybe<CategoryType>
  products: ProductTypeConnection
  /** 슬러그 */
  slug?: Maybe<Scalars['String']>
  subCategories?: Maybe<CategoryTypeConnection>
  /** 하위 카테고리 포함한 제품의 수 */
  subProductCount?: Maybe<Scalars['Int']>
  /** 상위카테고리>하위카테고리 텍스트 */
  text?: Maybe<Scalars['String']>
}

export type CategoryTypeCategoryChildrenArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brand?: InputMaybe<Scalars['String']>
  excludeCategories?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  hasImage?: InputMaybe<Scalars['Boolean']>
  isRootCategory?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  parentCategory?: InputMaybe<Scalars['String']>
}

export type CategoryTypeCategoryGnbsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type CategoryTypeCategoryProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type CategoryTypeCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type CategoryTypeExcludedCategoriesCouponGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroupStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  coverageOsType?: InputMaybe<Scalars['String']>
  coverageType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  eventType?: InputMaybe<Scalars['String']>
  excludeIssueType?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDiscountVisibleBeforeDownload?: InputMaybe<Scalars['Boolean']>
  isIssuing?: InputMaybe<Scalars['Boolean']>
  issueStatus?: InputMaybe<Scalars['String']>
  issueType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  usingEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  usingPeriod?: InputMaybe<Scalars['Float']>
  usingStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingStartedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type CategoryTypeIncludedBrandsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type CategoryTypeIncludedCategoriesCouponGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroupStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  coverageOsType?: InputMaybe<Scalars['String']>
  coverageType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  eventType?: InputMaybe<Scalars['String']>
  excludeIssueType?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDiscountVisibleBeforeDownload?: InputMaybe<Scalars['Boolean']>
  isIssuing?: InputMaybe<Scalars['Boolean']>
  issueStatus?: InputMaybe<Scalars['String']>
  issueType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  usingEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  usingPeriod?: InputMaybe<Scalars['Float']>
  usingStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingStartedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type CategoryTypeProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type CategoryTypeSubCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brand?: InputMaybe<Scalars['String']>
  excludeCategories?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  hasImage?: InputMaybe<Scalars['Boolean']>
  isRootCategory?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  parentCategory?: InputMaybe<Scalars['String']>
}

export type CategoryTypeConnection = {
  __typename?: 'CategoryTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoryTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `CategoryType` and its cursor. */
export type CategoryTypeEdge = {
  __typename?: 'CategoryTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CategoryType>
}

/** 통합매니저 변경 */
export type ChangeChiefSellerManagerMutation = {
  __typename?: 'ChangeChiefSellerManagerMutation'
  sellerUser?: Maybe<SellerUserType>
}

export type CheckEmailDuplicatedInput = {
  email: Scalars['String']
}

/** 아이디 중복 여부 확인 */
export type CheckEmailDuplicatedMutation = {
  __typename?: 'CheckEmailDuplicatedMutation'
  isDuplicated?: Maybe<Scalars['Boolean']>
}

/** 발주 확인 */
export type CheckOrderItemsMutation = {
  __typename?: 'CheckOrderItemsMutation'
  orderItems?: Maybe<Array<Maybe<OrderItemType>>>
}

export type CheckTokenAssociationType = {
  __typename?: 'CheckTokenAssociationType'
  text?: Maybe<Scalars['String']>
  tokenType?: Maybe<Scalars['String']>
}

/** 결제 승인 */
export type CheckUnconfirmedTransactionMutation = {
  __typename?: 'CheckUnconfirmedTransactionMutation'
  isSuccess?: Maybe<Scalars['Boolean']>
  transaction?: Maybe<TransactionType>
}

/** 배너 캐시 초기화 */
export type ClearBannerCacheMutation = {
  __typename?: 'ClearBannerCacheMutation'
  result?: Maybe<Scalars['String']>
}

/** Best 캐시 초기화 */
export type ClearBestCacheMutation = {
  __typename?: 'ClearBestCacheMutation'
  result?: Maybe<Scalars['String']>
}

/** 홈 캐시 초기화 */
export type ClearHomeCacheMutation = {
  __typename?: 'ClearHomeCacheMutation'
  result?: Maybe<Scalars['String']>
}

/** An enumeration. */
export enum CommerceUserPayMethodType {
  /** 관리자 결제처리 */
  Admin = 'ADMIN',
  /** 도서문화상품권 */
  Booknlife = 'BOOKNLIFE',
  /** 신용카드 */
  Card = 'CARD',
  /** 문화상품권 */
  Cultureland = 'CULTURELAND',
  /** 기타 결제 (마이그레이션 등) */
  Etc = 'ETC',
  /** 해피머니 */
  Happymoney = 'HAPPYMONEY',
  /** 카카오페이 */
  Kakaopay = 'KAKAOPAY',
  /** 케이비페이 */
  Kbpay = 'KBPAY',
  /** KPay */
  Kpay = 'KPAY',
  /** LPAY */
  Lpay = 'LPAY',
  /** 네이버페이 */
  Naverpay = 'NAVERPAY',
  /** 페이코 */
  Payco = 'PAYCO',
  /** 휴대폰소액결제 */
  Phone = 'PHONE',
  /** 포인트 결제 */
  Point = 'POINT',
  /** 삼성페이 */
  Samsung = 'SAMSUNG',
  /** 스마트문상 */
  Smartculture = 'SMARTCULTURE',
  /** SSG페이 */
  Ssgpay = 'SSGPAY',
  /** 토스간편결제 */
  Tosspay = 'TOSSPAY',
  /** 실시간계좌이체 */
  Trans = 'TRANS',
  /** 가상계좌 */
  Vbank = 'VBANK',
  /** 할인 등으로 0원제 결제 */
  ZeroPrice = 'ZERO_PRICE',
}

export type CommerceUserType = Node & {
  __typename?: 'CommerceUserType'
  /** The ID of the object. */
  id: Scalars['ID']
  /** 마일리지 전액 사용 여부 */
  isUseAllMileage: Scalars['Boolean']
  /** 마지막 결제 수단 */
  payMethodType: CommerceUserPayMethodType
  /** 사용자 */
  user?: Maybe<UserType>
}

/** 배송 완료 */
export type CompleteShippingOrderItemsMutation = {
  __typename?: 'CompleteShippingOrderItemsMutation'
  orderItems?: Maybe<Array<Maybe<OrderItemType>>>
}

export type ConfirmBillingInput = {
  isConfirm: Scalars['Boolean']
}

/** 수동 환불 */
export type ConfirmCancelOrderItemMutation = {
  __typename?: 'ConfirmCancelOrderItemMutation'
  orderItem?: Maybe<OrderItemType>
}

export type ConfirmExchangeReturnInfosInput = {
  /** 교환 배송비 (반품배송비 + 신규배송비) */
  exchangeShippingFee?: InputMaybe<Scalars['Decimal']>
  /** request_type이 ETC일 때 기타 사유 입력필드 */
  requestMessage?: InputMaybe<Scalars['String']>
  /** 상품불량 및 파손 (DEFECTIVE_PRODUCT), 오배송 (WRONG_DELIVERY), 배송지연 (DELAY_DELIVERY), 상품 정보 상이(DIFFERENT_PRODUCT_INFO), 서비스 및 상품 불만족(DISSATISFACTION), 단순 변심 (CHANGE_MIND), 상품 옵션을 잘못 선택함 (MISCHOICE_PRODUCT_OPTION), 기타 (ETC) */
  requestType: Scalars['String']
  /** 책임 주체, SELLER/CUSTOMER */
  responsibility: Scalars['String']
  returnInfos: Array<InputMaybe<ReturnInfoInput>>
}

/** 교환 상품 수거 완료 */
export type ConfirmExchangeReturnInfosMutation = {
  __typename?: 'ConfirmExchangeReturnInfosMutation'
  returnInfos?: Maybe<Array<Maybe<ReturnInfoType>>>
}

/** 구매 확정 */
export type ConfirmOrderItemMutation = {
  __typename?: 'ConfirmOrderItemMutation'
  orderItem?: Maybe<OrderItemType>
}

/** 멤버쉽 수동 환불 */
export type ConfirmRefundMembershipMutation = {
  __typename?: 'ConfirmRefundMembershipMutation'
  membershipHistory?: Maybe<MembershipHistoryType>
}

export type ConfirmReturnInfosInput = {
  /** request_type이 ETC일 때 기타 사유 입력필드 */
  requestMessage?: InputMaybe<Scalars['String']>
  /** 상품불량 및 파손 (DEFECTIVE_PRODUCT), 오배송 (WRONG_DELIVERY), 배송지연 (DELAY_DELIVERY), 상품 정보 상이(DIFFERENT_PRODUCT_INFO), 서비스 및 상품 불만족(DISSATISFACTION), 단순 변심 (CHANGE_MIND), 상품 옵션을 잘못 선택함 (MISCHOICE_PRODUCT_OPTION), 기타 (ETC) */
  requestType?: InputMaybe<Scalars['String']>
  /** 책임 주체, SELLER/CUSTOMER */
  responsibility?: InputMaybe<Scalars['String']>
  returnInfos: Array<InputMaybe<ReturnInfoInput>>
  /** 반품배송비 */
  returnShippingFee?: InputMaybe<Scalars['Decimal']>
}

/** 반품 확정 */
export type ConfirmReturnInfosMutation = {
  __typename?: 'ConfirmReturnInfosMutation'
  returnInfos?: Maybe<Array<Maybe<ReturnInfoType>>>
}

/** 셀러 월정산 확인 */
export type ConfirmSellerBillingMutation = {
  __typename?: 'ConfirmSellerBillingMutation'
  sellerBilling?: Maybe<SellerBillingType>
}

export type ConfirmUserCertNumberInput = {
  /** 인증번호 */
  confirmNumber: Scalars['String']
}

/** 사용자 인증 번호 확인 */
export type ConfirmUserCertNumberMutation = {
  __typename?: 'ConfirmUserCertNumberMutation'
  userCertification?: Maybe<UserCertificationType>
  userInfo?: Maybe<UserInfoType>
}

/** 상품 복사 - 관리자, 셀러 */
export type CopyProductMutation = {
  __typename?: 'CopyProductMutation'
  product?: Maybe<ProductType>
}

export type CouponByProductConnection = {
  __typename?: 'CouponByProductConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CouponByProductEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CouponByProduct` and its cursor. */
export type CouponByProductEdge = {
  __typename?: 'CouponByProductEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CouponByProductType>
}

export type CouponByProductType = {
  __typename?: 'CouponByProductType'
  coupon?: Maybe<CouponType>
  products?: Maybe<ProductTypeConnection>
}

export type CouponByProductTypeProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

/** An enumeration. */
export enum CouponGroupCannotIssueReasonEnum {
  HasCoupon = 'HAS_COUPON',
  OnlyFirstOrder = 'ONLY_FIRST_ORDER',
  OnlyMembership = 'ONLY_MEMBERSHIP',
}

/** An enumeration. */
export enum CouponGroupCouponGroupStatus {
  /** 삭제 */
  Deleted = 'DELETED',
  /** 등록 */
  Registered = 'REGISTERED',
  /** 등록중 */
  Registering = 'REGISTERING',
  /** 중지 */
  Suspended = 'SUSPENDED',
}

/** An enumeration. */
export enum CouponGroupCouponType {
  /** 주문쿠폰 */
  Order = 'ORDER',
  /** 상품쿠폰 */
  Product = 'PRODUCT',
  /** 배송비쿠폰 */
  Shipping = 'SHIPPING',
}

/** An enumeration. */
export enum CouponGroupCoverageOsType {
  /** 전체 */
  All = 'ALL',
  /** 앱 */
  App = 'APP',
  /** 웹 */
  Web = 'WEB',
}

/** An enumeration. */
export enum CouponGroupCoverageType {
  /** 모든 상품 */
  All = 'ALL',
  /** 기본값 */
  None = 'NONE',
  /** 특정 브랜드 */
  SpecificBrand = 'SPECIFIC_BRAND',
  /** 특정 카테고리 */
  SpecificCategory = 'SPECIFIC_CATEGORY',
  /** 특정 상품 */
  SpecificProduct = 'SPECIFIC_PRODUCT',
}

/** An enumeration. */
export enum CouponGroupDiscountType {
  /** 정률 할인 */
  Amount = 'AMOUNT',
  /** 정액 할인 */
  Rate = 'RATE',
}

/** An enumeration. */
export enum CouponGroupEventType {
  /** 마케팅 수신 동의 */
  AgreeMarketing = 'AGREE_MARKETING',
  /** 생일 축하 */
  Birth = 'BIRTH',
  /** DB 손해보험 멤버십 쿠폰 */
  DbInsuranceMembership = 'DB_INSURANCE_MEMBERSHIP',
  /** 첫 구매 */
  FirstOrder = 'FIRST_ORDER',
  /** 내 추천 코드로 회원이 가입 */
  Invite = 'INVITE',
  /** 추천 코드로 회원 가입 */
  Invited = 'INVITED',
  /** 추천 코드로 가입 후 첫 구매 확정 */
  InvitedFirstOrder = 'INVITED_FIRST_ORDER',
  /** 내 추천 코드로 가입한 회원이 첫 구매 확정 */
  InviteFirstOrder = 'INVITE_FIRST_ORDER',
  None = 'NONE',
  /** 회원 가입 축하 */
  SignUp = 'SIGN_UP',
  /** 구매 감사 */
  ThanksOrder = 'THANKS_ORDER',
  /** 휴면 해제 감사 */
  WakeUp = 'WAKE_UP',
}

export type CouponGroupInput = {
  /** 프로모션 */
  ad?: InputMaybe<Scalars['ID']>
  /** 주문쿠폰과 상품쿠폰 쿠폰 중복 사용 가능 여부 */
  canConjunctUse: Scalars['Boolean']
  /** 첫 주문 구매시에만 사용 가능한 쿠폰 */
  canUseOnlyFirstOrder?: InputMaybe<Scalars['Boolean']>
  /** 고양이 버튼 URL */
  catUrl?: InputMaybe<Scalars['String']>
  /** 페이퍼쿠폰 인증번호, null -> 회원별로 인증번호, not null -> 하나의 인증번호 */
  code?: InputMaybe<Scalars['String']>
  /** 쿠폰 사용 조건 */
  condition?: InputMaybe<Scalars['String']>
  /** 페이퍼 쿠폰 자동생성 수량 */
  couponQuantity?: InputMaybe<Scalars['Int']>
  /** 쿠폰 타입, PRODUCT: 상품, ORDER: 주문, SHIPPING: 배송비 */
  couponType: Scalars['String']
  /** 혜택 적용 OS 범위, ALL: 전체, WEB: 웹, APP: 앱 */
  coverageOsType: Scalars['String']
  /** 혜택 적용 범위, NONE: 기본값, ALL: 전상품/SPECIFIC_PRODUCT: 특정상품/SPECIFIC_CATEGORY: 특정카테고리/SPECIFIC_BRAND: 특정브랜드 */
  coverageType: Scalars['String']
  /** 쿠폰에 대한 설명 */
  description?: InputMaybe<Scalars['String']>
  /** 할인 금액(정액) */
  discountAmount?: InputMaybe<Scalars['Decimal']>
  /** 할인 비율(정률), 0.xx로 기입 */
  discountRate?: InputMaybe<Scalars['Float']>
  /** 할인방식, RATE: 정률할인/AMOUNT: 정액할인: 정액 할인, 정률 할인 */
  discountType?: InputMaybe<Scalars['String']>
  /** 강아지 버튼 URL */
  dogUrl?: InputMaybe<Scalars['String']>
  /** 이벤트 타입, NONE: 기본값/FIRST_ORDER: 첫주문/THANKS_ORDER: 주문감사/BIRTH: 생일자/SIGN_UP: 회원가입/WAKE_UP: 휴면회원/INVITE: 내 추천 코드로 회원이 가입/INVITED: 추천 코드로 회원 가입/DB_INSURANCE_MEMBERSHIP: DB 손해보험 멤버십 무료 가입 쿠폰(기존 1개월 + 추가 2개월), 기존 가입자는 2개월 /INVITE_FIRST_ORDER: 내 추천 코드로 가입한 회원이 첫 구매 확정/INVITED_FIRST_ORDER: 추천 코드로 가입 후 첫 구매 확정 */
  eventType?: InputMaybe<Scalars['String']>
  /** 쿠폰 제외 브랜드 범위 */
  excludedBrands?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  /** 쿠폰 제외 카테고리 범위 */
  excludedCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  /** 쿠폰 제외 상품 범위 */
  excludedProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  /** 쿠폰 종료 일시 */
  expiredDatetimeText?: InputMaybe<Scalars['String']>
  /** 쿠폰 사용 안내 */
  guide?: InputMaybe<Scalars['String']>
  /** 첫 구매 쿠폰과 중복 지급 */
  hasDuplicateIssueFirst?: InputMaybe<Scalars['Boolean']>
  /** 이벤트 SMS 발송 여부 */
  hasEventSms?: InputMaybe<Scalars['Boolean']>
  /** 쿠폰 포함 브랜드 범위 */
  includedBrands?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  /** 쿠폰 포함 카테고리 범위 */
  includedCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  /** 쿠폰 포함 상품 범위 */
  includedProducts?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  /** 할인정보 다운로드 전 노출 여부, 오직 상품쿠폰 다운로드 타입만 */
  isDiscountVisibleBeforeDownload?: InputMaybe<Scalars['Boolean']>
  /** 발급 종료일 */
  issueEndedAt?: InputMaybe<Scalars['String']>
  /** 발급 시작일 */
  issueStartedAt?: InputMaybe<Scalars['String']>
  /** 발급방식, AUTO: 자동/MANUAL: 수동/DOWNLOAD: 다운로드/GRADE: 등급용쿠폰/PAPER: 페이퍼쿠폰/PAPER_SKT: SKT페이퍼쿠폰/PAPER_SHINHAN_CARD: 페터이 신한카드 */
  issueType: Scalars['String']
  /** 카카오 메세지 발송 시각 */
  kakaoSentTime?: InputMaybe<Scalars['DateTime']>
  /** 최대 할인 금액 */
  maxDiscountAmount?: InputMaybe<Scalars['Decimal']>
  /** 최대 재발급 건수 */
  maxDuplicateIssueCount: Scalars['Int']
  /** 최대 발급 건수 */
  maxIssueCount?: InputMaybe<Scalars['Int']>
  /** 최소 주문 금액 */
  minOrderAmount?: InputMaybe<Scalars['Decimal']>
  /** 쿠폰명 */
  name: Scalars['String']
  /** 쿠폰 발급 사유 */
  reason?: InputMaybe<Scalars['String']>
  /** 카카오 템플릿, TE_2073: 따끈따끈 쿠폰 선물, TE_2072: 쿠폰 소멸 안내, TE_2071: 쿠폰 만료 안내, TE_4663: 쿠폰 도착 */
  template?: InputMaybe<Scalars['String']>
  /** 본문 URL (강아지 고양이 본문 URL시 강아지 URL) */
  url1?: InputMaybe<Scalars['String']>
  /** 본문 URL (강아지 고양이 본문 URL시 고양이 URL) */
  url2?: InputMaybe<Scalars['String']>
  /** 사용 종료일 */
  usingEndedAt?: InputMaybe<Scalars['String']>
  /** 사용기간(발급일로부터 n일간 사용가능) */
  usingPeriod?: InputMaybe<Scalars['Int']>
  /** 사용 시작일 */
  usingStartedAt?: InputMaybe<Scalars['String']>
}

/** An enumeration. */
export enum CouponGroupIssueType {
  /** 자동 */
  Auto = 'AUTO',
  /** 다운로드 */
  Download = 'DOWNLOAD',
  /** 등급 */
  Grade = 'GRADE',
  /** 수동 */
  Manual = 'MANUAL',
  /** 대량 발급 */
  Mass = 'MASS',
  /** 페이퍼 */
  Paper = 'PAPER',
  /** 페이퍼_신한카드 */
  PaperShinhanCard = 'PAPER_SHINHAN_CARD',
  /** 페이퍼_SKT */
  PaperSkt = 'PAPER_SKT',
  /** 정기 발급 */
  RegularIssue = 'REGULAR_ISSUE',
  /** 예약 발급 */
  ReservationIssue = 'RESERVATION_ISSUE',
}

export type CouponGroupType = Node & {
  __typename?: 'CouponGroupType'
  /** 프로모션 */
  ad?: Maybe<AdType>
  /** 주문쿠폰과 상품쿠폰 쿠폰 중복 사용 가능 여부 */
  canConjunctUse: Scalars['Boolean']
  canIssue?: Maybe<Scalars['Boolean']>
  canNotIssueReason?: Maybe<CouponGroupCannotIssueReasonEnum>
  canUseOnlyFirstOrder?: Maybe<Scalars['Boolean']>
  /** 페이퍼쿠폰 인증번호, null -> 회원별로 인증번호, not null -> 하나의 인증번호 */
  code?: Maybe<Scalars['String']>
  /** 쿠폰 그룹 */
  couponGroupCoupons: CouponTypeConnection
  /** 등급 쿠폰 */
  couponGroupCustomerGrades: CustomerGradeTypeConnection
  /** 쿠폰 그룹 */
  couponGroupExcelRequest: ExcelImportRequestTypeConnection
  /** 쿠폰 그룹 상태 */
  couponGroupStatus: CouponGroupCouponGroupStatus
  /** 쿠폰 타입, PRODUCT: 상품, ORDER: 주문, SHIPPING: 배송비 */
  couponType: CouponGroupCouponType
  coverageExcludeType?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 혜택 적용 OS 범위, ALL: 전체, WEB: 웹, APP: 앱 */
  coverageOsType: CouponGroupCoverageOsType
  /** 혜택 적용 범위, NONE: 기본값, ALL: 전상품/SPECIFIC_PRODUCT: 특정상품/SPECIFIC_CATEGORY: 특정카테고리/SPECIFIC_BRAND: 특정브랜드 */
  coverageType: CouponGroupCoverageType
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 쿠폰에 대한 설명 */
  description?: Maybe<Scalars['String']>
  /** 할인 금액(정액) */
  discountAmount: Scalars['Decimal']
  /** 할인 비율(정률), 0.xx로 기입 */
  discountRate: Scalars['Float']
  /** 할인방식, RATE: 정률할인/AMOUNT: 정액할인 */
  discountType: CouponGroupDiscountType
  /** 이벤트 타입, NONE: 기본값/FIRST_ORDER: 첫주문/THANKS_ORDER: 주문감사/BIRTH: 생일자/SIGN_UP: 회원가입/WAKE_UP: 휴면회원/INVITE: 내 추천 코드로 회원이 가입/INVITED: 추천 코드로 회원 가입/DB_INSURANCE_MEMBERSHIP: DB 손해보험 멤버십 무료 가입 쿠폰(기존 1개월 + 추가 2개월), 기존 가입자는 2개월 /INVITE_FIRST_ORDER: 내 추천 코드로 가입한 회원이 첫 구매 확정/INVITED_FIRST_ORDER: 추천 코드로 가입 후 첫 구매 확정 */
  eventType: CouponGroupEventType
  eventTypeDisplay?: Maybe<Scalars['String']>
  /** 쿠폰 제외 브랜드 범위 */
  excludedBrands: BrandTypeConnection
  /** 쿠폰 제외 카테고리 범위 */
  excludedCategories: CategoryTypeConnection
  /** 쿠폰 제외 상품 범위 */
  excludedProducts: ProductTypeConnection
  /** 만료 n일 전 알림 */
  expiredAlertDay?: Maybe<Scalars['Int']>
  /** 발급자 */
  fitpetAdmin?: Maybe<UserType>
  /** 첫 구매 쿠폰과 중복 지급 */
  hasDuplicateIssueFirst: Scalars['Boolean']
  /** 이벤트 SMS 발송 여부 */
  hasEventSms: Scalars['Boolean']
  hasExpiredAlert?: Maybe<Scalars['Boolean']>
  hasMaxIssueCount?: Maybe<Scalars['Boolean']>
  hasMinOrderAmount?: Maybe<Scalars['Boolean']>
  hasUsingPeriod?: Maybe<Scalars['Boolean']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 쿠폰 포함 브랜드 범위 */
  includedBrands: BrandTypeConnection
  /** 쿠폰 포함 카테고리 범위 */
  includedCategories: CategoryTypeConnection
  /** 쿠폰 포함 상품 범위 */
  includedProducts: ProductTypeConnection
  informKakaoQueue?: Maybe<InformKakaoQueueType>
  informkakaoqueue?: Maybe<InformKakaoQueueType>
  isDeletable?: Maybe<Scalars['Boolean']>
  /** 할인정보 다운로드 전 노출 여부, 오직 상품쿠폰 다운로드 타입만 */
  isDiscountVisibleBeforeDownload: Scalars['Boolean']
  /** 멤버십 쿠폰 여부 */
  isMembership: Scalars['Boolean']
  isMembershipCoupon?: Maybe<Scalars['Boolean']>
  issueCount?: Maybe<Scalars['Int']>
  /** 발급 종료일 */
  issueEndedAt?: Maybe<Scalars['DateTime']>
  /** 발급 시작일 */
  issueStartedAt?: Maybe<Scalars['DateTime']>
  /** 발급상태: 등록 중, 발급 중 일지 중지, 발급 중, 발급 가능 일자 아님, 발급 수량 초과, 발급 중지 */
  issueStatus?: Maybe<Scalars['String']>
  /** 발급방식, AUTO: 자동/MANUAL: 수동/DOWNLOAD: 다운로드/GRADE: 등급용쿠폰/PAPER: 페이퍼쿠폰/PAPER_SKT: SKT페이퍼쿠폰/PAPER_SHINHAN_CARD: 페터이 신한카드 */
  issueType: CouponGroupIssueType
  /** 최대 할인 금액 */
  maxDiscountAmount?: Maybe<Scalars['Decimal']>
  /** 최대 재발급 건수 */
  maxDuplicateIssueCount: Scalars['Int']
  /** 최대 발급 건수 */
  maxIssueCount?: Maybe<Scalars['Int']>
  /** 최소 주문 금액 */
  minOrderAmount?: Maybe<Scalars['Decimal']>
  /** 쿠폰명 */
  name: Scalars['String']
  /** 특수 쿠폰 관련 정보 */
  specialCouponDescription?: Maybe<Scalars['JSONString']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 사용 종료일 */
  usingEndedAt?: Maybe<Scalars['DateTime']>
  /** 사용기간(발급일로부터 n일간 사용가능) */
  usingPeriod?: Maybe<Scalars['Int']>
  /** 사용 시작일 */
  usingStartedAt?: Maybe<Scalars['DateTime']>
}

export type CouponGroupTypeCouponGroupCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroup?: InputMaybe<Scalars['String']>
  couponGroupName?: InputMaybe<Scalars['String']>
  couponStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  isUsed?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type CouponGroupTypeCouponGroupCustomerGradesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
}

export type CouponGroupTypeCouponGroupExcelRequestArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  importType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type CouponGroupTypeExcludedBrandsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeBrands?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  firstLetter?: InputMaybe<Scalars['String']>
  isApproved?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  seller?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
}

export type CouponGroupTypeExcludedCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brand?: InputMaybe<Scalars['String']>
  excludeCategories?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  hasImage?: InputMaybe<Scalars['Boolean']>
  isRootCategory?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  parentCategory?: InputMaybe<Scalars['String']>
}

export type CouponGroupTypeExcludedProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type CouponGroupTypeIncludedBrandsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeBrands?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  firstLetter?: InputMaybe<Scalars['String']>
  isApproved?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  seller?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
}

export type CouponGroupTypeIncludedCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brand?: InputMaybe<Scalars['String']>
  excludeCategories?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  hasImage?: InputMaybe<Scalars['Boolean']>
  isRootCategory?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  parentCategory?: InputMaybe<Scalars['String']>
}

export type CouponGroupTypeIncludedProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type CouponGroupTypeConnection = {
  __typename?: 'CouponGroupTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CouponGroupTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `CouponGroupType` and its cursor. */
export type CouponGroupTypeEdge = {
  __typename?: 'CouponGroupTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CouponGroupType>
}

export type CouponKakaoTemplateType = {
  __typename?: 'CouponKakaoTemplateType'
  message?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  parameters?: Maybe<Scalars['JSONString']>
  title?: Maybe<Scalars['String']>
}

export type CouponType = Node & {
  __typename?: 'CouponType'
  /** 쿠폰 코드 */
  code?: Maybe<Scalars['String']>
  /** 쿠폰 코드 - 제외 */
  codeWithoutDash?: Maybe<Scalars['String']>
  /** 쿠폰 그룹 */
  couponGroup?: Maybe<CouponGroupType>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 사용만료일 */
  expiredAt?: Maybe<Scalars['DateTime']>
  /** 발급자 */
  fitpetAdmin?: Maybe<UserType>
  /** The ID of the object. */
  id: Scalars['ID']
  isMembershipCoupon?: Maybe<Scalars['Boolean']>
  issueCount?: Maybe<Scalars['Int']>
  issuedFrom?: Maybe<Scalars['String']>
  /** 발급 사유 */
  message?: Maybe<Scalars['String']>
  /** 등록일시 */
  registeredAt?: Maybe<Scalars['DateTime']>
  /** 사용시작일 */
  startedAt?: Maybe<Scalars['DateTime']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 사용일시 */
  usedAt?: Maybe<Scalars['DateTime']>
  /** 사용한 주문건 */
  usedOrderItem?: Maybe<OrderItemType>
  usedStatus?: Maybe<Scalars['String']>
  /** 발급 대상 사용자 */
  user?: Maybe<UserType>
}

export type CouponTypeConnection = {
  __typename?: 'CouponTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CouponTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `CouponType` and its cursor. */
export type CouponTypeEdge = {
  __typename?: 'CouponTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CouponType>
}

export type CoverflowBannerInfoType = {
  __typename?: 'CoverflowBannerInfoType'
  adId?: Maybe<Scalars['ID']>
  adName?: Maybe<Scalars['String']>
  adSlug?: Maybe<Scalars['String']>
  coverflows?: Maybe<Array<Maybe<CoverflowType>>>
  kindText?: Maybe<Scalars['String']>
}

export type CoverflowType = {
  __typename?: 'CoverflowType'
  adId?: Maybe<Scalars['ID']>
  adName?: Maybe<Scalars['String']>
  adSlug?: Maybe<Scalars['String']>
  mobileBottomText?: Maybe<Scalars['String']>
  mobileDescription?: Maybe<Scalars['String']>
  mobileImage?: Maybe<BannerImageType>
  mobileProductNameText?: Maybe<Scalars['String']>
  mobileSubtitle?: Maybe<Scalars['String']>
  mobileTitle?: Maybe<Scalars['String']>
  pcBottomText?: Maybe<Scalars['String']>
  pcDescription?: Maybe<Scalars['String']>
  pcImage?: Maybe<BannerImageType>
  pcProductNameText?: Maybe<Scalars['String']>
  pcSubtitle?: Maybe<Scalars['String']>
  pcTitle?: Maybe<Scalars['String']>
}

export type CreateAdInput = {
  /** 프로모션 종류, NORMAL: 일반, DEAL_100: 100원딜, DEAL_990: 990원딜 */
  adType?: InputMaybe<Scalars['String']>
  /** 설명 */
  description?: InputMaybe<Scalars['String']>
  /** 종료일 */
  endedAt?: InputMaybe<Scalars['DateTime']>
  /** 이미지들 */
  images?: InputMaybe<Array<InputMaybe<AdImageInput>>>
  /** 노출 여부 */
  isShown?: InputMaybe<Scalars['Boolean']>
  /** 프로모션명 */
  name: Scalars['String']
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType?: InputMaybe<Scalars['String']>
  /** 프로모션 Url 주소 slug */
  slug?: InputMaybe<Scalars['String']>
  /** 시작일 */
  startedAt?: InputMaybe<Scalars['DateTime']>
  /** 배경색 등 스타일 정보 */
  style?: InputMaybe<Scalars['JSONString']>
}

/** Ad 생성 */
export type CreateAdMutation = {
  __typename?: 'CreateAdMutation'
  ad?: Maybe<AdType>
}

export type CreateAsInfoInput = {
  /** AS 전화번호 이름 */
  name: Scalars['String']
  /** 셀러 */
  seller: Scalars['ID']
  /** AS 전화번호 */
  telephoneNumber: Scalars['String']
}

/** AS 정보 등록 */
export type CreateAsInfoMutation = {
  __typename?: 'CreateAsInfoMutation'
  asInfo?: Maybe<AsInfoType>
}

export type CreateBestReviewsInput = {
  /** 베스트 리뷰 선정일 (년-월만 사용) */
  bestAt: Scalars['Date']
  /** 베스트로 선정할 리뷰 */
  reviews: Array<InputMaybe<Scalars['ID']>>
}

/** 베스트 리뷰 선정 */
export type CreateBestReviewsMutation = {
  __typename?: 'CreateBestReviewsMutation'
  reviews?: Maybe<Array<Maybe<ReviewType>>>
}

/** Brand 생성 - 관리자 */
export type CreateBrandByFitpetAdminMutation = {
  __typename?: 'CreateBrandByFitpetAdminMutation'
  brand?: Maybe<BrandType>
}

/** Brand 생성 - 셀러 */
export type CreateBrandBySellerMutation = {
  __typename?: 'CreateBrandBySellerMutation'
  brand?: Maybe<BrandType>
}

/** 관리자가 Excel 을 업로드하여 상품(Product) 댓글(Review) 대량 생성 */
export type CreateBulkReviewByFitpetAdminExcelMutation = {
  __typename?: 'CreateBulkReviewByFitpetAdminExcelMutation'
  /** 성공한 갯수 */
  count?: Maybe<Scalars['Int']>
  /** 실패 사유들 */
  failedReasons?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
  /** Task로 전환 여부 */
  isTask?: Maybe<Scalars['Boolean']>
}

export type CreateCartItemInput = {
  /** 장바구니에 담을 상품 아이템 */
  productOption: Scalars['ID']
  /** 상품 프로모션 */
  productPromotion?: InputMaybe<Scalars['ID']>
  /** 수량 */
  quantity: Scalars['Int']
}

/** 장바구니에 아이템 담기 */
export type CreateCartItemMutation = {
  __typename?: 'CreateCartItemMutation'
  cartItem?: Maybe<CartItemType>
}

export type CreateCartItemsInput = {
  productOptions: Array<InputMaybe<CreateCartItemInput>>
}

/** 장바구니 아이템 담기 (복수) */
export type CreateCartItemsMutation = {
  __typename?: 'CreateCartItemsMutation'
  cartItems?: Maybe<Array<Maybe<CartItemType>>>
}

/** Category 생성 */
export type CreateCategoryMutation = {
  __typename?: 'CreateCategoryMutation'
  category?: Maybe<CategoryType>
}

/** 쿠폰 정보 생성 */
export type CreateCouponGroupMutation = {
  __typename?: 'CreateCouponGroupMutation'
  couponGroup?: Maybe<CouponGroupType>
}

export type CreateCreditCardInput = {
  /** 멤버십 카드 변경용(True), 멤버십 가입용(False) */
  isUpdate: Scalars['Boolean']
  /** 카드 소유자 */
  user: Scalars['ID']
}

/** 신용카드 등록 전 생성 */
export type CreateCreditCardMutation = {
  __typename?: 'CreateCreditCardMutation'
  creditCard?: Maybe<CreditCardType>
}

/** 고객 등급 생성 */
export type CreateCustomerGradeMutation = {
  __typename?: 'CreateCustomerGradeMutation'
  customerGrade?: Maybe<CustomerGradeType>
}

export type CreateCustomerTemplateAddressInput = {
  /** 기본 주소 */
  basicAddress: Scalars['String']
  /** 상세 주소 */
  detailedAddress?: InputMaybe<Scalars['String']>
  /** 기본 주소지 여부 */
  isDefault?: InputMaybe<Scalars['Boolean']>
  /** 고객 핸드폰 번호 */
  mobileNumber: Scalars['String']
  /** 고객 이름 */
  name: Scalars['String']
  /** 우편번호 */
  postNumber: Scalars['String']
  /** 프리셋 이름 */
  templateName: Scalars['String']
}

/** 사용자 템플릿 주소 생성 */
export type CreateCustomerTemplateAddressMutation = {
  __typename?: 'CreateCustomerTemplateAddressMutation'
  customerTemplateAddress?: Maybe<CustomerTemplateAddressType>
}

export type CreateDisplayCollectionInput = {
  ad?: InputMaybe<Scalars['ID']>
  /** 배경 이미지 */
  backgroundImage?: InputMaybe<Scalars['ImageUpload']>
  /** 콜렉션 종류, BEST: 베스트, AD: 이벤트용, TODAY_SPECIAL_PRICE: Today 특가, COVERFLOW: 커버플로우, NORMAL: 일반, BEST_REVIEW: 리뷰좋은상품, MAGAZINE: 매거진이미지, MAGAZINE_BACKGROUND: 매거진 배경이미지 */
  collectionType?: InputMaybe<Scalars['String']>
  /** 설명 */
  description?: InputMaybe<Scalars['String']>
  /** 종료일 */
  endedAt?: InputMaybe<Scalars['DateTime']>
  /** 노출 여부 */
  isShown?: InputMaybe<Scalars['Boolean']>
  /** 콜렉션명 */
  name: Scalars['String']
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType?: InputMaybe<Scalars['String']>
  /** 시작일 */
  startedAt?: InputMaybe<Scalars['DateTime']>
  /** 콜렉션 탭 이름(기획전페이지 전용) */
  tabName?: InputMaybe<Scalars['String']>
}

/** Display Collection 생성 */
export type CreateDisplayCollectionMutation = {
  __typename?: 'CreateDisplayCollectionMutation'
  displayCollection?: Maybe<DisplayCollectionType>
}

export type CreateDisplayProductInfoInput = {
  /** 전시용 상품 콜렉션 */
  displayCollection: Scalars['ID']
  /** 종료일 */
  endedAt?: InputMaybe<Scalars['DateTime']>
  /** 상품 */
  product: Scalars['ID']
  /** 시작일 */
  startedAt?: InputMaybe<Scalars['DateTime']>
}

/** DisplayProductInfo 생성 */
export type CreateDisplayProductInfoMutation = {
  __typename?: 'CreateDisplayProductInfoMutation'
  displayProductInfo?: Maybe<DisplayProductInfoType>
}

export type CreateDisplayProductInfosInput = {
  /** 전시용 상품 콜렉션 */
  displayCollection: Scalars['ID']
  /** 종료일 */
  endedAt?: InputMaybe<Scalars['DateTime']>
  products: Array<InputMaybe<Scalars['ID']>>
  /** 시작일 */
  startedAt?: InputMaybe<Scalars['DateTime']>
}

/** DisplayProductInfo 복수 생성 */
export type CreateDisplayProductInfosMutation = {
  __typename?: 'CreateDisplayProductInfosMutation'
  /** Returns how many objects were affected. */
  count: Scalars['Int']
  displayProductInfo?: Maybe<DisplayProductInfoType>
}

export type CreateDisplayProductInfosWithProductPromotionsInput = {
  /** 전시용 상품 콜렉션 */
  displayCollection: Scalars['ID']
  /** 종료일 */
  endedAt?: InputMaybe<Scalars['DateTime']>
  /** 프로모션 종류, NORMAL: 검색가능, DEAL_100: 100원딜, 검색불가. DEAL_990: 990원딜, 검색불가. PET_REGISTRATION: 동물 등록제, 검색 가능 */
  productPromotionType: Scalars['String']
  products: Array<InputMaybe<Scalars['ID']>>
  /** 시작일 */
  startedAt?: InputMaybe<Scalars['DateTime']>
}

/** DisplayProductInfo와 ProductPromotion 복수 생성 / DisplayCollection에 상품추가할 때 호출 */
export type CreateDisplayProductInfosWithProductPromotionsMutation = {
  __typename?: 'CreateDisplayProductInfosWithProductPromotionsMutation'
  displayProductInfos?: Maybe<Array<Maybe<DisplayProductInfoType>>>
  productPromotions?: Maybe<Array<Maybe<ProductPromotionType>>>
}

/** 홈 하단 띠 배너 생성 */
export type CreateHomeBottomBannerMutation = {
  __typename?: 'CreateHomeBottomBannerMutation'
  banner?: Maybe<BannerType>
}

/** 홈 중단 띠 배너 생성 */
export type CreateHomeMiddleBannerMutation = {
  __typename?: 'CreateHomeMiddleBannerMutation'
  banner?: Maybe<BannerType>
}

/** 멤버쉽 가족 초대 코드 생성 */
export type CreateMembershipInvitationMutation = {
  __typename?: 'CreateMembershipInvitationMutation'
  membershipInvitation?: Maybe<MembershipInvitationType>
}

/** 홈 멀티라인 배너 생성 */
export type CreateMultiLineBannerMutation = {
  __typename?: 'CreateMultiLineBannerMutation'
  banner?: Maybe<BannerType>
}

/** 공지 사항 생성 */
export type CreateNoticeMutation = {
  __typename?: 'CreateNoticeMutation'
  notice?: Maybe<NoticeType>
}

/** 엑셀 파일로 SKU 생성 또는 업데이트 */
export type CreateOrUpdateSkusByExcelMutation = {
  __typename?: 'CreateOrUpdateSkusByExcelMutation'
  /** 성공한 갯수 */
  count?: Maybe<Scalars['Int']>
  /** 실패 사유들 */
  failedReasons?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
  /** Task로 전환 여부 */
  isTask?: Maybe<Scalars['Boolean']>
}

export type CreateOrderItemAdminNoteInput = {
  /** 관리자 메모 */
  note: Scalars['String']
  /** 작성한 노트 */
  orderItem: Scalars['ID']
}

/** CS용 관리자 메모 생성 */
export type CreateOrderItemAdminNoteMutation = {
  __typename?: 'CreateOrderItemAdminNoteMutation'
  orderItemAdminNote?: Maybe<OrderItemAdminNoteType>
}

/** 주문 생성 */
export type CreateOrderMutation = {
  __typename?: 'CreateOrderMutation'
  order?: Maybe<OrderType>
}

export type CreateParticipantResultInput = {
  /** 이벤트 아이디 */
  eventId: Scalars['ID']
  eventType: EventTypeEnum
  /** 최종 선택한 값(selected_id로 표현 안되는 값) */
  selectValue?: InputMaybe<Scalars['String']>
  /** 최종 선택한 아이디 */
  selectedId?: InputMaybe<Scalars['ID']>
  /** 최종 선택한 옵션 아이디 */
  selectedOptionId?: InputMaybe<Scalars['ID']>
}

/** 이벤트 참여 결과 생성 */
export type CreateParticipantResultMutation = {
  __typename?: 'CreateParticipantResultMutation'
  participantResult?: Maybe<ParticipantResultsType>
}

/** 인기 검색어 등록 */
export type CreatePopularSearchTermMutation = {
  __typename?: 'CreatePopularSearchTermMutation'
  popularSearchTerm?: Maybe<PopularSearchTermType>
}

/** 상품 등록 - 관리자 */
export type CreateProductByFitpetAdminMutation = {
  __typename?: 'CreateProductByFitpetAdminMutation'
  product?: Maybe<ProductType>
}

/** 상품 등록 - 셀러 */
export type CreateProductBySellerMutation = {
  __typename?: 'CreateProductBySellerMutation'
  product?: Maybe<ProductType>
}

export type CreateProductPromotionInput = {
  /** 구매 확정 시 적립율, 0.xx로 표기. */
  confirmMileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 프로모션 설명 (생략 가능) */
  description?: InputMaybe<Scalars['String']>
  /** 할인 가격 */
  discountPrice: Scalars['Decimal']
  /** 할인 비율(정률), 0.xx로 기입 */
  discountRate?: InputMaybe<Scalars['Float']>
  /** 화면표시 기간 끝 */
  displayEndedAt?: InputMaybe<Scalars['DateTime']>
  /** 상품 인포 */
  displayProductInfo?: InputMaybe<Scalars['ID']>
  /** 화면표시 기간 시작 */
  displayStartedAt?: InputMaybe<Scalars['DateTime']>
  /** 종료일 */
  endedAt?: InputMaybe<Scalars['DateTime']>
  /** 핏펫 수수료 */
  fitpetFeeRate?: InputMaybe<Scalars['Float']>
  /** 이미지 리뷰 작성 시 적립율, 0.xx로 표기. */
  imageReviewMileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 기본 핏펫 수수료 사용여부 */
  isDefaultFitpetFeeRate?: InputMaybe<Scalars['Boolean']>
  /** 기본 적립금 적립률 사용여부 */
  isDefaultMileageRewardRate?: InputMaybe<Scalars['Boolean']>
  /** 최대 주문 수량 */
  maxOrderCount?: InputMaybe<Scalars['Int']>
  /** 적립율, 0.xx로 표기. null일경우 ServerConfig 따름 */
  mileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 최소 주문금액 */
  minOrderAmount?: InputMaybe<Scalars['Decimal']>
  /** 할인 이름 */
  name?: InputMaybe<Scalars['String']>
  /** 상품 */
  product: Scalars['ID']
  /** 프로모션 종류, NORMAL: 검색가능, DEAL_100: 100원딜, 검색불가. DEAL_990: 990원딜, 검색불가. PET_REGISTRATION: 동물 등록제, 검색 가능 */
  productPromotionType?: InputMaybe<Scalars['String']>
  /** 리뷰 작성 시 적립율, 0.xx로 표기. */
  reviewMileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 시작일 */
  startedAt?: InputMaybe<Scalars['DateTime']>
}

/** 상품 할인 생성 */
export type CreateProductPromotionMutation = {
  __typename?: 'CreateProductPromotionMutation'
  productPromotion?: Maybe<ProductPromotionType>
}

export type CreateProductPromotionsByExcelInput = {
  /** DisplayCollection의 Type */
  collectionType?: InputMaybe<Scalars['String']>
  excel: Scalars['Upload']
  /** DisplayCollectoin의 ID */
  id: Scalars['ID']
}

/** 엑셀 파일로 프로모션 생성 */
export type CreateProductPromotionsByExcelMutation = {
  __typename?: 'CreateProductPromotionsByExcelMutation'
  /** 성공한 갯수 */
  count?: Maybe<Scalars['Int']>
  /** 실패 사유들 */
  failedReasons?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
  /** Task로 전환 여부 */
  isTask?: Maybe<Scalars['Boolean']>
}

export type CreateProductShownInput = {
  /** 판매 노출 여부 */
  isShown: Scalars['Boolean']
  /** 다음 쇼핑 노출 여부 */
  isShownDaum?: InputMaybe<Scalars['Boolean']>
  /** 페이스북 노출 여부 */
  isShownFacebook?: InputMaybe<Scalars['Boolean']>
  /** 구글 노출 여부 */
  isShownGoogle?: InputMaybe<Scalars['Boolean']>
  /** 네이버 쇼핑 노출 여부 */
  isShownNaver: Scalars['Boolean']
  /** 설정 품절 여부 */
  isSoldOut: Scalars['Boolean']
}

/** 상품 상세 상단 띠 배너 생성 */
export type CreateProductTopBannerMutation = {
  __typename?: 'CreateProductTopBannerMutation'
  banner?: Maybe<BannerType>
}

/** 엑셀 파일로 상품 생성 */
export type CreateProductsByExcelMutation = {
  __typename?: 'CreateProductsByExcelMutation'
  /** 성공한 갯수 */
  count?: Maybe<Scalars['Int']>
  /** 실패 사유들 */
  failedReasons?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
  /** Task로 전환 여부 */
  isTask?: Maybe<Scalars['Boolean']>
}

/** 엑셀 파일로 도서산간지역 추가 */
export type CreateRemoteAreaByExcelMutation = {
  __typename?: 'CreateRemoteAreaByExcelMutation'
  /** 성공한 갯수 */
  count?: Maybe<Scalars['Int']>
  /** 실패 사유들 */
  failedReasons?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
  /** Task로 전환 여부 */
  isTask?: Maybe<Scalars['Boolean']>
}

/** 도서산간지역 등록 */
export type CreateRemoteAreaMutation = {
  __typename?: 'CreateRemoteAreaMutation'
  remoteArea?: Maybe<RemoteAreaType>
}

export type CreateReviewInput = {
  /** 리뷰를 작성할 주문 상품 아이템 */
  orderItem: Scalars['ID']
  /** 리뷰 내용 */
  reviewBody: Scalars['String']
  reviewImages?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>
  /** 평점 */
  score: Scalars['Int']
}

/** 고객 리뷰 생성 */
export type CreateReviewMutation = {
  __typename?: 'CreateReviewMutation'
  review?: Maybe<ReviewType>
}

export type CreateSellerByFitpetAdminInput = {
  /** 예금주명 */
  accountHolderName?: InputMaybe<Scalars['String']>
  /** 정산 담당자명 */
  accountManagerName?: InputMaybe<Scalars['String']>
  /** 정산 담당자 연락처 */
  accountManagerTelephoneNumber?: InputMaybe<Scalars['String']>
  /** 계좌번호 */
  accountNumber?: InputMaybe<Scalars['String']>
  /** 판매대행신고여부동의일시 */
  agentReportedAt?: InputMaybe<Scalars['DateTime']>
  /** AS 전화번호 */
  asTelephoneNumber: Scalars['String']
  /** 통장 사본 */
  bankBookImage?: InputMaybe<Scalars['Upload']>
  /** 은행 코드 */
  bankCode?: InputMaybe<Scalars['String']>
  /** 사업장 기본주소 */
  basicAddress: Scalars['String']
  /** 업태 */
  businessCondition: Scalars['String']
  /** 종목 */
  businessItem: Scalars['String']
  /** 사업자등록증 */
  businessRegistration?: InputMaybe<Scalars['Upload']>
  /** 사업자 번호 */
  businessRegistrationNumber: Scalars['String']
  /** 셀러 매니저인 사용자 */
  chiefManager: Scalars['ID']
  /** 회사명 */
  companyName: Scalars['String']
  /** 대표자 이름 */
  companyOwnerName: Scalars['String']
  /** 사업장 상세주소 */
  detailedAddress: Scalars['String']
  /** 핏펫 수수료 */
  fitpetFeeRate: Scalars['Float']
  /** 판매대행신고여부 */
  isAgentReported: Scalars['Boolean']
  /** 통신판매업신고번호 */
  mailOrderReportNumber?: InputMaybe<Scalars['String']>
  /** 통신판매업신고증 */
  mailOrderReportRegistration?: InputMaybe<Scalars['Upload']>
  /** 알림을 수신받을 이메일 */
  notificationEmail?: InputMaybe<Scalars['String']>
  /** 알림 수신받을 담당자 명 */
  notificationManagerName?: InputMaybe<Scalars['String']>
  /** 알림톡 수신받을 핸드폰번호 */
  notificationMobileNumber?: InputMaybe<Scalars['String']>
  /** 사업장 우편번호 */
  postNumber: Scalars['String']
  /** 교환/환불 안내 */
  returnExchangeGuide?: InputMaybe<Scalars['String']>
  shippingPreset: ShippingPresetInput
  /** 셀러샵 이름 */
  shopName: Scalars['String']
  /** 세금계산서 발급용 이메일 */
  taxEmail?: InputMaybe<Scalars['String']>
  /** 회사 대표 전화번호 */
  telephoneNumber: Scalars['String']
}

/** seller 생성 - 관리자 */
export type CreateSellerByFitpetAdminMutation = {
  __typename?: 'CreateSellerByFitpetAdminMutation'
  seller?: Maybe<SellerType>
}

export type CreateSellerByUserRequestInput = {
  /** 예금주명 */
  accountHolderName?: InputMaybe<Scalars['String']>
  /** 정산 담당자명 */
  accountManagerName?: InputMaybe<Scalars['String']>
  /** 정산 담당자 연락처 */
  accountManagerTelephoneNumber?: InputMaybe<Scalars['String']>
  /** 계좌번호 */
  accountNumber?: InputMaybe<Scalars['String']>
  /** AS 전화번호 */
  asTelephoneNumber: Scalars['String']
  /** 통장 사본 */
  bankBookImage?: InputMaybe<Scalars['Upload']>
  /** 은행 코드 */
  bankCode?: InputMaybe<Scalars['String']>
  /** 사업장 기본주소 */
  basicAddress: Scalars['String']
  /** 업태 */
  businessCondition: Scalars['String']
  /** 종목 */
  businessItem: Scalars['String']
  /** 사업자등록증 */
  businessRegistration?: InputMaybe<Scalars['Upload']>
  /** 사업자 번호 */
  businessRegistrationNumber: Scalars['String']
  /** 회사명 */
  companyName: Scalars['String']
  /** 대표자 이름 */
  companyOwnerName: Scalars['String']
  /** 사업장 상세주소 */
  detailedAddress: Scalars['String']
  /** 통신판매업신고번호 */
  mailOrderReportNumber?: InputMaybe<Scalars['String']>
  /** 통신판매업신고증 */
  mailOrderReportRegistration?: InputMaybe<Scalars['Upload']>
  /** 알림을 수신받을 이메일 */
  notificationEmail?: InputMaybe<Scalars['String']>
  /** 알림 수신받을 담당자 명 */
  notificationManagerName?: InputMaybe<Scalars['String']>
  /** 알림톡 수신받을 핸드폰번호 */
  notificationMobileNumber?: InputMaybe<Scalars['String']>
  /** 사업장 우편번호 */
  postNumber: Scalars['String']
  /** 교환/환불 안내 */
  returnExchangeGuide?: InputMaybe<Scalars['String']>
  /** 셀러샵 이름 */
  shopName: Scalars['String']
  /** 세금계산서 발급용 이메일 */
  taxEmail?: InputMaybe<Scalars['String']>
  /** 회사 대표 전화번호 */
  telephoneNumber: Scalars['String']
}

/** seller 생성 - 사용자 */
export type CreateSellerByUserRequestMutation = {
  __typename?: 'CreateSellerByUserRequestMutation'
  seller?: Maybe<SellerType>
}

/** 셀러 유저 등록 */
export type CreateSellerManagerMutation = {
  __typename?: 'CreateSellerManagerMutation'
  sellerUser?: Maybe<SellerUserType>
}

export type CreateSellerTemplateAddressInput = {
  /** 기본 주소 */
  basicAddress: Scalars['String']
  /** 상세 주소 */
  detailedAddress?: InputMaybe<Scalars['String']>
  /** 기본 주소지 여부 */
  isDefault?: InputMaybe<Scalars['Boolean']>
  /** 우편번호 */
  postNumber: Scalars['String']
  /** 셀러 */
  seller: Scalars['ID']
}

/** 셀러 템플릿 주소 생성 */
export type CreateSellerTemplateAddressMutation = {
  __typename?: 'CreateSellerTemplateAddressMutation'
  sellerTemplateAddress?: Maybe<SellerTemplateAddressType>
}

/** 설정 생성 */
export type CreateServerConfigMutation = {
  __typename?: 'CreateServerConfigMutation'
  serverConfig?: Maybe<ServerConfigType>
}

/** 배송 회사 정보 생성 */
export type CreateShippingCompanyMutation = {
  __typename?: 'CreateShippingCompanyMutation'
  shippingCompany?: Maybe<ShippingCompanyType>
}

export type CreateShippingPresetInput = {
  /** 도서 산간 추가 배송비 적용 여부 */
  applyRemoteAreaFee?: InputMaybe<Scalars['Boolean']>
  /** 무로배송 기준 금액 */
  freeShippingBaseAmount?: InputMaybe<Scalars['Decimal']>
  /** 배송 가이드 */
  guide?: InputMaybe<Scalars['String']>
  /** 묶음 배송 가능 여부 */
  isPackage?: InputMaybe<Scalars['Boolean']>
  /** 배송 프리셋 이름 */
  name: Scalars['String']
  /** 배송 공지 */
  notice?: InputMaybe<Scalars['String']>
  /** 반품지 주소 */
  returnAddress: UpdateAddressInput
  /** 반품비 */
  returnFee?: InputMaybe<Scalars['Decimal']>
  /** 배송 셀러 */
  seller: Scalars['ID']
  /** 배송회사 */
  shippingCompany: Scalars['ID']
  /** 당일출고 마감시간 */
  shippingDeadline?: InputMaybe<Scalars['Int']>
  /** 운임 */
  shippingFee?: InputMaybe<Scalars['Decimal']>
  /** 출고지 주소 */
  warehouseAddress: UpdateAddressInput
}

/** 배송 프리셋 정보 생성 */
export type CreateShippingPresetMutation = {
  __typename?: 'CreateShippingPresetMutation'
  shippingPreset?: Maybe<ShippingPresetType>
}

/** Slim 배너 생성 */
export type CreateSlimBannerMutation = {
  __typename?: 'CreateSlimBannerMutation'
  banner?: Maybe<BannerType>
}

/** 상단 롤 배너 생성 */
export type CreateTopRollBannerMutation = {
  __typename?: 'CreateTopRollBannerMutation'
  banner?: Maybe<BannerType>
}

export type CreateTransactionInput = {
  /** 주문 */
  order: Scalars['ID']
  /** 결제 수단 */
  payMethodType?: InputMaybe<Scalars['String']>
}

/** 트랜잭션 생성 */
export type CreateTransactionMutation = {
  __typename?: 'CreateTransactionMutation'
  transaction?: Maybe<TransactionType>
}

export type CreateUserInput = {
  /** 이벤트 및 혜택 안내 동의 여부 */
  agreeMarketing?: InputMaybe<Scalars['Boolean']>
  /** 병원 알림 허용 여부 */
  agreeMarketingHospital?: InputMaybe<Scalars['Boolean']>
  /** 플레이 알림 허용 여부 */
  agreeMarketingPlay?: InputMaybe<Scalars['Boolean']>
  /** 야간 마케팅 알림 수신 동의 여부 */
  agreeNightMarketing?: InputMaybe<Scalars['Boolean']>
  /** 개인정보 수집 및 이용 동의 */
  agreePersonalInfoCollection?: InputMaybe<Scalars['Boolean']>
  /** 홍보 및 마케팅 동의 여부 */
  agreePrMarketing?: InputMaybe<Scalars['Boolean']>
  /** 서비스 알림 허용 여부 */
  agreeServicePushAllowed?: InputMaybe<Scalars['Boolean']>
  /** 생일 */
  birthday?: InputMaybe<Scalars['Date']>
  /** 성별, MALE(남성), FEMALE(여성), UNKNOWN(알 수 없음) */
  gender?: InputMaybe<Scalars['String']>
  grade?: InputMaybe<Scalars['ID']>
  /** 추천인 코드 */
  invitationCode?: InputMaybe<Scalars['String']>
  /** 사용자 핸드폰 번호 */
  mobileNumber: Scalars['String']
  /** 사용자 이름 */
  name: Scalars['String']
  password: Scalars['String']
  /** 사용자 이메일 주소 */
  username: Scalars['String']
}

/** Fitpet admin에 의한 user 생성 */
export type CreateUserMutation = {
  __typename?: 'CreateUserMutation'
  user?: Maybe<UserType>
}

/** An enumeration. */
export enum CreditCardCardStatus {
  /** 카드 삭제 */
  Deleted = 'DELETED',
  /** 카드 등록 대기 */
  Ready = 'READY',
  /** 카드 등록 완료 */
  Registered = 'REGISTERED',
}

export type CreditCardType = Node & {
  __typename?: 'CreditCardType'
  /** 카드 이름 */
  cardName?: Maybe<Scalars['String']>
  /** 결제 수단, READY: 카드 등록 대기, REGISTERED: 카드 등록 완료, DELETED: 카드 삭제 */
  cardStatus: CreditCardCardStatus
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 멤버쉽 결제 수단 */
  creditCardMembershipHistories: MembershipHistoryTypeConnection
  /** 결제 카드 */
  creditCardMemberships: MembershipTypeConnection
  /** User ID */
  customerId?: Maybe<Scalars['String']>
  /** 고객 결제 수단 고유 번호, 빌링키 */
  customerUid?: Maybe<Scalars['String']>
  /** 삭제 일시 */
  deletedAt?: Maybe<Scalars['DateTime']>
  /** 아임포트 카드 등록 시 결제 이름 */
  iamportName?: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 마지막 4자리 카드 번호 */
  lastFourDigitCardNumber?: Maybe<Scalars['String']>
  /** 가맹점 주문 고유 번호 */
  merchantUid?: Maybe<Scalars['String']>
  /** 등록 성공 일시 */
  registeredAt?: Maybe<Scalars['DateTime']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 카드 소유자 */
  user?: Maybe<UserType>
}

export type CreditCardTypeCreditCardMembershipHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  membership?: InputMaybe<Scalars['String']>
  membershipHistoryStatus?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
}

export type CreditCardTypeCreditCardMembershipsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type CustomerAddressType = Node & {
  __typename?: 'CustomerAddressType'
  /** 기본 주소 */
  basicAddress: Scalars['String']
  /** 상세 주소 */
  detailedAddress?: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 배송 메세지 */
  message?: Maybe<Scalars['String']>
  /** 고객 핸드폰 번호 */
  mobileNumber?: Maybe<Scalars['String']>
  /** 고객 이름 */
  name: Scalars['String']
  /** 우편번호 */
  postNumber: Scalars['String']
  returninfo?: Maybe<ReturnInfoType>
}

export type CustomerAddressTypeConnection = {
  __typename?: 'CustomerAddressTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerAddressTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `CustomerAddressType` and its cursor. */
export type CustomerAddressTypeEdge = {
  __typename?: 'CustomerAddressTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CustomerAddressType>
}

/** An enumeration. */
export enum CustomerGradeGradeType {
  /** AUTO */
  Auto = 'AUTO',
  /** MANUAL */
  Manual = 'MANUAL',
}

export type CustomerGradeInput = {
  couponGroups?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  /** 등급종류 AUTO:자동 MANUAL:수동관리 */
  gradeType: Scalars['String']
  /** 등급 최대 주문 금액 (미만) */
  maxOrderAmount?: InputMaybe<Scalars['Decimal']>
  /** 최대 주문 수량 */
  maxOrderCount?: InputMaybe<Scalars['Int']>
  /** 등급 최소 주문 금액 (이상) */
  minOrderAmount?: InputMaybe<Scalars['Decimal']>
  /** 최소 주문 수량 */
  minOrderCount?: InputMaybe<Scalars['Int']>
  /** 제목 */
  name: Scalars['String']
  /** 적립율, 0.xx로 표기 */
  rewardRate: Scalars['Float']
  /** 등급 순번 */
  series?: InputMaybe<Scalars['Int']>
}

export type CustomerGradeType = Node & {
  __typename?: 'CustomerGradeType'
  /** 등급 코드, 특수 등급에만 부여됨. 200: Style Fitpeter */
  code?: Maybe<Scalars['String']>
  /** 회원등급조건 */
  conditionText?: Maybe<Scalars['String']>
  /** 등급 쿠폰 */
  couponGroups: CouponGroupTypeConnection
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 등록한 관리자 */
  fitpetAdmin?: Maybe<UserType>
  /** 등급종류 AUTO:자동 MANUAL:수동관리 */
  gradeType: CustomerGradeGradeType
  gradeUsers?: Maybe<UserTypeConnection>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 적용 여부 */
  isActivated: Scalars['Boolean']
  /** 등급 최대 주문 금액 (미만) */
  maxOrderAmount?: Maybe<Scalars['Decimal']>
  /** 최대 주문 수량 */
  maxOrderCount?: Maybe<Scalars['Int']>
  /** 등급 최소 주문 금액 (이상) */
  minOrderAmount?: Maybe<Scalars['Decimal']>
  /** 최소 주문 수량 */
  minOrderCount?: Maybe<Scalars['Int']>
  /** 제목 */
  name: Scalars['String']
  /** 푸쉬 전송 대상 회원 명수 */
  pushMarketingUserCount?: Maybe<Scalars['Int']>
  /** 푸쉬 전송 대상 회원 명수 */
  pushUserCount?: Maybe<Scalars['Int']>
  /** 적립율, 0.xx로 표기 */
  rewardRate: Scalars['Float']
  /** 등급 순번 */
  series?: Maybe<Scalars['Int']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 회원수 */
  userCount?: Maybe<Scalars['Int']>
}

export type CustomerGradeTypeCouponGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroupStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  coverageOsType?: InputMaybe<Scalars['String']>
  coverageType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  eventType?: InputMaybe<Scalars['String']>
  excludeIssueType?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDiscountVisibleBeforeDownload?: InputMaybe<Scalars['Boolean']>
  isIssuing?: InputMaybe<Scalars['Boolean']>
  issueStatus?: InputMaybe<Scalars['String']>
  issueType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  usingEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  usingPeriod?: InputMaybe<Scalars['Float']>
  usingStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingStartedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type CustomerGradeTypeGradeUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  agreeMarketing?: InputMaybe<Scalars['Boolean']>
  agreeMarketingHospital?: InputMaybe<Scalars['Boolean']>
  agreeMarketingPlay?: InputMaybe<Scalars['Boolean']>
  agreeNightMarketing?: InputMaybe<Scalars['Boolean']>
  agreeServicePushAllowed?: InputMaybe<Scalars['Boolean']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  dateJoinedAfter?: InputMaybe<Scalars['DateTime']>
  dateJoinedBefore?: InputMaybe<Scalars['DateTime']>
  email?: InputMaybe<Scalars['String']>
  excludeUsers?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  grades?: InputMaybe<Scalars['String']>
  inactivatedAtAfter?: InputMaybe<Scalars['DateTime']>
  inactivatedAtBefore?: InputMaybe<Scalars['DateTime']>
  inactiveUserEmail?: InputMaybe<Scalars['String']>
  inactiveUserMobileNumber?: InputMaybe<Scalars['String']>
  inactiveUserName?: InputMaybe<Scalars['String']>
  inactiveUserUsername?: InputMaybe<Scalars['String']>
  isBuyer?: InputMaybe<Scalars['Boolean']>
  isInfluencer?: InputMaybe<Scalars['Boolean']>
  isLeft?: InputMaybe<Scalars['Boolean']>
  isSeller?: InputMaybe<Scalars['Boolean']>
  isStaff?: InputMaybe<Scalars['Boolean']>
  joinedPlatform?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  lastLoginAfter?: InputMaybe<Scalars['DateTime']>
  lastLoginBefore?: InputMaybe<Scalars['DateTime']>
  leftAtAfter?: InputMaybe<Scalars['DateTime']>
  leftAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipRefundStatus?: InputMaybe<Scalars['String']>
  membershipStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipStartedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipStatus?: InputMaybe<Scalars['String']>
  mileagePointLess?: InputMaybe<Scalars['Float']>
  mileagePointMore?: InputMaybe<Scalars['Float']>
  mobileNumber?: InputMaybe<Scalars['String']>
  mobileNumbers?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderAmountLess?: InputMaybe<Scalars['Float']>
  orderAmountMore?: InputMaybe<Scalars['Float']>
  orderBy?: InputMaybe<Scalars['String']>
  orderCountLess?: InputMaybe<Scalars['Float']>
  orderCountMore?: InputMaybe<Scalars['Float']>
  requiredUsername?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  socialType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userNumber?: InputMaybe<Scalars['String']>
  userStatus?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type CustomerGradeTypeConnection = {
  __typename?: 'CustomerGradeTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerGradeTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `CustomerGradeType` and its cursor. */
export type CustomerGradeTypeEdge = {
  __typename?: 'CustomerGradeTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CustomerGradeType>
}

export type CustomerTemplateAddressType = Node & {
  __typename?: 'CustomerTemplateAddressType'
  /** 기본 주소 */
  basicAddress: Scalars['String']
  /** 상세 주소 */
  detailedAddress?: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 기본 주소지 여부 */
  isDefault: Scalars['Boolean']
  /** 배송 메세지 */
  message?: Maybe<Scalars['String']>
  /** 고객 핸드폰 번호 */
  mobileNumber?: Maybe<Scalars['String']>
  /** 고객 이름 */
  name: Scalars['String']
  /** 우편번호 */
  postNumber: Scalars['String']
  /** 프리셋 이름 */
  templateName: Scalars['String']
  /** 배송지 사용자 */
  user?: Maybe<UserType>
}

export type CustomerTemplateAddressTypeConnection = {
  __typename?: 'CustomerTemplateAddressTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerTemplateAddressTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `CustomerTemplateAddressType` and its cursor. */
export type CustomerTemplateAddressTypeEdge = {
  __typename?: 'CustomerTemplateAddressTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<CustomerTemplateAddressType>
}

export type DashboardBillingType = {
  __typename?: 'DashboardBillingType'
  totalSellerAmount?: Maybe<Scalars['Decimal']>
}

export type DashboardBoardType = {
  __typename?: 'DashboardBoardType'
  orderQnaCount?: Maybe<Scalars['Int']>
  productQnaCount?: Maybe<Scalars['Int']>
  reviewCount?: Maybe<Scalars['Int']>
  serviceQnaCount?: Maybe<Scalars['Int']>
}

export type DashboardNoticeType = {
  __typename?: 'DashboardNoticeType'
  customerNotices?: Maybe<Array<Maybe<NoticeType>>>
  partnerImportantNotice?: Maybe<NoticeType>
  partnerNotices?: Maybe<Array<Maybe<NoticeType>>>
}

export type DashboardOrderTimeseriesType = {
  __typename?: 'DashboardOrderTimeseriesType'
  data?: Maybe<Array<Maybe<TimeseriesValueType>>>
  id?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
}

export type DashboardOrderType = {
  __typename?: 'DashboardOrderType'
  cancelRequestedCount?: Maybe<Scalars['Int']>
  confirmedCount?: Maybe<Scalars['Int']>
  newOrderCount?: Maybe<Scalars['Int']>
  readyForShippingCount?: Maybe<Scalars['Int']>
  returnRequestedCount?: Maybe<Scalars['Int']>
  shippedCount?: Maybe<Scalars['Int']>
  shippingCount?: Maybe<Scalars['Int']>
  timeseries?: Maybe<Array<Maybe<DashboardOrderTimeseriesType>>>
  unconfirmedCount?: Maybe<Scalars['Int']>
}

export type DashboardProductType = {
  __typename?: 'DashboardProductType'
  brandCount?: Maybe<Scalars['Int']>
  productCount?: Maybe<Scalars['Int']>
  sellerCount?: Maybe<Scalars['Int']>
}

export type DashboardType = {
  __typename?: 'DashboardType'
  dashboardBilling?: Maybe<DashboardBillingType>
  dashboardBoard?: Maybe<DashboardBoardType>
  dashboardNotice?: Maybe<DashboardNoticeType>
  dashboardOrder?: Maybe<DashboardOrderType>
  dashboardProduct?: Maybe<DashboardProductType>
  dashboardUser?: Maybe<DashboardUserType>
}

export type DashboardUserType = {
  __typename?: 'DashboardUserType'
  wishedBrandCount?: Maybe<Scalars['Int']>
  wishedProductCount?: Maybe<Scalars['Int']>
}

export type DefaultReturnChangeGuideType = {
  __typename?: 'DefaultReturnChangeGuideType'
  value?: Maybe<Scalars['String']>
}

/** Ad 생성 */
export type DeleteAdMutation = {
  __typename?: 'DeleteAdMutation'
  ad?: Maybe<AdType>
}

/** 프로모션 복수 삭제 */
export type DeleteAdsMutation = {
  __typename?: 'DeleteAdsMutation'
  /** Returns how many objects were affected. */
  count: Scalars['Int']
}

/** AS 정보 삭제 */
export type DeleteAsInfoMutation = {
  __typename?: 'DeleteAsInfoMutation'
  asInfo?: Maybe<AsInfoType>
}

export type DeleteBannersInput = {
  ids: Array<InputMaybe<Scalars['ID']>>
}

/** 배너 삭제 */
export type DeleteBannersMutation = {
  __typename?: 'DeleteBannersMutation'
  /** Returns how many objects were affected. */
  count: Scalars['Int']
}

export type DeleteCartItemsInput = {
  ids: Array<InputMaybe<Scalars['ID']>>
}

/** 장바구니 아이템 복수 삭제 */
export type DeleteCartItemsMutation = {
  __typename?: 'DeleteCartItemsMutation'
  /** Returns how many objects were affected. */
  count: Scalars['Int']
}

/** Category 삭제 */
export type DeleteCategoryMutation = {
  __typename?: 'DeleteCategoryMutation'
  category?: Maybe<CategoryType>
}

/** 쿠폰 정보 삭제 */
export type DeleteCouponGroupMutation = {
  __typename?: 'DeleteCouponGroupMutation'
  couponGroup?: Maybe<CouponGroupType>
}

/** 쿠폰 정보 삭제 */
export type DeleteCouponGroupWithUnusedCouponsMutation = {
  __typename?: 'DeleteCouponGroupWithUnusedCouponsMutation'
  couponGroup?: Maybe<CouponGroupType>
}

/** 미사용 쿠폰 삭제 */
export type DeleteCouponMutation = {
  __typename?: 'DeleteCouponMutation'
  coupon?: Maybe<CouponType>
}

/** 미사용 쿠폰 전체 삭제 */
export type DeleteCouponsByGroupMutation = {
  __typename?: 'DeleteCouponsByGroupMutation'
  couponGroup?: Maybe<CouponGroupType>
}

/** 미사용 쿠폰 선택 복수 삭제 */
export type DeleteCouponsMutation = {
  __typename?: 'DeleteCouponsMutation'
  /** Returns how many objects were affected. */
  count: Scalars['Int']
}

/** 고객 등급 삭제 */
export type DeleteCustomerGradeMutation = {
  __typename?: 'DeleteCustomerGradeMutation'
  customerGrade?: Maybe<CustomerGradeType>
}

/** 사용자 템플릿 주소 삭제 */
export type DeleteCustomerTemplateAddressMutation = {
  __typename?: 'DeleteCustomerTemplateAddressMutation'
  customerTemplateAddress?: Maybe<CustomerTemplateAddressType>
}

/** DisplayCollection 삭제 */
export type DeleteDisplayCollectionMutation = {
  __typename?: 'DeleteDisplayCollectionMutation'
  displayCollection?: Maybe<DisplayCollectionType>
}

/** DisplayCollection 복수 삭제 */
export type DeleteDisplayCollectionsMutation = {
  __typename?: 'DeleteDisplayCollectionsMutation'
  /** Returns how many objects were affected. */
  count: Scalars['Int']
}

/** DisplayProductInfo 삭제 */
export type DeleteDisplayProductInfoMutation = {
  __typename?: 'DeleteDisplayProductInfoMutation'
  displayProductInfo?: Maybe<DisplayProductInfoType>
}

/** DisplayProductInfo 복수 삭제 */
export type DeleteDisplayProductInfosMutation = {
  __typename?: 'DeleteDisplayProductInfosMutation'
  /** Returns how many objects were affected. */
  count: Scalars['Int']
}

export type DeleteInactiveUsersInput = {
  ids: Array<InputMaybe<Scalars['ID']>>
}

/** 탈퇴 회원 삭제 */
export type DeleteInactiveUsersMutation = {
  __typename?: 'DeleteInactiveUsersMutation'
  /** Returns how many objects were affected. */
  count: Scalars['Int']
}

/** 미등록 상품 복수 삭제 */
export type DeleteIncompleteProductsMutation = {
  __typename?: 'DeleteIncompleteProductsMutation'
  /** Returns how many objects were affected. */
  count: Scalars['Int']
}

/** 공지 사항 복수 삭제 */
export type DeleteNoticesMutation = {
  __typename?: 'DeleteNoticesMutation'
  /** Returns how many objects were affected. */
  count: Scalars['Int']
}

/** CS용 관리자 메모 삭제 */
export type DeleteOrderItemAdminNoteMutation = {
  __typename?: 'DeleteOrderItemAdminNoteMutation'
  orderItemAdminNote?: Maybe<OrderItemAdminNoteType>
}

/** 상품 할인 생성 */
export type DeleteProductPromotionMutation = {
  __typename?: 'DeleteProductPromotionMutation'
  productPromotion?: Maybe<ProductPromotionType>
}

/** 상품 문의 삭제 */
export type DeleteProductQuestionMutation = {
  __typename?: 'DeleteProductQuestionMutation'
  productQna?: Maybe<ProductQnaType>
}

/** 상품 문의 복수 삭제 (관리자) */
export type DeleteProductQuestionsMutation = {
  __typename?: 'DeleteProductQuestionsMutation'
  /** Returns how many objects were affected. */
  count: Scalars['Int']
}

/** Push 삭제 */
export type DeletePushMutation = {
  __typename?: 'DeletePushMutation'
  informPushQueue?: Maybe<InformPushQueueType>
}

export type DeleteQnasInput = {
  ids: Array<InputMaybe<Scalars['ID']>>
}

/** 도서산간지역 삭제 */
export type DeleteRemoteAreaMutation = {
  __typename?: 'DeleteRemoteAreaMutation'
  remoteArea?: Maybe<RemoteAreaType>
}

/** 요청 스테이터스 삭제 */
export type DeleteRequestMutation = {
  __typename?: 'DeleteRequestMutation'
  status?: Maybe<StatusType>
}

/** 관리자/셀러에 의해서 삭제 */
export type DeleteRequestsMutation = {
  __typename?: 'DeleteRequestsMutation'
  statuses?: Maybe<Array<Maybe<StatusType>>>
}

/** 리뷰 삭제 (고객) */
export type DeleteReviewMutation = {
  __typename?: 'DeleteReviewMutation'
  review?: Maybe<ReviewType>
}

export type DeleteReviewsInput = {
  ids: Array<InputMaybe<Scalars['ID']>>
}

/** 리뷰 복수 삭제 (관리자) */
export type DeleteReviewsMutation = {
  __typename?: 'DeleteReviewsMutation'
  count?: Maybe<Scalars['Int']>
}

/** 셀러 유저 삭제 */
export type DeleteSellerManagerMutation = {
  __typename?: 'DeleteSellerManagerMutation'
  sellerUser?: Maybe<SellerUserType>
}

/** 셀러 템플릿 주소 삭제 */
export type DeleteSellerTemplateAddressMutation = {
  __typename?: 'DeleteSellerTemplateAddressMutation'
  sellerTemplateAddress?: Maybe<SellerTemplateAddressType>
}

/** 설정 수정 */
export type DeleteServerConfigMutation = {
  __typename?: 'DeleteServerConfigMutation'
  serverConfig?: Maybe<ServerConfigType>
}

/** 1:1 문의 삭제 */
export type DeleteServiceQuestionMutation = {
  __typename?: 'DeleteServiceQuestionMutation'
  serviceQna?: Maybe<ServiceQnaType>
}

/** 1:1문의 복수 삭제 (관리자) */
export type DeleteServiceQuestionsMutation = {
  __typename?: 'DeleteServiceQuestionsMutation'
  /** Returns how many objects were affected. */
  count: Scalars['Int']
}

/** 배송 회사 정보 삭제 */
export type DeleteShippingCompanyMutation = {
  __typename?: 'DeleteShippingCompanyMutation'
  shippingCompany?: Maybe<ShippingCompanyType>
}

/** 배송 프리셋 정보 삭제 (deprecated, 삭제 안하기로 결정) */
export type DeleteShippingPresetMutation = {
  __typename?: 'DeleteShippingPresetMutation'
  shippingPreset?: Maybe<ShippingPresetType>
}

export type DesignersBannerInfoType = {
  __typename?: 'DesignersBannerInfoType'
  adId?: Maybe<Scalars['ID']>
  adName?: Maybe<Scalars['String']>
  adSlug?: Maybe<Scalars['String']>
  brandText?: Maybe<Scalars['String']>
  images?: Maybe<Array<Maybe<BannerImageType>>>
}

export type DeviceInput = {
  /** 현재 로그인된 앱의 버전 */
  appVersion?: InputMaybe<Scalars['String']>
  /** 접속환경: WEB / APP */
  clientType?: InputMaybe<Scalars['String']>
  /** 로그인 유지 여부 */
  keepSignIn?: InputMaybe<Scalars['Boolean']>
  /** 단말의 모델 명(iPhone X 등) */
  modelNumber?: InputMaybe<Scalars['String']>
  /** OS의 버전 정보(4.4.2 등) */
  osVersion?: InputMaybe<Scalars['String']>
  /** OS의 타입: ANDROID, IOS, MOBILE, PC */
  platformType?: InputMaybe<Scalars['String']>
  /** 푸시용 토큰 */
  pushToken?: InputMaybe<Scalars['String']>
  /** 리프레쉬용 토큰 */
  refreshToken?: InputMaybe<Scalars['String']>
  /** 로그인용 토큰 */
  token?: InputMaybe<Scalars['String']>
  /** Device 식별용 UUID */
  uuid: Scalars['String']
}

/** An enumeration. */
export enum DevicePlatformType {
  /** ANDROID */
  Android = 'ANDROID',
  /** IOS */
  Ios = 'IOS',
  /** MOBILE */
  Mobile = 'MOBILE',
  /** PC */
  Pc = 'PC',
}

/**
 * 사용자 디바이스 정보
 * ---
 * 사용자 기기 정보는 사용자가 직접 입력하지 않고, 앱에서 수집하여 전달해야함
 */
export type DeviceType = Node & {
  __typename?: 'DeviceType'
  /** 현재 로그인된 앱의 버전 */
  appVersion?: Maybe<Scalars['String']>
  /** 인증 만료 시간 */
  expiredAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 로그인 유지 여부 */
  keepSignIn: Scalars['Boolean']
  /** 마지막 로그인시간 */
  lastLoggedAt: Scalars['DateTime']
  /** 단말의 모델 명(iPhone X 등) */
  modelNumber?: Maybe<Scalars['String']>
  /** OS의 버전 정보(4.4.2 등) */
  osVersion: Scalars['String']
  /** OS의 타입: ANDROID, IOS, MOBILE, PC */
  platformType: DevicePlatformType
  /** 푸시용 토큰 */
  pushToken?: Maybe<Scalars['String']>
  /** 리프레쉬용 토큰 */
  refreshToken?: Maybe<Scalars['String']>
  /** 로그인한 유형 */
  socialService?: Maybe<SocialServiceType>
  /** 로그인용 토큰 */
  token: Scalars['String']
  /** Device 식별용 UUID */
  uuid: Scalars['String']
}

export type DeviceTypeConnection = {
  __typename?: 'DeviceTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DeviceTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `DeviceType` and its cursor. */
export type DeviceTypeEdge = {
  __typename?: 'DeviceTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<DeviceType>
}

/** 배너 노출 비허용 */
export type DisallowBannerMutation = {
  __typename?: 'DisallowBannerMutation'
  banner?: Maybe<BannerType>
}

/** An enumeration. */
export enum DisplayCollectionCollectionType {
  /** AD */
  Ad = 'AD',
  /** BEST */
  Best = 'BEST',
  /** BEST_REVIEW */
  BestReview = 'BEST_REVIEW',
  /** MAGAZINE_BACKGROUND_IMAGE */
  MagazineBackgroundImage = 'MAGAZINE_BACKGROUND_IMAGE',
  /** MAGAZINE_IMAGE */
  MagazineImage = 'MAGAZINE_IMAGE',
  /** NEW_ARRIVALS */
  NewArrivals = 'NEW_ARRIVALS',
  /** NORMAL */
  Normal = 'NORMAL',
  /** TODAY_SPECIAL_PRICE */
  TodaySpecialPrice = 'TODAY_SPECIAL_PRICE',
}

export type DisplayCollectionType = Node & {
  __typename?: 'DisplayCollectionType'
  /** 프로모션 */
  ad?: Maybe<AdType>
  /** 배경 이미지 */
  backgroundImage?: Maybe<Scalars['String']>
  /** 콜렉션 종류, BEST: 베스트, AD: 이벤트용, TODAY_SPECIAL_PRICE: Today 특가, COVERFLOW: 커버플로우, NORMAL: 일반, BEST_REVIEW: 리뷰좋은상품, MAGAZINE: 매거진이미지, MAGAZINE_BACKGROUND: 매거진 배경이미지 */
  collectionType: DisplayCollectionCollectionType
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 설명 */
  description?: Maybe<Scalars['String']>
  /** 전시용 상품 콜렉션 */
  displayCollectionDisplayProductInfos: DisplayProductInfoTypeConnection
  /** 디스플레이 컬렉션 */
  displayCollectionGnbs: GnbTypeConnection
  /** 디스플레이 순서 */
  displayOrder: Scalars['Int']
  displayProductInfos?: Maybe<DisplayProductInfoTypeConnection>
  /** 종료일 */
  endedAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 노출 여부 */
  isShown: Scalars['Boolean']
  /** 콜렉션명 */
  name?: Maybe<Scalars['String']>
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType: AdPetType
  /** 시작일 */
  startedAt?: Maybe<Scalars['DateTime']>
  /** 콜렉션 탭 이름(기획전페이지 전용) */
  tabName?: Maybe<Scalars['String']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type DisplayCollectionTypeDisplayCollectionDisplayProductInfosArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brand?: InputMaybe<Scalars['String']>
  displayCollection?: InputMaybe<Scalars['String']>
  excludeProductRealSoldOut?: InputMaybe<Scalars['Boolean']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isProductRange?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
}

export type DisplayCollectionTypeDisplayCollectionGnbsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type DisplayCollectionTypeDisplayProductInfosArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brand?: InputMaybe<Scalars['String']>
  displayCollection?: InputMaybe<Scalars['String']>
  excludeProductRealSoldOut?: InputMaybe<Scalars['Boolean']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isProductRange?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productCode?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
}

export type DisplayCollectionTypeConnection = {
  __typename?: 'DisplayCollectionTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DisplayCollectionTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `DisplayCollectionType` and its cursor. */
export type DisplayCollectionTypeEdge = {
  __typename?: 'DisplayCollectionTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<DisplayCollectionType>
}

export type DisplayProductInfoType = Node & {
  __typename?: 'DisplayProductInfoType'
  /** 전시용 상품 콜렉션 */
  displayCollection?: Maybe<DisplayCollectionType>
  /** 디스플레이 순서 */
  displayOrder: Scalars['Int']
  /** 종료일 */
  endedAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  product?: Maybe<ProductType>
  productpromotion?: Maybe<ProductPromotionType>
  /** 시작일 */
  startedAt?: Maybe<Scalars['DateTime']>
}

export type DisplayProductInfoTypeConnection = {
  __typename?: 'DisplayProductInfoTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DisplayProductInfoTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `DisplayProductInfoType` and its cursor. */
export type DisplayProductInfoTypeEdge = {
  __typename?: 'DisplayProductInfoTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<DisplayProductInfoType>
}

export type DownloadEventCouponsInput = {
  /** 쿠폰 그룹 */
  couponGroup?: InputMaybe<Scalars['ID']>
}

/** 이벤트 쿠폰 발급 */
export type DownloadEventCouponsMutation = {
  __typename?: 'DownloadEventCouponsMutation'
  coupons?: Maybe<Array<Maybe<CouponType>>>
}

export type EmailSigninInput = {
  device: DeviceInput
  user: UserSigninInput
}

/** Email 로그인 */
export type EmailSigninMutation = {
  __typename?: 'EmailSigninMutation'
  device?: Maybe<DeviceType>
  isReactivate?: Maybe<Scalars['Boolean']>
  prevLoggedAt?: Maybe<Scalars['DateTime']>
  user?: Maybe<UserType>
}

export type EmailSignupInput = {
  device: DeviceInput
  user: EmailUserSignupInput
}

/** Email 회원 가입 */
export type EmailSignupMutation = {
  __typename?: 'EmailSignupMutation'
  device?: Maybe<DeviceType>
  user?: Maybe<UserType>
}

export type EmailUserSignupInput = {
  /** 이벤트 및 혜택 안내 동의 여부 */
  agreeMarketing?: InputMaybe<Scalars['Boolean']>
  /** 병원 알림 허용 여부 */
  agreeMarketingHospital?: InputMaybe<Scalars['Boolean']>
  /** 플레이 알림 허용 여부 */
  agreeMarketingPlay?: InputMaybe<Scalars['Boolean']>
  /** 야간 마케팅 알림 수신 동의 여부 */
  agreeNightMarketing?: InputMaybe<Scalars['Boolean']>
  /** 개인정보 수집 및 이용 동의 */
  agreePersonalInfoCollection?: InputMaybe<Scalars['Boolean']>
  /** 홍보 및 마케팅 동의 여부 */
  agreePrMarketing?: InputMaybe<Scalars['Boolean']>
  /** 서비스 알림 허용 여부 */
  agreeServicePushAllowed?: InputMaybe<Scalars['Boolean']>
  /** 생일 */
  birthday?: InputMaybe<Scalars['Date']>
  email: Scalars['String']
  /** 성별, MALE(남성), FEMALE(여성), UNKNOWN(알 수 없음) */
  gender?: InputMaybe<Scalars['String']>
  /** 추천인 코드 */
  invitationCode?: InputMaybe<Scalars['String']>
  /** 사용자 이름 */
  name: Scalars['String']
  password: Scalars['String']
  /** 인증번호 GID */
  userCertification?: InputMaybe<Scalars['ID']>
}

export type EstimateOrderInput = {
  /** 주문 쿠폰 */
  coupon?: InputMaybe<Scalars['ID']>
  customerAddress?: InputMaybe<OrderCustomerAddressInput>
  /** 사용할 포인트 */
  mileage?: InputMaybe<Scalars['Int']>
  orderItems: Array<InputMaybe<OrderOrderItemInput>>
}

/** 주문 전 가격 미리보기 */
export type EstimateOrderMutation = {
  __typename?: 'EstimateOrderMutation'
  order?: Maybe<OrderType>
}

/** An enumeration. */
export enum EventTypeEnum {
  Cat = 'CAT',
  Dog = 'DOG',
  Product = 'PRODUCT',
}

/** An enumeration. */
export enum ExcelExportRequestRequestUserType {
  /** ADMIN */
  Admin = 'ADMIN',
  /** ALL */
  All = 'ALL',
  /** CUSTOMER */
  Customer = 'CUSTOMER',
  /** INFLUENCER */
  Influencer = 'INFLUENCER',
  /** NONE */
  None = 'NONE',
  /** SELLER */
  Seller = 'SELLER',
}

/** An enumeration. */
export enum ExcelExportRequestStatus {
  /** 취소 요청중 */
  Cancel = 'CANCEL',
  /** 취소됨 */
  Canceled = 'CANCELED',
  /** 완료 */
  Complete = 'COMPLETE',
  /** 에러 */
  Error = 'ERROR',
  /** 처리중 */
  InProgress = 'IN_PROGRESS',
}

export type ExcelExportRequestType = Node & {
  __typename?: 'ExcelExportRequestType'
  /** 완료일 */
  completedAt?: Maybe<Scalars['DateTime']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 요청 엑셀 파일 */
  excelFile?: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  requestName?: Maybe<Scalars['String']>
  requestSeller?: Maybe<SellerType>
  requestUser?: Maybe<UserType>
  /** 요청자 타입 (SELLER/INFLUENCER/ADMIN) */
  requestUserType: ExcelExportRequestRequestUserType
  /** 엑셀 요청 상태, IN_PROGRESS: 처리중, COMPLETE: 완료, ERROR: 에러 발생 */
  status: ExcelExportRequestStatus
}

export type ExcelExportRequestTypeConnection = {
  __typename?: 'ExcelExportRequestTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExcelExportRequestTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ExcelExportRequestType` and its cursor. */
export type ExcelExportRequestTypeEdge = {
  __typename?: 'ExcelExportRequestTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ExcelExportRequestType>
}

/** An enumeration. */
export enum ExcelImportRequestImportType {
  /** MANUAL_COUPON */
  ManualCoupon = 'MANUAL_COUPON',
  /** MILEAGE */
  Mileage = 'MILEAGE',
  /** PRODUCT */
  Product = 'PRODUCT',
  /** PRODUCT_PROMOTION */
  ProductPromotion = 'PRODUCT_PROMOTION',
  /** REMOTE_AREA */
  RemoteArea = 'REMOTE_AREA',
  /** SHIPPING */
  Shipping = 'SHIPPING',
}

/** An enumeration. */
export enum ExcelImportRequestStatus {
  /** 취소 요청중 */
  Cancel = 'CANCEL',
  /** 취소됨 */
  Canceled = 'CANCELED',
  /** 완료 */
  Complete = 'COMPLETE',
  /** 에러 */
  Error = 'ERROR',
  /** 처리중 */
  InProgress = 'IN_PROGRESS',
}

export type ExcelImportRequestType = Node & {
  __typename?: 'ExcelImportRequestType'
  /** 완료일 */
  completedAt?: Maybe<Scalars['DateTime']>
  completedRow: Scalars['Int']
  /** 쿠폰 그룹 */
  couponGroup?: Maybe<CouponGroupType>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 에러 메시지 */
  errorMessage?: Maybe<Scalars['String']>
  /** 요청 엑셀 파일 */
  excelFile?: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** Import할 파일 형식 */
  importType: ExcelImportRequestImportType
  requestUser?: Maybe<UserType>
  requestedRow: Scalars['Int']
  /** 엑셀 요청 상태, IN_PROGRESS: 처리중, COMPLETE: 완료, ERROR: 에러 발생 */
  status: ExcelImportRequestStatus
  /** 상태 */
  statusText?: Maybe<Scalars['String']>
}

export type ExcelImportRequestTypeConnection = {
  __typename?: 'ExcelImportRequestTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExcelImportRequestTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ExcelImportRequestType` and its cursor. */
export type ExcelImportRequestTypeEdge = {
  __typename?: 'ExcelImportRequestTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ExcelImportRequestType>
}

export type ExchangeOrderItemsInput = {
  /** 안내 메시지 */
  guideMessage?: InputMaybe<Scalars['String']>
  orderItems: Array<InputMaybe<OrderItemInput>>
  /** request_type이 ETC일 때 기타 사유 입력필드 */
  requestMessage?: InputMaybe<Scalars['String']>
  /** 상품불량 및 파손 (DEFECTIVE_PRODUCT), 오배송 (WRONG_DELIVERY), 배송지연 (DELAY_DELIVERY), 상품 정보 상이(DIFFERENT_PRODUCT_INFO), 서비스 및 상품 불만족(DISSATISFACTION), 단순 변심 (CHANGE_MIND), 상품 옵션을 잘못 선택함 (MISCHOICE_PRODUCT_OPTION), 기타 (ETC) */
  requestType: Scalars['String']
  /** 책임 주체, SELLER/CUSTOMER */
  responsibility: Scalars['String']
}

/** 판매자가 교환처리 */
export type ExchangeOrderItemsMutation = {
  __typename?: 'ExchangeOrderItemsMutation'
  newOrderItems?: Maybe<Array<Maybe<OrderItemType>>>
  orderItems?: Maybe<Array<Maybe<OrderItemType>>>
}

export type ExpectMembershipBenefitType = {
  __typename?: 'ExpectMembershipBenefitType'
  /** 3개월 평균 적립금 */
  mileage?: Maybe<Scalars['Int']>
  /** 3개월 평균 주문 수 */
  orderCount?: Maybe<Scalars['Int']>
  /** 3개월간 무료 배송 금액 */
  shippingDiscountAmount?: Maybe<Scalars['Decimal']>
}

/** 아이디 찾기 */
export type FindId = {
  __typename?: 'FindId'
  userInfo?: Maybe<UserInfoType>
}

/** Ad 종료 */
export type FinishAdMutation = {
  __typename?: 'FinishAdMutation'
  ad?: Maybe<AdType>
}

export type FitpetLinkType = Node & {
  __typename?: 'FitpetLinkType'
  clicks: Scalars['Int']
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  fullUrl: Scalars['String']
  /** The ID of the object. */
  id: Scalars['ID']
  shortenUrl?: Maybe<Scalars['String']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type FitpetLinkTypeConnection = {
  __typename?: 'FitpetLinkTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FitpetLinkTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `FitpetLinkType` and its cursor. */
export type FitpetLinkTypeEdge = {
  __typename?: 'FitpetLinkTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<FitpetLinkType>
}

/** An enumeration. */
export enum GnbGnbType {
  /** AD */
  Ad = 'AD',
  /** AD_LIST */
  AdList = 'AD_LIST',
  /** BEST */
  Best = 'BEST',
  /** BRAND */
  Brand = 'BRAND',
  /** BRAND_LIST */
  BrandList = 'BRAND_LIST',
  /** CATEGORY */
  Category = 'CATEGORY',
  /** DEAL_100 */
  Deal_100 = 'DEAL_100',
  /** DEAL_990 */
  Deal_990 = 'DEAL_990',
  /** DISPLAY_COLLECTION */
  DisplayCollection = 'DISPLAY_COLLECTION',
  /** HOME */
  Home = 'HOME',
  /** PRODUCT */
  Product = 'PRODUCT',
}

export type GnbType = Node & {
  __typename?: 'GnbType'
  /** 이벤트 */
  ad?: Maybe<AdType>
  /** 브랜드 */
  brand?: Maybe<ShippingPresetType>
  /** 카테고리 */
  category?: Maybe<CategoryType>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 디스플레이 컬렉션 */
  displayCollection?: Maybe<DisplayCollectionType>
  /** 디스플레이 순서 */
  displayOrder: Scalars['Int']
  /** 종료일 */
  endedAt?: Maybe<Scalars['DateTime']>
  /** Gnb 종류, AD, AD_LIST, DISPLAY_COLLECTION, BRAND, BRAND_LIST, CATEGORY, BEST, PRODUCT, DEAL_100, DEAL_990 */
  gnbType: GnbGnbType
  /** The ID of the object. */
  id: Scalars['ID']
  /** 강조 여부 */
  isEmphasize: Scalars['Boolean']
  /** 노출 여부 */
  isShown: Scalars['Boolean']
  /** 연결할 URL */
  link?: Maybe<Scalars['String']>
  /** 섹션명 */
  name?: Maybe<Scalars['String']>
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType: AdPetType
  /** 상품 */
  product?: Maybe<ProductType>
  /** 시작일 */
  startedAt?: Maybe<Scalars['DateTime']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type GnbTypeConnection = {
  __typename?: 'GnbTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GnbTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `GnbType` and its cursor. */
export type GnbTypeEdge = {
  __typename?: 'GnbTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<GnbType>
}

export type HomeSectionConnection = {
  __typename?: 'HomeSectionConnection'
  abTestGroupCode?: Maybe<Scalars['String']>
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<HomeSectionEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `HomeSection` and its cursor. */
export type HomeSectionEdge = {
  __typename?: 'HomeSectionEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<HomeSectionType>
}

export type HomeSectionType = {
  __typename?: 'HomeSectionType'
  /** 배너 종류 */
  bannerType?: Maybe<Scalars['String']>
  /** 배너들 */
  banners?: Maybe<Scalars['JSONString']>
  /** 브랜드들 */
  brands?: Maybe<Scalars['JSONString']>
  /** 디스플레이컬렉션 */
  displayCollection?: Maybe<Scalars['JSONString']>
  /** 홈 섹션 종류, DISPLAY_COLLECTION, BANNER, CATEGORY, WEEKLY_BEST, BRAND, RECOMMEND, MAGAZINE_IMAGE, MAGAZINE_BACKGROUND_IMAGE, BEST_REVIEW */
  homeSectionType?: Maybe<Scalars['String']>
  /** 노출 여부 */
  isShown?: Maybe<Scalars['Boolean']>
  /** 섹션명 */
  name?: Maybe<Scalars['String']>
  /** 추천 개인화 상품들 */
  recommendedPersonalProducts?: Maybe<Scalars['JSONString']>
  /** 추천 연관 상품들 */
  recommendedRelatedProducts?: Maybe<Scalars['JSONString']>
  /** 배너들 */
  typedBanners?: Maybe<Array<Maybe<TypedBannerType>>>
  /** 리뷰좋은 상품 */
  typedBestReview?: Maybe<Array<Maybe<TypedBestReviewType>>>
  /** 브랜드들 */
  typedBrands?: Maybe<Array<Maybe<TypedBrandType>>>
  /** 디스플레이컬렉션 */
  typedDisplayCollections?: Maybe<TypedDisplayCollectionType>
  /** 추천 연관 상품들 */
  typedRecommendedRelatedProducts?: Maybe<Array<Maybe<TypedRecommendedRelatedProductType>>>
  /** 주간 베스트 디스플레이컬렉션들 */
  typedWeeklyBestCollections?: Maybe<Array<Maybe<TypedWeeklyBestCollectionType>>>
  /** 주간 베스트 디스플레이컬렉션들 */
  weeklyBestCollections?: Maybe<Scalars['JSONString']>
}

export type HomeType = {
  __typename?: 'HomeType'
  /** 브랜드 */
  brands?: Maybe<Scalars['JSONString']>
  /** 디스플레이 콜렉션 */
  displayCollections?: Maybe<Scalars['JSONString']>
  /** 홈 하단 배너 */
  homeBottomBanners?: Maybe<Scalars['JSONString']>
  /** 홈 중단 배너 */
  homeMiddleBanners?: Maybe<Scalars['JSONString']>
  /** 홈 하단 배너 */
  multiLineBanners?: Maybe<Scalars['JSONString']>
  /** 탑롤배너 */
  topRollBanners?: Maybe<Scalars['JSONString']>
  /** 탑슬림배너 */
  topSlimBanners?: Maybe<Scalars['JSONString']>
}

/** An enumeration. */
export enum InactiveUserInactiveType {
  /** INACTIVATED */
  Inactivated = 'INACTIVATED',
  /** LEFT */
  Left = 'LEFT',
}

export type InactiveUserType = Node & {
  __typename?: 'InactiveUserType'
  /** 예금주명 */
  accountHolderName?: Maybe<Scalars['String']>
  /** 계좌번호 */
  accountNumber?: Maybe<Scalars['String']>
  /** 마케팅 수신 동의 여부 */
  agreeMarketing: Scalars['Boolean']
  /** 야간 마케팅 알림 수신 동의 여부 */
  agreeNightMarketing: Scalars['Boolean']
  /** 은행 코드 */
  bankCode?: Maybe<Scalars['String']>
  /** 은행 이름 */
  bankName?: Maybe<Scalars['String']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  email: Scalars['String']
  /** The ID of the object. */
  id: Scalars['ID']
  /** 비활성회원 상태: INACTIVATED(휴면), LEFT(탈퇴) */
  inactiveType: InactiveUserInactiveType
  /** 사용자 핸드폰 번호 */
  mobileNumber?: Maybe<Scalars['String']>
  /** 사용자 이름 */
  name: Scalars['String']
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  user?: Maybe<UserType>
  /** 사용자 이메일 주소 */
  username: Scalars['String']
}

export type InfluencerStatisticColumnType = {
  __typename?: 'InfluencerStatisticColumnType'
  avgProductPrice?: Maybe<Scalars['Int']>
  guestUnitPrice?: Maybe<Scalars['Int']>
  influencerName?: Maybe<Scalars['String']>
  totalAmount?: Maybe<Scalars['Int']>
  totalCouponDiscountAmount?: Maybe<Scalars['Int']>
  totalCustomerCount?: Maybe<Scalars['Int']>
  totalMileageDiscountAmount?: Maybe<Scalars['Int']>
  totalOrderCount?: Maybe<Scalars['Int']>
  totalSoldAmount?: Maybe<Scalars['Int']>
  totalSoldCount?: Maybe<Scalars['Int']>
}

export type InfluencerStatisticColumnTypeConnection = {
  __typename?: 'InfluencerStatisticColumnTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InfluencerStatisticColumnTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `InfluencerStatisticColumnType` and its cursor. */
export type InfluencerStatisticColumnTypeEdge = {
  __typename?: 'InfluencerStatisticColumnTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<InfluencerStatisticColumnType>
}

export type InformEmailAttachmentType = Node & {
  __typename?: 'InformEmailAttachmentType'
  /** 이메일 첨부 파일 */
  file?: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 첨부파일 */
  informEmailQueue?: Maybe<InformEmailQueueType>
}

export type InformEmailAttachmentTypeConnection = {
  __typename?: 'InformEmailAttachmentTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InformEmailAttachmentTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `InformEmailAttachmentType` and its cursor. */
export type InformEmailAttachmentTypeEdge = {
  __typename?: 'InformEmailAttachmentTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<InformEmailAttachmentType>
}

/** An enumeration. */
export enum InformEmailQueueTemplateName {
  /** M00_DEFAULT */
  M00Default = 'M00_DEFAULT',
  /** M01_ORDER_COMPLETE */
  M01OrderComplete = 'M01_ORDER_COMPLETE',
  /** M02_ORDER_PAYMENT_CONFIRM */
  M02OrderPaymentConfirm = 'M02_ORDER_PAYMENT_CONFIRM',
  /** M04_USER_SIGN_UP */
  M04UserSignUp = 'M04_USER_SIGN_UP',
  /** M05_USER_CERTIFICATION */
  M05UserCertification = 'M05_USER_CERTIFICATION',
  /** M07_USER_LEAVE */
  M07UserLeave = 'M07_USER_LEAVE',
  /** M10_USER_MARKETING_CHANGE */
  M10UserMarketingChange = 'M10_USER_MARKETING_CHANGE',
  /** M11_USER_PASSWORD_CHANGE */
  M11UserPasswordChange = 'M11_USER_PASSWORD_CHANGE',
  /** M13_USER_GRADE_CHANGE */
  M13UserGradeChange = 'M13_USER_GRADE_CHANGE',
  /** M14_STYLE_WAVER_SUPPORT */
  M14StyleWaverSupport = 'M14_STYLE_WAVER_SUPPORT',
}

export type InformEmailQueueType = Node & {
  __typename?: 'InformEmailQueueType'
  /** 메일 주소 리스트 */
  emails?: Maybe<Scalars['JSONString']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 첨부파일 */
  informEmailQueueInformEmailAttachments: InformEmailAttachmentTypeConnection
  /** 발송예정일 */
  sendAt?: Maybe<Scalars['DateTime']>
  /** 이메일 제목 */
  subject: Scalars['String']
  /** 요청 템플릿, 비워놓을 경우 기본템플릿이 사용됨 */
  templateName: InformEmailQueueTemplateName
  /** 내용 */
  text: Scalars['String']
  /** 본문 제목 */
  title: Scalars['String']
}

export type InformEmailQueueTypeInformEmailQueueInformEmailAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type InformEmailQueueTypeConnection = {
  __typename?: 'InformEmailQueueTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InformEmailQueueTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `InformEmailQueueType` and its cursor. */
export type InformEmailQueueTypeEdge = {
  __typename?: 'InformEmailQueueTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<InformEmailQueueType>
}

export type InformKakaoQueueType = Node & {
  __typename?: 'InformKakaoQueueType'
  /** 쿠폰그룹 */
  couponGroup?: Maybe<CouponGroupType>
  /** 카카오 데이터 */
  data?: Maybe<Scalars['JSONString']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 발송 여부 */
  isSent: Scalars['Boolean']
  /** 발송예정일 */
  sendAt?: Maybe<Scalars['DateTime']>
  /** 발송 실패 횟수 */
  sentFailCount: Scalars['Int']
  /** 발송 성공 횟수 */
  sentSuccessCount: Scalars['Int']
  /** 템플릿 번호 */
  template: Scalars['String']
}

/** An enumeration. */
export enum InformPushQueuePushQueueStatus {
  /** 발송예정 */
  Send = 'SEND',
  /** 발송성공 */
  Success = 'SUCCESS',
  /** 임시저장 */
  Temp = 'TEMP',
}

export type InformPushQueueType = Node & {
  __typename?: 'InformPushQueueType'
  /** 발송 담당자 */
  fitpetAdmin?: Maybe<UserType>
  grades?: Maybe<CustomerGradeTypeConnection>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 관리자용 메모 */
  memo: Scalars['String']
  /** Push 데이터 */
  push?: Maybe<PushType>
  /** TEMP: 임시저장/SEND: 발송예정/SUCCESS: 발송성공 */
  pushQueueStatus: InformPushQueuePushQueueStatus
  pushQueueStatusDisplay?: Maybe<Scalars['String']>
  readCount?: Maybe<Scalars['Int']>
  /** 발송예정일시 */
  sendAt?: Maybe<Scalars['DateTime']>
  /** 발송 성공 횟수 */
  sentSuccessCount: Scalars['Int']
  /** 발송 대상 */
  target?: Maybe<Scalars['JSONString']>
  /** 수신 회원 정보, 회원정보/성별/OS 등 */
  targetInfo?: Maybe<Scalars['JSONString']>
  /** 대상 키 */
  targetKey: Scalars['String']
  totalReadCount?: Maybe<Scalars['Int']>
  totalUserCount?: Maybe<Scalars['Int']>
  users?: Maybe<UserTypeConnection>
}

export type InformPushQueueTypeGradesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type InformPushQueueTypeUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type InformPushQueueTypeConnection = {
  __typename?: 'InformPushQueueTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InformPushQueueTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `InformPushQueueType` and its cursor. */
export type InformPushQueueTypeEdge = {
  __typename?: 'InformPushQueueTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<InformPushQueueType>
}

export type InformSmsHistoryType = Node & {
  __typename?: 'InformSmsHistoryType'
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 핸드폰 번호 리스트 */
  mobileNumbers?: Maybe<Scalars['JSONString']>
  /** 전송 오류메세지 */
  resultMessage: Scalars['String']
  /** 전송 스테이터스 */
  resultStatus: Scalars['String']
  /** 내용 */
  text: Scalars['String']
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type InformSmsHistoryTypeConnection = {
  __typename?: 'InformSmsHistoryTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InformSmsHistoryTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `InformSmsHistoryType` and its cursor. */
export type InformSmsHistoryTypeEdge = {
  __typename?: 'InformSmsHistoryTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<InformSmsHistoryType>
}

export type InformSmsQueueType = Node & {
  __typename?: 'InformSmsQueueType'
  /** The ID of the object. */
  id: Scalars['ID']
  /** 핸드폰 번호 리스트 */
  mobileNumbers?: Maybe<Scalars['JSONString']>
  /** 발송예정일 */
  sendAt?: Maybe<Scalars['DateTime']>
  /** 내용 */
  text: Scalars['String']
}

export type InformSmsQueueTypeConnection = {
  __typename?: 'InformSmsQueueTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InformSmsQueueTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `InformSmsQueueType` and its cursor. */
export type InformSmsQueueTypeEdge = {
  __typename?: 'InformSmsQueueTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<InformSmsQueueType>
}

export type InitDataType = {
  __typename?: 'InitDataType'
  banks?: Maybe<Array<Maybe<BankType>>>
  customerGrades?: Maybe<Array<Maybe<CustomerGradeType>>>
  mileageRewardPolicy?: Maybe<MileageRewardPolicyType>
  shippingCompanies?: Maybe<Array<Maybe<ShippingCompanyType>>>
}

/** 액션파워 연동 (유저 이미지 업로드 버튼 클릭) */
export type IntegrateActionPowerOrderItemMutation = {
  __typename?: 'IntegrateActionPowerOrderItemMutation'
  orderItem?: Maybe<OrderItemType>
}

/** 결제 페이지 접근시 구매 하려는 상품들의 유효성 검사 (액션파워 진행시 구버전 앱 호환 문제로 개발) */
export type InvalidConditionBeforeEstimateOrderType = {
  __typename?: 'InvalidConditionBeforeEstimateOrderType'
  /** 유효하지 않은 액션파워 상품들 */
  actionPowerProducts?: Maybe<Array<Maybe<ProductType>>>
}

export type InvalidConditionBeforeEstimateOrderTypeInput = {
  /** 상품 옵션 */
  productOption: Scalars['ID']
  /** 주문 갯수 */
  quantity: Scalars['Int']
}

export type IssueCouponCodeInput = {
  /** 쿠폰 그룹 */
  couponGroup: Scalars['ID']
}

/** 쿠폰 코드 발급 */
export type IssueCouponCodeMutation = {
  __typename?: 'IssueCouponCodeMutation'
  coupon?: Maybe<CouponType>
}

export type IssueManualCouponInput = {
  /** 쿠폰 그룹 */
  couponGroup: Scalars['ID']
  users: Array<InputMaybe<Scalars['ID']>>
}

export type IssueManualCouponsByExcelInput = {
  excel: Scalars['Upload']
  /** CouponGroup의 ID */
  id: Scalars['ID']
}

/** 엑셀 파일로 쿠폰 수동 생성 */
export type IssueManualCouponsByExcelMutation = {
  __typename?: 'IssueManualCouponsByExcelMutation'
  /** 성공한 갯수 */
  count?: Maybe<Scalars['Int']>
  /** 실패 사유들 */
  failedReasons?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
  /** Task로 전환 여부 */
  isTask?: Maybe<Scalars['Boolean']>
}

/** 쿠폰 수동 발급 */
export type IssueManualCouponsMutation = {
  __typename?: 'IssueManualCouponsMutation'
  coupons?: Maybe<Array<Maybe<CouponType>>>
}

export type KickMembershipFamilyInput = {
  /** 내보낼 가족 Membership ID */
  familyMembership: Scalars['ID']
}

/** 가족 내보내기 */
export type KickMembershipFamilyMutation = {
  __typename?: 'KickMembershipFamilyMutation'
  membership?: Maybe<MembershipType>
}

export type LeaveReasonType = {
  __typename?: 'LeaveReasonType'
  /** 탈퇴 사유 */
  leaveReasons?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type LeaveUserInput = {
  /** 고객님 의견 */
  leftMessage?: InputMaybe<Scalars['String']>
  /** 탈퇴 사유 */
  leftReasons?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** 사용자 회원 탈퇴 */
export type LeaveUserMutation = {
  __typename?: 'LeaveUserMutation'
  user?: Maybe<UserType>
}

export type LeaveUsersByFitpetAdminInput = {
  ids: Array<InputMaybe<Scalars['ID']>>
}

/** 관리자에 의한 사용자들의 회원 탈퇴 */
export type LeaveUsersByFitpetAdminMutation = {
  __typename?: 'LeaveUsersByFitpetAdminMutation'
  count?: Maybe<Scalars['Int']>
}

export type LikedProductConnection = {
  __typename?: 'LikedProductConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LikedProductEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `LikedProduct` and its cursor. */
export type LikedProductEdge = {
  __typename?: 'LikedProductEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<LikedProductType>
}

export type LikedProductType = Node & {
  __typename?: 'LikedProductType'
  /** The ID of the object. */
  id: Scalars['ID']
  /** 좋아요한 날짜 */
  likedAt?: Maybe<Scalars['DateTime']>
  /** 찜된 상품 */
  product?: Maybe<ProductType>
  /** 찜 당시 상품 가격 */
  productPrice?: Maybe<Scalars['Decimal']>
  /** 찜된 상품의 프로모션 */
  productPromotion?: Maybe<ProductPromotionType>
  /** 찜 당시 상품 가격(할인가노출상품쿠폰적용) */
  salePriceAfterCoupon?: Maybe<Scalars['Decimal']>
  /** 찜한 사용자 */
  user?: Maybe<UserType>
}

export type LikedProductTypeConnection = {
  __typename?: 'LikedProductTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LikedProductTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `LikedProductType` and its cursor. */
export type LikedProductTypeEdge = {
  __typename?: 'LikedProductTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<LikedProductType>
}

export type LinkAccountByEmailInput = {
  email: Scalars['String']
  password: Scalars['String']
  userCertification: Scalars['ID']
}

/** 이메일 신규가입 회원을 기존 소셜 계정과 연동 */
export type LinkAccountByEmailMutation = {
  __typename?: 'LinkAccountByEmailMutation'
  user?: Maybe<UserType>
}

export type LinkAccountBySocialInput = {
  socialService: SocialServiceInput
  userCertification?: InputMaybe<Scalars['ID']>
}

/** 소셜 신규가입을 이메일 회원과 연동 / 로그인 후 소셜 신규로 연동 */
export type LinkAccountBySocialMutation = {
  __typename?: 'LinkAccountBySocialMutation'
  user?: Maybe<UserType>
}

export type MembershipBenefitMonthlyType = Node & {
  __typename?: 'MembershipBenefitMonthlyType'
  /** 관리자용 결제 금액 */
  amount?: Maybe<Scalars['Decimal']>
  /** 멤버쉽 구매 확정 갯수 */
  confirmCount: Scalars['Int']
  /** 구매 확정 총 적립 마일리지 */
  confirmMileageAmount: Scalars['Int']
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 종료일 */
  endedAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 멤버쉽 이미지 리뷰 갯수 */
  imageReviewCount: Scalars['Int']
  /** 아미지 리뷰 총 적립 마일리지 */
  imageReviewMileageAmount: Scalars['Int']
  /** 멤버십 첫달 무료 */
  isFreeFirstMonthMembership?: Maybe<Scalars['Boolean']>
  /** 대표 여부 */
  isOwner?: Maybe<Scalars['Boolean']>
  /** 예상 (멤버쉽 - 일반) 구매 확정 적립금 */
  membershipConfirmDiffMileageAmount?: Maybe<Scalars['Int']>
  /** 구매 확정 총 적립 마일리지 */
  membershipConfirmMileageAmount: Scalars['Int']
  /** 관리자용 멤버쉽 종료일 */
  membershipEndedAt?: Maybe<Scalars['DateTime']>
  /** 멤버쉽 히스토리 */
  membershipHistory?: Maybe<MembershipHistoryType>
  /** 아미지 리뷰 총 적립 마일리지 */
  membershipImageReviewMileageAmount: Scalars['Int']
  /** 리뷰 총 적립 마일리지 */
  membershipReviewMileageAmount: Scalars['Int']
  /** 관리자용 멤버쉽 시작일 */
  membershipStartedAt?: Maybe<Scalars['DateTime']>
  /** 멤버쉽 주문 갯수 */
  orderCount: Scalars['Int']
  /** 멤버쉽 상품 주문 갯수 */
  orderItemCount: Scalars['Int']
  /** 관리자용 결제일. 패밀리 회원일 경우 null */
  paidAt?: Maybe<Scalars['DateTime']>
  /** 결제 n개월 단위 */
  payDuration?: Maybe<Scalars['Int']>
  /** 멤버쉽 리뷰 갯수 */
  reviewCount: Scalars['Int']
  /** 리뷰 총 적립 마일리지 */
  reviewMileageAmount: Scalars['Int']
  /** 멤버쉽 배송 갯수 */
  shippingCount: Scalars['Int']
  /** 배송비 총 할인액 */
  shippingDiscountAmount: Scalars['Decimal']
  /** 시작일 */
  startedAt?: Maybe<Scalars['DateTime']>
  /** 구독 횟수 */
  subscribeSequence?: Maybe<Scalars['Int']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 멤버쉽 사용자 */
  user?: Maybe<UserType>
}

export type MembershipBenefitMonthlyTypeConnection = {
  __typename?: 'MembershipBenefitMonthlyTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MembershipBenefitMonthlyTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `MembershipBenefitMonthlyType` and its cursor. */
export type MembershipBenefitMonthlyTypeEdge = {
  __typename?: 'MembershipBenefitMonthlyTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<MembershipBenefitMonthlyType>
}

export type MembershipCancelReasonInput = {
  /** 멤버쉽 해지 기타 사유 */
  cancelMessage?: InputMaybe<Scalars['String']>
  /** EXPENSIVE: 혜택 대비 이용 요금이 비싸요, NO_PRODUCT: 구매하고 싶은 제품이 없어요, NO_BENEFIT: 사용할 만한 혜택이 없어요, UNKIND: 핏펫플러스의 고객 응대가 불친절해요, ETC: 기타 */
  cancelReasonType: Scalars['String']
}

export type MembershipFamilyConnection = {
  __typename?: 'MembershipFamilyConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MembershipFamilyEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `MembershipFamily` and its cursor. */
export type MembershipFamilyEdge = {
  __typename?: 'MembershipFamilyEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<MembershipFamilyType>
}

export type MembershipFamilyType = {
  __typename?: 'MembershipFamilyType'
  /** membership ID */
  id?: Maybe<Scalars['ID']>
  /** 본인 여부 */
  isMe?: Maybe<Scalars['Boolean']>
  /** 대표 여부 */
  isOwner?: Maybe<Scalars['Boolean']>
  /** 가족 이름 */
  name?: Maybe<Scalars['String']>
  /** 사용자 ID */
  userId?: Maybe<Scalars['ID']>
  /** 가족 username */
  username?: Maybe<Scalars['String']>
}

/** An enumeration. */
export enum MembershipHistoryCancelReasonType {
  /** 기타 */
  Etc = 'ETC',
  /** 혜택 대비 이용 요금이 비싸요 */
  Expensive = 'EXPENSIVE',
  /** 사용할 만한 혜택이 없어요 */
  NoBenefit = 'NO_BENEFIT',
  /** 구매하고 싶은 제품이 없어요 */
  NoProduct = 'NO_PRODUCT',
  /** 핏펫플러스의 고객 응대가 불친절해요 */
  Unkind = 'UNKIND',
}

/** An enumeration. */
export enum MembershipHistoryCancelType {
  /** 즉시 환불 */
  Cancel = 'CANCEL',
  /** 구독중 */
  None = 'NONE',
  /** 구독 취소 */
  Unsubscribe = 'UNSUBSCRIBE',
}

/** An enumeration. */
export enum MembershipHistoryMembershipHistoryStatus {
  /** 환불 완료 */
  Canceled = 'CANCELED',
  /** 환불 실패 */
  CanceledFailed = 'CANCELED_FAILED',
  /** 기본 */
  None = 'NONE',
  /** 결제 완료 */
  Paid = 'PAID',
  /** 결제 실패 */
  PaidFailed = 'PAID_FAILED',
}

export type MembershipHistoryType = Node & {
  __typename?: 'MembershipHistoryType'
  /** 결제 총액 */
  amount: Scalars['Decimal']
  /** 결제 트랜잭션 */
  approvedTransaction?: Maybe<TransactionType>
  /** 멤버쉽 해지 기타 사유 */
  cancelMessage?: Maybe<Scalars['String']>
  /** EXPENSIVE: 혜택 대비 이용 요금이 비싸요, NO_PRODUCT: 구매하고 싶은 제품이 없어요, NO_BENEFIT: 사용할 만한 혜택이 없어요, UNKIND: 핏펫플러스의 고객 응대가 불친절해요, ETC: 기타 */
  cancelReasonType: MembershipHistoryCancelReasonType
  /** 취소 요청 일시 */
  cancelRequestedAt?: Maybe<Scalars['DateTime']>
  /** NONE: 구독중, UNSUBSCRIBE: 구독 취소, CANCEL: 즉시 환불 */
  cancelType: MembershipHistoryCancelType
  /** 취소자 */
  cancelUser?: Maybe<Scalars['String']>
  /** 취소 일시 */
  canceledAt?: Maybe<Scalars['DateTime']>
  /** 취소 트랜잭션 */
  canceledTransaction?: Maybe<CancelTransactionType>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 멤버쉽 결제 수단 */
  creditCard?: Maybe<CreditCardType>
  /** 멤버십 종료 시간 */
  endedAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 멤버쉽 */
  membership?: Maybe<MembershipType>
  /** 멤버쉽 히스토리 */
  membershipBenefitMonthlyMembershipHistories: MembershipBenefitMonthlyTypeConnection
  /** 멤버쉽 히스토리 */
  membershipHistoryCancelTransactions: CancelTransactionTypeConnection
  /** NONE: 기본값, PAID: 결제 완료, PAID_FAILED: 결제 실패, CANCELED: 환불 완료, CANCELED_FAILED: 환불 실패 */
  membershipHistoryStatus: MembershipHistoryMembershipHistoryStatus
  /** 멤버쉽 이력 이름 */
  name?: Maybe<Scalars['String']>
  /** 결제 일시 */
  paidAt?: Maybe<Scalars['DateTime']>
  /** 멤버십 시작 시간 */
  startedAt?: Maybe<Scalars['DateTime']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type MembershipHistoryTypeMembershipBenefitMonthlyMembershipHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
}

export type MembershipHistoryTypeMembershipHistoryCancelTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MembershipHistoryTypeConnection = {
  __typename?: 'MembershipHistoryTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MembershipHistoryTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `MembershipHistoryType` and its cursor. */
export type MembershipHistoryTypeEdge = {
  __typename?: 'MembershipHistoryTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<MembershipHistoryType>
}

export type MembershipInvitationType = Node & {
  __typename?: 'MembershipInvitationType'
  /** 현재 가족 초대 받을 수 있는지 여부 */
  canInvite?: Maybe<Scalars['Boolean']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 초대 만료 일시 */
  expiredAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 멤버쉽 추천 코드 */
  invitationCode?: Maybe<Scalars['String']>
  /** 초드 코드 만료 여부 */
  isExpired?: Maybe<Scalars['Boolean']>
  /** 대표가 멤버쉽 활성화 된 상태인지 여부 */
  isOwnerActive?: Maybe<Scalars['Boolean']>
  /** 초대한 대표 이름 */
  ownerName?: Maybe<Scalars['String']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type MembershipInvitationTypeConnection = {
  __typename?: 'MembershipInvitationTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MembershipInvitationTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `MembershipInvitationType` and its cursor. */
export type MembershipInvitationTypeEdge = {
  __typename?: 'MembershipInvitationTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<MembershipInvitationType>
}

export type MembershipPayInfoType = {
  __typename?: 'MembershipPayInfoType'
  /** 멤버십 할인율 */
  discountPercent?: Maybe<Scalars['Decimal']>
  /** 멤버십 기간(월) */
  period?: Maybe<Scalars['Int']>
  /** 멤버십 가격 */
  price?: Maybe<Scalars['Decimal']>
}

export type MembershipSubscribeInfoBeforePaymentType = {
  __typename?: 'MembershipSubscribeInfoBeforePaymentType'
  /** 오늘 결제 금액 */
  amount?: Maybe<Scalars['Decimal']>
  /** 다음 결제 예정 금액 */
  nextAmount?: Maybe<Scalars['String']>
  /** 다음 결제 예정일 */
  nextPaidAt?: Maybe<Scalars['DateTime']>
  /** 결제 정보 */
  payInfo?: Maybe<Array<Maybe<MembershipPayInfoType>>>
}

export type MembershipType = Node & {
  __typename?: 'MembershipType'
  /** 즉시 해지 가능 여부 */
  canRefund?: Maybe<Scalars['Boolean']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 결제 카드 */
  creditCard?: Maybe<CreditCardType>
  /** 종료일 */
  endedAt?: Maybe<Scalars['DateTime']>
  /** 지난 3개월간 평균 혜택 */
  expectMembershipBenefit?: Maybe<ExpectMembershipBenefitType>
  /** 예상 환불 금액 */
  expectRefundAmount?: Maybe<Scalars['Decimal']>
  /** 멤버쉽 대표 */
  familyMemberships: MembershipTypeConnection
  /** The ID of the object. */
  id: Scalars['ID']
  /** 사용 여부 */
  isActive: Scalars['Boolean']
  /** 멤버쉽 대표 여부 */
  isOwner?: Maybe<Scalars['Boolean']>
  /** 멤버쉽 */
  membershipHistories: MembershipHistoryTypeConnection
  /** 초대한 멤버쉽 */
  membershipInvitations: MembershipInvitationTypeConnection
  /** 다음 결제 금액 */
  nextAmount?: Maybe<Scalars['Decimal']>
  /** 다음 멤버십 종료일 */
  nextEndedAt?: Maybe<Scalars['DateTime']>
  /** 예상 다음 멤버십 종료일 */
  nextExpectedEndedAt?: Maybe<Scalars['DateTime']>
  /** 다음 결제 일시 */
  nextPaidAt?: Maybe<Scalars['DateTime']>
  /** 다음 멤버십 시작일 */
  nextStartedAt?: Maybe<Scalars['DateTime']>
  /** 결제 일시 */
  paidAt?: Maybe<Scalars['DateTime']>
  /** 결제 n개월 단위 */
  payDuration?: Maybe<Scalars['Int']>
  /** 시작일 */
  startedAt?: Maybe<Scalars['DateTime']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  user?: Maybe<UserType>
}

export type MembershipTypeFamilyMembershipsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MembershipTypeMembershipHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  membership?: InputMaybe<Scalars['String']>
  membershipHistoryStatus?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
}

export type MembershipTypeMembershipInvitationsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MembershipTypeConnection = {
  __typename?: 'MembershipTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MembershipTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `MembershipType` and its cursor. */
export type MembershipTypeEdge = {
  __typename?: 'MembershipTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<MembershipType>
}

export type MileageConfigInput = {
  isSuspend?: InputMaybe<Scalars['Boolean']>
  key: Scalars['String']
  point?: InputMaybe<Scalars['Int']>
  rate?: InputMaybe<Scalars['Float']>
}

/** An enumeration. */
export enum MileageHistoryMileageType {
  /** 관리자 */
  Admin = 'ADMIN',
  /** 마케팅 수신 동의로 회원 가입으로 적립금 적립 */
  AgreeMarketing = 'AGREE_MARKETING',
  /** 리뷰 삭제 */
  DeleteReview = 'DELETE_REVIEW',
  /** 기타 */
  Etc = 'ETC',
  /** 유효기간 만료 */
  Expired = 'EXPIRED',
  /** 활동 포인트 적립 */
  HealthActivity = 'HEALTH_ACTIVITY',
  /** 미션 포인트 적립 */
  HealthMission = 'HEALTH_MISSION',
  /** 병원 리뷰 */
  HospitalReview = 'HOSPITAL_REVIEW',
  /** 포토 리뷰 작성 */
  ImageReview = 'IMAGE_REVIEW',
  /** 추천인 코드 제공 */
  Invite = 'INVITE',
  /** 제공된 추천인 코드로 가입 */
  Invited = 'INVITED',
  /** 회원탈퇴 */
  Leave = 'LEAVE',
  /** 마케팅 및 프로모션 */
  MarketingPromotion = 'MARKETING_PROMOTION',
  /** 고도몰 회원전환 */
  Migrated = 'MIGRATED',
  /** 고도몰 회원전환, 고도몰 버그로 인해 무시함 */
  MigratedIgnore = 'MIGRATED_IGNORE',
  /** 포인트 사용 */
  Order = 'ORDER',
  /** 구매 확정 */
  OrderConfirm = 'ORDER_CONFIRM',
  /** 포인트 사용 취소 */
  OrderItemCanceled = 'ORDER_ITEM_CANCELED',
  /** 구매 환불 */
  OrderItemConfirmCanceled = 'ORDER_ITEM_CONFIRM_CANCELED',
  /** 반품배송비 적립금 차감 */
  ReturnShippingFee = 'RETURN_SHIPPING_FEE',
  /** 자회사 프로모션 */
  ScinsPromotion = 'SCINS_PROMOTION',
  /** 배송비 취소로 인한 적립금 반환 */
  ShippingCanceled = 'SHIPPING_CANCELED',
  /** 스타일 웨이버 활동 지원 */
  StyleWaver = 'STYLE_WAVER',
  /** 미사용 포인트 제거 */
  Unused = 'UNUSED',
  /** 일반 리뷰 작성 */
  WriteReview = 'WRITE_REVIEW',
}

export type MileageHistoryType = Node & {
  __typename?: 'MileageHistoryType'
  /** 적립금 가감 변동액 */
  addMileage: Scalars['Int']
  /** 변경 후 적립금 */
  afterMileage: Scalars['Int']
  /** 변경 전 적립금 */
  beforeMileage: Scalars['Int']
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  expiredAt?: Maybe<Scalars['DateTime']>
  /** 최종 처리 Fitpet Admin */
  fitpetAdmin?: Maybe<UserType>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 멤버쉽 추가 적립금 */
  membershipAddMileage?: Maybe<Scalars['Int']>
  /** 적립금 적립 내역 */
  message: Scalars['String']
  /** 적립금 */
  mileage?: Maybe<MileageType>
  mileageDiff?: Maybe<Scalars['Int']>
  /** 요청 타입 */
  mileageType: MileageHistoryMileageType
  /** 적립금이 사용/적립된 주문 */
  order?: Maybe<OrderType>
  /** 적립금 사용/적립된 주문 아이템 */
  orderItem?: Maybe<OrderItemType>
  /** 반품 정보 */
  returnInfo?: Maybe<ReturnInfoType>
  /** 적립금 적립한/취소한 리뷰 */
  review?: Maybe<ReviewType>
  /** 배송비 적립금 할인 차감 */
  shipping?: Maybe<ShippingType>
  /** 적립금 적립 내역 상세 */
  subMessage?: Maybe<Scalars['String']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type MileageHistoryTypeConnection = {
  __typename?: 'MileageHistoryTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MileageHistoryTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `MileageHistoryType` and its cursor. */
export type MileageHistoryTypeEdge = {
  __typename?: 'MileageHistoryTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<MileageHistoryType>
}

export type MileageInput = {
  /** 관리자 메세지 */
  message?: InputMaybe<Scalars['String']>
  /** 사유, ADMIN/UNUSED/ORDER_ITEM_CONFIRM_CANCELED/ETC */
  mileageType?: InputMaybe<Scalars['String']>
  /** 증감할 포인트 */
  point: Scalars['Int']
}

export type MileagePolicyType = {
  __typename?: 'MileagePolicyType'
  mileageMaxDiscountRate?: Maybe<Scalars['Float']>
  mileageMinHoldingAmount?: Maybe<Scalars['Int']>
  mileageMinOrderAmount?: Maybe<Scalars['Int']>
  mileageMinUsageAmount?: Maybe<Scalars['Int']>
  mileageUnusedDay?: Maybe<Scalars['Int']>
}

export type MileageRateInput = {
  /** 구매 확정 시 적립율, 0.xx로 표기. */
  confirmMileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 이미지 리뷰 작성 시 적립율, 0.xx로 표기. */
  imageReviewMileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 리뷰 작성 시 적립율, 0.xx로 표기. */
  reviewMileageRewardRate?: InputMaybe<Scalars['Float']>
}

export type MileageRewardPolicyType = {
  __typename?: 'MileageRewardPolicyType'
  imageReviewRate?: Maybe<Scalars['Float']>
  orderConfirmRate?: Maybe<Scalars['Float']>
  reviewRate?: Maybe<Scalars['Float']>
}

export type MileageType = Node & {
  __typename?: 'MileageType'
  /** The ID of the object. */
  id: Scalars['ID']
  /** 적립금 */
  mileageMileageHistories: MileageHistoryTypeConnection
  /** 쌓인 적립금 */
  point: Scalars['Int']
  user?: Maybe<UserType>
}

export type MileageTypeMileageMileageHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isMembership?: InputMaybe<Scalars['Boolean']>
  isPlus?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mileageType?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
}

export type MileageTypeConnection = {
  __typename?: 'MileageTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MileageTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `MileageType` and its cursor. */
export type MileageTypeEdge = {
  __typename?: 'MileageTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<MileageType>
}

export type MinAppVersionInput = {
  /** 앱 최신 버전 */
  latestAppVersion: Scalars['String']
  /** 최소 앱 버전 */
  minAppVersion: Scalars['String']
  /** 앱 선택 업데이트용 버전 */
  normalAppVersion: Scalars['String']
  /** 플랫폼 */
  platform: Scalars['String']
}

export type MinAppVersionType = {
  __typename?: 'MinAppVersionType'
  /** 앱 최신 버전 */
  latestAppVersion?: Maybe<Scalars['String']>
  /** 최소 앱 버전 */
  minAppVersion?: Maybe<Scalars['String']>
  /** 적정 앱 버전 */
  normalAppVersion?: Maybe<Scalars['String']>
  /** 플랫폼 */
  platform?: Maybe<Scalars['String']>
}

export type ModelDeletesInput = {
  ids: Array<InputMaybe<Scalars['ID']>>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** 교환 요청 -> 교환수거중 */
  acceptExchangeReturnInfos?: Maybe<AcceptExchangeReturnInfosMutation>
  /** 반품 진행, 요청 -> 반품수거중 */
  acceptReturnInfos?: Maybe<AcceptReturnInfosMutation>
  /** 회원등급 적용 유무 */
  activateCustomerGrade?: Maybe<ActivateCustomerGradeMutation>
  /** 관리자 권한 추가 */
  addAdminPermission?: Maybe<AddAdminPermissionMutation>
  /** 배너 노출 허용 */
  allowBanner?: Maybe<AllowBannerMutation>
  /** 상품 문의 답변 */
  answerProductQuestion?: Maybe<AnswerProductQuestionMutation>
  /** 1:1 문의 답변 */
  answerServiceQuestion?: Maybe<AnswerServiceQuestionMutation>
  /** 상품 판매, 노출 일괄 승인 */
  approveProductsSelling?: Maybe<ApproveProductsSellingMutation>
  /** 요청 승인 */
  approveRequest?: Maybe<ApproveRequestMutation>
  /** 관리자에 의해 다중의 요청을 승인 */
  approveRequests?: Maybe<ApproveRequestsMutation>
  /** 결제 승인 */
  approveTransaction?: Maybe<ApproveTransactionMutation>
  /** 정보변경 요청 승인 */
  approveUpdateRequest?: Maybe<ApproveUpdateRequestMutation>
  /** 상품 문의 생성 */
  askProductQuestion?: Maybe<AskProductQuestionMutation>
  /** 주문 / 1:1 문의 생성 */
  askServiceQuestion?: Maybe<AskServiceQuestionMutation>
  /** 검색사전 OpenSearch 연동 */
  associateSearchToken?: Maybe<AssociateSearchTokenMutation>
  /** 요청 스테이터스 금지 */
  banRequest?: Maybe<BanRequestMutation>
  /** 관리자에 의해서 권한이 금지 */
  banRequests?: Maybe<BanRequestsMutation>
  /** 사용자 차단/차단 해제 */
  blockUser?: Maybe<BlockUserMutation>
  /** 엑셀 파일로 쿠폰 수동 생성 취소 */
  cancelIssuingManualCoupons?: Maybe<CancelIssuingManualCoupons>
  /** 관리자/셀러/사용자 주문 취소 */
  cancelOrderItems?: Maybe<CancelOrderItemsMutation>
  /** 통합매니저 변경 */
  changeChiefSellerManager?: Maybe<ChangeChiefSellerManagerMutation>
  /** 아이디 중복 여부 확인 */
  checkEmailDuplicated?: Maybe<CheckEmailDuplicatedMutation>
  /** 발주 확인 */
  checkOrderItems?: Maybe<CheckOrderItemsMutation>
  /** 결제 승인 */
  checkUnconfirmedTransaction?: Maybe<CheckUnconfirmedTransactionMutation>
  /** 배너 캐시 초기화 */
  clearBannerCache?: Maybe<ClearBannerCacheMutation>
  /** Best 캐시 초기화 */
  clearBestCache?: Maybe<ClearBestCacheMutation>
  /** 홈 캐시 초기화 */
  clearHomeCache?: Maybe<ClearHomeCacheMutation>
  /** 배송 완료 */
  completeShippingOrderItems?: Maybe<CompleteShippingOrderItemsMutation>
  /** 수동 환불 */
  confirmCancelOrderItem?: Maybe<ConfirmCancelOrderItemMutation>
  /** 교환 상품 수거 완료 */
  confirmExchangeReturnInfos?: Maybe<ConfirmExchangeReturnInfosMutation>
  /** 구매 확정 */
  confirmOrderItem?: Maybe<ConfirmOrderItemMutation>
  /** 멤버쉽 수동 환불 */
  confirmRefundMembership?: Maybe<ConfirmRefundMembershipMutation>
  /** 반품 확정 */
  confirmReturnInfos?: Maybe<ConfirmReturnInfosMutation>
  /** 셀러 월정산 확인 */
  confirmSellerBilling?: Maybe<ConfirmSellerBillingMutation>
  /** 사용자 인증 번호 확인 */
  confirmUserCertNumber?: Maybe<ConfirmUserCertNumberMutation>
  /** 상품 복사 - 관리자, 셀러 */
  copyProduct?: Maybe<CopyProductMutation>
  /** Ad 생성 */
  createAd?: Maybe<CreateAdMutation>
  /** AS 정보 등록 */
  createAsInfo?: Maybe<CreateAsInfoMutation>
  /** 베스트 리뷰 선정 */
  createBestReviews?: Maybe<CreateBestReviewsMutation>
  /** Brand 생성 - 관리자 */
  createBrandByFitpetAdmin?: Maybe<CreateBrandByFitpetAdminMutation>
  /** Brand 생성 - 셀러 */
  createBrandBySeller?: Maybe<CreateBrandBySellerMutation>
  /** 관리자가 Excel 을 업로드하여 상품(Product) 댓글(Review) 대량 생성 */
  createBulkReviewByFitpetAdminExcel?: Maybe<CreateBulkReviewByFitpetAdminExcelMutation>
  /** 장바구니에 아이템 담기 */
  createCartItem?: Maybe<CreateCartItemMutation>
  /** 장바구니 아이템 담기 (복수) */
  createCartItems?: Maybe<CreateCartItemsMutation>
  /** Category 생성 */
  createCategory?: Maybe<CreateCategoryMutation>
  /** 쿠폰 정보 생성 */
  createCouponGroup?: Maybe<CreateCouponGroupMutation>
  /** 신용카드 등록 전 생성 */
  createCreditCard?: Maybe<CreateCreditCardMutation>
  /** 고객 등급 생성 */
  createCustomerGrade?: Maybe<CreateCustomerGradeMutation>
  /** 사용자 템플릿 주소 생성 */
  createCustomerTemplateAddress?: Maybe<CreateCustomerTemplateAddressMutation>
  /** Display Collection 생성 */
  createDisplayCollection?: Maybe<CreateDisplayCollectionMutation>
  /** DisplayProductInfo 생성 */
  createDisplayProductInfo?: Maybe<CreateDisplayProductInfoMutation>
  /** DisplayProductInfo 복수 생성 */
  createDisplayProductInfos?: Maybe<CreateDisplayProductInfosMutation>
  /** DisplayProductInfo와 ProductPromotion 복수 생성 / DisplayCollection에 상품추가할 때 호출 */
  createDisplayProductInfosWithProductPromotions?: Maybe<CreateDisplayProductInfosWithProductPromotionsMutation>
  /** 홈 하단 띠 배너 생성 */
  createHomeBottomBanner?: Maybe<CreateHomeBottomBannerMutation>
  /** 홈 중단 띠 배너 생성 */
  createHomeMiddleBanner?: Maybe<CreateHomeMiddleBannerMutation>
  /** 홈 멀티라인 배너 생성 */
  createHomeMultiLineBanner?: Maybe<CreateMultiLineBannerMutation>
  /** 멤버쉽 가족 초대 코드 생성 */
  createMembershipInvitation?: Maybe<CreateMembershipInvitationMutation>
  /** 공지 사항 생성 */
  createNotice?: Maybe<CreateNoticeMutation>
  /** 엑셀 파일로 SKU 생성 또는 업데이트 */
  createOrUpdateSkusByExcel?: Maybe<CreateOrUpdateSkusByExcelMutation>
  /** 주문 생성 */
  createOrder?: Maybe<CreateOrderMutation>
  /** CS용 관리자 메모 생성 */
  createOrderItemAdminNote?: Maybe<CreateOrderItemAdminNoteMutation>
  /** 이벤트 참여 결과 생성 */
  createParticipantResult?: Maybe<CreateParticipantResultMutation>
  /** 인기 검색어 등록 */
  createPopularSearchTerm?: Maybe<CreatePopularSearchTermMutation>
  /** 상품 등록 - 관리자 */
  createProductByFitpetAdmin?: Maybe<CreateProductByFitpetAdminMutation>
  /** 상품 등록 - 셀러 */
  createProductBySeller?: Maybe<CreateProductBySellerMutation>
  /** 상품 할인 생성 */
  createProductPromotion?: Maybe<CreateProductPromotionMutation>
  /** 엑셀 파일로 프로모션 생성 */
  createProductPromotionsByExcel?: Maybe<CreateProductPromotionsByExcelMutation>
  /** 상품 상세 상단 띠 배너 생성 */
  createProductTopBanner?: Maybe<CreateProductTopBannerMutation>
  /** 엑셀 파일로 상품 생성 */
  createProductsByExcel?: Maybe<CreateProductsByExcelMutation>
  /** 도서산간지역 등록 */
  createRemoteArea?: Maybe<CreateRemoteAreaMutation>
  /** 엑셀 파일로 도서산간지역 추가 */
  createRemoteAreaByExcel?: Maybe<CreateRemoteAreaByExcelMutation>
  /** 고객 리뷰 생성 */
  createReview?: Maybe<CreateReviewMutation>
  /** seller 생성 - 관리자 */
  createSellerByFitpetAdmin?: Maybe<CreateSellerByFitpetAdminMutation>
  /** seller 생성 - 사용자 */
  createSellerByUserRequest?: Maybe<CreateSellerByUserRequestMutation>
  /** 셀러 유저 등록 */
  createSellerManager?: Maybe<CreateSellerManagerMutation>
  /** 셀러 템플릿 주소 생성 */
  createSellerTemplateAddress?: Maybe<CreateSellerTemplateAddressMutation>
  /** 설정 생성 */
  createServerConfig?: Maybe<CreateServerConfigMutation>
  /** 배송 회사 정보 생성 */
  createShippingCompany?: Maybe<CreateShippingCompanyMutation>
  /** 배송 프리셋 정보 생성 */
  createShippingPreset?: Maybe<CreateShippingPresetMutation>
  /** Slim 배너 생성 */
  createSlimBanner?: Maybe<CreateSlimBannerMutation>
  /** 상단 롤 배너 생성 */
  createTopRollBanner?: Maybe<CreateTopRollBannerMutation>
  /** 트랜잭션 생성 */
  createTransaction?: Maybe<CreateTransactionMutation>
  /** Fitpet admin에 의한 user 생성 */
  createUser?: Maybe<CreateUserMutation>
  /** Ad 생성 */
  deleteAd?: Maybe<DeleteAdMutation>
  /** 프로모션 복수 삭제 */
  deleteAds?: Maybe<DeleteAdsMutation>
  /** AS 정보 삭제 */
  deleteAsInfo?: Maybe<DeleteAsInfoMutation>
  /** 배너 삭제 */
  deleteBanners?: Maybe<DeleteBannersMutation>
  /** 장바구니 아이템 복수 삭제 */
  deleteCartItems?: Maybe<DeleteCartItemsMutation>
  /** Category 삭제 */
  deleteCategory?: Maybe<DeleteCategoryMutation>
  /** 미사용 쿠폰 삭제 */
  deleteCoupon?: Maybe<DeleteCouponMutation>
  /** 쿠폰 정보 삭제 */
  deleteCouponGroup?: Maybe<DeleteCouponGroupMutation>
  /** 쿠폰 정보 삭제 */
  deleteCouponGroupWithUnusedCoupons?: Maybe<DeleteCouponGroupWithUnusedCouponsMutation>
  /** 미사용 쿠폰 선택 복수 삭제 */
  deleteCoupons?: Maybe<DeleteCouponsMutation>
  /** 미사용 쿠폰 전체 삭제 */
  deleteCouponsByGroup?: Maybe<DeleteCouponsByGroupMutation>
  /** 고객 등급 삭제 */
  deleteCustomerGrade?: Maybe<DeleteCustomerGradeMutation>
  /** 사용자 템플릿 주소 삭제 */
  deleteCustomerTemplateAddress?: Maybe<DeleteCustomerTemplateAddressMutation>
  /** DisplayCollection 삭제 */
  deleteDisplayCollection?: Maybe<DeleteDisplayCollectionMutation>
  /** DisplayCollection 복수 삭제 */
  deleteDisplayCollections?: Maybe<DeleteDisplayCollectionsMutation>
  /** DisplayProductInfo 삭제 */
  deleteDisplayProductInfo?: Maybe<DeleteDisplayProductInfoMutation>
  /** DisplayProductInfo 복수 삭제 */
  deleteDisplayProductInfos?: Maybe<DeleteDisplayProductInfosMutation>
  /** 탈퇴 회원 삭제 */
  deleteInactiveUsers?: Maybe<DeleteInactiveUsersMutation>
  /** 미등록 상품 복수 삭제 */
  deleteIncompleteProducts?: Maybe<DeleteIncompleteProductsMutation>
  /** 공지 사항 복수 삭제 */
  deleteNotices?: Maybe<DeleteNoticesMutation>
  /** CS용 관리자 메모 삭제 */
  deleteOrderItemAdminNote?: Maybe<DeleteOrderItemAdminNoteMutation>
  /** 상품 할인 생성 */
  deleteProductPromotion?: Maybe<DeleteProductPromotionMutation>
  /** 상품 문의 삭제 */
  deleteProductQuestion?: Maybe<DeleteProductQuestionMutation>
  /** 상품 문의 복수 삭제 (관리자) */
  deleteProductQuestions?: Maybe<DeleteProductQuestionsMutation>
  /** Push 삭제 */
  deletePush?: Maybe<DeletePushMutation>
  /** 도서산간지역 삭제 */
  deleteRemoteArea?: Maybe<DeleteRemoteAreaMutation>
  /** 요청 스테이터스 삭제 */
  deleteRequest?: Maybe<DeleteRequestMutation>
  /** 관리자/셀러에 의해서 삭제 */
  deleteRequests?: Maybe<DeleteRequestsMutation>
  /** 리뷰 삭제 (고객) */
  deleteReview?: Maybe<DeleteReviewMutation>
  /** 리뷰 복수 삭제 (관리자) */
  deleteReviews?: Maybe<DeleteReviewsMutation>
  /** 셀러 유저 삭제 */
  deleteSellerManager?: Maybe<DeleteSellerManagerMutation>
  /** 셀러 템플릿 주소 삭제 */
  deleteSellerTemplateAddress?: Maybe<DeleteSellerTemplateAddressMutation>
  /** 설정 수정 */
  deleteServerConfig?: Maybe<DeleteServerConfigMutation>
  /** 1:1 문의 삭제 */
  deleteServiceQuestion?: Maybe<DeleteServiceQuestionMutation>
  /** 1:1문의 복수 삭제 (관리자) */
  deleteServiceQuestions?: Maybe<DeleteServiceQuestionsMutation>
  /** 배송 회사 정보 삭제 */
  deleteShippingCompany?: Maybe<DeleteShippingCompanyMutation>
  /** 배송 프리셋 정보 삭제 (deprecated, 삭제 안하기로 결정) */
  deleteShippingPreset?: Maybe<DeleteShippingPresetMutation>
  /** 배너 노출 비허용 */
  disallowBanner?: Maybe<DisallowBannerMutation>
  /** 이벤트 쿠폰 발급 */
  downloadEventCoupons?: Maybe<DownloadEventCouponsMutation>
  /** Email 로그인 */
  emailSignin?: Maybe<EmailSigninMutation>
  /** Email 회원 가입 */
  emailSignup?: Maybe<EmailSignupMutation>
  /** 주문 전 가격 미리보기 */
  estimateOrder?: Maybe<EstimateOrderMutation>
  /** 판매자가 교환처리 */
  exchangeOrderItems?: Maybe<ExchangeOrderItemsMutation>
  /** 아이디 찾기 */
  findId?: Maybe<FindId>
  /** Ad 종료 */
  finishAd?: Maybe<FinishAdMutation>
  /** 액션파워 연동 (유저 이미지 업로드 버튼 클릭) */
  integrateActionPowerOrderItem?: Maybe<IntegrateActionPowerOrderItemMutation>
  /** 쿠폰 코드 발급 */
  issueCouponCode?: Maybe<IssueCouponCodeMutation>
  /** 쿠폰 수동 발급 */
  issueManualCoupons?: Maybe<IssueManualCouponsMutation>
  /** 엑셀 파일로 쿠폰 수동 생성 */
  issueManualCouponsByExcel?: Maybe<IssueManualCouponsByExcelMutation>
  /** 가족 내보내기 */
  kickMembershipFamily?: Maybe<KickMembershipFamilyMutation>
  /** 사용자 회원 탈퇴 */
  leaveUser?: Maybe<LeaveUserMutation>
  /** 관리자에 의한 사용자들의 회원 탈퇴 */
  leaveUsersByFitpetAdmin?: Maybe<LeaveUsersByFitpetAdminMutation>
  /** 이메일 신규가입 회원을 기존 소셜 계정과 연동 */
  linkAccountByEmail?: Maybe<LinkAccountByEmailMutation>
  /** 소셜 신규가입을 이메일 회원과 연동 / 로그인 후 소셜 신규로 연동 */
  linkAccountBySocial?: Maybe<LinkAccountBySocialMutation>
  /** Push 메세지 읽음 처리 */
  readPush?: Maybe<ReadPushMutation>
  /** 장바구니에서 만료된 ProductPromotion 제거 */
  refreshCartItems?: Maybe<RefreshCartItemsMutation>
  /** 멤버쉽 구독 취소 (즉시 해지) */
  refundMembership?: Maybe<RefundMembershipMutation>
  /** 쿠폰 번호를 통한 쿠폰 등록 */
  registerCoupon?: Maybe<RegisterCouponMutation>
  /** 교환 요청 거절 */
  rejectExchangeReturnInfos?: Maybe<RejectExchangeReturnInfosMutation>
  /** 요청 거절 */
  rejectRequest?: Maybe<RejectRequestMutation>
  /** 관리자에 의해 다중의 요청을 거절 */
  rejectRequests?: Maybe<RejectRequestsMutation>
  /** 반품 요청에 대한 거절 (반품요청 철회) */
  rejectReturnInfos?: Maybe<RejectReturnInfosMutation>
  /** 정보변경 요청 거절 */
  rejectUpdateRequest?: Maybe<RejectUpdateRequestMutation>
  /** 관리자 권한 해제 */
  removeAdminPermission?: Maybe<RemoveAdminPermissionMutation>
  /** 관리자 정산 엑셀 파일 요청 */
  requestAdminBillingItemExcelFile?: Maybe<RequestAdminBillingItemExcelFileMutation>
  /** 감사 자료 엑셀 파일 요청 */
  requestAuditDataExcelFile?: Maybe<RequestAuditDataExcelFileMutation>
  /** 쿠폰 목록 엑셀 파일 요청 */
  requestCouponExcelFile?: Maybe<RequestCouponExcelFileMutation>
  /** 상품 프로모션 엑셀 파일 요청 */
  requestDisplayProductPromotionExcelFile?: Maybe<RequestDisplayProductPromotionExcelFileMutation>
  /** 교환 요청 */
  requestExchangeOrderItems?: Maybe<RequestExchangeOrderItemsMutation>
  /** 사용자 목록 엑셀 파일 요청 */
  requestInactiveUserExcelFile?: Maybe<RequestInactiveUserExcelFileMutation>
  /** 사용자 목록 엑셀 파일 요청 */
  requestMembershipUserExcelFile?: Maybe<RequestMembershipUserExcelFileMutation>
  /** 주문 엑셀 파일 요청 */
  requestOrderExcelFile?: Maybe<RequestOrderExcelFileMutation>
  /** 반품 완료 주문건 엑셀 파일 요청 */
  requestOrderReturnConfirmedExcelFile?: Maybe<RequestOrderReturnConfirmedExcelFileMutation>
  /** 상품 목록 엑셀 파일 요청 */
  requestProductExcelFile?: Maybe<RequestProductExcelFileMutation>
  /** 상품 목록 어드민 엑셀 파일 요청 */
  requestProductExcelFileByAdmin?: Maybe<RequestProductExcelFileByAdminMutation>
  /** 상품 목록 업로드 불가 어드민 엑셀 파일 요청 */
  requestProductExcelFileWithoutPasswordByAdmin?: Maybe<RequestProductExcelFileWithoutPasswordByAdminMutation>
  /** TossPayments Escrow 주문 업로드용, 배송 준비중 상태의 주문 엑셀 파일 요청 */
  requestReadyForShippingOrderEscrowTossPaymentsExcelFile?: Maybe<RequestReadyForShippingOrderForEscrowTossPaymentsExcelFileMutation>
  /** 사방넷 주문 연동용 배송 준비중 상태의 주문 엑셀 파일 요청 */
  requestReadyForShippingOrderExcelFile?: Maybe<RequestReadyForShippingOrderExcelFileMutation>
  /** Fitpet WMS 주문 연동용 배송 준비중 상태의 주문 엑셀 파일 요청 */
  requestReadyForShippingOrderFitpetWmsExcelFile?: Maybe<RequestReadyForShippingOrderForFitpetWmsExcelFileMutation>
  /** WMS 주문 연동용 배송 준비중 상태의 주문 엑셀 파일 요청 */
  requestReadyForShippingOrderWmsExcelFile?: Maybe<RequestReadyForShippingOrderForWmsExcelFileMutation>
  /** 반품 요청 */
  requestReturnOrderItems?: Maybe<RequestReturnOrderItemsMutation>
  /** 셀러 정산 엑셀 파일 요청 */
  requestSellerBillingItemExcelFile?: Maybe<RequestSellerBillingItemExcelFileMutation>
  /** SKU 목록 엑셀 파일 요청 */
  requestSkuExcelFile?: Maybe<RequestSkuExcelFileMutation>
  /** 사용자 목록 엑셀 파일 요청 */
  requestUserExcelFile?: Maybe<RequestUserExcelFileMutation>
  /** 비밀번호 리셋 */
  resetPassword?: Maybe<ResetPasswordMutation>
  /** 판매자가 반품처리 */
  returnOrderItems?: Maybe<ReturnOrderItemsMutation>
  /** 수거 완료, 반품 수거중 -> 환불완료 or 환불처리중 */
  returnedReturnInfos?: Maybe<ReturnedReturnInfosMutation>
  /** 수동 Email 전송 */
  sendEmail?: Maybe<SendEmailMutation>
  /** Push 전송 */
  sendPush?: Maybe<SendPushMutation>
  /** 수동 SMS 전송 */
  sendSms?: Maybe<SendSmsMutation>
  /** 모바일/이메일 인증 번호 전송 */
  sendUserCertNumber?: Maybe<SendUserCertNumberMutation>
  /** 로그아웃 */
  signOut?: Maybe<SignOutMutation>
  /** 소셜 회원 로그인 */
  socialSignin?: Maybe<SocialSigninMutation>
  /** 소셜 회원 가입 */
  socialSignup?: Maybe<SocialSignupMutation>
  /** 복수 발송 처리 */
  startShippings?: Maybe<StartShippingsMutation>
  /** 멤버십 가입 */
  subscribeMembership?: Maybe<SubscribeMembershipMutation>
  /** 가족 초대 */
  subscribeMembershipFamily?: Maybe<SubscribeMembershipFamilyMutation>
  /** 요청 스테이터스 중지 */
  suspendRequest?: Maybe<SuspendRequestMutation>
  /** 관리자 혹은 사용자에 의해서 권한이 중지(판매 중지중 등에 사용) */
  suspendRequests?: Maybe<SuspendRequestsMutation>
  /** Category 노출여부 토글 */
  toggleCategoryVisible?: Maybe<ToggleCategoryVisibleMutation>
  /** 상품 찜 여부 토글 */
  toggleProductLiked?: Maybe<ToggleProductLikedMutation>
  /** 유저가 재입고 알림 신청 생성/삭제 토글 */
  toggleRestockProductOption?: Maybe<ToggleRestockProductOptionMutation>
  /** 리뷰 공감여부 토글 */
  toggleReviewLiked?: Maybe<ToggleReviewLikedMutation>
  /** Token 로그인 */
  tokenSignin?: Maybe<TokenSigninMutation>
  /** 멤버쉽 구독 취소 (정기 결제 해지) */
  unsubscribeMembership?: Maybe<UnsubscribeMembershipMutation>
  /** 가족 멤버쉽 구독 해제 */
  unsubscribeMembershipFamily?: Maybe<UnsubscribeMembershipFamilyMutation>
  /** Ad 생성 */
  updateAd?: Maybe<UpdateAdMutation>
  /** 추가 약관 동의 */
  updateAgreeTerms?: Maybe<UpdateAgreeTermsMutation>
  /** AS 정보 등록 */
  updateAsInfo?: Maybe<UpdateAsInfoMutation>
  /** brand 수정 - 관리자 */
  updateBrandByFitpetAdmin?: Maybe<UpdateBrandByFitpetAdminMutation>
  /** 사용자에 의한 brand 수정 요청 */
  updateBrandBySeller?: Maybe<UpdateBrandBySellerMutation>
  /** 장바구니 아이템 수정 (수량) */
  updateCartItem?: Maybe<UpdateCartItemMutation>
  /** Category 수정 */
  updateCategory?: Maybe<UpdateCategoryMutation>
  /** Category 이미지 수정 */
  updateCategoryImage?: Maybe<UpdateCategoryImageMutation>
  /** Category slug 수정 */
  updateCategorySlug?: Maybe<UpdateCategorySlugMutation>
  /** 쿠폰 정보 수정 */
  updateCouponGroup?: Maybe<UpdateCouponGroupMutation>
  /** 쿠폰 정보 수정 */
  updateCouponGroupStatus?: Maybe<UpdateCouponGroupStatusMutation>
  /** 고객 등급 수정 */
  updateCustomerGrade?: Maybe<UpdateCustomerGradeMutation>
  /** 사용자 템플릿 주소 수정 */
  updateCustomerTemplateAddress?: Maybe<UpdateCustomerTemplateAddressMutation>
  /** Display Collection 수정시 - DisplayProductInfo & ProductPromotion Datetime Sync 동기화 */
  updateDateOfDisplayCollection?: Maybe<UpdateDateOfDisplayCollectionMutation>
  /** Display Collection 수정 */
  updateDisplayCollection?: Maybe<UpdateDisplayCollectionMutation>
  /** 디스플레이 순서 변경 */
  updateDisplayOrder?: Maybe<UpdateDisplayOrderMutation>
  /** 배너 디스플레이 순서 변경 */
  updateDisplayOrderForBanner?: Maybe<UpdateDisplayOrderForBannerMutation>
  /** 펫 타입 변경 */
  updateDisplayPetType?: Maybe<UpdateDisplayPetTypeMutation>
  /** DisplayProductInfo 수정 */
  updateDisplayProductInfo?: Maybe<UpdateDisplayProductInfoMutation>
  /** 홈 하단 띠 배너 수정 */
  updateHomeBottomBanner?: Maybe<UpdateHomeBottomBannerMutation>
  /** 홈 중단 띠 배너 수정 */
  updateHomeMiddleBanner?: Maybe<UpdateHomeMiddleBannerMutation>
  /** 홈 멀티라인 배너 수정 */
  updateHomeMultiLineBanner?: Maybe<UpdateMultiLineBannerMutation>
  /** 멤버쉽 결제 수단 변경 */
  updateMembershipCreditCard?: Maybe<UpdateMembershipCreditCardMutation>
  /** 관리자 적립금 변경 */
  updateMileage?: Maybe<UpdateMileageMutation>
  /** 엑셀 파일로 적립금 반영 */
  updateMileageByExcel?: Maybe<UpdateMileageByExcelMutation>
  /** 적립금 설정 변경 */
  updateMileageConfig?: Maybe<UpdateMileageConfigMutation>
  /** 최소 앱 버전 변경 */
  updateMinAppVersion?: Maybe<UpdateMinAppVersionMutation>
  /** 핸드폰 번호 수정 */
  updateMobileNumber?: Maybe<UpdateMobileNumberMutation>
  /** 공지 사항 수정 */
  updateNotice?: Maybe<UpdateNoticeMutation>
  /** 사용자 배송 주소 수정 */
  updateOrderCustomerAddress?: Maybe<UpdateOrderCustomerAddressMutation>
  /** CS용 관리자 메모 수정 */
  updateOrderItemAdminNote?: Maybe<UpdateOrderItemAdminNoteMutation>
  /** 상품 수정 - 관리자 */
  updateProductByFitpetAdmin?: Maybe<UpdateProductByFitpetAdminMutation>
  /** 상품 수정 (신청) - 셀러 */
  updateProductBySeller?: Maybe<UpdateProductBySellerMutation>
  /** 엑셀 파일로 상품 마일리지 적립율 변경 */
  updateProductMileagesByExcel?: Maybe<UpdateProductMileagesByExcelMutation>
  /** 상품 할인 수정 */
  updateProductPromotion?: Maybe<UpdateProductPromotionMutation>
  /** Product Promotions 복수 변경 */
  updateProductPromotions?: Maybe<UpdateProductPromotionsMutation>
  /** 엑셀 파일로 상품 연관검색어 업데이트 */
  updateProductSearchKeywordsByExcel?: Maybe<UpdateProductSearchKeywordsByExcelMutation>
  /** 상품 상세 상단 띠 배너 수정 */
  updateProductTopBanner?: Maybe<UpdateProductTopBannerMutation>
  /** 상품 판매, 노출 일괄 변경 */
  updateProductsSelling?: Maybe<UpdateProductsSellingMutation>
  /** 상품들 품절 설정 상태 변경 */
  updateProductsSoldOut?: Maybe<UpdateProductsSoldOutMutation>
  /** Push 수정 */
  updatePush?: Maybe<UpdatePushMutation>
  /** 고객에게 등록된 환불 계좌 수정 */
  updateRefundAccount?: Maybe<UpdateRefundAccountMutation>
  /** 도서산간지역 수정 */
  updateRemoteArea?: Maybe<UpdateRemoteAreaMutation>
  /** 반품/교환 요청 주소 수정 */
  updateReturnInfoCustomerAddress?: Maybe<UpdateReturnInfoCustomerAddressMutation>
  /** 리뷰 수정 */
  updateReview?: Maybe<UpdateReviewMutation>
  /** 리뷰 수정 */
  updateReviewByFitpetAdmin?: Maybe<UpdateReviewByFitpetAdminMutation>
  /** 리뷰그룹 이름변경 및 상품 지정 */
  updateReviewGroupProducts?: Maybe<UpdateReviewGroupProductsMutation>
  /** 검색 Placeholder Text 문구 수정 */
  updateSearchTextMutation?: Maybe<UpdateSearchTextMutation>
  /** seller 수정 - 관리자 */
  updateSellerByFitpetAdmin?: Maybe<UpdateSellerByFitpetAdminMutation>
  /** 사용자에 의한 seller 수정 요청 */
  updateSellerByUserRequest?: Maybe<UpdateSellerByUserRequestMutation>
  /** 셀러 운영 담당 정보 수정 */
  updateSellerNotification?: Maybe<UpdateSellerNotificationMutation>
  /** 셀러 템플릿 주소 수정 */
  updateSellerTemplateAddress?: Maybe<UpdateSellerTemplateAddressMutation>
  /** 설정 수정 */
  updateServerConfig?: Maybe<UpdateServerConfigMutation>
  /** 엑셀 파일로 송장 번호 업데이트 */
  updateShippingByExcel?: Maybe<UpdateShippingByExcelMutation>
  /** 배송 회사 정보 수정 */
  updateShippingCompany?: Maybe<UpdateShippingCompanyMutation>
  /** 배송 프리셋 정보 수정 */
  updateShippingPreset?: Maybe<UpdateShippingPresetMutation>
  /** 배송 정보 복수 수정 */
  updateShippings?: Maybe<UpdateShippingsMutation>
  /** SKU 재고 수정 */
  updateSku?: Maybe<UpdateSkuMutation>
  /** Slim 배너 수정 */
  updateSlimBanner?: Maybe<UpdateSlimBannerMutation>
  /** 테스트 유저 등록 */
  updateTestUser?: Maybe<UpdateTestUserMutation>
  /** 상단 롤 배너 수정 */
  updateTopRollBanner?: Maybe<UpdateTopRollBannerMutation>
  /** 사용자에 의한 유저 정보 수정 */
  updateUserByCustomer?: Maybe<UpdateUserByCustomerMutation>
  /** 관리자에 의한 유저 정보 수정 */
  updateUserByFitpetAdmin?: Maybe<UpdateUserByFitpetAdminMutation>
  /** 관리자에 의한 비밀번호 변경 */
  updateUserPasswordByFitpetAdmin?: Maybe<UpdateUserPasswordByFitpetAdminMutation>
  /** 검색사전 S3 Upload 및 패키징 */
  uploadSearchToken?: Maybe<UploadSearchTokenMutation>
  /** 사용자 브랜드 찜/찜취소 */
  wishBrandByCustomer?: Maybe<WishBrandByCustomerMutation>
  /** 사용자 프로덕트 찜/찜취소 */
  wishProductByCustomer?: Maybe<WishProductByCustomerMutation>
}

export type MutationAcceptExchangeReturnInfosArgs = {
  input: ReturnInfosInput
}

export type MutationAcceptReturnInfosArgs = {
  input: ReturnInfosInput
}

export type MutationActivateCustomerGradeArgs = {
  id: Scalars['ID']
  input: ActivateCustomerGradeInput
}

export type MutationAddAdminPermissionArgs = {
  id: Scalars['ID']
}

export type MutationAllowBannerArgs = {
  id: Scalars['ID']
}

export type MutationAnswerProductQuestionArgs = {
  id: Scalars['ID']
  input: AnswerQuestionInput
}

export type MutationAnswerServiceQuestionArgs = {
  id: Scalars['ID']
  input: AnswerQuestionInput
}

export type MutationApproveProductsSellingArgs = {
  input: ApproveProductsSellingInput
}

export type MutationApproveRequestArgs = {
  id: Scalars['ID']
}

export type MutationApproveRequestsArgs = {
  input: StatusesInput
}

export type MutationApproveTransactionArgs = {
  id: Scalars['ID']
  input?: InputMaybe<ApproveTransactionInput>
}

export type MutationApproveUpdateRequestArgs = {
  id: Scalars['ID']
}

export type MutationAskProductQuestionArgs = {
  input: AskProductQuestionInput
}

export type MutationAskServiceQuestionArgs = {
  input: AskServiceQuestionInput
}

export type MutationAssociateSearchTokenArgs = {
  input: TokenTypeInput
}

export type MutationBanRequestArgs = {
  id: Scalars['ID']
  input: StatusInput
}

export type MutationBanRequestsArgs = {
  input: StatusesWithMessageInput
}

export type MutationBlockUserArgs = {
  id: Scalars['ID']
  input: BlockUserInput
}

export type MutationCancelIssuingManualCouponsArgs = {
  id: Scalars['ID']
}

export type MutationCancelOrderItemsArgs = {
  input: CancelOrderItemsInput
}

export type MutationChangeChiefSellerManagerArgs = {
  input: SellerManagerInput
}

export type MutationCheckEmailDuplicatedArgs = {
  input: CheckEmailDuplicatedInput
}

export type MutationCheckOrderItemsArgs = {
  input: OrderItemsInput
}

export type MutationCheckUnconfirmedTransactionArgs = {
  id: Scalars['ID']
}

export type MutationClearBannerCacheArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type MutationClearBestCacheArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type MutationClearHomeCacheArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type MutationCompleteShippingOrderItemsArgs = {
  input: OrderItemsInput
}

export type MutationConfirmCancelOrderItemArgs = {
  id: Scalars['ID']
}

export type MutationConfirmExchangeReturnInfosArgs = {
  input: ConfirmExchangeReturnInfosInput
}

export type MutationConfirmOrderItemArgs = {
  id: Scalars['ID']
}

export type MutationConfirmRefundMembershipArgs = {
  id: Scalars['ID']
}

export type MutationConfirmReturnInfosArgs = {
  input: ConfirmReturnInfosInput
}

export type MutationConfirmSellerBillingArgs = {
  id: Scalars['ID']
  input: ConfirmBillingInput
}

export type MutationConfirmUserCertNumberArgs = {
  id: Scalars['ID']
  input: ConfirmUserCertNumberInput
}

export type MutationCopyProductArgs = {
  id: Scalars['ID']
}

export type MutationCreateAdArgs = {
  input: CreateAdInput
}

export type MutationCreateAsInfoArgs = {
  input: CreateAsInfoInput
}

export type MutationCreateBestReviewsArgs = {
  input: CreateBestReviewsInput
}

export type MutationCreateBrandByFitpetAdminArgs = {
  input: BrandByFitpetAdminInput
}

export type MutationCreateBrandBySellerArgs = {
  input: BrandBySellerInput
}

export type MutationCreateBulkReviewByFitpetAdminExcelArgs = {
  input: BaseImportByExcelInput
}

export type MutationCreateCartItemArgs = {
  input: CreateCartItemInput
}

export type MutationCreateCartItemsArgs = {
  input: CreateCartItemsInput
}

export type MutationCreateCategoryArgs = {
  input: CategoryInput
}

export type MutationCreateCouponGroupArgs = {
  input: CouponGroupInput
}

export type MutationCreateCreditCardArgs = {
  input: CreateCreditCardInput
}

export type MutationCreateCustomerGradeArgs = {
  input: CustomerGradeInput
}

export type MutationCreateCustomerTemplateAddressArgs = {
  input: CreateCustomerTemplateAddressInput
}

export type MutationCreateDisplayCollectionArgs = {
  input: CreateDisplayCollectionInput
}

export type MutationCreateDisplayProductInfoArgs = {
  input: CreateDisplayProductInfoInput
}

export type MutationCreateDisplayProductInfosArgs = {
  input: CreateDisplayProductInfosInput
}

export type MutationCreateDisplayProductInfosWithProductPromotionsArgs = {
  input: CreateDisplayProductInfosWithProductPromotionsInput
}

export type MutationCreateHomeBottomBannerArgs = {
  input: BannerInput
}

export type MutationCreateHomeMiddleBannerArgs = {
  input: BannerInput
}

export type MutationCreateHomeMultiLineBannerArgs = {
  input: BannerInput
}

export type MutationCreateMembershipInvitationArgs = {
  id: Scalars['ID']
}

export type MutationCreateNoticeArgs = {
  input: NoticeInput
}

export type MutationCreateOrUpdateSkusByExcelArgs = {
  input: BaseImportByExcelInput
}

export type MutationCreateOrderArgs = {
  input: OrderInput
}

export type MutationCreateOrderItemAdminNoteArgs = {
  input: CreateOrderItemAdminNoteInput
}

export type MutationCreateParticipantResultArgs = {
  input: CreateParticipantResultInput
}

export type MutationCreatePopularSearchTermArgs = {
  input: PopularSearchTermInput
}

export type MutationCreateProductByFitpetAdminArgs = {
  input: ProductInput
}

export type MutationCreateProductBySellerArgs = {
  input: ProductInput
}

export type MutationCreateProductPromotionArgs = {
  input: CreateProductPromotionInput
}

export type MutationCreateProductPromotionsByExcelArgs = {
  input: CreateProductPromotionsByExcelInput
}

export type MutationCreateProductTopBannerArgs = {
  input: BannerInput
}

export type MutationCreateProductsByExcelArgs = {
  input: BaseImportByExcelInput
}

export type MutationCreateRemoteAreaArgs = {
  input: RemoteAreaInput
}

export type MutationCreateRemoteAreaByExcelArgs = {
  input: BaseImportByExcelInput
}

export type MutationCreateReviewArgs = {
  input: CreateReviewInput
}

export type MutationCreateSellerByFitpetAdminArgs = {
  input: CreateSellerByFitpetAdminInput
}

export type MutationCreateSellerByUserRequestArgs = {
  input: CreateSellerByUserRequestInput
}

export type MutationCreateSellerManagerArgs = {
  input: SellerManagerInput
}

export type MutationCreateSellerTemplateAddressArgs = {
  input: CreateSellerTemplateAddressInput
}

export type MutationCreateServerConfigArgs = {
  input: ServerConfigInput
}

export type MutationCreateShippingCompanyArgs = {
  input: ShippingCompanyInput
}

export type MutationCreateShippingPresetArgs = {
  input: CreateShippingPresetInput
}

export type MutationCreateSlimBannerArgs = {
  input: BannerInput
}

export type MutationCreateTopRollBannerArgs = {
  input: BannerInput
}

export type MutationCreateTransactionArgs = {
  input: CreateTransactionInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationDeleteAdArgs = {
  id: Scalars['ID']
}

export type MutationDeleteAdsArgs = {
  input: ModelDeletesInput
}

export type MutationDeleteAsInfoArgs = {
  id: Scalars['ID']
}

export type MutationDeleteBannersArgs = {
  input: DeleteBannersInput
}

export type MutationDeleteCartItemsArgs = {
  input: DeleteCartItemsInput
}

export type MutationDeleteCategoryArgs = {
  id: Scalars['ID']
}

export type MutationDeleteCouponArgs = {
  id: Scalars['ID']
}

export type MutationDeleteCouponGroupArgs = {
  id: Scalars['ID']
}

export type MutationDeleteCouponGroupWithUnusedCouponsArgs = {
  id: Scalars['ID']
}

export type MutationDeleteCouponsArgs = {
  input: ModelDeletesInput
}

export type MutationDeleteCouponsByGroupArgs = {
  id: Scalars['ID']
}

export type MutationDeleteCustomerGradeArgs = {
  id: Scalars['ID']
}

export type MutationDeleteCustomerTemplateAddressArgs = {
  id: Scalars['ID']
}

export type MutationDeleteDisplayCollectionArgs = {
  id: Scalars['ID']
}

export type MutationDeleteDisplayCollectionsArgs = {
  input: ModelDeletesInput
}

export type MutationDeleteDisplayProductInfoArgs = {
  id: Scalars['ID']
}

export type MutationDeleteDisplayProductInfosArgs = {
  input: ModelDeletesInput
}

export type MutationDeleteInactiveUsersArgs = {
  input: DeleteInactiveUsersInput
}

export type MutationDeleteIncompleteProductsArgs = {
  input: ModelDeletesInput
}

export type MutationDeleteNoticesArgs = {
  input: ModelDeletesInput
}

export type MutationDeleteOrderItemAdminNoteArgs = {
  id: Scalars['ID']
}

export type MutationDeleteProductPromotionArgs = {
  id: Scalars['ID']
}

export type MutationDeleteProductQuestionArgs = {
  id: Scalars['ID']
}

export type MutationDeleteProductQuestionsArgs = {
  input: DeleteQnasInput
}

export type MutationDeletePushArgs = {
  id: Scalars['ID']
}

export type MutationDeleteRemoteAreaArgs = {
  id: Scalars['ID']
}

export type MutationDeleteRequestArgs = {
  id: Scalars['ID']
}

export type MutationDeleteRequestsArgs = {
  input: StatusesInput
}

export type MutationDeleteReviewArgs = {
  id: Scalars['ID']
}

export type MutationDeleteReviewsArgs = {
  input: DeleteReviewsInput
}

export type MutationDeleteSellerManagerArgs = {
  input: SellerManagerInput
}

export type MutationDeleteSellerTemplateAddressArgs = {
  id: Scalars['ID']
}

export type MutationDeleteServerConfigArgs = {
  id: Scalars['ID']
}

export type MutationDeleteServiceQuestionArgs = {
  id: Scalars['ID']
}

export type MutationDeleteServiceQuestionsArgs = {
  input: DeleteQnasInput
}

export type MutationDeleteShippingCompanyArgs = {
  id: Scalars['ID']
}

export type MutationDeleteShippingPresetArgs = {
  id: Scalars['ID']
}

export type MutationDisallowBannerArgs = {
  id: Scalars['ID']
}

export type MutationDownloadEventCouponsArgs = {
  input: DownloadEventCouponsInput
}

export type MutationEmailSigninArgs = {
  input: EmailSigninInput
}

export type MutationEmailSignupArgs = {
  input: EmailSignupInput
}

export type MutationEstimateOrderArgs = {
  input: EstimateOrderInput
}

export type MutationExchangeOrderItemsArgs = {
  input: ExchangeOrderItemsInput
}

export type MutationFindIdArgs = {
  input: UserCertificationConfirmInput
}

export type MutationFinishAdArgs = {
  id: Scalars['ID']
}

export type MutationIntegrateActionPowerOrderItemArgs = {
  id: Scalars['ID']
}

export type MutationIssueCouponCodeArgs = {
  input: IssueCouponCodeInput
}

export type MutationIssueManualCouponsArgs = {
  input: IssueManualCouponInput
}

export type MutationIssueManualCouponsByExcelArgs = {
  input: IssueManualCouponsByExcelInput
}

export type MutationKickMembershipFamilyArgs = {
  id: Scalars['ID']
  input: KickMembershipFamilyInput
}

export type MutationLeaveUserArgs = {
  id: Scalars['ID']
  input: LeaveUserInput
}

export type MutationLeaveUsersByFitpetAdminArgs = {
  input: LeaveUsersByFitpetAdminInput
}

export type MutationLinkAccountByEmailArgs = {
  id: Scalars['ID']
  input: LinkAccountByEmailInput
}

export type MutationLinkAccountBySocialArgs = {
  id: Scalars['ID']
  input: LinkAccountBySocialInput
}

export type MutationReadPushArgs = {
  id: Scalars['ID']
}

export type MutationRefreshCartItemsArgs = {
  id: Scalars['ID']
}

export type MutationRefundMembershipArgs = {
  id: Scalars['ID']
  input: MembershipCancelReasonInput
}

export type MutationRegisterCouponArgs = {
  input: RegisterCouponInput
}

export type MutationRejectExchangeReturnInfosArgs = {
  input: RejectExchangeReturnInfosInput
}

export type MutationRejectRequestArgs = {
  id: Scalars['ID']
  input: StatusInput
}

export type MutationRejectRequestsArgs = {
  input: StatusesWithMessageInput
}

export type MutationRejectReturnInfosArgs = {
  input: RejectReturnInfosInput
}

export type MutationRejectUpdateRequestArgs = {
  id: Scalars['ID']
  input: StatusInput
}

export type MutationRemoveAdminPermissionArgs = {
  id: Scalars['ID']
}

export type MutationRequestAdminBillingItemExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestAuditDataExcelFileArgs = {
  input: AuditRequestExcelFileInput
}

export type MutationRequestCouponExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestDisplayProductPromotionExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestExchangeOrderItemsArgs = {
  input: RequestExchangeOrderItemsInput
}

export type MutationRequestInactiveUserExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestMembershipUserExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestOrderExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestOrderReturnConfirmedExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestProductExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestProductExcelFileByAdminArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestProductExcelFileWithoutPasswordByAdminArgs = {
  input: RequestExcelFileWithoutPasswordInput
}

export type MutationRequestReadyForShippingOrderEscrowTossPaymentsExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestReadyForShippingOrderExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestReadyForShippingOrderFitpetWmsExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestReadyForShippingOrderWmsExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestReturnOrderItemsArgs = {
  input: RequestReturnOrderItemsInput
}

export type MutationRequestSellerBillingItemExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestSkuExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationRequestUserExcelFileArgs = {
  input: RequestExcelFileInput
}

export type MutationResetPasswordArgs = {
  id: Scalars['ID']
  input: ResetPasswordInput
}

export type MutationReturnOrderItemsArgs = {
  input: ReturnOrderItemsInput
}

export type MutationReturnedReturnInfosArgs = {
  input: ReturnedReturnInfosInput
}

export type MutationSendEmailArgs = {
  input: SendEmailInput
}

export type MutationSendPushArgs = {
  input: SendPushInput
}

export type MutationSendSmsArgs = {
  input: SendSmsInput
}

export type MutationSendUserCertNumberArgs = {
  input: SendUserCertNumberInput
}

export type MutationSignOutArgs = {
  input: SignOutInput
}

export type MutationSocialSigninArgs = {
  input: SocialSigninInput
}

export type MutationSocialSignupArgs = {
  input: SocialSignupInput
}

export type MutationStartShippingsArgs = {
  input: ShippingsInput
}

export type MutationSubscribeMembershipArgs = {
  input: SubscribeMembershipInput
}

export type MutationSubscribeMembershipFamilyArgs = {
  input: SubscribeMembershipFamilyInput
}

export type MutationSuspendRequestArgs = {
  id: Scalars['ID']
}

export type MutationSuspendRequestsArgs = {
  input: StatusesInput
}

export type MutationToggleCategoryVisibleArgs = {
  id: Scalars['ID']
}

export type MutationToggleProductLikedArgs = {
  id: Scalars['ID']
  input?: InputMaybe<ProductLikedPromotionInput>
}

export type MutationToggleRestockProductOptionArgs = {
  input: ToggleRestockProductOptionInput
}

export type MutationToggleReviewLikedArgs = {
  id: Scalars['ID']
}

export type MutationTokenSigninArgs = {
  input: TokenSigninInput
}

export type MutationUnsubscribeMembershipArgs = {
  id: Scalars['ID']
  input: MembershipCancelReasonInput
}

export type MutationUnsubscribeMembershipFamilyArgs = {
  id: Scalars['ID']
}

export type MutationUpdateAdArgs = {
  id: Scalars['ID']
  input: UpdateAdInput
}

export type MutationUpdateAgreeTermsArgs = {
  id: Scalars['ID']
  input: UpdateAgreeTermsInput
}

export type MutationUpdateAsInfoArgs = {
  id: Scalars['ID']
  input: UpdateAsInfoInput
}

export type MutationUpdateBrandByFitpetAdminArgs = {
  id: Scalars['ID']
  input: BrandByFitpetAdminInput
}

export type MutationUpdateBrandBySellerArgs = {
  id: Scalars['ID']
  input: BrandBySellerInput
}

export type MutationUpdateCartItemArgs = {
  id: Scalars['ID']
  input: UpdateCartItemInput
}

export type MutationUpdateCategoryArgs = {
  id: Scalars['ID']
  input: CategoryInput
}

export type MutationUpdateCategoryImageArgs = {
  id: Scalars['ID']
  input: CategoryImageInput
}

export type MutationUpdateCategorySlugArgs = {
  id: Scalars['ID']
  input: CategorySlugInput
}

export type MutationUpdateCouponGroupArgs = {
  id: Scalars['ID']
  input: CouponGroupInput
}

export type MutationUpdateCouponGroupStatusArgs = {
  id: Scalars['ID']
  input: UpdateCouponGroupStatusInput
}

export type MutationUpdateCustomerGradeArgs = {
  id: Scalars['ID']
  input: CustomerGradeInput
}

export type MutationUpdateCustomerTemplateAddressArgs = {
  id: Scalars['ID']
  input: UpdateCustomerTemplateAddressInput
}

export type MutationUpdateDateOfDisplayCollectionArgs = {
  id: Scalars['ID']
  input: UpdateDisplayCollectionInput
}

export type MutationUpdateDisplayCollectionArgs = {
  id: Scalars['ID']
  input: UpdateDisplayCollectionInput
}

export type MutationUpdateDisplayOrderArgs = {
  id: Scalars['ID']
  input: UpdateDisplayOrderInput
}

export type MutationUpdateDisplayOrderForBannerArgs = {
  id: Scalars['ID']
  input: UpdateDisplayOrderInput
}

export type MutationUpdateDisplayPetTypeArgs = {
  id: Scalars['ID']
  input: UpdateDisplayPetTypeInput
}

export type MutationUpdateDisplayProductInfoArgs = {
  id: Scalars['ID']
  input: UpdateDisplayProductInfoInput
}

export type MutationUpdateHomeBottomBannerArgs = {
  id: Scalars['ID']
  input: BannerInput
}

export type MutationUpdateHomeMiddleBannerArgs = {
  id: Scalars['ID']
  input: BannerInput
}

export type MutationUpdateHomeMultiLineBannerArgs = {
  id: Scalars['ID']
  input: BannerInput
}

export type MutationUpdateMembershipCreditCardArgs = {
  input: SubscribeMembershipInput
}

export type MutationUpdateMileageArgs = {
  id: Scalars['ID']
  input: MileageInput
}

export type MutationUpdateMileageByExcelArgs = {
  input: BaseImportByExcelInput
}

export type MutationUpdateMileageConfigArgs = {
  input: MileageConfigInput
}

export type MutationUpdateMinAppVersionArgs = {
  input: MinAppVersionInput
}

export type MutationUpdateMobileNumberArgs = {
  id: Scalars['ID']
  input: UserCertificationConfirmInput
}

export type MutationUpdateNoticeArgs = {
  id: Scalars['ID']
  input: NoticeInput
}

export type MutationUpdateOrderCustomerAddressArgs = {
  id: Scalars['ID']
  input: UpdateOrderCustomerAddressInput
}

export type MutationUpdateOrderItemAdminNoteArgs = {
  id: Scalars['ID']
  input: UpdateOrderItemAdminNoteInput
}

export type MutationUpdateProductByFitpetAdminArgs = {
  id: Scalars['ID']
  input: ProductInput
}

export type MutationUpdateProductBySellerArgs = {
  id: Scalars['ID']
  input: ProductInput
}

export type MutationUpdateProductMileagesByExcelArgs = {
  input: BaseImportByExcelInput
}

export type MutationUpdateProductPromotionArgs = {
  id: Scalars['ID']
  input: UpdateProductPromotionInput
}

export type MutationUpdateProductPromotionsArgs = {
  input: UpdateProductPromotionsInput
}

export type MutationUpdateProductSearchKeywordsByExcelArgs = {
  input: BaseImportByExcelInput
}

export type MutationUpdateProductTopBannerArgs = {
  id: Scalars['ID']
  input: BannerInput
}

export type MutationUpdateProductsSellingArgs = {
  input: ProductsSellingInput
}

export type MutationUpdateProductsSoldOutArgs = {
  input: ProductsSoldOutInput
}

export type MutationUpdatePushArgs = {
  id: Scalars['ID']
  input: SendPushInput
}

export type MutationUpdateRefundAccountArgs = {
  id: Scalars['ID']
  input: RefundAccountInput
}

export type MutationUpdateRemoteAreaArgs = {
  id: Scalars['ID']
  input: RemoteAreaInput
}

export type MutationUpdateReturnInfoCustomerAddressArgs = {
  id: Scalars['ID']
  input: UpdateReturnInfoCustomerAddressInput
}

export type MutationUpdateReviewArgs = {
  id: Scalars['ID']
  input: UpdateReviewInput
}

export type MutationUpdateReviewByFitpetAdminArgs = {
  id: Scalars['ID']
  input: UpdateReviewInput
}

export type MutationUpdateReviewGroupProductsArgs = {
  id: Scalars['ID']
  input: UpdateReviewGroupProductsInput
}

export type MutationUpdateSearchTextMutationArgs = {
  input: UpdateSearchTextInput
}

export type MutationUpdateSellerByFitpetAdminArgs = {
  id: Scalars['ID']
  input: UpdateSellerByFitpetAdminInput
}

export type MutationUpdateSellerByUserRequestArgs = {
  id: Scalars['ID']
  input: UpdateSellerInput
}

export type MutationUpdateSellerNotificationArgs = {
  id: Scalars['ID']
  input: SellerNotificationInput
}

export type MutationUpdateSellerTemplateAddressArgs = {
  id: Scalars['ID']
  input: UpdateSellerTemplateAddressInput
}

export type MutationUpdateServerConfigArgs = {
  id: Scalars['ID']
  input: ServerConfigInput
}

export type MutationUpdateShippingByExcelArgs = {
  input: BaseImportByExcelInput
}

export type MutationUpdateShippingCompanyArgs = {
  id: Scalars['ID']
  input: ShippingCompanyInput
}

export type MutationUpdateShippingPresetArgs = {
  id: Scalars['ID']
  input: UpdateShippingPresetInput
}

export type MutationUpdateShippingsArgs = {
  input: ShippingsInput
}

export type MutationUpdateSkuArgs = {
  id: Scalars['ID']
  input: UpdateSkuInput
}

export type MutationUpdateSlimBannerArgs = {
  id: Scalars['ID']
  input: BannerInput
}

export type MutationUpdateTestUserArgs = {
  input: UpdateTestUserInput
}

export type MutationUpdateTopRollBannerArgs = {
  id: Scalars['ID']
  input: BannerInput
}

export type MutationUpdateUserByCustomerArgs = {
  id: Scalars['ID']
  input: UpdateUserByCustomerInput
}

export type MutationUpdateUserByFitpetAdminArgs = {
  id: Scalars['ID']
  input: UpdateUserByFitpetAdminInput
}

export type MutationUpdateUserPasswordByFitpetAdminArgs = {
  id: Scalars['ID']
  input: UpdateUserPasswordByFitpetAdminInput
}

export type MutationUploadSearchTokenArgs = {
  input: UploadSearchTokenInput
}

export type MutationWishBrandByCustomerArgs = {
  input: WishBrandByCustomerInput
}

export type MutationWishProductByCustomerArgs = {
  input: WishProductByCustomerInput
}

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']
}

export type NoticeInput = {
  attachment?: InputMaybe<Scalars['Upload']>
  /** 공지사항 내용 */
  body: Scalars['String']
  /** 중요 여부 */
  isImportant?: InputMaybe<Scalars['Boolean']>
  /** 공지사항 구분 */
  kind: Scalars['String']
  /** 대상자 */
  receiverType: Scalars['String']
  /** 공지사항 제목 */
  title: Scalars['String']
}

/** An enumeration. */
export enum NoticeKind {
  /** NOTICE_KIND_BILLING */
  NoticeKindBilling = 'NOTICE_KIND_BILLING',
  /** NOTICE_KIND_GENERAL */
  NoticeKindGeneral = 'NOTICE_KIND_GENERAL',
  /** NOTICE_KIND_MANUAL */
  NoticeKindManual = 'NOTICE_KIND_MANUAL',
}

/** An enumeration. */
export enum NoticeReceiverType {
  /** ALL */
  All = 'ALL',
  /** CUSTOMER */
  Customer = 'CUSTOMER',
  /** INFLUENCER */
  Influencer = 'INFLUENCER',
  /** PARTNER */
  Partner = 'PARTNER',
  /** SELLER */
  Seller = 'SELLER',
}

export type NoticeType = Node & {
  __typename?: 'NoticeType'
  /** 공지 첨부파일 */
  attachment?: Maybe<Scalars['String']>
  /** 공지사항 내용 */
  body: Scalars['String']
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 중요 여부 */
  isImportant: Scalars['Boolean']
  /** 공지사항 구분 */
  kind: NoticeKind
  /** 대상자 */
  receiverType: NoticeReceiverType
  /** 공지사항 제목 */
  title: Scalars['String']
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 작성자 */
  user?: Maybe<UserType>
}

export type NoticeTypeConnection = {
  __typename?: 'NoticeTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NoticeTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `NoticeType` and its cursor. */
export type NoticeTypeEdge = {
  __typename?: 'NoticeTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<NoticeType>
}

export type OrderCustomerAddressInput = {
  /** 기본 주소 */
  basicAddress?: InputMaybe<Scalars['String']>
  /** 사용자 배송 주소 템플릿 */
  customerTemplateAddress?: InputMaybe<Scalars['ID']>
  /** 상세 주소 */
  detailedAddress?: InputMaybe<Scalars['String']>
  /** 기본 배송지로 지정 여부 */
  isDefault: Scalars['Boolean']
  /** 배송메세지 */
  message?: InputMaybe<Scalars['String']>
  /** 고객 핸드폰 번호 */
  mobileNumber: Scalars['String']
  /** 고객 이름 */
  name: Scalars['String']
  /** 우편번호 */
  postNumber?: InputMaybe<Scalars['String']>
  /** 배송지명 */
  templateName: Scalars['String']
}

export type OrderInput = {
  /** 주문 쿠폰 */
  coupon?: InputMaybe<Scalars['ID']>
  customerAddress: OrderCustomerAddressInput
  /** 바로 구매 여부 */
  isDirect?: InputMaybe<Scalars['Boolean']>
  /** 마일리지 전액 사용 여부 */
  isUseAllMileage?: InputMaybe<Scalars['Boolean']>
  /** 사용할 포인트 */
  mileage?: InputMaybe<Scalars['Int']>
  /** 마지막 결제 수단 */
  payMethodType?: InputMaybe<Scalars['String']>
  /** 배송 단위 */
  shippings: Array<InputMaybe<OrderShippingInput>>
  /** 주문서 API 버전 */
  version?: InputMaybe<Scalars['String']>
}

export type OrderItemAdminNoteType = Node & {
  __typename?: 'OrderItemAdminNoteType'
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 작성한 관리자 */
  fitpetAdmin?: Maybe<UserType>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 관리자 메모 */
  note?: Maybe<Scalars['String']>
  /** 작성한 노트 */
  orderItem?: Maybe<OrderItemType>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type OrderItemAdminNoteTypeConnection = {
  __typename?: 'OrderItemAdminNoteTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderItemAdminNoteTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `OrderItemAdminNoteType` and its cursor. */
export type OrderItemAdminNoteTypeEdge = {
  __typename?: 'OrderItemAdminNoteTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<OrderItemAdminNoteType>
}

export type OrderItemCancelReasonType = {
  __typename?: 'OrderItemCancelReasonType'
  /** 취소사유목록 */
  cancelReasons?: Maybe<Array<Maybe<CancelReasonType>>>
}

/** An enumeration. */
export enum OrderItemCancelRequestType {
  /** 가상계좌 미입금 자동취소 */
  AutoVbank = 'AUTO_VBANK',
  /** 단순 변심 */
  ChangeMind = 'CHANGE_MIND',
  /** 다른 쇼핑몰 가격이 더 저렴함 */
  CheaperOther = 'CHEAPER_OTHER',
  /** 입고 지연 */
  DelayArrival = 'DELAY_ARRIVAL',
  /** 배송지연으로 취소 */
  DelayDelivery = 'DELAY_DELIVERY',
  /** 기타 */
  Etc = 'ETC',
  /** 상품 추가 또는 옵션 변경 후 재주문 */
  MischoiceProductOption = 'MISCHOICE_PRODUCT_OPTION',
  /** 상품 품절 */
  SoldOutProduct = 'SOLD_OUT_PRODUCT',
  /** 가상계좌 미입금 취소 */
  UnconfirmedVbank = 'UNCONFIRMED_VBANK',
}

/** An enumeration. */
export enum OrderItemCancelResponsibility {
  /** ADMIN */
  Admin = 'ADMIN',
  /** ALL */
  All = 'ALL',
  /** CUSTOMER */
  Customer = 'CUSTOMER',
  /** INFLUENCER */
  Influencer = 'INFLUENCER',
  /** NONE */
  None = 'NONE',
  /** SELLER */
  Seller = 'SELLER',
}

export type OrderItemCountType = {
  __typename?: 'OrderItemCountType'
  /** 취소요청건수 */
  cancelRequestedCount?: Maybe<Scalars['Int']>
  /** 취소완료건수 */
  canceledCount?: Maybe<Scalars['Int']>
  /** 구매확정건수 */
  confirmedCount?: Maybe<Scalars['Int']>
  /** 교환완료 건수 */
  exchangeConfirmedCount?: Maybe<Scalars['Int']>
  /** 교환요청 건수 */
  exchangeRequestedCount?: Maybe<Scalars['Int']>
  /** 교환수거중 건수 */
  exchangeUnderReturnCount?: Maybe<Scalars['Int']>
  /** 결제완료건수 */
  newOrderCount?: Maybe<Scalars['Int']>
  /** 배송준비중건수 */
  readyForShippingCount?: Maybe<Scalars['Int']>
  /** 반품환불완료 건수 */
  returnConfirmedCount?: Maybe<Scalars['Int']>
  /** 반품총갯수 */
  returnCount?: Maybe<Scalars['Int']>
  /** 반품요청 건수 */
  returnRequestedCount?: Maybe<Scalars['Int']>
  /** 반품완료 건수 */
  returnReturnedCount?: Maybe<Scalars['Int']>
  /** 반품수거중 건수 */
  returnUnderReturnCount?: Maybe<Scalars['Int']>
  /** 배송완료건수 */
  shippedCount?: Maybe<Scalars['Int']>
  /** 배송중건수 */
  shippingCount?: Maybe<Scalars['Int']>
  /** 미결제건수 */
  unconfirmedCount?: Maybe<Scalars['Int']>
}

export type OrderItemInput = {
  orderItem: Scalars['ID']
}

export type OrderItemLineType = Node & {
  __typename?: 'OrderItemLineType'
  /** 구매 확정 적립금 */
  confirmMileage?: Maybe<Scalars['Int']>
  /** 구매 확정 시 적립율, 0.xx로 표기. */
  confirmMileageRewardRate?: Maybe<Scalars['Float']>
  /** 핏펫 정액 수수료 */
  fitpetFeeAmount?: Maybe<Scalars['Decimal']>
  /** 핏펫 수수료 */
  fitpetFeeRate?: Maybe<Scalars['Float']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 이미지 리뷰 적립금 */
  imageReviewMileage?: Maybe<Scalars['Int']>
  /** 이미지 리뷰 작성 시 적립율, 0.xx로 표기. */
  imageReviewMileageRewardRate?: Maybe<Scalars['Float']>
  /** 멤버쉽 구매 여부 */
  isMembership: Scalars['Boolean']
  /** 멤버쉽 구매 확정 적립금 */
  membershipConfirmMileage?: Maybe<Scalars['Int']>
  /** 멤버쉽 회원 구매 확정 시 적립율, 0.xx로 표기. */
  membershipConfirmMileageRewardRate?: Maybe<Scalars['Float']>
  /** 멤버쉽 이미지 리뷰 적립금 */
  membershipImageReviewMileage?: Maybe<Scalars['Int']>
  /** 멤버쉽 회원 이미지 리뷰 작성 시 적립율, 0.xx로 표기. */
  membershipImageReviewMileageRewardRate?: Maybe<Scalars['Float']>
  /** 멤버쉽 리뷰 적립금 */
  membershipReviewMileage?: Maybe<Scalars['Int']>
  /** 멤버쉽 회원 리뷰 작성 시 적립율, 0.xx로 표기. */
  membershipReviewMileageRewardRate?: Maybe<Scalars['Float']>
  /** 상품 주문 */
  orderItem?: Maybe<OrderItemType>
  /** 리뷰 적립금 */
  reviewMileage?: Maybe<Scalars['Int']>
  /** 리뷰 작성 시 적립율, 0.xx로 표기. */
  reviewMileageRewardRate?: Maybe<Scalars['Float']>
  shippingCouponDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 분할된 기본 배송비 할인액 */
  shippingDiscountPrice: Scalars['Decimal']
  /** 분할된 도서산간 추가 배송비 */
  shippingExtraFee?: Maybe<Scalars['Decimal']>
  shippingFee?: Maybe<Scalars['Decimal']>
  shippingMileageDiscountPrice?: Maybe<Scalars['Decimal']>
  shippingNumberRegisteredAt?: Maybe<Scalars['Decimal']>
}

/** An enumeration. */
export enum OrderItemOrderStatus {
  /** BEFORE_PAYMENT */
  BeforePayment = 'BEFORE_PAYMENT',
  /** CANCELED */
  Canceled = 'CANCELED',
  /** CANCEL_REQUESTED */
  CancelRequested = 'CANCEL_REQUESTED',
  /** CONFIRMED */
  Confirmed = 'CONFIRMED',
  /** EXCHANGE_CONFIRMED */
  ExchangeConfirmed = 'EXCHANGE_CONFIRMED',
  /** EXCHANGE_REQUESTED */
  ExchangeRequested = 'EXCHANGE_REQUESTED',
  /** EXCHANGE_UNDER_RETURN */
  ExchangeUnderReturn = 'EXCHANGE_UNDER_RETURN',
  /** NEW_ORDER */
  NewOrder = 'NEW_ORDER',
  /** READY_FOR_SHIPPING */
  ReadyForShipping = 'READY_FOR_SHIPPING',
  /** RETURN_CONFIRMED */
  ReturnConfirmed = 'RETURN_CONFIRMED',
  /** RETURN_REQUESTED */
  ReturnRequested = 'RETURN_REQUESTED',
  /** RETURN_RETURNED */
  ReturnReturned = 'RETURN_RETURNED',
  /** RETURN_UNDER_RETURN */
  ReturnUnderReturn = 'RETURN_UNDER_RETURN',
  /** SHIPPED */
  Shipped = 'SHIPPED',
  /** SHIPPING */
  Shipping = 'SHIPPING',
  /** UNCONFIRMED_PAYMENT */
  UnconfirmedPayment = 'UNCONFIRMED_PAYMENT',
}

export type OrderItemReturnReasonType = {
  __typename?: 'OrderItemReturnReasonType'
  /** 반품사유목록 */
  returnReasons?: Maybe<Array<Maybe<ReturnReasonType>>>
}

export type OrderItemType = Node & {
  __typename?: 'OrderItemType'
  actionPowerOrder?: Maybe<ActionPowerOrderType>
  adminNotes?: Maybe<OrderItemAdminNoteTypeConnection>
  /** 최종 금액 */
  amount?: Maybe<Scalars['Decimal']>
  /** 주문금액 제외 최종금액 */
  amountWithoutOrderCoupon?: Maybe<Scalars['Decimal']>
  approvedCancelTransaction?: Maybe<CancelTransactionType>
  approvedTransaction?: Maybe<TransactionType>
  availableCoupons?: Maybe<CouponTypeConnection>
  /** 정산 대상 주문 아이템 */
  billingitemSet: BillingItemTypeConnection
  /** 브랜드 */
  brand?: Maybe<BrandType>
  /** 부분 취소 가능 여부 */
  canPartialCancel?: Maybe<Scalars['Boolean']>
  /** 취소 안내 메세지 */
  cancelGuideMessage?: Maybe<Scalars['String']>
  /** 취소 요청 반려 사유 */
  cancelRejectMessage?: Maybe<Scalars['String']>
  /** 취소 요청 반려일 */
  cancelRejectedAt?: Maybe<Scalars['DateTime']>
  /** cancel_request_type이 ETC일 때 기타 사유 입력필드 */
  cancelRequestMessage?: Maybe<Scalars['String']>
  /** 단순 변심 (CHANGE_MIND), 상품 추가 또는 옵션 변경 후 재주문 (MISCHOICE_PRODUCT_OPTION), 상품 불량 및 파손 (DEFECTIVE_PRODUCT), 오배송 (WRONG_DELIVERY), 상품이 설명과 다름 (DIFFERENT_PRODUCT_INFO), 서비스 불만족 (DISSATISFACTION), 가상계좌 미입금 자동취소(AUTO_VBANK), 가상계좌 미입금 취소(UNCONFIRMED_VBANK), 기타 (ETC), */
  cancelRequestType: OrderItemCancelRequestType
  /** 취소 요청일 */
  cancelRequestedAt?: Maybe<Scalars['DateTime']>
  /** 취소 요청자 (CUSTOMER/SELLER/ADMIN) */
  cancelResponsibility: OrderItemCancelResponsibility
  /** 취소자 */
  cancelUser?: Maybe<Scalars['String']>
  /** 취소 확정일 */
  canceledAt?: Maybe<Scalars['DateTime']>
  canceltransaction?: Maybe<CancelTransactionType>
  /** 발주확인일 */
  checkedAt?: Maybe<Scalars['DateTime']>
  /** 구매확정일 */
  confirmedAt?: Maybe<Scalars['DateTime']>
  /** 상품 쿠폰 할인액 */
  couponDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 사용한 쿠폰 정보 */
  couponInfo?: Maybe<Scalars['JSONString']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  customerAmount?: Maybe<Scalars['Decimal']>
  customerPrice?: Maybe<Scalars['Decimal']>
  /** 상품 기본 할인액 */
  discountPrice?: Maybe<Scalars['Decimal']>
  exchangeNewOrderItem?: Maybe<OrderItemType>
  /** 교환 배송비 */
  exchangeShippingFee?: Maybe<Scalars['Decimal']>
  /** 교환 대상 오더 아이템 */
  exchangedOrderItem?: Maybe<OrderItemType>
  /** 핏펫 수수료 */
  fitpetFeeRate?: Maybe<Scalars['Float']>
  /** 사용자가 쓴 리뷰가 있는지 여부 */
  hasUserReview?: Maybe<Scalars['Boolean']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 예상 (이미지 리뷰 - 리뷰) 적립금 */
  imageReviewDiffReviewMileage?: Maybe<Scalars['Int']>
  /** 예상 (이미지 리뷰 - 리뷰) 적립율 */
  imageReviewDiffReviewMileageRate?: Maybe<Scalars['Float']>
  /** 예상 이미지 리뷰 적립금 */
  imageReviewMileage?: Maybe<Scalars['Int']>
  /** 예상 이미지 리뷰 적립율 */
  imageReviewMileageRate?: Maybe<Scalars['Float']>
  /** 예상 멤버쉽 최대 적립금 */
  membershipMileage?: Maybe<Scalars['Int']>
  /** 예상 멤버쉽 최대 적립율 */
  membershipMileageRate?: Maybe<Scalars['Float']>
  /** 예상 (멤버쉽 - 일반) 구매 확정 적립금 */
  membershipOrderConfirmDiffMileage?: Maybe<Scalars['Int']>
  /** 예상 (멤버쉽 - 일반) 구매 확정 적립율 */
  membershipOrderConfirmDiffMileageRate?: Maybe<Scalars['Float']>
  /** 예상 멤버쉽 구매 확정 적립금 */
  membershipOrderConfirmMileage?: Maybe<Scalars['Int']>
  /** 예상 멤버쉽 구매 확정 적립율 */
  membershipOrderConfirmMileageRate?: Maybe<Scalars['Float']>
  /** 예상 최대 적립금 */
  mileage?: Maybe<Scalars['Int']>
  /** 상품 적립금 할인액 */
  mileageDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 예상 최대 적립율 */
  mileageRate?: Maybe<Scalars['Float']>
  /** 적립율, 0.xx로 표기 */
  mileageRewardRate: Scalars['Float']
  /** 주문 */
  order?: Maybe<OrderType>
  /** 예상 구매 확정 적립금 */
  orderConfirmMileage?: Maybe<Scalars['Int']>
  /** 예상 구매 확정 적립율 */
  orderConfirmMileageRate?: Maybe<Scalars['Float']>
  /** 금액 비율로 쪼개진 주문 쿠폰 할인액 */
  orderCouponDiscountPrice: Scalars['Decimal']
  /** 쿠폰 적용 주문 아이템 */
  orderItemCoupons: CouponTypeConnection
  /** 적립금 사용/적립된 주문 아이템 */
  orderItemMileageHistories: MileageHistoryTypeConnection
  /** 주문번호 */
  orderItemNumber: Scalars['String']
  /** 작성한 노트 */
  orderItemOrderItemAdminNotes: OrderItemAdminNoteTypeConnection
  /** 주문상품 아이템 */
  orderItemReviews: ReviewTypeConnection
  /** QnA관련 주문 */
  orderItemServiceQnas: ServiceQnaTypeConnection
  /** STATUS_ORDER_BEFORE_PAYMENT(신규생성)/UNCONFIRMED_PAYMENT(입금확인중)/NEW_ORDER(신규주문)/READY_FOR_SHIPPING(배송준비)/SHIPPING(배송중)/SHIPPED(배송완료)/CANCEL_REQUESTED(취소요청)/CANCELED(취소완료)/CONFIRMED(구매확정)/RETURN_REQUESTED(반품요청)/RETURN_UNDER_RETURN(반품중)/RETURN_RETURNED(반품완료)/RETURN_CONFIRMED(환불완료)/EXCHANGE_REQUESTED(교환요청)EXCHANGE_UNDER_RETURN(교환반품중)/EXCHANGE_CONFIRM(교환반품완료) */
  orderStatus: OrderItemOrderStatus
  orderStatusText?: Maybe<Scalars['String']>
  orderitem?: Maybe<OrderItemType>
  orderitemline?: Maybe<OrderItemLineType>
  /** 상품 원가 * 갯수 */
  originalAmount?: Maybe<Scalars['Decimal']>
  /** 상품 원가 */
  originalPrice: Scalars['Decimal']
  /** 상품 할인액 * 갯수 */
  productDiscountAmount?: Maybe<Scalars['Decimal']>
  /** 상품 할인액 */
  productDiscountPrice?: Maybe<Scalars['Decimal']>
  productImage?: Maybe<ProductImageType>
  /** 상품명 */
  productName?: Maybe<Scalars['String']>
  /** 상품 옵션 */
  productOption?: Maybe<ProductOptionType>
  /** 프로모션 */
  productPromotion?: Maybe<ProductPromotionType>
  /** 판매금액 - 프로모션금액 - 쿠폰할인 */
  productPromotionCouponCustomerAmount?: Maybe<Scalars['Decimal']>
  productPromotionCustomerAmount?: Maybe<Scalars['Decimal']>
  productPromotionCustomerAmountExists?: Maybe<Scalars['Decimal']>
  productPromotionCustomerPrice?: Maybe<Scalars['Decimal']>
  productPromotionCustomerPriceExists?: Maybe<Scalars['Decimal']>
  /** 이벤트 할인액 */
  productPromotionDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 주문 갯수 */
  quantity: Scalars['Int']
  /** 배송완료일 */
  receivedAt?: Maybe<Scalars['DateTime']>
  refundAmount?: Maybe<Scalars['Decimal']>
  /** 환불/반품금액정보 */
  refundInfo?: Maybe<RefundInfoType>
  /** 배송비 및 환불 수수료 정보 */
  refundShippingInfo?: Maybe<RefundShippingInfoType>
  /** 취소/반품/교환 가능 여부 */
  refundable?: Maybe<Scalars['Boolean']>
  /** 반품접수일 */
  returnAcceptedAt?: Maybe<Scalars['DateTime']>
  returnAddress?: Maybe<SellerAddressType>
  /** 환불완료일 */
  returnConfirmedAt?: Maybe<Scalars['DateTime']>
  returnGuideMessage?: Maybe<Scalars['String']>
  /** 반품 관련 정보 */
  returnInfo?: Maybe<ReturnInfoType>
  /** 반품반려메세지 */
  returnRejectMessage?: Maybe<Scalars['String']>
  /** 반품반려일 */
  returnRejectedAt?: Maybe<Scalars['DateTime']>
  /** 반품요청메세지 */
  returnRequestMessage?: Maybe<Scalars['String']>
  /** 반품요청일 */
  returnRequestedAt?: Maybe<Scalars['DateTime']>
  /** 반품 책임 */
  returnResponsibility?: Maybe<Scalars['String']>
  /** 수거완료일 */
  returnReturnedAt?: Maybe<Scalars['DateTime']>
  /** 반품 배송비 */
  returnShippingFee?: Maybe<Scalars['Decimal']>
  /** 예상 리뷰 적립금 */
  reviewMileage?: Maybe<Scalars['Int']>
  /** 예상 리뷰 적립율 */
  reviewMileageRate?: Maybe<Scalars['Float']>
  /** 리뷰 작성 한계 시간 */
  reviewWritableDatetime?: Maybe<Scalars['DateTime']>
  /** 셀러 */
  seller?: Maybe<SellerType>
  /** 배송시작일 */
  sentAt?: Maybe<Scalars['DateTime']>
  /** 배송관련정보 */
  shipping?: Maybe<ShippingType>
  /** 상품 타입 */
  supplyType?: Maybe<Scalars['String']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 예금주명 */
  vbankAccountHolderName?: Maybe<Scalars['String']>
  /** 계좌번호 */
  vbankAccountNumber?: Maybe<Scalars['String']>
  /** 입금은행명 */
  vbankName?: Maybe<Scalars['String']>
}

export type OrderItemTypeAdminNotesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type OrderItemTypeAvailableCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroup?: InputMaybe<Scalars['String']>
  couponGroupName?: InputMaybe<Scalars['String']>
  couponStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  isUsed?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type OrderItemTypeBillingitemSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  confirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  confirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeFitpetSeller?: InputMaybe<Scalars['Boolean']>
  excludeMileageBilling?: InputMaybe<Scalars['Boolean']>
  excludeSeller?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  hasSellerFee?: InputMaybe<Scalars['Boolean']>
  isSellerBilled?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItemNumber?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerBilledAtAfter?: InputMaybe<Scalars['DateTime']>
  sellerBilledAtBefore?: InputMaybe<Scalars['DateTime']>
  sellerChiefManagerName?: InputMaybe<Scalars['String']>
  sellerFeeGte?: InputMaybe<Scalars['Float']>
  sellerName?: InputMaybe<Scalars['String']>
}

export type OrderItemTypeImageReviewDiffReviewMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeImageReviewDiffReviewMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeImageReviewMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeImageReviewMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeMembershipMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeMembershipMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeMembershipOrderConfirmDiffMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeMembershipOrderConfirmDiffMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeMembershipOrderConfirmMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeMembershipOrderConfirmMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeOrderConfirmMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeOrderConfirmMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeOrderItemCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroup?: InputMaybe<Scalars['String']>
  couponGroupName?: InputMaybe<Scalars['String']>
  couponStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  isUsed?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type OrderItemTypeOrderItemMileageHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isMembership?: InputMaybe<Scalars['Boolean']>
  isPlus?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mileageType?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
}

export type OrderItemTypeOrderItemOrderItemAdminNotesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type OrderItemTypeOrderItemReviewsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  bestAt?: InputMaybe<Scalars['Date']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isPhotoReview?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  reviewBody?: InputMaybe<Scalars['String']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type OrderItemTypeOrderItemServiceQnasArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItem?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  questionType?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type OrderItemTypeReviewMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeReviewMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type OrderItemTypeConnection = {
  __typename?: 'OrderItemTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderItemTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `OrderItemType` and its cursor. */
export type OrderItemTypeEdge = {
  __typename?: 'OrderItemTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<OrderItemType>
}

export type OrderItemsInput = {
  orderItems: Array<InputMaybe<OrderItemInput>>
}

export type OrderOrderItemInput = {
  /** 쿠폰 */
  coupon?: InputMaybe<Scalars['ID']>
  /** 상품 옵션 */
  productOption: Scalars['ID']
  /** 프로모션 */
  productPromotion?: InputMaybe<Scalars['ID']>
  /** 주문 갯수 */
  quantity: Scalars['Int']
}

export type OrderRefundInfoType = {
  __typename?: 'OrderRefundInfoType'
  couponDiscountAmount?: Maybe<Scalars['Decimal']>
  exchangeNewShippingExtraFeeAmount?: Maybe<Scalars['Decimal']>
  exchangeNewShippingFeeAmount?: Maybe<Scalars['Decimal']>
  exchangeReturnShippingFeeAmount?: Maybe<Scalars['Decimal']>
  mileageDiscountAmount?: Maybe<Scalars['Decimal']>
  orderCouponDiscountAmount?: Maybe<Scalars['Decimal']>
  originalAmount?: Maybe<Scalars['Decimal']>
  productDiscountAmount?: Maybe<Scalars['Decimal']>
  productPromotionDiscountAmount?: Maybe<Scalars['Decimal']>
  refundShippingExtraFeeAmount?: Maybe<Scalars['Decimal']>
  refundShippingFeeAmount?: Maybe<Scalars['Decimal']>
  returnShippingExtraFeeAmount?: Maybe<Scalars['Decimal']>
  returnShippingFeeAmount?: Maybe<Scalars['Decimal']>
  soldAmount?: Maybe<Scalars['Decimal']>
  totalRefundAmount?: Maybe<Scalars['Decimal']>
}

export type OrderShippingInput = {
  /** 배송비 쿠폰 */
  coupon?: InputMaybe<Scalars['ID']>
  orderItems: Array<InputMaybe<OrderOrderItemInput>>
}

export type OrderType = Node & {
  __typename?: 'OrderType'
  amount?: Maybe<Scalars['Decimal']>
  /** 실제 결제가 일어난 트랜잭션 */
  approvedTransaction?: Maybe<TransactionType>
  availableCoupons?: Maybe<CouponTypeConnection>
  /** 현재 주문을 제외한 마지막 구매일 */
  beforeOrderedAt?: Maybe<Scalars['DateTime']>
  /** 부분 취소 가능 여부 */
  canPartialCancel?: Maybe<Scalars['Boolean']>
  /** 상품 쿠폰 할인액 합 */
  couponDiscountAmount?: Maybe<Scalars['Decimal']>
  /** 사용한 쿠폰 정보 */
  couponInfo?: Maybe<Scalars['JSONString']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 배송주소 */
  customerAddress?: Maybe<CustomerAddressType>
  /** 주문 디바이스 */
  device?: Maybe<DeviceType>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 예상 (이미지 리뷰 - 리뷰) 적립금 */
  imageReviewDiffReviewMileage?: Maybe<Scalars['Int']>
  /** 예상 이미지 리뷰 적립금 */
  imageReviewMileage?: Maybe<Scalars['Int']>
  /** 첫 구매 여부, 입금 여부와 상관 없이 이전 결제의 결제 완료(가상계좌 포함)이 있다면 False */
  isFirstPurchase?: Maybe<Scalars['Boolean']>
  /** 멤버쉽 */
  membership?: Maybe<MembershipType>
  /** 예상 멤버쉽 최대 적립금 */
  membershipMileage?: Maybe<Scalars['Int']>
  /** 예상 (멤버쉽 - 일반) 구매 확정 적립금 */
  membershipOrderConfirmDiffMileage?: Maybe<Scalars['Int']>
  /** 예상 멤버쉽 구매 확정 적립금 */
  membershipOrderConfirmMileage?: Maybe<Scalars['Int']>
  /** 예상 최대 적립금 */
  mileage?: Maybe<Scalars['Int']>
  /** 상품 적립금 할인액 합 */
  mileageDiscountAmount?: Maybe<Scalars['Decimal']>
  /** 취소 대상 Order */
  orderCancelTransactions: CancelTransactionTypeConnection
  /** 예상 구매 확정 적립금 */
  orderConfirmMileage?: Maybe<Scalars['Int']>
  /** 주문 쿠폰 할인액 */
  orderCouponDiscountAmount: Scalars['Decimal']
  /** 쿠폰 적용 주문 */
  orderCoupons: CouponTypeConnection
  /** 적립금이 사용/적립된 주문 */
  orderMileageHistories: MileageHistoryTypeConnection
  /** 주문번호 */
  orderNumber: Scalars['String']
  orderOrderItems?: Maybe<OrderItemTypeConnection>
  orderRefundInfo?: Maybe<OrderRefundInfoType>
  /** 주문 */
  orderTransactions: TransactionTypeConnection
  /** 상품 원가 합 */
  originalAmount: Scalars['Decimal']
  /** 상품 기본 할인액 합 */
  productDiscountAmount?: Maybe<Scalars['Decimal']>
  productPromotionCustomerAmount?: Maybe<Scalars['Decimal']>
  /** 이벤트 할인액 합 */
  productPromotionDiscountAmount: Scalars['Decimal']
  /** 예상 리뷰 적립금 */
  reviewMileage?: Maybe<Scalars['Int']>
  shippingAmount?: Maybe<Scalars['Decimal']>
  /** 배송비 쿠폰 할인액 합 */
  shippingCouponDiscountAmount: Scalars['Decimal']
  /** 기본 배송비 할인액 합 */
  shippingDiscountAmount: Scalars['Decimal']
  /** 도서 산간 추가 배송비 합 */
  shippingExtraFees: Scalars['Decimal']
  /** 기본 배송비 합 */
  shippingFees: Scalars['Decimal']
  /** 배송비 적립금 할인액 합 */
  shippingMileageDiscountAmount: Scalars['Decimal']
  shippings?: Maybe<ShippingTypeConnection>
  totalItemQuantity?: Maybe<Scalars['Int']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 주문자 */
  user?: Maybe<UserType>
}

export type OrderTypeAvailableCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type OrderTypeOrderCancelTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type OrderTypeOrderCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroup?: InputMaybe<Scalars['String']>
  couponGroupName?: InputMaybe<Scalars['String']>
  couponStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  isUsed?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type OrderTypeOrderMileageHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isMembership?: InputMaybe<Scalars['Boolean']>
  isPlus?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mileageType?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
}

export type OrderTypeOrderOrderItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  excludeExchanges?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type OrderTypeOrderTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type OrderTypeShippingsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type OrderTypeConnection = {
  __typename?: 'OrderTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `OrderType` and its cursor. */
export type OrderTypeEdge = {
  __typename?: 'OrderTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<OrderType>
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
}

export type PageInfoInputType = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isUnlimited?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
}

/** An enumeration. */
export enum ParticipantResultsEventType {
  /** 고양이 */
  Cat = 'CAT',
  /** 강아지 */
  Dog = 'DOG',
  /** 상품 */
  Product = 'PRODUCT',
}

export type ParticipantResultsType = Node & {
  __typename?: 'ParticipantResultsType'
  createdAt: Scalars['DateTime']
  deletedAt?: Maybe<Scalars['DateTime']>
  /** 이벤트 아이디 */
  eventId: Scalars['Int']
  /** 이벤트 타입 */
  eventType?: Maybe<ParticipantResultsEventType>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 당첨 여부 */
  result: Scalars['Boolean']
  /** 최종 선택한 값(selected_id로 표현 안되는 값) */
  selectValue?: Maybe<Scalars['String']>
  /** 최종 선택한 아이디 */
  selectedId?: Maybe<Scalars['Int']>
  /** 최종 선택한 옵션 아이디 */
  selectedOptionId?: Maybe<Scalars['Int']>
  updatedAt: Scalars['DateTime']
  /** 유저 아이디 */
  user?: Maybe<UserType>
}

export type ParticipantResultsTypeConnection = {
  __typename?: 'ParticipantResultsTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ParticipantResultsTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ParticipantResultsType` and its cursor. */
export type ParticipantResultsTypeEdge = {
  __typename?: 'ParticipantResultsTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ParticipantResultsType>
}

export type PopularKeywordConnection = {
  __typename?: 'PopularKeywordConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PopularKeywordEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `PopularKeyword` and its cursor. */
export type PopularKeywordEdge = {
  __typename?: 'PopularKeywordEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<PopularKeywordType>
}

export type PopularKeywordType = {
  __typename?: 'PopularKeywordType'
  changeRank: Scalars['Int']
  isNewRank?: Maybe<Scalars['Boolean']>
  keyword?: Maybe<Scalars['String']>
  rank?: Maybe<Scalars['Int']>
}

export type PopularSearchTermInput = {
  /** 디스플레이 순서 */
  series?: InputMaybe<Scalars['String']>
  /** 검색어 */
  term: Scalars['String']
}

export type PopularSearchTermType = Node & {
  __typename?: 'PopularSearchTermType'
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 디스플레이 순서 */
  series: Scalars['Int']
  /** 검색어 */
  term?: Maybe<Scalars['String']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type PopularSearchTermTypeConnection = {
  __typename?: 'PopularSearchTermTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PopularSearchTermTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `PopularSearchTermType` and its cursor. */
export type PopularSearchTermTypeEdge = {
  __typename?: 'PopularSearchTermTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<PopularSearchTermType>
}

export type PopupBannerInfoType = {
  __typename?: 'PopupBannerInfoType'
  adId?: Maybe<Scalars['ID']>
  adName?: Maybe<Scalars['String']>
  adSlug?: Maybe<Scalars['String']>
  mobileImage?: Maybe<BannerImageType>
  mobileSubtitle?: Maybe<Scalars['String']>
  mobileTitle?: Maybe<Scalars['String']>
  pcImage?: Maybe<BannerImageType>
}

export type ProductBasicInput = {
  /** AS 관련 정보 */
  asInfo?: InputMaybe<Scalars['ID']>
  /** 브랜드 */
  brand: Scalars['ID']
  /** 카테고리 */
  categories: Array<InputMaybe<Scalars['ID']>>
  /** 상품명 */
  name: Scalars['String']
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType?: InputMaybe<Scalars['String']>
  /** 상품 판매 점수 */
  point?: InputMaybe<Scalars['Int']>
  /** 검색키워드 */
  searchKeywords?: InputMaybe<Scalars['String']>
  /** 셀러 */
  seller: Scalars['ID']
  /** 배송 가이드 */
  shippingGuide?: InputMaybe<Scalars['String']>
  /** 배송 공지 */
  shippingNotice?: InputMaybe<Scalars['String']>
  /** 상품 배송/환불 정보 */
  shippingPreset: Scalars['ID']
  /** 공급자 타입 */
  supplyType?: InputMaybe<Scalars['String']>
  /** Weekly best에서 판매량 계산에 사용되는 가중치값. 기본 100이고 더 높은 가중치를 원하면 높은 숫자를 넣어야 함. */
  weight?: InputMaybe<Scalars['Int']>
}

export type ProductCategoryType = Node & {
  __typename?: 'ProductCategoryType'
  /** 카테고리 */
  category?: Maybe<CategoryType>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 상품 */
  product?: Maybe<ProductType>
}

export type ProductCategoryTypeConnection = {
  __typename?: 'ProductCategoryTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductCategoryTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ProductCategoryType` and its cursor. */
export type ProductCategoryTypeEdge = {
  __typename?: 'ProductCategoryTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProductCategoryType>
}

/** An enumeration. */
export enum ProductImageDisplayType {
  /** ANIMATED_MAIN */
  AnimatedMain = 'ANIMATED_MAIN',
  /** IN_EDIT */
  InEdit = 'IN_EDIT',
  /** MAIN */
  Main = 'MAIN',
  /** SUB */
  Sub = 'SUB',
}

export type ProductImageInput = {
  /** 디스플레이 순서 */
  displayOrder?: InputMaybe<Scalars['Int']>
  /** 이미지 노출 위치 */
  displayType?: InputMaybe<Scalars['String']>
  image: Scalars['ImageUpload']
}

export type ProductImageType = Node & {
  __typename?: 'ProductImageType'
  /** 디스플레이 순서 */
  displayOrder: Scalars['Int']
  /** 이미지 타입 */
  displayType: ProductImageDisplayType
  /** The ID of the object. */
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  /** 상품 */
  product?: Maybe<ProductType>
  thumbnail?: Maybe<Scalars['String']>
}

export type ProductImageTypeThumbnailArgs = {
  productImageType?: InputMaybe<Scalars['String']>
}

export type ProductImageTypeConnection = {
  __typename?: 'ProductImageTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductImageTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ProductImageType` and its cursor. */
export type ProductImageTypeEdge = {
  __typename?: 'ProductImageTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProductImageType>
}

export type ProductInput = {
  basic: ProductBasicInput
  /** 설명 */
  description: Scalars['String']
  /** 주문 쿠폰 사용 불가 여부 */
  isOrderCouponBlocked?: InputMaybe<Scalars['Boolean']>
  /** 상품 쿠폰 사용 불가 여부 */
  isProductCouponBlocked?: InputMaybe<Scalars['Boolean']>
  /** 기본 확인 mileage 사용 여부 */
  isUseDefaultConfirmMileage?: InputMaybe<Scalars['Boolean']>
  mileageRate?: InputMaybe<MileageRateInput>
  /** 상품 이미지들 */
  productImages?: InputMaybe<Array<InputMaybe<ProductImageInput>>>
  /** 상품 옵션 타입 */
  productOptionType?: InputMaybe<Scalars['String']>
  /** 상품 옵션 */
  productOptions?: InputMaybe<Array<InputMaybe<ProductOptionsInput>>>
  selling: ProductSellingInput
  shown: CreateProductShownInput
}

export type ProductLikedPromotionInput = {
  /** 좋아요한 상품의 Promotion, 100원딜, 990원딜일 경우에만 넣는다. */
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionGroupType = Node & {
  __typename?: 'ProductOptionGroupType'
  /** The ID of the object. */
  id: Scalars['ID']
  /** 사용자 정의 옵션 그룹 여부 */
  isCustomGroup: Scalars['Boolean']
  isShownProductName?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  /** 상품 옵션 그룹 */
  productOptionGroupValues: ProductOptionValueTypeConnection
  realName?: Maybe<Scalars['String']>
}

export type ProductOptionGroupTypeProductOptionGroupValuesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  productOptionGroup?: InputMaybe<Scalars['String']>
}

export type ProductOptionKind = {
  /** 옵션 종류 */
  key: Scalars['String']
  /** 옵션 값 */
  value: Scalars['String']
}

export type ProductOptionKindType = {
  __typename?: 'ProductOptionKindType'
  key?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type ProductOptionSkuType = Node & {
  __typename?: 'ProductOptionSkuType'
  count: Scalars['Int']
  /** The ID of the object. */
  id: Scalars['ID']
  /** 상품 옵션 */
  productOption?: Maybe<ProductOptionType>
  /** 옵션에 연결된 SKU */
  sku?: Maybe<SkuType>
}

export type ProductOptionSkuTypeConnection = {
  __typename?: 'ProductOptionSkuTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductOptionSkuTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ProductOptionSkuType` and its cursor. */
export type ProductOptionSkuTypeEdge = {
  __typename?: 'ProductOptionSkuTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProductOptionSkuType>
}

export type ProductOptionType = Node & {
  __typename?: 'ProductOptionType'
  /** 소비자가, 최종노출가, =( 상품값 - 할인가 ), 기본옵션의경우 프로모션할인 적용하여 노출 */
  customerPrice: Scalars['Decimal']
  /** 상품 기본가와의 차액 */
  difference?: Maybe<Scalars['Decimal']>
  /** 정가-판매가 */
  discountPrice: Scalars['Decimal']
  /** The ID of the object. */
  id: Scalars['ID']
  /** 예상 (이미지 리뷰 - 리뷰) 적립금 */
  imageReviewDiffReviewMileage?: Maybe<Scalars['Int']>
  /** 예상 (이미지 리뷰 - 리뷰) 적립율 */
  imageReviewDiffReviewMileageRate?: Maybe<Scalars['Float']>
  /** 예상 이미지 리뷰 적립금 */
  imageReviewMileage?: Maybe<Scalars['Int']>
  /** 예상 이미지 리뷰 적립율 */
  imageReviewMileageRate?: Maybe<Scalars['Float']>
  /** 상품 기본 정보 */
  isDefault: Scalars['Boolean']
  /** 삭제 여부 */
  isDelete: Scalars['Boolean']
  /** 품절 임박 */
  isImminentSoldOut?: Maybe<Scalars['Boolean']>
  /** 사용 여부 */
  isUse: Scalars['Boolean']
  /** 예상 멤버쉽 최대 적립금 */
  membershipMileage?: Maybe<Scalars['Int']>
  /** 예상 멤버쉽 최대 적립율 */
  membershipMileageRate?: Maybe<Scalars['Float']>
  /** 예상 (멤버쉽 - 일반) 구매 확정 적립금 */
  membershipOrderConfirmDiffMileage?: Maybe<Scalars['Int']>
  /** 예상 (멤버쉽 - 일반) 구매 확정 적립율 */
  membershipOrderConfirmDiffMileageRate?: Maybe<Scalars['Float']>
  /** 예상 멤버쉽 구매 확정 적립금 */
  membershipOrderConfirmMileage?: Maybe<Scalars['Int']>
  /** 예상 멤버쉽 구매 확정 적립율 */
  membershipOrderConfirmMileageRate?: Maybe<Scalars['Float']>
  /** 예상 최대 적립금 */
  mileage?: Maybe<Scalars['Int']>
  /** 예상 최대 적립율 */
  mileageRate?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  /** 옵션가, =( 옵션#소비자가 - 기본옵션#소비자가 ) */
  optionPrice: Scalars['Decimal']
  /** 예상 구매 확정 적립금 */
  orderConfirmMileage?: Maybe<Scalars['Int']>
  /** 예상 구매 확정 적립율 */
  orderConfirmMileageRate?: Maybe<Scalars['Float']>
  /** 정가/옵션가 */
  price: Scalars['Decimal']
  /** 옵션상품 sku당 개당 가격 */
  pricePerSku?: Maybe<Scalars['Int']>
  /** 상품 */
  product?: Maybe<ProductType>
  productId?: Maybe<Scalars['Int']>
  /** 상품옵션 */
  productOptionCartItemHistories: CartItemHistoryTypeConnection
  productOptionKinds?: Maybe<Array<Maybe<ProductOptionKindType>>>
  /**
   * 옵션추가가
   * @deprecated option_price 필드로 대체됨
   */
  productOptionPrice?: Maybe<Scalars['Decimal']>
  /** 상품 옵션 */
  productOptionProductOptionSkus: ProductOptionSkuTypeConnection
  productOptionRestocks?: Maybe<RestockProductOptionUserTypeConnection>
  /** QnA관련 상품 옵션 */
  productOptionReviews: ProductQnaTypeConnection
  productOptionSkus?: Maybe<ProductOptionSkuTypeConnection>
  /** 상품 옵션 값 이름 */
  productOptionValues: ProductOptionValueTypeConnection
  /** 이벤트 할인된 가격 */
  productPromotionCustomerPrice?: Maybe<Scalars['Decimal']>
  /** 이벤트 할인액 */
  productPromotionDiscountPrice?: Maybe<Scalars['Decimal']>
  purchasePrice?: Maybe<Scalars['Decimal']>
  relatedProduct?: Maybe<ProductType>
  /** 예상 리뷰 적립금 */
  reviewMileage?: Maybe<Scalars['Int']>
  /** 예상 리뷰 적립율 */
  reviewMileageRate?: Maybe<Scalars['Float']>
  /** 디스플레이 순서 */
  series: Scalars['Int']
  /** 상품 옵션 SKU들 */
  skus: SkuTypeConnection
  stockQuantity?: Maybe<Scalars['Int']>
}

export type ProductOptionTypeImageReviewDiffReviewMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeImageReviewDiffReviewMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeImageReviewMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeImageReviewMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeMembershipMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeMembershipMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeMembershipOrderConfirmDiffMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeMembershipOrderConfirmDiffMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeMembershipOrderConfirmMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeMembershipOrderConfirmMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeOrderConfirmMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeOrderConfirmMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeProductOptionCartItemHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProductOptionTypeProductOptionProductOptionSkusArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProductOptionTypeProductOptionRestocksArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProductOptionTypeProductOptionReviewsArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type ProductOptionTypeProductOptionSkusArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProductOptionTypeProductOptionValuesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  productOptionGroup?: InputMaybe<Scalars['String']>
}

export type ProductOptionTypeProductPromotionCustomerPriceArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeProductPromotionDiscountPriceArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeReviewMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeReviewMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductOptionTypeSkusArgs = {
  after?: InputMaybe<Scalars['String']>
  barcodeNumber?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeSkus?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
}

export type ProductOptionTypeConnection = {
  __typename?: 'ProductOptionTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductOptionTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ProductOptionType` and its cursor. */
export type ProductOptionTypeEdge = {
  __typename?: 'ProductOptionTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProductOptionType>
}

export type ProductOptionValueType = Node & {
  __typename?: 'ProductOptionValueType'
  /** The ID of the object. */
  id: Scalars['ID']
  /** 옵션 값 이름 */
  name: Scalars['String']
  /** 상품 옵션 그룹 */
  productOptionGroup?: Maybe<ProductOptionGroupType>
  /** 상품 옵션 값 이름 */
  productOptions: ProductOptionTypeConnection
}

export type ProductOptionValueTypeProductOptionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDelete?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  skus?: InputMaybe<Scalars['String']>
}

export type ProductOptionValueTypeConnection = {
  __typename?: 'ProductOptionValueTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductOptionValueTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ProductOptionValueType` and its cursor. */
export type ProductOptionValueTypeEdge = {
  __typename?: 'ProductOptionValueTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProductOptionValueType>
}

export type ProductOptionsInput = {
  /** 소비자가 */
  customerPrice?: InputMaybe<Scalars['Decimal']>
  /** 사용 여부 */
  isUse?: InputMaybe<Scalars['Boolean']>
  /** 옵션명 */
  name?: InputMaybe<Scalars['String']>
  productOptionKinds: Array<InputMaybe<ProductOptionKind>>
  /** 옵션가 */
  productOptionPrice?: InputMaybe<Scalars['Decimal']>
  /** 디스플레이 순서 */
  series: Scalars['Int']
  /** 상품 옵션 SKU들 */
  skus: Array<InputMaybe<SkuInput>>
}

export type ProductOptionsWithPromotionConnection = {
  __typename?: 'ProductOptionsWithPromotionConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductOptionsWithPromotionEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ProductOptionsWithPromotion` and its cursor. */
export type ProductOptionsWithPromotionEdge = {
  __typename?: 'ProductOptionsWithPromotionEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProductOptionType>
}

/** An enumeration. */
export enum ProductProductOptionType {
  /** COMBINATION */
  Combination = 'COMBINATION',
  /** SEPARATION */
  Separation = 'SEPARATION',
}

/** An enumeration. */
export enum ProductProductType {
  /** ACTION_POWER */
  ActionPower = 'ACTION_POWER',
  /** NORMAL */
  Normal = 'NORMAL',
}

/** An enumeration. */
export enum ProductPromotionProductPromotionType {
  /** DEAL_100 */
  Deal_100 = 'DEAL_100',
  /** DEAL_990 */
  Deal_990 = 'DEAL_990',
  /** NORMAL */
  Normal = 'NORMAL',
  /** PET_REGISTRATION */
  PetRegistration = 'PET_REGISTRATION',
}

export type ProductPromotionType = Node & {
  __typename?: 'ProductPromotionType'
  /** 해당 프로모션으로 지금까지 구입한 갯수 */
  boughtCount?: Maybe<Scalars['Int']>
  /** 구매 확정 시 적립율, 0.xx로 표기. */
  confirmMileageRewardRate?: Maybe<Scalars['Float']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  defaultFitpetFeeRate?: Maybe<Scalars['Float']>
  /** 프로모션 설명 (생략 가능) */
  description?: Maybe<Scalars['String']>
  /** 할인 가격 */
  discountPrice: Scalars['Decimal']
  /** 할인 비율(정률), 0.xx로 기입 */
  discountRate?: Maybe<Scalars['Float']>
  /** 상품 인포 */
  displayProductInfo?: Maybe<DisplayProductInfoType>
  /** 종료일 */
  endedAt?: Maybe<Scalars['DateTime']>
  /** 핏펫 정액 수수료 */
  fitpetFeeAmount?: Maybe<Scalars['Decimal']>
  /** 핏펫 수수료 */
  fitpetFeeRate?: Maybe<Scalars['Float']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 이미지 리뷰 작성 시 적립율, 0.xx로 표기. */
  imageReviewMileageRewardRate?: Maybe<Scalars['Float']>
  /** 삭제 여부 */
  isDelete: Scalars['Boolean']
  /** 최대 주문 수량 */
  maxOrderCount?: Maybe<Scalars['Int']>
  /** 멤버쉽 회원 구매 확정 시 적립율, 0.xx로 표기. */
  membershipConfirmMileageRewardRate?: Maybe<Scalars['Float']>
  /** 멤버쉽 회원 이미지 리뷰 작성 시 적립율, 0.xx로 표기. */
  membershipImageReviewMileageRewardRate?: Maybe<Scalars['Float']>
  /** 멤버쉽 회원 리뷰 작성 시 적립율, 0.xx로 표기. */
  membershipReviewMileageRewardRate?: Maybe<Scalars['Float']>
  /** 적립율, 0.xx로 표기. null일경우 ServerConfig 따름 */
  mileageRewardRate?: Maybe<Scalars['Float']>
  /** 최소 주문금액 */
  minOrderAmount: Scalars['Decimal']
  /** 할인 이름 */
  name?: Maybe<Scalars['String']>
  /** 상품 */
  product?: Maybe<ProductType>
  /** 상품 프로모션 */
  productPromotionCartItemHistories: CartItemHistoryTypeConnection
  productPromotionCustomerPrice?: Maybe<Scalars['Decimal']>
  /** 찜된 상품의 프로모션 */
  productPromotionLikedProducts: LikedProductTypeConnection
  /** 프로모션 종류, NORMAL: 검색가능, DEAL_100: 100원딜, 검색불가. DEAL_990: 990원딜, 검색불가. PET_REGISTRATION: 동물 등록제, 검색 가능 */
  productPromotionType: ProductPromotionProductPromotionType
  /** 리뷰 작성 시 적립율, 0.xx로 표기. */
  reviewMileageRewardRate?: Maybe<Scalars['Float']>
  /** 시작일 */
  startedAt?: Maybe<Scalars['DateTime']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ProductPromotionTypeProductPromotionCartItemHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProductPromotionTypeProductPromotionLikedProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProductPromotionTypeConnection = {
  __typename?: 'ProductPromotionTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductPromotionTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ProductPromotionType` and its cursor. */
export type ProductPromotionTypeEdge = {
  __typename?: 'ProductPromotionTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProductPromotionType>
}

/** An enumeration. */
export enum ProductQnaAnsweredUserType {
  /** ADMIN */
  Admin = 'ADMIN',
  /** NONE */
  None = 'NONE',
  /** SELLER */
  Seller = 'SELLER',
}

export type ProductQnaType = Node & {
  __typename?: 'ProductQnaType'
  answerBody?: Maybe<Scalars['String']>
  /** 답변일 */
  answeredAt?: Maybe<Scalars['DateTime']>
  /** 답변자 */
  answeredUser?: Maybe<UserType>
  /** 답변 단 사용자 타입 (SELLER/ADMIN/NONE) */
  answeredUserType: ProductQnaAnsweredUserType
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  isAdmin?: Maybe<Scalars['Boolean']>
  isAnswered?: Maybe<Scalars['Boolean']>
  /** 비밀글 여부 */
  isSecret: Scalars['Boolean']
  /** QnA관련 상품 */
  product?: Maybe<ProductType>
  /** QnA관련 상품 옵션 */
  productOption?: Maybe<ProductOptionType>
  questionBody?: Maybe<Scalars['String']>
  seller?: Maybe<SellerType>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 질문자 */
  user?: Maybe<UserType>
  username?: Maybe<Scalars['String']>
  /** Zendesk에 fitpet 상품일때만 등록하는 ticket id */
  zendeskTicketId: Scalars['Int']
}

export type ProductQnaTypeConnection = {
  __typename?: 'ProductQnaTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductQnaTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ProductQnaType` and its cursor. */
export type ProductQnaTypeEdge = {
  __typename?: 'ProductQnaTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProductQnaType>
}

export type ProductSellingInput = {
  /** 거래처 */
  correspondent?: InputMaybe<Scalars['String']>
  /** 판매가 */
  customerPrice: Scalars['Decimal']
  /** 종료일 */
  endedAt?: InputMaybe<Scalars['String']>
  /** 핏펫 수수료 */
  fitpetFeeRate?: InputMaybe<Scalars['Float']>
  /** 정가 */
  price: Scalars['Decimal']
  /** 리뷰 그룹 */
  reviewGroup?: InputMaybe<Scalars['ID']>
  /** 옵션이 없는 경우 상품 기본 옵션 SKU */
  skus?: InputMaybe<Array<InputMaybe<SkuInput>>>
  /** 시작일 */
  startedAt?: InputMaybe<Scalars['String']>
}

export type ProductStatisticByInfluencerColumnType = {
  __typename?: 'ProductStatisticByInfluencerColumnType'
  brandName?: Maybe<Scalars['String']>
  influencerAmount?: Maybe<Scalars['Int']>
  influencerFeeRate?: Maybe<Scalars['Float']>
  influencerName?: Maybe<Scalars['String']>
  productId?: Maybe<Scalars['String']>
  productName?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
  totalCustomerCount?: Maybe<Scalars['Int']>
  totalSoldAmount?: Maybe<Scalars['Int']>
  totalSoldCount?: Maybe<Scalars['Int']>
}

export type ProductStatisticByInfluencerColumnTypeConnection = {
  __typename?: 'ProductStatisticByInfluencerColumnTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductStatisticByInfluencerColumnTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ProductStatisticByInfluencerColumnType` and its cursor. */
export type ProductStatisticByInfluencerColumnTypeEdge = {
  __typename?: 'ProductStatisticByInfluencerColumnTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProductStatisticByInfluencerColumnType>
}

export type ProductStatisticBySellerColumnType = {
  __typename?: 'ProductStatisticBySellerColumnType'
  brandName?: Maybe<Scalars['String']>
  fitpetAmount?: Maybe<Scalars['Int']>
  fitpetFeeRate?: Maybe<Scalars['Float']>
  productId?: Maybe<Scalars['String']>
  productName?: Maybe<Scalars['String']>
  sellerName?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
  totalCustomerCount?: Maybe<Scalars['Int']>
  totalSoldAmount?: Maybe<Scalars['Int']>
  totalSoldCount?: Maybe<Scalars['Int']>
}

export type ProductStatisticBySellerColumnTypeConnection = {
  __typename?: 'ProductStatisticBySellerColumnTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductStatisticBySellerColumnTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ProductStatisticBySellerColumnType` and its cursor. */
export type ProductStatisticBySellerColumnTypeEdge = {
  __typename?: 'ProductStatisticBySellerColumnTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProductStatisticBySellerColumnType>
}

export type ProductStatusCountType = {
  __typename?: 'ProductStatusCountType'
  bannedCount?: Maybe<Scalars['Int']>
  deletedCount?: Maybe<Scalars['Int']>
  endSellingCount?: Maybe<Scalars['Int']>
  preSellingCount?: Maybe<Scalars['Int']>
  rejectedCount?: Maybe<Scalars['Int']>
  requestCount?: Maybe<Scalars['Int']>
  sellingCount?: Maybe<Scalars['Int']>
  soldOutCount?: Maybe<Scalars['Int']>
  suspendCount?: Maybe<Scalars['Int']>
  totalCount?: Maybe<Scalars['Int']>
  updateRejectedCount?: Maybe<Scalars['Int']>
  updateRequestCount?: Maybe<Scalars['Int']>
}

/** An enumeration. */
export enum ProductSupplyType {
  /** BUY */
  Buy = 'BUY',
  /** CONSIGNMENT */
  Consignment = 'CONSIGNMENT',
  /** NONE */
  None = 'NONE',
  /** PB */
  Pb = 'PB',
}

export type ProductType = Node & {
  __typename?: 'ProductType'
  /** 승인일 */
  approvedAt?: Maybe<Scalars['DateTime']>
  /** AS 관련 정보 */
  asInfo?: Maybe<AsInfoType>
  /** 상품에 등록된 리뷰의 평균 점수 */
  averageReviewScore?: Maybe<Scalars['Float']>
  /** 브랜드 */
  brand?: Maybe<BrandType>
  brandCoupons?: Maybe<CouponTypeConnection>
  /** 묶음상품 */
  bundledProducts?: Maybe<ProductTypeConnection>
  /** 쿠폰 발급 가능 여부 */
  canDownloadCoupon?: Maybe<Scalars['Boolean']>
  categories?: Maybe<CategoryTypeConnection>
  categoryCoupons?: Maybe<CouponTypeConnection>
  /** 구매 확정 시 적립율, 0.xx로 표기. */
  confirmMileageRewardRate?: Maybe<Scalars['Float']>
  /** 복사된 상품의 오리지널 상품 정보 */
  copiedProduct: ProductTypeConnection
  /** 거래처 */
  correspondent?: Maybe<Scalars['String']>
  /** 최대쿠폰할인액 */
  couponDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 최대쿠폰할인액 */
  couponDiscountRate?: Maybe<Scalars['Decimal']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 생성자 */
  createdBy?: Maybe<Scalars['Int']>
  /** 상품의 대표 소비자가 */
  customerPrice?: Maybe<Scalars['Decimal']>
  /** 기본 상품 옵션 */
  defaultProductOption?: Maybe<ProductOptionType>
  /** 최종삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']>
  /** 설명 */
  description?: Maybe<Scalars['String']>
  /** 상품의 대표 할인액 */
  discountPrice?: Maybe<Scalars['Decimal']>
  /** 상품의 대표 할인율 */
  discountRate?: Maybe<Scalars['Float']>
  /** 디스플레이 순서 */
  displayOrder: Scalars['Int']
  downloadCouponGroups?: Maybe<CouponGroupTypeConnection>
  /** 종료일 */
  endedAt?: Maybe<Scalars['DateTime']>
  /** 쿠폰 제외 상품 범위 */
  excludedProductsCouponGroups: CouponGroupTypeConnection
  /** 사료/간식 */
  feedCategoryType?: Maybe<Scalars['String']>
  /** 최종 할인액 (기본할인액 + 프로모션할인액) */
  finalDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 최종 할인율 ((기본할인액 + 프로모션할인액)/원가) */
  finalDiscountRate?: Maybe<Scalars['Float']>
  /** 핏펫 정액 수수료 */
  fitpetFeeAmount?: Maybe<Scalars['Decimal']>
  /** 핏펫 수수료 */
  fitpetFeeRate?: Maybe<Scalars['Float']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 예상 (이미지 리뷰 - 리뷰) 적립금 */
  imageReviewDiffReviewMileage?: Maybe<Scalars['Int']>
  /** 예상 (이미지 리뷰 - 리뷰) 적립율 */
  imageReviewDiffReviewMileageRate?: Maybe<Scalars['Float']>
  /** 예상 이미지 리뷰 적립금 */
  imageReviewMileage?: Maybe<Scalars['Int']>
  /** 예상 이미지 리뷰 적립율 */
  imageReviewMileageRate?: Maybe<Scalars['Float']>
  /** 이미지 리뷰 작성 시 적립율, 0.xx로 표기. */
  imageReviewMileageRewardRate?: Maybe<Scalars['Float']>
  /** 쿠폰 포함 상품 범위 */
  includedProductsCouponGroups: CouponGroupTypeConnection
  /** 성분(저단백/고단백 등) */
  ingredientTypes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 품절 임박 여부 */
  isImminentSoldOut?: Maybe<Scalars['Boolean']>
  /** 사용자가 찜한 여부 */
  isLiked?: Maybe<Scalars['Boolean']>
  /** 주문 쿠폰 사용 불가 여부 */
  isOrderCouponBlocked: Scalars['Boolean']
  /** 상품 쿠폰 사용 불가 여부 */
  isProductCouponBlocked: Scalars['Boolean']
  /** 상품 수량이 0개 || 셀러가 품절처리 */
  isRealSoldOut?: Maybe<Scalars['Boolean']>
  /** 판매 노출 여부 */
  isShown?: Maybe<Scalars['Boolean']>
  /** 다음 쇼핑 노출 여부 */
  isShownDaum?: Maybe<Scalars['Boolean']>
  /** 페이스북 노출 여부 */
  isShownFacebook?: Maybe<Scalars['Boolean']>
  /** 구글 노출 여부 */
  isShownGoogle?: Maybe<Scalars['Boolean']>
  /** 네이버 쇼핑 노출 여부 */
  isShownNaver?: Maybe<Scalars['Boolean']>
  /** 단일옵션 여부 */
  isSingleOption?: Maybe<Scalars['Boolean']>
  /** 설정 품절 여부 */
  isSoldOut?: Maybe<Scalars['Boolean']>
  /** 재고 품절 여부 */
  isStockSoldOut?: Maybe<Scalars['Boolean']>
  /** 타임세일 진행여부 */
  isTimesale?: Maybe<Scalars['Boolean']>
  /** 사용자가 찜한 여부 */
  isWished?: Maybe<Scalars['Boolean']>
  /** 사용자들이 찜한 갯수 */
  likedCount?: Maybe<Scalars['Int']>
  mainImage?: Maybe<ProductImageType>
  maxDiscountCoupon?: Maybe<CouponType>
  /** 해당 상품에 걸려있는 옵션들중 SKU 최대 값 */
  maxProductOptionSkuCount?: Maybe<Scalars['Int']>
  /** 멤버쉽 회원 구매 확정 시 적립율, 0.xx로 표기. */
  membershipConfirmMileageRewardRate?: Maybe<Scalars['Float']>
  /** 멤버쉽 회원 이미지 리뷰 작성 시 적립율, 0.xx로 표기. */
  membershipImageReviewMileageRewardRate?: Maybe<Scalars['Float']>
  /** 예상 멤버쉽 최대 적립금 */
  membershipMileage?: Maybe<Scalars['Int']>
  /** 예상 멤버쉽 최대 적립율 */
  membershipMileageRate?: Maybe<Scalars['Float']>
  /** 예상 (멤버쉽 - 일반) 구매 확정 적립금 */
  membershipOrderConfirmDiffMileage?: Maybe<Scalars['Int']>
  /** 예상 (멤버쉽 - 일반) 구매 확정 적립율 */
  membershipOrderConfirmDiffMileageRate?: Maybe<Scalars['Float']>
  /** 예상 멤버쉽 구매 확정 적립금 */
  membershipOrderConfirmMileage?: Maybe<Scalars['Int']>
  /** 예상 멤버쉽 구매 확정 적립율 */
  membershipOrderConfirmMileageRate?: Maybe<Scalars['Float']>
  /** 멤버쉽 회원 리뷰 작성 시 적립율, 0.xx로 표기. */
  membershipReviewMileageRewardRate?: Maybe<Scalars['Float']>
  /** 예상 최대 적립금 */
  mileage?: Maybe<Scalars['Int']>
  /** 예상 최대 적립율 */
  mileageRate?: Maybe<Scalars['Float']>
  /** 프로모션 상품명 */
  name?: Maybe<Scalars['String']>
  /** 예상 구매 확정 적립금 */
  orderConfirmMileage?: Maybe<Scalars['Int']>
  /** 예상 구매 확정 적립율 */
  orderConfirmMileageRate?: Maybe<Scalars['Float']>
  /** 복사된 상품의 오리지널 상품 정보 */
  originalProduct?: Maybe<ProductType>
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType: AdPetType
  /** 상품 판매 점수 */
  point: Scalars['Int']
  /** 상품의 대표 금액 */
  price?: Maybe<Scalars['Decimal']>
  /** 묶음상품 Sku당 가격 */
  pricePerSku?: Maybe<Scalars['Int']>
  productCoupons?: Maybe<CouponTypeConnection>
  /** 상품 */
  productDisplayProductInfos: DisplayProductInfoTypeConnection
  /** 상품 */
  productGnbs: GnbTypeConnection
  /** 찜된 상품 */
  productLikedProducts: LikedProductTypeConnection
  /** 상품코드 */
  productNumber?: Maybe<Scalars['String']>
  /** 상품 옵션 타입 */
  productOptionType: ProductProductOptionType
  /** 상품 */
  productProductCategories: ProductCategoryTypeConnection
  /** 상품 */
  productProductImages: ProductImageTypeConnection
  productProductOptions?: Maybe<ProductOptionTypeConnection>
  /** 상품 */
  productProductPromotions: ProductPromotionTypeConnection
  productPromotion?: Maybe<ProductPromotionType>
  /** 이벤트 할인된 가격 */
  productPromotionCustomerPrice?: Maybe<Scalars['Decimal']>
  /** 이벤트 할인액 */
  productPromotionDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 이벤트 할인율 */
  productPromotionDiscountRate?: Maybe<Scalars['Float']>
  /** 이벤트 종료 시간 */
  productPromotionEndedAt?: Maybe<Scalars['DateTime']>
  /** 이벤트 시작 시간 */
  productPromotionStartedAt?: Maybe<Scalars['DateTime']>
  /** QnA관련 상품 */
  productQnas: ProductQnaTypeConnection
  productReviews?: Maybe<ReviewTypeConnection>
  /** 상품 유형 */
  productType: ProductProductType
  /** 매입가 */
  purchasePrice?: Maybe<Scalars['Decimal']>
  /** 프로모션 제외된 상품명 */
  realName?: Maybe<Scalars['String']>
  /** 최종등록신청일 */
  requestedAt?: Maybe<Scalars['DateTime']>
  /** 상품에 등록된 리뷰의 수 */
  reviewCount?: Maybe<Scalars['Int']>
  /** 리뷰 그룹 */
  reviewGroup?: Maybe<ReviewGroupType>
  /** 예상 리뷰 적립금 */
  reviewMileage?: Maybe<Scalars['Int']>
  /** 예상 리뷰 적립율 */
  reviewMileageRate?: Maybe<Scalars['Float']>
  /** 리뷰 작성 시 적립율, 0.xx로 표기. */
  reviewMileageRewardRate?: Maybe<Scalars['Float']>
  reviews?: Maybe<ReviewTypeConnection>
  /** 검색 그룹 키워드 */
  searchGroupKeyword?: Maybe<Scalars['String']>
  /** 검색키워드 */
  searchKeywords?: Maybe<Scalars['String']>
  /** 셀러 */
  seller?: Maybe<SellerType>
  /** 배송 안내 */
  shippingGuide?: Maybe<Scalars['String']>
  /** 배송 가이드 */
  shippingNotice?: Maybe<Scalars['String']>
  /** 상품 배송/환불 정보 */
  shippingPreset?: Maybe<ShippingPresetType>
  /** 상품 누적 판매량 */
  soldQuantity?: Maybe<Scalars['Int']>
  /** 시작일 */
  startedAt?: Maybe<Scalars['DateTime']>
  /** 상품 등록 요청 상태, REQUEST(요청)/APPROVED(승인)/REJECTED(거부)/SUSPEND(중단) */
  status?: Maybe<StatusType>
  /** 상품의 총 재고수 */
  stockQuantity?: Maybe<Scalars['Int']>
  /** 공급자 타입 */
  supplyType: ProductSupplyType
  /** 정보 변경 요청 승인일 */
  updateApprovedAt?: Maybe<Scalars['DateTime']>
  updateInfo?: Maybe<UpdatedProductType>
  /** 수정 상태, REQUEST(요청)/APPROVED(승인)/REJECTED(거부) */
  updateStatus?: Maybe<StatusType>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 수정자 */
  updatedBy?: Maybe<Scalars['Int']>
  /** Weekly best에서 판매량 계산에 사용되는 가중치값. 기본 100이고 더 높은 가중치를 원하면 높은 숫자를 넣어야 함. */
  weight: Scalars['Int']
  /** 사용자들이 찜한 갯수 */
  wishedCount?: Maybe<Scalars['Int']>
  wishedProductUsers?: Maybe<UserTypeConnection>
}

export type ProductTypeBrandCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroup?: InputMaybe<Scalars['String']>
  couponGroupName?: InputMaybe<Scalars['String']>
  couponStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  isUsed?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type ProductTypeBundledProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type ProductTypeCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProductTypeCategoryCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroup?: InputMaybe<Scalars['String']>
  couponGroupName?: InputMaybe<Scalars['String']>
  couponStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  isUsed?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type ProductTypeCopiedProductArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type ProductTypeDownloadCouponGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroupStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  coverageOsType?: InputMaybe<Scalars['String']>
  coverageType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  eventType?: InputMaybe<Scalars['String']>
  excludeIssueType?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDiscountVisibleBeforeDownload?: InputMaybe<Scalars['Boolean']>
  isIssuing?: InputMaybe<Scalars['Boolean']>
  issueStatus?: InputMaybe<Scalars['String']>
  issueType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  usingEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  usingPeriod?: InputMaybe<Scalars['Float']>
  usingStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingStartedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type ProductTypeExcludedProductsCouponGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroupStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  coverageOsType?: InputMaybe<Scalars['String']>
  coverageType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  eventType?: InputMaybe<Scalars['String']>
  excludeIssueType?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDiscountVisibleBeforeDownload?: InputMaybe<Scalars['Boolean']>
  isIssuing?: InputMaybe<Scalars['Boolean']>
  issueStatus?: InputMaybe<Scalars['String']>
  issueType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  usingEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  usingPeriod?: InputMaybe<Scalars['Float']>
  usingStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingStartedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type ProductTypeFinalDiscountPriceArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeFinalDiscountRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeImageReviewDiffReviewMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeImageReviewDiffReviewMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeImageReviewMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeImageReviewMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeIncludedProductsCouponGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroupStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  coverageOsType?: InputMaybe<Scalars['String']>
  coverageType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  eventType?: InputMaybe<Scalars['String']>
  excludeIssueType?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDiscountVisibleBeforeDownload?: InputMaybe<Scalars['Boolean']>
  isIssuing?: InputMaybe<Scalars['Boolean']>
  issueStatus?: InputMaybe<Scalars['String']>
  issueType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  usingEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  usingPeriod?: InputMaybe<Scalars['Float']>
  usingStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingStartedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type ProductTypeMembershipMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeMembershipMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeMembershipOrderConfirmDiffMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeMembershipOrderConfirmDiffMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeMembershipOrderConfirmMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeMembershipOrderConfirmMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeNameArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeOrderConfirmMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeOrderConfirmMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeProductCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroup?: InputMaybe<Scalars['String']>
  couponGroupName?: InputMaybe<Scalars['String']>
  couponStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  isUsed?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type ProductTypeProductDisplayProductInfosArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brand?: InputMaybe<Scalars['String']>
  displayCollection?: InputMaybe<Scalars['String']>
  excludeProductRealSoldOut?: InputMaybe<Scalars['Boolean']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isProductRange?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
}

export type ProductTypeProductGnbsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProductTypeProductLikedProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProductTypeProductProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProductTypeProductProductImagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProductTypeProductProductOptionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  includeNotUse?: InputMaybe<Scalars['Boolean']>
  isDelete?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  skus?: InputMaybe<Scalars['String']>
}

export type ProductTypeProductProductPromotionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  displayProductInfo?: InputMaybe<Scalars['String']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type ProductTypeProductPromotionCustomerPriceArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeProductPromotionDiscountPriceArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeProductPromotionDiscountRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeProductPromotionEndedAtArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeProductPromotionStartedAtArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeProductQnasArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type ProductTypeProductReviewsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  bestAt?: InputMaybe<Scalars['Date']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isPhotoReview?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  reviewBody?: InputMaybe<Scalars['String']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type ProductTypeReviewMileageArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeReviewMileageRateArgs = {
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type ProductTypeReviewsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  bestAt?: InputMaybe<Scalars['Date']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isPhotoReview?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  reviewBody?: InputMaybe<Scalars['String']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type ProductTypeWishedProductUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  agreeMarketing?: InputMaybe<Scalars['Boolean']>
  agreeMarketingHospital?: InputMaybe<Scalars['Boolean']>
  agreeMarketingPlay?: InputMaybe<Scalars['Boolean']>
  agreeNightMarketing?: InputMaybe<Scalars['Boolean']>
  agreeServicePushAllowed?: InputMaybe<Scalars['Boolean']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  dateJoinedAfter?: InputMaybe<Scalars['DateTime']>
  dateJoinedBefore?: InputMaybe<Scalars['DateTime']>
  email?: InputMaybe<Scalars['String']>
  excludeUsers?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  grades?: InputMaybe<Scalars['String']>
  inactivatedAtAfter?: InputMaybe<Scalars['DateTime']>
  inactivatedAtBefore?: InputMaybe<Scalars['DateTime']>
  inactiveUserEmail?: InputMaybe<Scalars['String']>
  inactiveUserMobileNumber?: InputMaybe<Scalars['String']>
  inactiveUserName?: InputMaybe<Scalars['String']>
  inactiveUserUsername?: InputMaybe<Scalars['String']>
  isBuyer?: InputMaybe<Scalars['Boolean']>
  isInfluencer?: InputMaybe<Scalars['Boolean']>
  isLeft?: InputMaybe<Scalars['Boolean']>
  isSeller?: InputMaybe<Scalars['Boolean']>
  isStaff?: InputMaybe<Scalars['Boolean']>
  joinedPlatform?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  lastLoginAfter?: InputMaybe<Scalars['DateTime']>
  lastLoginBefore?: InputMaybe<Scalars['DateTime']>
  leftAtAfter?: InputMaybe<Scalars['DateTime']>
  leftAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipRefundStatus?: InputMaybe<Scalars['String']>
  membershipStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipStartedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipStatus?: InputMaybe<Scalars['String']>
  mileagePointLess?: InputMaybe<Scalars['Float']>
  mileagePointMore?: InputMaybe<Scalars['Float']>
  mobileNumber?: InputMaybe<Scalars['String']>
  mobileNumbers?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderAmountLess?: InputMaybe<Scalars['Float']>
  orderAmountMore?: InputMaybe<Scalars['Float']>
  orderBy?: InputMaybe<Scalars['String']>
  orderCountLess?: InputMaybe<Scalars['Float']>
  orderCountMore?: InputMaybe<Scalars['Float']>
  requiredUsername?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  socialType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userNumber?: InputMaybe<Scalars['String']>
  userStatus?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type ProductTypeConnection = {
  __typename?: 'ProductTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ProductType` and its cursor. */
export type ProductTypeEdge = {
  __typename?: 'ProductTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProductType>
}

export type ProductsSellingInput = {
  /** 판매 여부 */
  isApproved: Scalars['Boolean']
  /** 판매 노출 여부 */
  isShown: Scalars['Boolean']
  products: Array<InputMaybe<Scalars['ID']>>
}

export type ProductsSoldOutInput = {
  /** 설정 품절 여부 */
  isSoldOut: Scalars['Boolean']
  products: Array<InputMaybe<Scalars['ID']>>
}

/** An enumeration. */
export enum PushPushType {
  /** 자동발송 */
  Auto = 'AUTO',
  /** 마케팅 */
  Marketing = 'MARKETING',
  /** 공지 */
  Notice = 'NOTICE',
}

export type PushType = Node & {
  __typename?: 'PushType'
  appData?: Maybe<Scalars['JSONString']>
  appUrl?: Maybe<Scalars['String']>
  /** 앱에서 사용할 데이터 */
  data?: Maybe<Scalars['JSONString']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 푸쉬 첨부 이미지 */
  image?: Maybe<Scalars['String']>
  informpushqueue?: Maybe<InformPushQueueType>
  /** Push 데이터 */
  pushPushUserHistories: PushUserHistoryTypeConnection
  /** 푸쉬 타입, MARKETING: 마케팅, NOTICE: 공지, AUTO: 자동발송 푸쉬 */
  pushType: PushPushType
  pushTypeDisplay?: Maybe<Scalars['String']>
  /** 푸쉬에 표시될 내용 */
  text: Scalars['String']
  /** 푸쉬에 표시될 제목 */
  title: Scalars['String']
  url?: Maybe<Scalars['String']>
}

export type PushTypePushPushUserHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isSuccess?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
}

export type PushUserHistoryType = Node & {
  __typename?: 'PushUserHistoryType'
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 장치 별 메타 정보 */
  deviceInfo?: Maybe<Scalars['JSONString']>
  /** The ID of the object. */
  id: Scalars['ID']
  isRead: Scalars['Boolean']
  isSuccess: Scalars['Boolean']
  /** Push 데이터 */
  push?: Maybe<PushType>
  /** 조회일시 */
  readAt?: Maybe<Scalars['DateTime']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 발송 대상 사용자 */
  user?: Maybe<UserType>
}

export type PushUserHistoryTypeConnection = {
  __typename?: 'PushUserHistoryTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PushUserHistoryTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `PushUserHistoryType` and its cursor. */
export type PushUserHistoryTypeEdge = {
  __typename?: 'PushUserHistoryTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<PushUserHistoryType>
}

export type Query = {
  __typename?: 'Query'
  activeUserCount?: Maybe<UserCountType>
  /** The ID of the object */
  ad?: Maybe<AdType>
  adBySlug?: Maybe<AdType>
  ads?: Maybe<AdTypeConnection>
  adsDeal100?: Maybe<AdTypeConnection>
  asInfos?: Maybe<AsInfoTypeConnection>
  banks?: Maybe<Array<Maybe<BankType>>>
  /** The ID of the object */
  banner?: Maybe<BannerType>
  banners?: Maybe<BannerTypeConnection>
  billingItems?: Maybe<BillingItemTypeConnection>
  /** The ID of the object */
  brand?: Maybe<BrandType>
  brands?: Maybe<BrandTypeConnection>
  /** 함께 묶음 반품/교환/취소 된 상품 주문 조회 */
  bundledOrderItems?: Maybe<OrderItemTypeConnection>
  cacheProducts?: Maybe<CacheProductType>
  /** 캐쉬된 이벤트 상세 */
  cachedDisplayCollection?: Maybe<Scalars['JSONString']>
  /** The ID of the object */
  cartItem?: Maybe<CartItemType>
  cartItemHistories?: Maybe<CartItemHistoryTypeConnection>
  cartItems?: Maybe<CartItemTypeConnection>
  categories?: Maybe<CategoryTypeConnection>
  /** The ID of the object */
  category?: Maybe<CategoryType>
  categoryBySlug?: Maybe<CategoryType>
  /** 초대코드 유효성 체크, True=유효 */
  checkInvitationCode?: Maybe<Scalars['Boolean']>
  checkTokenAssociation?: Maybe<CheckTokenAssociationType>
  /** The ID of the object */
  couponGroup?: Maybe<CouponGroupType>
  couponGroups?: Maybe<CouponGroupTypeConnection>
  couponKakaoTemplates?: Maybe<Array<Maybe<CouponKakaoTemplateType>>>
  coupons?: Maybe<CouponTypeConnection>
  couponsByProducts?: Maybe<CouponByProductConnection>
  customerAddresses?: Maybe<CustomerAddressTypeConnection>
  /** The ID of the object */
  customerGrade?: Maybe<CustomerGradeType>
  customerGrades?: Maybe<CustomerGradeTypeConnection>
  customerTemplateAddress?: Maybe<CustomerTemplateAddressType>
  customerTemplateAddresses?: Maybe<CustomerTemplateAddressTypeConnection>
  dailyStatistics?: Maybe<TermStatisticColumnTypeConnection>
  dashboard?: Maybe<DashboardType>
  defaultReturnExchangeGuide?: Maybe<DefaultReturnChangeGuideType>
  devices?: Maybe<DeviceTypeConnection>
  /** The ID of the object */
  displayCollection?: Maybe<DisplayCollectionType>
  displayCollections?: Maybe<DisplayCollectionTypeConnection>
  /** The ID of the object */
  displayProductInfo?: Maybe<DisplayProductInfoType>
  displayProductInfos?: Maybe<DisplayProductInfoTypeConnection>
  emailQueues?: Maybe<InformEmailQueueTypeConnection>
  excelExportRequests?: Maybe<ExcelExportRequestTypeConnection>
  excelImportRequests?: Maybe<ExcelImportRequestTypeConnection>
  fitpetLinks?: Maybe<FitpetLinkTypeConnection>
  gnbs?: Maybe<GnbTypeConnection>
  home?: Maybe<HomeType>
  homeSections?: Maybe<HomeSectionConnection>
  incompleteProductsByRole?: Maybe<ProductTypeConnection>
  influencerSalesStatistics?: Maybe<InfluencerStatisticColumnTypeConnection>
  influencerStatisticsByRole?: Maybe<ProductStatisticByInfluencerColumnTypeConnection>
  /** The ID of the object */
  informPushQueue?: Maybe<InformPushQueueType>
  informPushQueues?: Maybe<InformPushQueueTypeConnection>
  ingredientBasedFeedRecommendationProducts?: Maybe<ProductTypeConnection>
  initData?: Maybe<InitDataType>
  invalidConditionBeforeEstimateOrder?: Maybe<InvalidConditionBeforeEstimateOrderType>
  isAppCouponDownload?: Maybe<Scalars['Boolean']>
  jwt?: Maybe<Scalars['String']>
  leaveReasons?: Maybe<LeaveReasonType>
  likedProducts?: Maybe<ProductTypeConnection>
  likedProductsWithPromotion?: Maybe<LikedProductConnection>
  membership?: Maybe<MembershipType>
  membershipBenefitMonthly?: Maybe<MembershipBenefitMonthlyTypeConnection>
  membershipFamilies?: Maybe<MembershipFamilyConnection>
  membershipHistories?: Maybe<MembershipHistoryTypeConnection>
  membershipInvitation?: Maybe<MembershipInvitationType>
  membershipPayInfo?: Maybe<MembershipPayInfoType>
  membershipSubscribeInfoBeforePayment?: Maybe<MembershipSubscribeInfoBeforePaymentType>
  /** 관리자용 멤버쉽 구독 이력 */
  membershipSubscribeInfos?: Maybe<MembershipBenefitMonthlyTypeConnection>
  membershipUsers?: Maybe<UserTypeConnection>
  mileageHistories?: Maybe<MileageHistoryTypeConnection>
  mileagePolicy?: Maybe<MileagePolicyType>
  mileages?: Maybe<MileageTypeConnection>
  minAppVersion?: Maybe<MinAppVersionType>
  monthlyStatistics?: Maybe<TermStatisticColumnTypeConnection>
  nonUserOrder?: Maybe<OrderType>
  /** The ID of the object */
  notice?: Maybe<NoticeType>
  notices?: Maybe<NoticeTypeConnection>
  oneOnOneQnas?: Maybe<ServiceQnaTypeConnection>
  oneOnOneQnasByAdmin?: Maybe<ServiceQnaTypeConnection>
  /** The ID of the object */
  order?: Maybe<OrderType>
  /** The ID of the object */
  orderItem?: Maybe<OrderItemType>
  orderItemCancelReasons?: Maybe<OrderItemCancelReasonType>
  orderItemCount?: Maybe<OrderItemCountType>
  orderItemReturnReasons?: Maybe<OrderItemReturnReasonType>
  orderItems?: Maybe<OrderItemTypeConnection>
  orderQnas?: Maybe<ServiceQnaTypeConnection>
  orderQnasByAdmin?: Maybe<ServiceQnaTypeConnection>
  orderQnasBySeller?: Maybe<ServiceQnaTypeConnection>
  orders?: Maybe<OrderTypeConnection>
  popularKeywords?: Maybe<PopularKeywordConnection>
  popularSearchTerms?: Maybe<PopularSearchTermTypeConnection>
  product?: Maybe<ProductType>
  productCount?: Maybe<ProductStatusCountType>
  /** The ID of the object */
  productImage?: Maybe<ProductImageType>
  productImages?: Maybe<ProductImageTypeConnection>
  productOptionValue?: Maybe<ProductOptionValueType>
  productOptionValues?: Maybe<ProductOptionValueTypeConnection>
  productOptions?: Maybe<ProductOptionTypeConnection>
  /** ProductOption과 ProductPromotion 목록을 받아 내려줌. Estimation 대체 */
  productOptionsWithPromotion?: Maybe<ProductOptionsWithPromotionConnection>
  /** The ID of the object */
  productPromotion?: Maybe<ProductPromotionType>
  productPromotions?: Maybe<ProductPromotionTypeConnection>
  productQna?: Maybe<ProductQnaType>
  productQnas?: Maybe<ProductQnaTypeConnection>
  products?: Maybe<ProductTypeConnection>
  productsWithoutPetType?: Maybe<ProductTypeConnection>
  pushUserHistories?: Maybe<PushUserHistoryTypeConnection>
  pushUserHistoryHasNotRead?: Maybe<Scalars['Boolean']>
  /** 장바구니 담을 때 묶음상품 추천 */
  recommendBundledProducts?: Maybe<BundledProductConnection>
  /** 추천 검색 키워드 */
  recommendSearchKeywords?: Maybe<Array<Maybe<Scalars['String']>>>
  recommendedCartProducts?: Maybe<ProductTypeConnection>
  recommendedPersonalProducts?: Maybe<ProductTypeConnection>
  recommendedProductDetailProducts?: Maybe<ProductTypeConnection>
  recommendedProducts?: Maybe<ProductTypeConnection>
  recommendedRelatedProducts?: Maybe<ProductTypeConnection>
  recommendedSimilarProducts?: Maybe<ProductTypeConnection>
  remainPriceForFreeShipping?: Maybe<Scalars['Int']>
  remoteArea?: Maybe<RemoteAreaType>
  remoteAreas?: Maybe<RemoteAreaTypeConnection>
  restockProductOptions?: Maybe<RestockProductOptionUserTypeConnection>
  /** The ID of the object */
  review?: Maybe<ReviewType>
  reviewGroups?: Maybe<ReviewGroupTypeConnection>
  reviewGroupsBySeller?: Maybe<ReviewGroupTypeConnection>
  reviewImages?: Maybe<ReviewImageTypeConnection>
  /** 리뷰 혜택 조회 */
  reviewPromotionInfo?: Maybe<Scalars['JSONString']>
  reviewWritableOrderItems?: Maybe<OrderItemTypeConnection>
  reviews?: Maybe<ReviewTypeConnection>
  searchHistories?: Maybe<SearchHistoryTypeConnection>
  searchProducts?: Maybe<SearchProductConnection>
  searchProductsWithDetails?: Maybe<SearchProductsWithDetailsConnection>
  searchText?: Maybe<Scalars['String']>
  /** 브랜드 검색 결과 */
  searchedBrands?: Maybe<Array<Maybe<BrandType>>>
  /** 키워드 검색 결과 */
  searchedKeywords?: Maybe<Array<Maybe<Scalars['String']>>>
  searchedProducts?: Maybe<ProductTypeConnection>
  /** The ID of the object */
  seller?: Maybe<SellerType>
  sellerAddresses?: Maybe<SellerAddressTypeConnection>
  sellerBillings?: Maybe<SellerBillingTypeConnection>
  sellerCount?: Maybe<SellerStatusCountType>
  sellerSalesStatistics?: Maybe<SellerStatisticColumnTypeConnection>
  sellerStatisticsByRole?: Maybe<ProductStatisticBySellerColumnTypeConnection>
  sellerTemplateAddresses?: Maybe<SellerTemplateAddressTypeConnection>
  sellerUsers?: Maybe<SellerUserTypeConnection>
  sellers?: Maybe<SellerTypeConnection>
  serverConfigs?: Maybe<ServerConfigTypeConnection>
  serviceQna?: Maybe<ServiceQnaType>
  serviceQnas?: Maybe<ServiceQnaTypeConnection>
  serviceQnasByAdmin?: Maybe<ServiceQnaTypeConnection>
  /** The ID of the object */
  shipping?: Maybe<ShippingType>
  shippingCompanies?: Maybe<ShippingCompanyTypeConnection>
  shippingPresets?: Maybe<ShippingPresetTypeConnection>
  shippingTrack?: Maybe<ShippingTrackType>
  shippings?: Maybe<ShippingTypeConnection>
  sku?: Maybe<SkuType>
  skuHistory?: Maybe<SkuHistoryTypeConnection>
  skus?: Maybe<SkuTypeConnection>
  smsHistories?: Maybe<InformSmsHistoryTypeConnection>
  smsQueues?: Maybe<InformSmsQueueTypeConnection>
  socialServices?: Maybe<SocialServiceTypeConnection>
  splashUrl?: Maybe<Scalars['String']>
  statusHistories?: Maybe<StatusHistoryTypeConnection>
  statuses?: Maybe<StatusTypeConnection>
  totalInfluencerStatistic?: Maybe<TotalInfluencerStatisticType>
  totalSalesStatistic?: Maybe<TotalSalesStatisticType>
  totalSellerStatistic?: Maybe<TotalSellerStatisticType>
  /** The ID of the object */
  transaction?: Maybe<TransactionType>
  transactions?: Maybe<TransactionTypeConnection>
  user?: Maybe<UserType>
  userInfo?: Maybe<UserInfoType>
  userSubscriptions?: Maybe<UserSubscriptionTypeConnection>
  userWithPassword?: Maybe<UserType>
  userWithSocial?: Maybe<UserType>
  userWithToken?: Maybe<UserType>
  users?: Maybe<UserTypeConnection>
  weeklyStatistics?: Maybe<TermStatisticColumnTypeConnection>
  wishedBrands?: Maybe<BrandTypeConnection>
  wishedProducts?: Maybe<ProductTypeConnection>
  writableReviewCount?: Maybe<Scalars['Int']>
}

export type QueryAdArgs = {
  id: Scalars['ID']
}

export type QueryAdBySlugArgs = {
  slug: Scalars['String']
}

export type QueryAdsArgs = {
  adStatus?: InputMaybe<Scalars['String']>
  adStatuses?: InputMaybe<Scalars['String']>
  adType?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryAdsDeal100Args = {
  adStatus?: InputMaybe<Scalars['String']>
  adStatuses?: InputMaybe<Scalars['String']>
  adType?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryAsInfosArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryBannerArgs = {
  id: Scalars['ID']
}

export type QueryBannersArgs = {
  after?: InputMaybe<Scalars['String']>
  allowDisplay?: InputMaybe<Scalars['Boolean']>
  bannerType?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isCurrentDisplay?: InputMaybe<Scalars['Boolean']>
  isFinished?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
}

export type QueryBillingItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  confirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  confirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeFitpetSeller?: InputMaybe<Scalars['Boolean']>
  excludeMileageBilling?: InputMaybe<Scalars['Boolean']>
  excludeSeller?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasSellerFee?: InputMaybe<Scalars['Boolean']>
  isSellerBilled?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItemNumber?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerBilledAtAfter?: InputMaybe<Scalars['DateTime']>
  sellerBilledAtBefore?: InputMaybe<Scalars['DateTime']>
  sellerChiefManagerName?: InputMaybe<Scalars['String']>
  sellerFeeGte?: InputMaybe<Scalars['Float']>
  sellerName?: InputMaybe<Scalars['String']>
}

export type QueryBrandArgs = {
  id: Scalars['ID']
}

export type QueryBrandsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeBrands?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  firstLetter?: InputMaybe<Scalars['String']>
  isApproved?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  seller?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
}

export type QueryBundledOrderItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  cancelRequestedAtAfter?: InputMaybe<Scalars['DateTime']>
  cancelRequestedAtBefore?: InputMaybe<Scalars['DateTime']>
  canceledAtAfter?: InputMaybe<Scalars['DateTime']>
  canceledAtBefore?: InputMaybe<Scalars['DateTime']>
  checkedAtAfter?: InputMaybe<Scalars['DateTime']>
  checkedAtBefore?: InputMaybe<Scalars['DateTime']>
  confirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  confirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtOrRefundAtBetween?: InputMaybe<Scalars['String']>
  customerEmail?: InputMaybe<Scalars['String']>
  customerMobileNumber?: InputMaybe<Scalars['String']>
  customerName?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  includeMileage?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItem: Scalars['ID']
  orderItemNumber?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  orderStatus?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  receivedAtAfter?: InputMaybe<Scalars['DateTime']>
  receivedAtBefore?: InputMaybe<Scalars['DateTime']>
  recipientName?: InputMaybe<Scalars['String']>
  returnAcceptedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnAcceptedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnConfirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnConfirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnRequestedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnRequestedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnStatus?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerCompanyName?: InputMaybe<Scalars['String']>
  sellerShopName?: InputMaybe<Scalars['String']>
  sentAtAfter?: InputMaybe<Scalars['DateTime']>
  sentAtBefore?: InputMaybe<Scalars['DateTime']>
  sentAtOrRefundAtBetween?: InputMaybe<Scalars['String']>
  shippingNumber?: InputMaybe<Scalars['String']>
  shippingStatus?: InputMaybe<Scalars['String']>
  shippings?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  transactionDevice?: InputMaybe<Scalars['String']>
  transactionIsEscrow?: InputMaybe<Scalars['Boolean']>
  transactionPayAppType?: InputMaybe<Scalars['String']>
  transactionPayMethodType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userCustomerGrades?: InputMaybe<Scalars['String']>
}

export type QueryCacheProductsArgs = {
  filter?: InputMaybe<Scalars['GenericScalar']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryCachedDisplayCollectionArgs = {
  id: Scalars['ID']
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryCartItemArgs = {
  id: Scalars['ID']
}

export type QueryCartItemHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryCartItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brand?: InputMaybe<Scalars['String']>
  excludeCategories?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasImage?: InputMaybe<Scalars['Boolean']>
  isRootCategory?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  parentCategory?: InputMaybe<Scalars['String']>
}

export type QueryCategoryArgs = {
  id: Scalars['ID']
}

export type QueryCategoryBySlugArgs = {
  slug: Scalars['String']
}

export type QueryCheckInvitationCodeArgs = {
  invitationCode: Scalars['String']
}

export type QueryCheckTokenAssociationArgs = {
  filter?: InputMaybe<Scalars['GenericScalar']>
}

export type QueryCouponGroupArgs = {
  id: Scalars['ID']
}

export type QueryCouponGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroupStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  coverageOsType?: InputMaybe<Scalars['String']>
  coverageType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  eventType?: InputMaybe<Scalars['String']>
  excludeIssueType?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isDiscountVisibleBeforeDownload?: InputMaybe<Scalars['Boolean']>
  isIssuing?: InputMaybe<Scalars['Boolean']>
  issueStatus?: InputMaybe<Scalars['String']>
  issueType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  usingEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  usingPeriod?: InputMaybe<Scalars['Float']>
  usingStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingStartedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroup?: InputMaybe<Scalars['String']>
  couponGroupName?: InputMaybe<Scalars['String']>
  couponStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  isUsed?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QueryCouponsByProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  products: Array<InputMaybe<Scalars['String']>>
}

export type QueryCustomerAddressesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryCustomerGradeArgs = {
  id: Scalars['ID']
}

export type QueryCustomerGradesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryCustomerTemplateAddressArgs = {
  id: Scalars['ID']
}

export type QueryCustomerTemplateAddressesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  user?: InputMaybe<Scalars['String']>
}

export type QueryDailyStatisticsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryDashboardArgs = {
  influencer?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
}

export type QueryDevicesArgs = {
  after?: InputMaybe<Scalars['String']>
  appVersion?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  osVersion?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  platformType?: InputMaybe<Scalars['String']>
}

export type QueryDisplayCollectionArgs = {
  id: Scalars['ID']
}

export type QueryDisplayCollectionsArgs = {
  ad?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  collectionType?: InputMaybe<Scalars['String']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  isHome?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryDisplayProductInfoArgs = {
  id: Scalars['ID']
}

export type QueryDisplayProductInfosArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brand?: InputMaybe<Scalars['String']>
  displayCollection?: InputMaybe<Scalars['String']>
  excludeProductRealSoldOut?: InputMaybe<Scalars['Boolean']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isProductRange?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productCode?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
}

export type QueryEmailQueuesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryExcelExportRequestsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
  seller?: InputMaybe<Scalars['String']>
}

export type QueryExcelImportRequestsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  importType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryFitpetLinksArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
  product?: InputMaybe<Scalars['String']>
}

export type QueryGnbsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryHomeSectionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  uuid?: InputMaybe<Scalars['String']>
}

export type QueryIncompleteProductsByRoleArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryInfluencerSalesStatisticsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryInfluencerStatisticsByRoleArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryInformPushQueueArgs = {
  id: Scalars['ID']
}

export type QueryInformPushQueuesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  grades?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  pushQueueStatus?: InputMaybe<Scalars['String']>
  pushType?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  sendAtAfter?: InputMaybe<Scalars['DateTime']>
  sendAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryIngredientBasedFeedRecommendationProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  feedIngredientType?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryInvalidConditionBeforeEstimateOrderArgs = {
  orderItems: Array<InputMaybe<InvalidConditionBeforeEstimateOrderTypeInput>>
}

export type QueryLikedProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryLikedProductsWithPromotionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryMembershipArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryMembershipBenefitMonthlyArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryMembershipFamiliesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryMembershipHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  membership?: InputMaybe<Scalars['String']>
  membershipHistoryStatus?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  user?: InputMaybe<Scalars['String']>
}

export type QueryMembershipInvitationArgs = {
  code: Scalars['String']
}

export type QueryMembershipSubscribeInfosArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  userId: Scalars['ID']
}

export type QueryMembershipUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  agreeMarketing?: InputMaybe<Scalars['Boolean']>
  agreeMarketingHospital?: InputMaybe<Scalars['Boolean']>
  agreeMarketingPlay?: InputMaybe<Scalars['Boolean']>
  agreeNightMarketing?: InputMaybe<Scalars['Boolean']>
  agreeServicePushAllowed?: InputMaybe<Scalars['Boolean']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  dateJoinedAfter?: InputMaybe<Scalars['DateTime']>
  dateJoinedBefore?: InputMaybe<Scalars['DateTime']>
  email?: InputMaybe<Scalars['String']>
  excludeUsers?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  grades?: InputMaybe<Scalars['String']>
  inactivatedAtAfter?: InputMaybe<Scalars['DateTime']>
  inactivatedAtBefore?: InputMaybe<Scalars['DateTime']>
  inactiveUserEmail?: InputMaybe<Scalars['String']>
  inactiveUserMobileNumber?: InputMaybe<Scalars['String']>
  inactiveUserName?: InputMaybe<Scalars['String']>
  inactiveUserUsername?: InputMaybe<Scalars['String']>
  isBuyer?: InputMaybe<Scalars['Boolean']>
  isInfluencer?: InputMaybe<Scalars['Boolean']>
  isLeft?: InputMaybe<Scalars['Boolean']>
  isSeller?: InputMaybe<Scalars['Boolean']>
  isStaff?: InputMaybe<Scalars['Boolean']>
  joinedPlatform?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  lastLoginAfter?: InputMaybe<Scalars['DateTime']>
  lastLoginBefore?: InputMaybe<Scalars['DateTime']>
  leftAtAfter?: InputMaybe<Scalars['DateTime']>
  leftAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipRefundStatus?: InputMaybe<Scalars['String']>
  membershipStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipStartedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipStatus?: InputMaybe<Scalars['String']>
  mileagePointLess?: InputMaybe<Scalars['Float']>
  mileagePointMore?: InputMaybe<Scalars['Float']>
  mobileNumber?: InputMaybe<Scalars['String']>
  mobileNumbers?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  orderAmountLess?: InputMaybe<Scalars['Float']>
  orderAmountMore?: InputMaybe<Scalars['Float']>
  orderBy?: InputMaybe<Scalars['String']>
  orderCountLess?: InputMaybe<Scalars['Float']>
  orderCountMore?: InputMaybe<Scalars['Float']>
  pageInfo?: InputMaybe<PageInfoInputType>
  requiredUsername?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  socialType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userNumber?: InputMaybe<Scalars['String']>
  userStatus?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QueryMileageHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isMembership?: InputMaybe<Scalars['Boolean']>
  isPlus?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mileageType?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
}

export type QueryMileagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
  user?: InputMaybe<Scalars['String']>
}

export type QueryMinAppVersionArgs = {
  platform: Scalars['String']
}

export type QueryMonthlyStatisticsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryNonUserOrderArgs = {
  name: Scalars['String']
  orderNumber: Scalars['String']
}

export type QueryNoticeArgs = {
  id: Scalars['ID']
}

export type QueryNoticesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  body?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  kind?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  receiverType?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
}

export type QueryOneOnOneQnasArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItem?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  questionType?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QueryOneOnOneQnasByAdminArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItem?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  questionType?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QueryOrderArgs = {
  id: Scalars['ID']
}

export type QueryOrderItemArgs = {
  id: Scalars['ID']
}

export type QueryOrderItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  cancelRequestedAtAfter?: InputMaybe<Scalars['DateTime']>
  cancelRequestedAtBefore?: InputMaybe<Scalars['DateTime']>
  canceledAtAfter?: InputMaybe<Scalars['DateTime']>
  canceledAtBefore?: InputMaybe<Scalars['DateTime']>
  checkedAtAfter?: InputMaybe<Scalars['DateTime']>
  checkedAtBefore?: InputMaybe<Scalars['DateTime']>
  confirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  confirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtOrRefundAtBetween?: InputMaybe<Scalars['String']>
  customerEmail?: InputMaybe<Scalars['String']>
  customerMobileNumber?: InputMaybe<Scalars['String']>
  customerName?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  includeMileage?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItemNumber?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  orderStatus?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  receivedAtAfter?: InputMaybe<Scalars['DateTime']>
  receivedAtBefore?: InputMaybe<Scalars['DateTime']>
  recipientName?: InputMaybe<Scalars['String']>
  returnAcceptedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnAcceptedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnConfirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnConfirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnRequestedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnRequestedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnStatus?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerCompanyName?: InputMaybe<Scalars['String']>
  sellerShopName?: InputMaybe<Scalars['String']>
  sentAtAfter?: InputMaybe<Scalars['DateTime']>
  sentAtBefore?: InputMaybe<Scalars['DateTime']>
  sentAtOrRefundAtBetween?: InputMaybe<Scalars['String']>
  shippingNumber?: InputMaybe<Scalars['String']>
  shippingStatus?: InputMaybe<Scalars['String']>
  shippings?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  transactionDevice?: InputMaybe<Scalars['String']>
  transactionIsEscrow?: InputMaybe<Scalars['Boolean']>
  transactionPayAppType?: InputMaybe<Scalars['String']>
  transactionPayMethodType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userCustomerGrades?: InputMaybe<Scalars['String']>
}

export type QueryOrderQnasArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItem?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  questionType?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QueryOrderQnasByAdminArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItem?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  questionType?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QueryOrderQnasBySellerArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  id: Scalars['ID']
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItem?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  questionType?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderStatus?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryPopularKeywordsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  petType?: InputMaybe<Scalars['String']>
}

export type QueryPopularSearchTermsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryProductArgs = {
  id: Scalars['ID']
  productPromotion?: InputMaybe<Scalars['ID']>
}

export type QueryProductCountArgs = {
  seller?: InputMaybe<Scalars['ID']>
}

export type QueryProductImageArgs = {
  id: Scalars['ID']
}

export type QueryProductImagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryProductOptionValueArgs = {
  name?: InputMaybe<Scalars['String']>
  productOptionGroup: Scalars['ID']
}

export type QueryProductOptionValuesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productOptionGroup?: InputMaybe<Scalars['String']>
}

export type QueryProductOptionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isDelete?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  skus?: InputMaybe<Scalars['String']>
}

export type QueryProductOptionsWithPromotionArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  productOptions?: InputMaybe<Scalars['String']>
}

export type QueryProductPromotionArgs = {
  id: Scalars['ID']
}

export type QueryProductPromotionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  displayProductInfo?: InputMaybe<Scalars['String']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  product?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryProductQnaArgs = {
  id: Scalars['ID']
}

export type QueryProductQnasArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryProductsWithoutPetTypeArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryPushUserHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isSuccess?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryRecommendBundledProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  id: Scalars['ID']
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryRecommendSearchKeywordsArgs = {
  petType?: InputMaybe<Scalars['String']>
}

export type QueryRecommendedCartProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  ids: Array<InputMaybe<Scalars['ID']>>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryRecommendedPersonalProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
  userId: Scalars['ID']
}

export type QueryRecommendedProductDetailProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryRecommendedProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryRecommendedRelatedProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryRecommendedSimilarProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryRemainPriceForFreeShippingArgs = {
  productOptionId: Scalars['String']
}

export type QueryRemoteAreaArgs = {
  postNumber: Scalars['String']
}

export type QueryRemoteAreasArgs = {
  address?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
  postNumber?: InputMaybe<Scalars['String']>
}

export type QueryRestockProductOptionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryReviewArgs = {
  id: Scalars['ID']
}

export type QueryReviewGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  product?: InputMaybe<Scalars['String']>
}

export type QueryReviewGroupsBySellerArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  product?: InputMaybe<Scalars['String']>
  seller: Scalars['ID']
}

export type QueryReviewImagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
  product?: InputMaybe<Scalars['String']>
}

export type QueryReviewWritableOrderItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  cancelRequestedAtAfter?: InputMaybe<Scalars['DateTime']>
  cancelRequestedAtBefore?: InputMaybe<Scalars['DateTime']>
  canceledAtAfter?: InputMaybe<Scalars['DateTime']>
  canceledAtBefore?: InputMaybe<Scalars['DateTime']>
  checkedAtAfter?: InputMaybe<Scalars['DateTime']>
  checkedAtBefore?: InputMaybe<Scalars['DateTime']>
  confirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  confirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtOrRefundAtBetween?: InputMaybe<Scalars['String']>
  customerEmail?: InputMaybe<Scalars['String']>
  customerMobileNumber?: InputMaybe<Scalars['String']>
  customerName?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  includeMileage?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItemNumber?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  orderStatus?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  receivedAtAfter?: InputMaybe<Scalars['DateTime']>
  receivedAtBefore?: InputMaybe<Scalars['DateTime']>
  recipientName?: InputMaybe<Scalars['String']>
  returnAcceptedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnAcceptedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnConfirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnConfirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnRequestedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnRequestedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnStatus?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerCompanyName?: InputMaybe<Scalars['String']>
  sellerShopName?: InputMaybe<Scalars['String']>
  sentAtAfter?: InputMaybe<Scalars['DateTime']>
  sentAtBefore?: InputMaybe<Scalars['DateTime']>
  sentAtOrRefundAtBetween?: InputMaybe<Scalars['String']>
  shippingNumber?: InputMaybe<Scalars['String']>
  shippingStatus?: InputMaybe<Scalars['String']>
  shippings?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  transactionDevice?: InputMaybe<Scalars['String']>
  transactionIsEscrow?: InputMaybe<Scalars['Boolean']>
  transactionPayAppType?: InputMaybe<Scalars['String']>
  transactionPayMethodType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userCustomerGrades?: InputMaybe<Scalars['String']>
}

export type QueryReviewsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  bestAt?: InputMaybe<Scalars['Date']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isPhotoReview?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  reviewBody?: InputMaybe<Scalars['String']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QuerySearchHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  searchKeyword?: InputMaybe<Scalars['String']>
}

export type QuerySearchProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  petType: Scalars['String']
  text: Scalars['String']
}

export type QuerySearchProductsWithDetailsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType: Scalars['String']
  text: Scalars['String']
}

export type QuerySearchTextArgs = {
  petType?: InputMaybe<Scalars['String']>
}

export type QuerySearchedBrandsArgs = {
  keyword?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
}

export type QuerySearchedKeywordsArgs = {
  keyword?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
}

export type QuerySearchedProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QuerySellerArgs = {
  id: Scalars['ID']
}

export type QuerySellerAddressesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QuerySellerBillingsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  billedAtAfter?: InputMaybe<Scalars['DateTime']>
  billedAtBefore?: InputMaybe<Scalars['DateTime']>
  brand?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  seller?: InputMaybe<Scalars['String']>
  sellerChiefManagerName?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
}

export type QuerySellerSalesStatisticsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QuerySellerStatisticsByRoleArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QuerySellerTemplateAddressesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QuerySellerUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
  seller?: InputMaybe<Scalars['String']>
  shopName?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
}

export type QuerySellersArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  chiefManagerName?: InputMaybe<Scalars['String']>
  chiefManagerUsername?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeSellers?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  followerInfluencer?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productName?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  shopName?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
}

export type QueryServerConfigsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  configType?: InputMaybe<Scalars['String']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  key?: InputMaybe<Scalars['String']>
  keys?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type QueryServiceQnaArgs = {
  id: Scalars['ID']
}

export type QueryServiceQnasArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItem?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  questionType?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QueryServiceQnasByAdminArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItem?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  questionType?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QueryShippingArgs = {
  id: Scalars['ID']
}

export type QueryShippingCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryShippingPresetsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryShippingTrackArgs = {
  orderItem: Scalars['ID']
  shippingNumber: Scalars['String']
}

export type QueryShippingsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QuerySkuArgs = {
  id: Scalars['ID']
}

export type QuerySkuHistoryArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  sku?: InputMaybe<Scalars['String']>
}

export type QuerySkusArgs = {
  after?: InputMaybe<Scalars['String']>
  barcodeNumber?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeSkus?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  search?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
}

export type QuerySmsHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QuerySmsQueuesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QuerySocialServicesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
  socialType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['ID']>
}

export type QuerySplashUrlArgs = {
  platform: Scalars['String']
}

export type QueryStatusHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
  status?: InputMaybe<Scalars['String']>
  statusExclude?: InputMaybe<Scalars['String']>
}

export type QueryStatusesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  updatedAtAfter?: InputMaybe<Scalars['Date']>
  updatedAtBefore?: InputMaybe<Scalars['Date']>
}

export type QueryTransactionArgs = {
  id: Scalars['ID']
}

export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryUserArgs = {
  id: Scalars['ID']
}

export type QueryUserInfoArgs = {
  userCertification: Scalars['ID']
}

export type QueryUserSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
  user?: InputMaybe<Scalars['String']>
}

export type QueryUserWithPasswordArgs = {
  password: Scalars['String']
}

export type QueryUserWithSocialArgs = {
  accessToken: Scalars['String']
  clientType?: InputMaybe<Scalars['String']>
  socialType: Scalars['String']
  uniqueId: Scalars['String']
}

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  agreeMarketing?: InputMaybe<Scalars['Boolean']>
  agreeMarketingHospital?: InputMaybe<Scalars['Boolean']>
  agreeMarketingPlay?: InputMaybe<Scalars['Boolean']>
  agreeNightMarketing?: InputMaybe<Scalars['Boolean']>
  agreeServicePushAllowed?: InputMaybe<Scalars['Boolean']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  dateJoinedAfter?: InputMaybe<Scalars['DateTime']>
  dateJoinedBefore?: InputMaybe<Scalars['DateTime']>
  email?: InputMaybe<Scalars['String']>
  excludeUsers?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  grades?: InputMaybe<Scalars['String']>
  inactivatedAtAfter?: InputMaybe<Scalars['DateTime']>
  inactivatedAtBefore?: InputMaybe<Scalars['DateTime']>
  inactiveUserEmail?: InputMaybe<Scalars['String']>
  inactiveUserMobileNumber?: InputMaybe<Scalars['String']>
  inactiveUserName?: InputMaybe<Scalars['String']>
  inactiveUserUsername?: InputMaybe<Scalars['String']>
  isBuyer?: InputMaybe<Scalars['Boolean']>
  isInfluencer?: InputMaybe<Scalars['Boolean']>
  isLeft?: InputMaybe<Scalars['Boolean']>
  isSeller?: InputMaybe<Scalars['Boolean']>
  isStaff?: InputMaybe<Scalars['Boolean']>
  joinedPlatform?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  lastLoginAfter?: InputMaybe<Scalars['DateTime']>
  lastLoginBefore?: InputMaybe<Scalars['DateTime']>
  leftAtAfter?: InputMaybe<Scalars['DateTime']>
  leftAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipRefundStatus?: InputMaybe<Scalars['String']>
  membershipStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipStartedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipStatus?: InputMaybe<Scalars['String']>
  mileagePointLess?: InputMaybe<Scalars['Float']>
  mileagePointMore?: InputMaybe<Scalars['Float']>
  mobileNumber?: InputMaybe<Scalars['String']>
  mobileNumbers?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  needExcel?: InputMaybe<Scalars['Boolean']>
  orderAmountLess?: InputMaybe<Scalars['Float']>
  orderAmountMore?: InputMaybe<Scalars['Float']>
  orderBy?: InputMaybe<Scalars['String']>
  orderCountLess?: InputMaybe<Scalars['Float']>
  orderCountMore?: InputMaybe<Scalars['Float']>
  pageInfo?: InputMaybe<PageInfoInputType>
  requiredUsername?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  socialType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userNumber?: InputMaybe<Scalars['String']>
  userStatus?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type QueryWeeklyStatisticsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type QueryWishedBrandsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeBrands?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  firstLetter?: InputMaybe<Scalars['String']>
  isApproved?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  seller?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
}

export type QueryWishedProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  orderBy?: InputMaybe<Scalars['String']>
  pageInfo?: InputMaybe<PageInfoInputType>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

/** Push 메세지 읽음 처리 */
export type ReadPushMutation = {
  __typename?: 'ReadPushMutation'
  push?: Maybe<PushType>
}

/** 장바구니에서 만료된 ProductPromotion 제거 */
export type RefreshCartItemsMutation = {
  __typename?: 'RefreshCartItemsMutation'
  cartItems?: Maybe<Array<Maybe<CartItemType>>>
}

export type RefundAccountInput = {
  /** 예금주명 */
  accountHolderName: Scalars['String']
  /** 계좌번호 */
  accountNumber: Scalars['String']
  /** 은행코드 */
  bankCode: Scalars['String']
}

export type RefundInfoType = Node & {
  __typename?: 'RefundInfoType'
  /** 예금주명 */
  accountHolderName?: Maybe<Scalars['String']>
  /** 계좌번호 */
  accountNumber?: Maybe<Scalars['String']>
  /** 은행 코드 */
  bankCode?: Maybe<Scalars['String']>
  /** 은행 이름 */
  bankName?: Maybe<Scalars['String']>
  /** 상품 쿠폰 할인액 */
  couponDiscountPrice?: Maybe<Scalars['Decimal']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 적립금 할인액 */
  mileageDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 금액 비율로 쪼개진 주문 쿠폰 할인액 */
  orderCouponDiscountPrice: Scalars['Decimal']
  orderitem?: Maybe<OrderItemType>
  /** 상품 원가 */
  originalPrice: Scalars['Decimal']
  /** 상품 기본 할인액 */
  productDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 이벤트 할인액 */
  productPromotionDiscountPrice?: Maybe<Scalars['Decimal']>
  refundAmount?: Maybe<Scalars['Decimal']>
  /** 금액 비율로 쪼개진 반품 도서 산간 배송비 */
  returnShippingExtraFee: Scalars['Decimal']
  /** 금액 비율로 쪼개진 반품 배송비 */
  returnShippingFee: Scalars['Decimal']
}

/** 멤버쉽 구독 취소 (즉시 해지) */
export type RefundMembershipMutation = {
  __typename?: 'RefundMembershipMutation'
  membership?: Maybe<MembershipType>
}

export type RefundShippingInfoType = Node & {
  __typename?: 'RefundShippingInfoType'
  /** 예금주명 */
  accountHolderName?: Maybe<Scalars['String']>
  /** 계좌번호 */
  accountNumber?: Maybe<Scalars['String']>
  /** 최종 배송비, 배송비 - 기본 할인 - 쿠폰 할인 - 마일리지 할인 */
  amount?: Maybe<Scalars['Decimal']>
  /** 배송비 - 기본 할인 - 쿠폰 할인 */
  amountWithoutMileage?: Maybe<Scalars['Decimal']>
  /** 은행 코드 */
  bankCode?: Maybe<Scalars['String']>
  /** 은행 이름 */
  bankName?: Maybe<Scalars['String']>
  /** 배송비 및 환불 수수료 정보 */
  billingitemSet: BillingItemTypeConnection
  /** 배송비 쿠폰 할인액 */
  couponDiscountPrice: Scalars['Decimal']
  /** 기본 배송비 할인액 */
  discountPrice: Scalars['Decimal']
  /** 도서산간 추가 배송비 */
  extraFee: Scalars['Decimal']
  /** 기본 배송비 */
  fee: Scalars['Decimal']
  /** 배송비 + 도서산간비 */
  feeWithExtraFee?: Maybe<Scalars['Decimal']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 배송비 멤버쉽 할인액 */
  membershipDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 배송비 적립금 할인액 */
  mileageDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 배송비 및 환불 수수료 정보 */
  refundShippingInfoOrderItems: OrderItemTypeConnection
  /** 반품 도서 산간 수수료 */
  returnShippingExtraFee: Scalars['Decimal']
  /** 반품 수수료 */
  returnShippingFee: Scalars['Decimal']
  /** 환불된 Shipping */
  shipping?: Maybe<ShippingType>
  /** 배송비 + 도서산간비, 현재 도서산간 0원이므로 fee와 같음 */
  sumFee?: Maybe<Scalars['Decimal']>
  /** 할인 제외 배송비 */
  sumFeeWithDiscount?: Maybe<Scalars['Decimal']>
}

export type RefundShippingInfoTypeBillingitemSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  confirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  confirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeFitpetSeller?: InputMaybe<Scalars['Boolean']>
  excludeMileageBilling?: InputMaybe<Scalars['Boolean']>
  excludeSeller?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  hasSellerFee?: InputMaybe<Scalars['Boolean']>
  isSellerBilled?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItemNumber?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerBilledAtAfter?: InputMaybe<Scalars['DateTime']>
  sellerBilledAtBefore?: InputMaybe<Scalars['DateTime']>
  sellerChiefManagerName?: InputMaybe<Scalars['String']>
  sellerFeeGte?: InputMaybe<Scalars['Float']>
  sellerName?: InputMaybe<Scalars['String']>
}

export type RefundShippingInfoTypeRefundShippingInfoOrderItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  cancelRequestedAtAfter?: InputMaybe<Scalars['DateTime']>
  cancelRequestedAtBefore?: InputMaybe<Scalars['DateTime']>
  canceledAtAfter?: InputMaybe<Scalars['DateTime']>
  canceledAtBefore?: InputMaybe<Scalars['DateTime']>
  checkedAtAfter?: InputMaybe<Scalars['DateTime']>
  checkedAtBefore?: InputMaybe<Scalars['DateTime']>
  confirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  confirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtOrRefundAtBetween?: InputMaybe<Scalars['String']>
  customerEmail?: InputMaybe<Scalars['String']>
  customerMobileNumber?: InputMaybe<Scalars['String']>
  customerName?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  includeMileage?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItemNumber?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  orderStatus?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  receivedAtAfter?: InputMaybe<Scalars['DateTime']>
  receivedAtBefore?: InputMaybe<Scalars['DateTime']>
  recipientName?: InputMaybe<Scalars['String']>
  returnAcceptedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnAcceptedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnConfirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnConfirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnRequestedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnRequestedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnStatus?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerCompanyName?: InputMaybe<Scalars['String']>
  sellerShopName?: InputMaybe<Scalars['String']>
  sentAtAfter?: InputMaybe<Scalars['DateTime']>
  sentAtBefore?: InputMaybe<Scalars['DateTime']>
  sentAtOrRefundAtBetween?: InputMaybe<Scalars['String']>
  shippingNumber?: InputMaybe<Scalars['String']>
  shippingStatus?: InputMaybe<Scalars['String']>
  shippings?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  transactionDevice?: InputMaybe<Scalars['String']>
  transactionIsEscrow?: InputMaybe<Scalars['Boolean']>
  transactionPayAppType?: InputMaybe<Scalars['String']>
  transactionPayMethodType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userCustomerGrades?: InputMaybe<Scalars['String']>
}

export type RefundShippingInfoTypeConnection = {
  __typename?: 'RefundShippingInfoTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RefundShippingInfoTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `RefundShippingInfoType` and its cursor. */
export type RefundShippingInfoTypeEdge = {
  __typename?: 'RefundShippingInfoTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<RefundShippingInfoType>
}

export type RegisterCouponInput = {
  /** 쿠폰 코드 */
  code: Scalars['String']
}

/** 쿠폰 번호를 통한 쿠폰 등록 */
export type RegisterCouponMutation = {
  __typename?: 'RegisterCouponMutation'
  coupon?: Maybe<CouponType>
}

export type RejectExchangeReturnInfosInput = {
  /** 반려사유 */
  rejectMessage?: InputMaybe<Scalars['String']>
  returnInfos: Array<InputMaybe<RejectReturnInfoInput>>
}

/** 교환 요청 거절 */
export type RejectExchangeReturnInfosMutation = {
  __typename?: 'RejectExchangeReturnInfosMutation'
  returnInfos?: Maybe<Array<Maybe<ReturnInfoType>>>
}

/** 요청 거절 */
export type RejectRequestMutation = {
  __typename?: 'RejectRequestMutation'
  status?: Maybe<StatusType>
}

/** 관리자에 의해 다중의 요청을 거절 */
export type RejectRequestsMutation = {
  __typename?: 'RejectRequestsMutation'
  statuses?: Maybe<Array<Maybe<StatusType>>>
}

export type RejectReturnInfoInput = {
  returnInfo: Scalars['ID']
}

export type RejectReturnInfosInput = {
  /** 반려사유 */
  rejectMessage?: InputMaybe<Scalars['String']>
  returnInfos: Array<InputMaybe<RejectReturnInfoInput>>
}

/** 반품 요청에 대한 거절 (반품요청 철회) */
export type RejectReturnInfosMutation = {
  __typename?: 'RejectReturnInfosMutation'
  returnInfos?: Maybe<Array<Maybe<ReturnInfoType>>>
}

/** 정보변경 요청 거절 */
export type RejectUpdateRequestMutation = {
  __typename?: 'RejectUpdateRequestMutation'
  status?: Maybe<StatusType>
}

export type RemoteAreaInput = {
  /** 추가 운임 */
  extraFee: Scalars['String']
  /** 추가 운임 */
  extraReturnFee: Scalars['String']
  /** 우편번호 */
  postNumber: Scalars['String']
}

export type RemoteAreaType = Node & {
  __typename?: 'RemoteAreaType'
  /** 주소 */
  address?: Maybe<Scalars['String']>
  /** 추가 운임 */
  extraFee: Scalars['Decimal']
  /** 추가 반품 운임 */
  extraReturnFee: Scalars['Decimal']
  /** The ID of the object. */
  id: Scalars['ID']
  /** 우편번호 */
  postNumber?: Maybe<Scalars['String']>
}

export type RemoteAreaTypeConnection = {
  __typename?: 'RemoteAreaTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RemoteAreaTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `RemoteAreaType` and its cursor. */
export type RemoteAreaTypeEdge = {
  __typename?: 'RemoteAreaTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<RemoteAreaType>
}

/** 관리자 권한 해제 */
export type RemoveAdminPermissionMutation = {
  __typename?: 'RemoveAdminPermissionMutation'
  user?: Maybe<UserType>
}

/** 관리자 정산 엑셀 파일 요청 */
export type RequestAdminBillingItemExcelFileMutation = {
  __typename?: 'RequestAdminBillingItemExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** 감사 자료 엑셀 파일 요청 */
export type RequestAuditDataExcelFileMutation = {
  __typename?: 'RequestAuditDataExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** 쿠폰 목록 엑셀 파일 요청 */
export type RequestCouponExcelFileMutation = {
  __typename?: 'RequestCouponExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** 상품 프로모션 엑셀 파일 요청 */
export type RequestDisplayProductPromotionExcelFileMutation = {
  __typename?: 'RequestDisplayProductPromotionExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

export type RequestExcelFileInput = {
  /** 필터 */
  filter?: InputMaybe<Scalars['GenericScalar']>
  /** 정렬방식 */
  orderBy?: InputMaybe<Scalars['String']>
  /** 페이지 정보 */
  pageInfo?: InputMaybe<PageInfoInputType>
  /** 압축파일 암호화 패스워드 */
  password: Scalars['String']
  /** 템플릿이 필요할 경우 사용 */
  requireTemplate?: InputMaybe<Scalars['Boolean']>
}

export type RequestExcelFileWithoutPasswordInput = {
  /** 필터 */
  filter?: InputMaybe<Scalars['GenericScalar']>
  /** 정렬방식 */
  orderBy?: InputMaybe<Scalars['String']>
  /** 페이지 정보 */
  pageInfo?: InputMaybe<PageInfoInputType>
  /** 템플릿이 필요할 경우 사용 */
  requireTemplate?: InputMaybe<Scalars['Boolean']>
}

export type RequestExchangeOrderItemsInput = {
  customerAddress?: InputMaybe<RequestReturnOrderItemCustomerAddressInput>
  customerTemplateAddress?: InputMaybe<Scalars['ID']>
  orderItems: Array<InputMaybe<OrderItemInput>>
  /** request_type이 ETC일 때 기타 사유 입력필드 */
  requestMessage?: InputMaybe<Scalars['String']>
  /** 상품불량 및 파손 (DEFECTIVE_PRODUCT), 오배송 (WRONG_DELIVERY), 배송지연 (DELAY_DELIVERY), 상품 정보 상이(DIFFERENT_PRODUCT_INFO), 서비스 및 상품 불만족(DISSATISFACTION), 단순 변심 (CHANGE_MIND), 상품 옵션을 잘못 선택함 (MISCHOICE_PRODUCT_OPTION), 기타 (ETC) */
  requestType: Scalars['String']
  /** 책임 주체, SELLER/CUSTOMER */
  responsibility: Scalars['String']
  /** 첨부 이미지 */
  returnInfoImages?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>
}

/** 교환 요청 */
export type RequestExchangeOrderItemsMutation = {
  __typename?: 'RequestExchangeOrderItemsMutation'
  orderItems?: Maybe<Array<Maybe<OrderItemType>>>
}

/** 사용자 목록 엑셀 파일 요청 */
export type RequestInactiveUserExcelFileMutation = {
  __typename?: 'RequestInactiveUserExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** 사용자 목록 엑셀 파일 요청 */
export type RequestMembershipUserExcelFileMutation = {
  __typename?: 'RequestMembershipUserExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** 주문 엑셀 파일 요청 */
export type RequestOrderExcelFileMutation = {
  __typename?: 'RequestOrderExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** 반품 완료 주문건 엑셀 파일 요청 */
export type RequestOrderReturnConfirmedExcelFileMutation = {
  __typename?: 'RequestOrderReturnConfirmedExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** 상품 목록 어드민 엑셀 파일 요청 */
export type RequestProductExcelFileByAdminMutation = {
  __typename?: 'RequestProductExcelFileByAdminMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** 상품 목록 엑셀 파일 요청 */
export type RequestProductExcelFileMutation = {
  __typename?: 'RequestProductExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** 상품 목록 업로드 불가 어드민 엑셀 파일 요청 */
export type RequestProductExcelFileWithoutPasswordByAdminMutation = {
  __typename?: 'RequestProductExcelFileWithoutPasswordByAdminMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** 사방넷 주문 연동용 배송 준비중 상태의 주문 엑셀 파일 요청 */
export type RequestReadyForShippingOrderExcelFileMutation = {
  __typename?: 'RequestReadyForShippingOrderExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** TossPayments Escrow 주문 업로드용, 배송 준비중 상태의 주문 엑셀 파일 요청 */
export type RequestReadyForShippingOrderForEscrowTossPaymentsExcelFileMutation = {
  __typename?: 'RequestReadyForShippingOrderForEscrowTossPaymentsExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** Fitpet WMS 주문 연동용 배송 준비중 상태의 주문 엑셀 파일 요청 */
export type RequestReadyForShippingOrderForFitpetWmsExcelFileMutation = {
  __typename?: 'RequestReadyForShippingOrderForFitpetWmsExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** WMS 주문 연동용 배송 준비중 상태의 주문 엑셀 파일 요청 */
export type RequestReadyForShippingOrderForWmsExcelFileMutation = {
  __typename?: 'RequestReadyForShippingOrderForWmsExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

export type RequestReturnOrderItemCustomerAddressInput = {
  /** 기본 주소 */
  basicAddress: Scalars['String']
  /** 상세 주소 */
  detailedAddress?: InputMaybe<Scalars['String']>
  /** 고객 핸드폰 번호 */
  mobileNumber: Scalars['String']
  /** 고객 이름 */
  name: Scalars['String']
  /** 우편번호 */
  postNumber: Scalars['String']
}

export type RequestReturnOrderItemsInput = {
  customerAddress?: InputMaybe<RequestReturnOrderItemCustomerAddressInput>
  customerTemplateAddress?: InputMaybe<Scalars['ID']>
  orderItems: Array<InputMaybe<OrderItemInput>>
  /** request_type이 ETC일 때 기타 사유 입력필드 */
  requestMessage?: InputMaybe<Scalars['String']>
  /** 상품불량 및 파손 (DEFECTIVE_PRODUCT), 오배송 (WRONG_DELIVERY), 배송지연 (DELAY_DELIVERY), 상품 정보 상이(DIFFERENT_PRODUCT_INFO), 서비스 및 상품 불만족(DISSATISFACTION), 단순 변심 (CHANGE_MIND), 상품 옵션을 잘못 선택함 (MISCHOICE_PRODUCT_OPTION), 기타 (ETC) */
  requestType: Scalars['String']
  /** 책임 주체, SELLER/CUSTOMER */
  responsibility: Scalars['String']
  /** 첨부 이미지 */
  returnInfoImages?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>
}

/** 반품 요청 */
export type RequestReturnOrderItemsMutation = {
  __typename?: 'RequestReturnOrderItemsMutation'
  orderItems?: Maybe<Array<Maybe<OrderItemType>>>
}

/** 셀러 정산 엑셀 파일 요청 */
export type RequestSellerBillingItemExcelFileMutation = {
  __typename?: 'RequestSellerBillingItemExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** SKU 목록 엑셀 파일 요청 */
export type RequestSkuExcelFileMutation = {
  __typename?: 'RequestSkuExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** 사용자 목록 엑셀 파일 요청 */
export type RequestUserExcelFileMutation = {
  __typename?: 'RequestUserExcelFileMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

export type ResetPasswordInput = {
  newPassword: Scalars['String']
  userCertification: Scalars['ID']
}

/** 비밀번호 리셋 */
export type ResetPasswordMutation = {
  __typename?: 'ResetPasswordMutation'
  user?: Maybe<UserType>
}

export type RestockProductOptionUserType = Node & {
  __typename?: 'RestockProductOptionUserType'
  /** The ID of the object. */
  id: Scalars['ID']
  /** 재입고 알림 일시 */
  informedAt?: Maybe<Scalars['DateTime']>
  /** 재입고 요청 상품의 옵션 */
  productOption?: Maybe<ProductOptionType>
  /** 요청한 일시 */
  requestedAt?: Maybe<Scalars['DateTime']>
  /** 재입고 요청 사용자 */
  user?: Maybe<UserType>
}

export type RestockProductOptionUserTypeConnection = {
  __typename?: 'RestockProductOptionUserTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RestockProductOptionUserTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `RestockProductOptionUserType` and its cursor. */
export type RestockProductOptionUserTypeEdge = {
  __typename?: 'RestockProductOptionUserTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<RestockProductOptionUserType>
}

export type ReturnInfoImageType = Node & {
  __typename?: 'ReturnInfoImageType'
  /** The ID of the object. */
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  /** 상품 */
  returnInfo?: Maybe<ReturnInfoType>
  thumbnail?: Maybe<Scalars['String']>
}

export type ReturnInfoImageTypeThumbnailArgs = {
  imageType?: InputMaybe<Scalars['String']>
}

export type ReturnInfoImageTypeConnection = {
  __typename?: 'ReturnInfoImageTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReturnInfoImageTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ReturnInfoImageType` and its cursor. */
export type ReturnInfoImageTypeEdge = {
  __typename?: 'ReturnInfoImageTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ReturnInfoImageType>
}

export type ReturnInfoInput = {
  returnInfo: Scalars['ID']
}

/** An enumeration. */
export enum ReturnInfoRequestType {
  /** 단순 변심 */
  ChangeMind = 'CHANGE_MIND',
  /** 상품 불량 및 파손 */
  DefectiveProduct = 'DEFECTIVE_PRODUCT',
  /** 상품이 설명과 다름 */
  DifferentProductInfo = 'DIFFERENT_PRODUCT_INFO',
  /** 서비스 불만족 */
  Dissatisfaction = 'DISSATISFACTION',
  /** 기타 */
  Etc = 'ETC',
  /** 상품 추가 또는 옵션 변경 후 재주문 */
  MischoiceProductOption = 'MISCHOICE_PRODUCT_OPTION',
  /** 오배송 */
  WrongDelivery = 'WRONG_DELIVERY',
}

/** An enumeration. */
export enum ReturnInfoResponsibility {
  /** ADMIN */
  Admin = 'ADMIN',
  /** ALL */
  All = 'ALL',
  /** CUSTOMER */
  Customer = 'CUSTOMER',
  /** INFLUENCER */
  Influencer = 'INFLUENCER',
  /** NONE */
  None = 'NONE',
  /** SELLER */
  Seller = 'SELLER',
}

export type ReturnInfoType = Node & {
  __typename?: 'ReturnInfoType'
  /** 반품 수락일 */
  acceptedAt?: Maybe<Scalars['DateTime']>
  /** 정산 대상 반품 배송 정보 */
  billingitemSet: BillingItemTypeConnection
  /** 환불 완료일 */
  confirmedAt?: Maybe<Scalars['DateTime']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 보내는 사람 주소 */
  customerAddress?: Maybe<CustomerAddressType>
  /** 수락한 관리자 */
  fitpetAdminName?: Maybe<Scalars['String']>
  /** 안내 메시지 */
  guideMessage?: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 반품 요청 반려 사유 */
  rejectMessage?: Maybe<Scalars['String']>
  /** 반품 요청 반려일 */
  rejectedAt?: Maybe<Scalars['DateTime']>
  /** request_type이 ETC일 때 기타 사유 입력필드 */
  requestMessage?: Maybe<Scalars['String']>
  /** 상품불량 및 파손 (DEFECTIVE_PRODUCT), 오배송 (WRONG_DELIVERY), 배송지연 (DELAY_DELIVERY), 상품 정보 상이(DIFFERENT_PRODUCT_INFO), 서비스 및 상품 불만족(DISSATISFACTION), 단순 변심 (CHANGE_MIND), 상품 옵션을 잘못 선택함 (MISCHOICE_PRODUCT_OPTION), 기타 (ETC) */
  requestType: ReturnInfoRequestType
  /** 책임, SELLER(셀러책임) / CUSTOMER(구매자책임) */
  responsibility: ReturnInfoResponsibility
  /** 반품 정보 */
  returnInfoMileageHistories: MileageHistoryTypeConnection
  /** 반품 관련 정보 */
  returnInfoOrderItems: OrderItemTypeConnection
  /** 상품 */
  returnInfoReturnInfoImages: ReturnInfoImageTypeConnection
  /** 청구된 반품 도서 산간 배송비 */
  returnShippingExtraFee: Scalars['Decimal']
  /** 청구된 반품 배송비 */
  returnShippingFee: Scalars['Decimal']
  /** 반품자 */
  returnUser?: Maybe<Scalars['String']>
  /** 반품 확정일 */
  returnedAt?: Maybe<Scalars['DateTime']>
  /** 배송관련정보 */
  shipping?: Maybe<ShippingType>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ReturnInfoTypeBillingitemSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  confirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  confirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeFitpetSeller?: InputMaybe<Scalars['Boolean']>
  excludeMileageBilling?: InputMaybe<Scalars['Boolean']>
  excludeSeller?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  hasSellerFee?: InputMaybe<Scalars['Boolean']>
  isSellerBilled?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItemNumber?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerBilledAtAfter?: InputMaybe<Scalars['DateTime']>
  sellerBilledAtBefore?: InputMaybe<Scalars['DateTime']>
  sellerChiefManagerName?: InputMaybe<Scalars['String']>
  sellerFeeGte?: InputMaybe<Scalars['Float']>
  sellerName?: InputMaybe<Scalars['String']>
}

export type ReturnInfoTypeReturnInfoMileageHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isMembership?: InputMaybe<Scalars['Boolean']>
  isPlus?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mileageType?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
}

export type ReturnInfoTypeReturnInfoOrderItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  cancelRequestedAtAfter?: InputMaybe<Scalars['DateTime']>
  cancelRequestedAtBefore?: InputMaybe<Scalars['DateTime']>
  canceledAtAfter?: InputMaybe<Scalars['DateTime']>
  canceledAtBefore?: InputMaybe<Scalars['DateTime']>
  checkedAtAfter?: InputMaybe<Scalars['DateTime']>
  checkedAtBefore?: InputMaybe<Scalars['DateTime']>
  confirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  confirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtOrRefundAtBetween?: InputMaybe<Scalars['String']>
  customerEmail?: InputMaybe<Scalars['String']>
  customerMobileNumber?: InputMaybe<Scalars['String']>
  customerName?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  includeMileage?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItemNumber?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  orderStatus?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  receivedAtAfter?: InputMaybe<Scalars['DateTime']>
  receivedAtBefore?: InputMaybe<Scalars['DateTime']>
  recipientName?: InputMaybe<Scalars['String']>
  returnAcceptedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnAcceptedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnConfirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnConfirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnRequestedAtAfter?: InputMaybe<Scalars['DateTime']>
  returnRequestedAtBefore?: InputMaybe<Scalars['DateTime']>
  returnStatus?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerCompanyName?: InputMaybe<Scalars['String']>
  sellerShopName?: InputMaybe<Scalars['String']>
  sentAtAfter?: InputMaybe<Scalars['DateTime']>
  sentAtBefore?: InputMaybe<Scalars['DateTime']>
  sentAtOrRefundAtBetween?: InputMaybe<Scalars['String']>
  shippingNumber?: InputMaybe<Scalars['String']>
  shippingStatus?: InputMaybe<Scalars['String']>
  shippings?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  transactionDevice?: InputMaybe<Scalars['String']>
  transactionIsEscrow?: InputMaybe<Scalars['Boolean']>
  transactionPayAppType?: InputMaybe<Scalars['String']>
  transactionPayMethodType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userCustomerGrades?: InputMaybe<Scalars['String']>
}

export type ReturnInfoTypeReturnInfoReturnInfoImagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ReturnInfoTypeConnection = {
  __typename?: 'ReturnInfoTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReturnInfoTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ReturnInfoType` and its cursor. */
export type ReturnInfoTypeEdge = {
  __typename?: 'ReturnInfoTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ReturnInfoType>
}

export type ReturnInfosInput = {
  returnInfos: Array<InputMaybe<ReturnInfoInput>>
}

export type ReturnOrderItemsInput = {
  /** 안내 메시지 */
  guideMessage?: InputMaybe<Scalars['String']>
  orderItems: Array<InputMaybe<OrderItemInput>>
  /** request_type이 ETC일 때 기타 사유 입력필드 */
  requestMessage?: InputMaybe<Scalars['String']>
  /** 상품불량 및 파손 (DEFECTIVE_PRODUCT), 오배송 (WRONG_DELIVERY), 배송지연 (DELAY_DELIVERY), 상품 정보 상이(DIFFERENT_PRODUCT_INFO), 서비스 및 상품 불만족(DISSATISFACTION), 단순 변심 (CHANGE_MIND), 상품 옵션을 잘못 선택함 (MISCHOICE_PRODUCT_OPTION), 기타 (ETC) */
  requestType: Scalars['String']
  /** 책임 주체, ADMIN/SELLER/CUSTOMER */
  responsibility: Scalars['String']
}

/** 판매자가 반품처리 */
export type ReturnOrderItemsMutation = {
  __typename?: 'ReturnOrderItemsMutation'
  orderItems?: Maybe<Array<Maybe<OrderItemType>>>
}

export type ReturnReasonType = {
  __typename?: 'ReturnReasonType'
  isUserVisible?: Maybe<Scalars['Boolean']>
  requestMessage?: Maybe<Scalars['String']>
  requestType?: Maybe<Scalars['String']>
  responsibility?: Maybe<Scalars['String']>
}

export type ReturnedReturnInfosInput = {
  /** request_type이 ETC일 때 기타 사유 입력필드 */
  requestMessage?: InputMaybe<Scalars['String']>
  /** 상품불량 및 파손 (DEFECTIVE_PRODUCT), 오배송 (WRONG_DELIVERY), 배송지연 (DELAY_DELIVERY), 상품 정보 상이(DIFFERENT_PRODUCT_INFO), 서비스 및 상품 불만족(DISSATISFACTION), 단순 변심 (CHANGE_MIND), 상품 옵션을 잘못 선택함 (MISCHOICE_PRODUCT_OPTION), 기타 (ETC) */
  requestType: Scalars['String']
  /** 책임 주체, SELLER/CUSTOMER */
  responsibility: Scalars['String']
  returnInfos: Array<InputMaybe<ReturnInfoInput>>
  /** 반품배송비 */
  returnShippingFee?: InputMaybe<Scalars['Decimal']>
}

/** 수거 완료, 반품 수거중 -> 환불완료 or 환불처리중 */
export type ReturnedReturnInfosMutation = {
  __typename?: 'ReturnedReturnInfosMutation'
  returnInfos?: Maybe<Array<Maybe<ReturnInfoType>>>
}

export type ReviewGroupType = Node & {
  __typename?: 'ReviewGroupType'
  averageScore: Scalars['Decimal']
  /** The ID of the object. */
  id: Scalars['ID']
  /** 상품명 */
  name: Scalars['String']
  productCount?: Maybe<Scalars['Int']>
  reviewGroupProducts?: Maybe<ProductTypeConnection>
  totalCount: Scalars['Int']
}

export type ReviewGroupTypeReviewGroupProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type ReviewGroupTypeConnection = {
  __typename?: 'ReviewGroupTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReviewGroupTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ReviewGroupType` and its cursor. */
export type ReviewGroupTypeEdge = {
  __typename?: 'ReviewGroupTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ReviewGroupType>
}

export type ReviewImageType = Node & {
  __typename?: 'ReviewImageType'
  /** The ID of the object. */
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  /** 리뷰 이미지 */
  review?: Maybe<ReviewType>
  thumbnail?: Maybe<Scalars['String']>
}

export type ReviewImageTypeThumbnailArgs = {
  reviewImageType?: InputMaybe<Scalars['String']>
}

export type ReviewImageTypeConnection = {
  __typename?: 'ReviewImageTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReviewImageTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ReviewImageType` and its cursor. */
export type ReviewImageTypeEdge = {
  __typename?: 'ReviewImageTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ReviewImageType>
}

export type ReviewType = Node & {
  __typename?: 'ReviewType'
  /** 답변 내용 */
  answerBody?: Maybe<Scalars['String']>
  /** 답변일 */
  answeredAt?: Maybe<Scalars['DateTime']>
  /** 답변자 */
  answeredUser?: Maybe<UserType>
  /** 베스트 리뷰 선정일 (년-월만 사용) */
  bestAt?: Maybe<Scalars['Date']>
  /** 베스트 리뷰 순번 */
  bestSeries: Scalars['Int']
  /** 적립 관련 관리자 확인 일 */
  checkAdminAt?: Maybe<Scalars['DateTime']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  isAnswered?: Maybe<Scalars['Boolean']>
  /** 삭제 여부 */
  isDelete: Scalars['Boolean']
  isFirstReviewProduct?: Maybe<Scalars['Boolean']>
  isLiked?: Maybe<Scalars['Boolean']>
  isPhotoReview?: Maybe<Scalars['Boolean']>
  likedCount?: Maybe<Scalars['Int']>
  likedUsers: UserTypeConnection
  /** 리뷰로 적립된 적립금 */
  mileage?: Maybe<Scalars['Int']>
  mileageText?: Maybe<Scalars['String']>
  /** 주문상품 아이템 */
  orderItem?: Maybe<OrderItemType>
  /** 상품 */
  product?: Maybe<ProductType>
  /** 옵션명 */
  productOptionName: Scalars['String']
  /** 리뷰 내용 */
  reviewBody: Scalars['String']
  /** 적립금 적립한/취소한 리뷰 */
  reviewMileageHistories: MileageHistoryTypeConnection
  /** 리뷰 이미지 */
  reviewReviewImages: ReviewImageTypeConnection
  /** 평점 */
  score: Scalars['Float']
  seller?: Maybe<SellerType>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 구매자 */
  user?: Maybe<UserType>
  username?: Maybe<Scalars['String']>
  /** 관리자가 부여하는 가중치로 50이 기본 값. 높을 수록 높은 우선 순위임 */
  weight: Scalars['Int']
}

export type ReviewTypeLikedUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  agreeMarketing?: InputMaybe<Scalars['Boolean']>
  agreeMarketingHospital?: InputMaybe<Scalars['Boolean']>
  agreeMarketingPlay?: InputMaybe<Scalars['Boolean']>
  agreeNightMarketing?: InputMaybe<Scalars['Boolean']>
  agreeServicePushAllowed?: InputMaybe<Scalars['Boolean']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  dateJoinedAfter?: InputMaybe<Scalars['DateTime']>
  dateJoinedBefore?: InputMaybe<Scalars['DateTime']>
  email?: InputMaybe<Scalars['String']>
  excludeUsers?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  grades?: InputMaybe<Scalars['String']>
  inactivatedAtAfter?: InputMaybe<Scalars['DateTime']>
  inactivatedAtBefore?: InputMaybe<Scalars['DateTime']>
  inactiveUserEmail?: InputMaybe<Scalars['String']>
  inactiveUserMobileNumber?: InputMaybe<Scalars['String']>
  inactiveUserName?: InputMaybe<Scalars['String']>
  inactiveUserUsername?: InputMaybe<Scalars['String']>
  isBuyer?: InputMaybe<Scalars['Boolean']>
  isInfluencer?: InputMaybe<Scalars['Boolean']>
  isLeft?: InputMaybe<Scalars['Boolean']>
  isSeller?: InputMaybe<Scalars['Boolean']>
  isStaff?: InputMaybe<Scalars['Boolean']>
  joinedPlatform?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  lastLoginAfter?: InputMaybe<Scalars['DateTime']>
  lastLoginBefore?: InputMaybe<Scalars['DateTime']>
  leftAtAfter?: InputMaybe<Scalars['DateTime']>
  leftAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipRefundStatus?: InputMaybe<Scalars['String']>
  membershipStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipStartedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipStatus?: InputMaybe<Scalars['String']>
  mileagePointLess?: InputMaybe<Scalars['Float']>
  mileagePointMore?: InputMaybe<Scalars['Float']>
  mobileNumber?: InputMaybe<Scalars['String']>
  mobileNumbers?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderAmountLess?: InputMaybe<Scalars['Float']>
  orderAmountMore?: InputMaybe<Scalars['Float']>
  orderBy?: InputMaybe<Scalars['String']>
  orderCountLess?: InputMaybe<Scalars['Float']>
  orderCountMore?: InputMaybe<Scalars['Float']>
  requiredUsername?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  socialType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userNumber?: InputMaybe<Scalars['String']>
  userStatus?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type ReviewTypeReviewMileageHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isMembership?: InputMaybe<Scalars['Boolean']>
  isPlus?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mileageType?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
}

export type ReviewTypeReviewReviewImagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  product?: InputMaybe<Scalars['String']>
}

export type ReviewTypeConnection = {
  __typename?: 'ReviewTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReviewTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ReviewType` and its cursor. */
export type ReviewTypeEdge = {
  __typename?: 'ReviewTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ReviewType>
}

export type SearchBrandConnection = {
  __typename?: 'SearchBrandConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SearchBrandEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `SearchBrand` and its cursor. */
export type SearchBrandEdge = {
  __typename?: 'SearchBrandEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SearchBrandType>
}

export type SearchBrandType = {
  __typename?: 'SearchBrandType'
  id?: Maybe<Scalars['ID']>
  listImage?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type SearchCategoryConnection = {
  __typename?: 'SearchCategoryConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SearchCategoryEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `SearchCategory` and its cursor. */
export type SearchCategoryEdge = {
  __typename?: 'SearchCategoryEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SearchCategoryType>
}

export type SearchCategoryType = {
  __typename?: 'SearchCategoryType'
  categoryChildren?: Maybe<SearchCategoryConnection>
  code?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type SearchCategoryTypeCategoryChildrenArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SearchHistoryType = Node & {
  __typename?: 'SearchHistoryType'
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType: AdPetType
  /** 검색키워드 */
  searchKeyword: Scalars['String']
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SearchHistoryTypeConnection = {
  __typename?: 'SearchHistoryTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SearchHistoryTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `SearchHistoryType` and its cursor. */
export type SearchHistoryTypeEdge = {
  __typename?: 'SearchHistoryTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SearchHistoryType>
}

export type SearchProductConnection = {
  __typename?: 'SearchProductConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SearchProductEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `SearchProduct` and its cursor. */
export type SearchProductEdge = {
  __typename?: 'SearchProductEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SearchProductType>
}

export type SearchProductImageType = {
  __typename?: 'SearchProductImageType'
  id?: Maybe<Scalars['ID']>
  thumbnail?: Maybe<Scalars['String']>
}

export type SearchProductImageTypeThumbnailArgs = {
  productImageType?: InputMaybe<Scalars['String']>
}

export type SearchProductPromotionType = {
  __typename?: 'SearchProductPromotionType'
  id?: Maybe<Scalars['ID']>
}

export type SearchProductType = {
  __typename?: 'SearchProductType'
  id?: Maybe<Scalars['ID']>
}

export type SearchProductsItemType = {
  __typename?: 'SearchProductsItemType'
  averageReviewScore?: Maybe<Scalars['Float']>
  brand?: Maybe<SearchBrandType>
  categories?: Maybe<SearchCategoryConnection>
  customerPrice?: Maybe<Scalars['Decimal']>
  discountPrice?: Maybe<Scalars['Decimal']>
  discountRate?: Maybe<Scalars['Float']>
  finalDiscountRate?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['ID']>
  isRealSoldOut?: Maybe<Scalars['Boolean']>
  isSoldOut?: Maybe<Scalars['Boolean']>
  isTimesale?: Maybe<Scalars['Boolean']>
  mainImage?: Maybe<SearchProductImageType>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Decimal']>
  productNumber?: Maybe<Scalars['String']>
  productPromotion?: Maybe<SearchProductPromotionType>
  productPromotionCustomerPrice?: Maybe<Scalars['Decimal']>
  productPromotionDiscountRate?: Maybe<Scalars['Float']>
  reviewCount?: Maybe<Scalars['Int']>
}

export type SearchProductsItemTypeCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SearchProductsWithDetailsConnection = {
  __typename?: 'SearchProductsWithDetailsConnection'
  brands?: Maybe<SearchBrandConnection>
  categories?: Maybe<SearchCategoryConnection>
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SearchProductsWithDetailsEdge>>
  maxPrice?: Maybe<Scalars['Int']>
  pageInfo?: Maybe<PageInfo>
  soldOutCount?: Maybe<Scalars['Int']>
  totalCount?: Maybe<Scalars['Int']>
}

export type SearchProductsWithDetailsConnectionBrandsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type SearchProductsWithDetailsConnectionCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** A Relay edge containing a `SearchProductsWithDetails` and its cursor. */
export type SearchProductsWithDetailsEdge = {
  __typename?: 'SearchProductsWithDetailsEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SearchProductsItemType>
}

export type SellerAddressType = Node & {
  __typename?: 'SellerAddressType'
  /** 기본 주소 */
  basicAddress: Scalars['String']
  /** 상세 주소 */
  detailedAddress?: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 우편번호 */
  postNumber: Scalars['String']
  /** 수거지 주소 */
  returnAddressShippingPreset: ShippingPresetTypeConnection
  /** 출고지 주소 */
  warehouseAddressShippingPreset: ShippingPresetTypeConnection
}

export type SellerAddressTypeReturnAddressShippingPresetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type SellerAddressTypeWarehouseAddressShippingPresetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type SellerAddressTypeConnection = {
  __typename?: 'SellerAddressTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SellerAddressTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `SellerAddressType` and its cursor. */
export type SellerAddressTypeEdge = {
  __typename?: 'SellerAddressTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SellerAddressType>
}

/** An enumeration. */
export enum SellerBillingBillingType {
  /** BANK_TRANSFER */
  BankTransfer = 'BANK_TRANSFER',
}

export type SellerBillingType = Node & {
  __typename?: 'SellerBillingType'
  /** 정산 기준 일자, 년-월만 사용 */
  baseAt?: Maybe<Scalars['DateTime']>
  /** 정산 예정일 */
  billedAt?: Maybe<Scalars['DateTime']>
  /** 정산종료일 */
  billingEndedAt?: Maybe<Scalars['DateTime']>
  /** 정산시작일 */
  billingStartedAt?: Maybe<Scalars['DateTime']>
  /** 정산 방식 */
  billingType: SellerBillingBillingType
  /** 정산 대상 브랜드 */
  brand?: Maybe<BrandType>
  /** 구매 확정 시 적립액 합 */
  confirmMileageAmount: Scalars['Decimal']
  /** 정산 확인일 */
  confirmedAt?: Maybe<Scalars['DateTime']>
  /** 상품 쿠폰할인액 합 */
  couponDiscountAmount: Scalars['Decimal']
  /** 총 판매금액 */
  customerAmount?: Maybe<Scalars['Decimal']>
  /** 반품수수료 정산금액 */
  customerReturnFees: Scalars['Decimal']
  /** 기본 배송비 */
  customerShippingFees: Scalars['Decimal']
  /** fitpet 수수료 금액 */
  fitpetAmount?: Maybe<Scalars['Decimal']>
  /** fitpet 판매촉진비 */
  fitpetPromotionAmount: Scalars['Decimal']
  /** fitpet 매출 */
  fitpetSupplyAmount: Scalars['Decimal']
  /** The ID of the object. */
  id: Scalars['ID']
  /** 이미지 리뷰 작성 시 적립액 합 */
  imageReviewMileageAmount: Scalars['Decimal']
  /** 멤버쉽 회원 구매 확정 시 적립액 합 */
  membershipConfirmMileageAmount: Scalars['Decimal']
  /** 멤버쉽 할인액 합 */
  membershipDiscountAmount: Scalars['Decimal']
  /** 멤버쉽 회원 이미지 리뷰 작성 시 적립액 합 */
  membershipImageReviewMileageAmount: Scalars['Decimal']
  /** 멤버쉽 회원 리뷰 작성 시 적립액 합 */
  membershipReviewMileageAmount: Scalars['Decimal']
  /** 적립금 할인액 합 */
  mileageDiscountAmount: Scalars['Decimal']
  /** 다음달 예상 정산일 */
  nextBillingAt?: Maybe<Scalars['DateTime']>
  /** 리뷰 작성 시 적립액 합 */
  reviewMileageAmount: Scalars['Decimal']
  /** 정산 대상 셀러 */
  seller?: Maybe<SellerType>
  /** 상품 수수료 금액 */
  sellerAmount?: Maybe<Scalars['Decimal']>
  /** 셀러 정산 */
  sellerBillingItems: BillingItemTypeConnection
  /** 셀러 매출(상품 정산금액) */
  sellerSupplyAmount: Scalars['Decimal']
  /** 셀러 총 정산금액 */
  sellerTotalAmount?: Maybe<Scalars['Decimal']>
  /** 배송비 정산 금액 */
  shippingBillingAmount?: Maybe<Scalars['Decimal']>
  /** 배송비 쿠폰 할인 금액 */
  shippingCouponDiscountAmount: Scalars['Decimal']
  /** 배송비 할인 금액 */
  shippingDiscountAmount: Scalars['Decimal']
  /** 배송비 적립금 할인 금액 */
  shippingMileageDiscountAmount: Scalars['Decimal']
  /** 공급가액 */
  supplyAmount?: Maybe<Scalars['Decimal']>
  /** 부가세액 */
  valuedAddedTaxAmount?: Maybe<Scalars['Decimal']>
}

export type SellerBillingTypeSellerBillingItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  confirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  confirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeFitpetSeller?: InputMaybe<Scalars['Boolean']>
  excludeMileageBilling?: InputMaybe<Scalars['Boolean']>
  excludeSeller?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  hasSellerFee?: InputMaybe<Scalars['Boolean']>
  isSellerBilled?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItemNumber?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerBilledAtAfter?: InputMaybe<Scalars['DateTime']>
  sellerBilledAtBefore?: InputMaybe<Scalars['DateTime']>
  sellerChiefManagerName?: InputMaybe<Scalars['String']>
  sellerFeeGte?: InputMaybe<Scalars['Float']>
  sellerName?: InputMaybe<Scalars['String']>
}

export type SellerBillingTypeConnection = {
  __typename?: 'SellerBillingTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SellerBillingTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `SellerBillingType` and its cursor. */
export type SellerBillingTypeEdge = {
  __typename?: 'SellerBillingTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SellerBillingType>
}

export type SellerManagerInput = {
  /** 셀러 */
  seller: Scalars['ID']
  /** 셀러 매니저인 사용자 */
  user: Scalars['ID']
}

export type SellerNotificationInput = {
  /** 알림을 수신받을 이메일 */
  notificationEmail: Scalars['String']
  /** 알림 수신받을 담당자 명 */
  notificationManagerName: Scalars['String']
  /** 알림톡 수신받을 핸드폰번호 */
  notificationMobileNumber: Scalars['String']
}

export type SellerStatisticColumnType = {
  __typename?: 'SellerStatisticColumnType'
  avgProductPrice?: Maybe<Scalars['Int']>
  brandName?: Maybe<Scalars['String']>
  guestUnitPrice?: Maybe<Scalars['Int']>
  sellerName?: Maybe<Scalars['String']>
  totalAmount?: Maybe<Scalars['Int']>
  totalCouponDiscountAmount?: Maybe<Scalars['Int']>
  totalCustomerCount?: Maybe<Scalars['Int']>
  totalMileageDiscountAmount?: Maybe<Scalars['Int']>
  totalOrderCount?: Maybe<Scalars['Int']>
  totalSoldAmount?: Maybe<Scalars['Int']>
  totalSoldCount?: Maybe<Scalars['Int']>
}

export type SellerStatisticColumnTypeConnection = {
  __typename?: 'SellerStatisticColumnTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SellerStatisticColumnTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `SellerStatisticColumnType` and its cursor. */
export type SellerStatisticColumnTypeEdge = {
  __typename?: 'SellerStatisticColumnTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SellerStatisticColumnType>
}

export type SellerStatusCountType = {
  __typename?: 'SellerStatusCountType'
  approvedCount?: Maybe<Scalars['Int']>
  bannedCount?: Maybe<Scalars['Int']>
  deletedCount?: Maybe<Scalars['Int']>
  rejectedCount?: Maybe<Scalars['Int']>
  requestCount?: Maybe<Scalars['Int']>
  suspendCount?: Maybe<Scalars['Int']>
  totalCount?: Maybe<Scalars['Int']>
  updateRejectedCount?: Maybe<Scalars['Int']>
  updateRequestCount?: Maybe<Scalars['Int']>
}

export type SellerTemplateAddressType = Node & {
  __typename?: 'SellerTemplateAddressType'
  /** 기본 주소 */
  basicAddress: Scalars['String']
  /** 상세 주소 */
  detailedAddress?: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 기본 주소지 여부 */
  isDefault: Scalars['Boolean']
  /** 우편번호 */
  postNumber: Scalars['String']
  /** 셀러 */
  seller?: Maybe<SellerType>
}

export type SellerTemplateAddressTypeConnection = {
  __typename?: 'SellerTemplateAddressTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SellerTemplateAddressTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `SellerTemplateAddressType` and its cursor. */
export type SellerTemplateAddressTypeEdge = {
  __typename?: 'SellerTemplateAddressTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SellerTemplateAddressType>
}

export type SellerType = Node & {
  __typename?: 'SellerType'
  /** 예금주명 */
  accountHolderName?: Maybe<Scalars['String']>
  /** 정산 담당자명 */
  accountManagerName: Scalars['String']
  /** 정산 담당자 연락처 */
  accountManagerTelephoneNumber: Scalars['String']
  /** 계좌번호 */
  accountNumber?: Maybe<Scalars['String']>
  /** 판매대행신고여부동의일시 */
  agentReportedAt?: Maybe<Scalars['DateTime']>
  /** 승인일 */
  approvedAt?: Maybe<Scalars['DateTime']>
  /** 통장 사본 */
  bankBookImage?: Maybe<Scalars['String']>
  /** 은행 코드 */
  bankCode?: Maybe<Scalars['String']>
  /** 은행 이름 */
  bankName?: Maybe<Scalars['String']>
  /** 사업장 기본주소 */
  basicAddress: Scalars['String']
  /** 업태 */
  businessCondition: Scalars['String']
  /** 종목 */
  businessItem: Scalars['String']
  /** 사업자등록증 */
  businessRegistration?: Maybe<Scalars['String']>
  /** 사업자 번호 */
  businessRegistrationNumber: Scalars['String']
  chiefManager?: Maybe<UserType>
  /** 사업자 번호 */
  companyId: Scalars['String']
  /** 회사명 */
  companyName: Scalars['String']
  /** 대표자 이름 */
  companyOwnerName: Scalars['String']
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 사업장 상세주소 */
  detailedAddress: Scalars['String']
  /** 핏펫 정액 수수료 */
  fitpetFeeAmount?: Maybe<Scalars['Decimal']>
  /** 핏펫 수수료 */
  fitpetFeeRate?: Maybe<Scalars['Float']>
  /** 홈페이지 주소 */
  homepage: Scalars['String']
  /** The ID of the object. */
  id: Scalars['ID']
  /** 판매대행신고여부 */
  isAgentReported: Scalars['Boolean']
  isFitpet?: Maybe<Scalars['Boolean']>
  /** deprecated. 사용하지 마세요 */
  isFollowed?: Maybe<Scalars['Boolean']>
  /** 통신판매업신고번호 */
  mailOrderReportNumber: Scalars['String']
  /** 통신판매업신고증 */
  mailOrderReportRegistration?: Maybe<Scalars['String']>
  /** 알림을 수신받을 이메일 */
  notificationEmail?: Maybe<Scalars['String']>
  /** 알림 수신받을 담당자 명 */
  notificationManagerName: Scalars['String']
  /** 알림톡 수신받을 핸드폰번호 */
  notificationMobileNumber: Scalars['String']
  /** 사업장 우편번호 */
  postNumber: Scalars['String']
  /** 해당 셀러가 현재 팔고있는 상품 수 */
  productCount?: Maybe<Scalars['Int']>
  /** 최종 신청일 */
  requestedAt?: Maybe<Scalars['DateTime']>
  /** 교환/환불 안내 */
  returnExchangeGuide?: Maybe<Scalars['String']>
  /** 사방넷 셀러 토큰 */
  sabangnetToken?: Maybe<Scalars['String']>
  /** 셀러 */
  sellerAddresses: SellerTemplateAddressTypeConnection
  /** 셀러 */
  sellerAsInfos: AsInfoTypeConnection
  /** 브랜드를 소유한 셀러들 */
  sellerBrands: BrandTypeConnection
  sellerExcelRequests: ExcelExportRequestTypeConnection
  /** 셀러 */
  sellerProducts: ProductTypeConnection
  /** 배송 셀러 */
  sellerSkus: SkuTypeConnection
  sellerTemplateAddresses?: Maybe<SellerTemplateAddressTypeConnection>
  /** 셀러 */
  sellerUsers: SellerUserTypeConnection
  /** 셀러샵 이름 */
  shopName: Scalars['String']
  soldAmount?: Maybe<Scalars['Decimal']>
  soldQuantity?: Maybe<Scalars['Int']>
  soldQuantityLastMonth?: Maybe<Scalars['Int']>
  /** 입점 신청 요청 상태, REQUEST(요청)/APPROVED(승인)/REJECTED(거부)/SUSPENDED(중단) */
  status?: Maybe<StatusType>
  /** 세금계산서 발급용 이메일 */
  taxEmail?: Maybe<Scalars['String']>
  /** 회사 대표 전화번호 */
  telephoneNumber: Scalars['String']
  /** 정보 변경 요청 승인일 */
  updateApprovedAt?: Maybe<Scalars['DateTime']>
  updateInfo?: Maybe<UpdatedSellerType>
  /** 수정 상태, REQUEST(요청)/APPROVED(승인)/REJECTED(거부) */
  updateStatus?: Maybe<StatusType>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 배송 셀러 */
  userShippingPresets: ShippingPresetTypeConnection
  users: UserTypeConnection
}

export type SellerTypeSellerAddressesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type SellerTypeSellerAsInfosArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type SellerTypeSellerBrandsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeBrands?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  firstLetter?: InputMaybe<Scalars['String']>
  isApproved?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  seller?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
}

export type SellerTypeSellerExcelRequestsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  seller?: InputMaybe<Scalars['String']>
}

export type SellerTypeSellerProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type SellerTypeSellerSkusArgs = {
  after?: InputMaybe<Scalars['String']>
  barcodeNumber?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeSkus?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
}

export type SellerTypeSellerTemplateAddressesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type SellerTypeSellerUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  seller?: InputMaybe<Scalars['String']>
  shopName?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
}

export type SellerTypeUserShippingPresetsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type SellerTypeUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  agreeMarketing?: InputMaybe<Scalars['Boolean']>
  agreeMarketingHospital?: InputMaybe<Scalars['Boolean']>
  agreeMarketingPlay?: InputMaybe<Scalars['Boolean']>
  agreeNightMarketing?: InputMaybe<Scalars['Boolean']>
  agreeServicePushAllowed?: InputMaybe<Scalars['Boolean']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  dateJoinedAfter?: InputMaybe<Scalars['DateTime']>
  dateJoinedBefore?: InputMaybe<Scalars['DateTime']>
  email?: InputMaybe<Scalars['String']>
  excludeUsers?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  grades?: InputMaybe<Scalars['String']>
  inactivatedAtAfter?: InputMaybe<Scalars['DateTime']>
  inactivatedAtBefore?: InputMaybe<Scalars['DateTime']>
  inactiveUserEmail?: InputMaybe<Scalars['String']>
  inactiveUserMobileNumber?: InputMaybe<Scalars['String']>
  inactiveUserName?: InputMaybe<Scalars['String']>
  inactiveUserUsername?: InputMaybe<Scalars['String']>
  isBuyer?: InputMaybe<Scalars['Boolean']>
  isInfluencer?: InputMaybe<Scalars['Boolean']>
  isLeft?: InputMaybe<Scalars['Boolean']>
  isSeller?: InputMaybe<Scalars['Boolean']>
  isStaff?: InputMaybe<Scalars['Boolean']>
  joinedPlatform?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  lastLoginAfter?: InputMaybe<Scalars['DateTime']>
  lastLoginBefore?: InputMaybe<Scalars['DateTime']>
  leftAtAfter?: InputMaybe<Scalars['DateTime']>
  leftAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipFirstSubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipLastUnsubscribedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipRefundStatus?: InputMaybe<Scalars['String']>
  membershipStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  membershipStartedAtBefore?: InputMaybe<Scalars['DateTime']>
  membershipStatus?: InputMaybe<Scalars['String']>
  mileagePointLess?: InputMaybe<Scalars['Float']>
  mileagePointMore?: InputMaybe<Scalars['Float']>
  mobileNumber?: InputMaybe<Scalars['String']>
  mobileNumbers?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderAmountLess?: InputMaybe<Scalars['Float']>
  orderAmountMore?: InputMaybe<Scalars['Float']>
  orderBy?: InputMaybe<Scalars['String']>
  orderCountLess?: InputMaybe<Scalars['Float']>
  orderCountMore?: InputMaybe<Scalars['Float']>
  requiredUsername?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  socialType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userNumber?: InputMaybe<Scalars['String']>
  userStatus?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type SellerTypeConnection = {
  __typename?: 'SellerTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SellerTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `SellerType` and its cursor. */
export type SellerTypeEdge = {
  __typename?: 'SellerTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SellerType>
}

/** An enumeration. */
export enum SellerUserAuthType {
  /** CHIEF */
  Chief = 'CHIEF',
  /** MANAGER */
  Manager = 'MANAGER',
}

export type SellerUserType = Node & {
  __typename?: 'SellerUserType'
  /** 셀러 메니저의 권한 */
  authType: SellerUserAuthType
  /** The ID of the object. */
  id: Scalars['ID']
  /** 셀러 */
  seller?: Maybe<SellerType>
  /** 셀러 매니저인 사용자 */
  user?: Maybe<UserType>
}

export type SellerUserTypeConnection = {
  __typename?: 'SellerUserTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SellerUserTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `SellerUserType` and its cursor. */
export type SellerUserTypeEdge = {
  __typename?: 'SellerUserTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SellerUserType>
}

export type SendEmailInput = {
  /** E-mail 전송대상 리스트 excel file */
  addressExcelFile?: InputMaybe<Scalars['Upload']>
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>
  /** 메일 주소 리스트 */
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /** grade 등급 id */
  grade?: InputMaybe<Scalars['ID']>
  /** 마케팅 이메일 수신 여부 */
  isMarketing?: InputMaybe<Scalars['Boolean']>
  /** 발송예정일 */
  sendAt?: InputMaybe<Scalars['DateTime']>
  /** 이메일 제목 */
  subject: Scalars['String']
  /** 대상 회원 목록 종류 */
  targetKey?: InputMaybe<Scalars['String']>
  /** 내용 */
  text: Scalars['String']
  /** 본문 제목 */
  title: Scalars['String']
}

/** 수동 Email 전송 */
export type SendEmailMutation = {
  __typename?: 'SendEmailMutation'
  informEmailQueue?: Maybe<InformEmailQueueType>
}

export type SendPushInput = {
  /** E-mail 전송대상 리스트 excel file */
  addressExcelFile?: InputMaybe<Scalars['Upload']>
  /** grade 등급 id */
  grade?: InputMaybe<Scalars['ID']>
  image?: InputMaybe<Scalars['Upload']>
  /** 마케팅 푸쉬 여부 */
  isMarketing: Scalars['Boolean']
  /** 테스트 전송 */
  isTest?: InputMaybe<Scalars['Boolean']>
  /** 관리자용 메모 */
  memo?: InputMaybe<Scalars['String']>
  /** 발송예정일 */
  sendAt?: InputMaybe<Scalars['DateTime']>
  /** 대상 회원 목록 종류 */
  targetKey?: InputMaybe<Scalars['String']>
  /** 푸쉬에 표시될 내용 */
  text: Scalars['String']
  /** 푸쉬에 표시될 제목 */
  title: Scalars['String']
  /** URL */
  url?: InputMaybe<Scalars['String']>
  /** 사용자 GID 리스트, 수동 발송 시 사용 */
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
}

/** Push 전송 */
export type SendPushMutation = {
  __typename?: 'SendPushMutation'
  informPushQueue?: Maybe<InformPushQueueType>
}

export type SendSmsInput = {
  /** E-mail 전송대상 리스트 excel file */
  addressExcelFile?: InputMaybe<Scalars['Upload']>
  /** grade 등급 id */
  grade?: InputMaybe<Scalars['ID']>
  /** 마케팅 문자 수신 여부 */
  isMarketing?: InputMaybe<Scalars['Boolean']>
  /** 핸드폰 번호 리스트 */
  mobileNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  /** 발송예정일 */
  sendAt?: InputMaybe<Scalars['DateTime']>
  /** 대상 회원 목록 종류 */
  targetKey?: InputMaybe<Scalars['String']>
  /** 내용 */
  text: Scalars['String']
}

/** 수동 SMS 전송 */
export type SendSmsMutation = {
  __typename?: 'SendSmsMutation'
  informSmsQueue?: Maybe<InformSmsQueueType>
}

export type SendUserCertNumberInput = {
  /** 인증 타입, NEW_USER: 신규회원가입, RESET_PASSWORD_BY_MOBILE_NUMBER: 핸드폰번호로 비밀번호찾기, REST_PASSWORD_BY_EMAIL: 이메일로 비밀번호찾기, CHANGE_MOBILE_NUMBER: 핸드폰 번호 변경, FIND_ID_BY_MOBILE_NUMBER: 아이디찾기 */
  certType: Scalars['String']
  /** 사용자 이메일 */
  email?: InputMaybe<Scalars['String']>
  /** 사용자 핸드폰 번호 */
  mobileNumber?: InputMaybe<Scalars['String']>
  /** 핸드폰번호 변경시만 사용, 사용자 GID */
  user?: InputMaybe<Scalars['ID']>
}

/** 모바일/이메일 인증 번호 전송 */
export type SendUserCertNumberMutation = {
  __typename?: 'SendUserCertNumberMutation'
  userCertification?: Maybe<UserCertificationType>
}

export type ServerConfigInput = {
  /** 설정 타입 */
  configType?: InputMaybe<Scalars['String']>
  /** 설명 */
  description?: InputMaybe<Scalars['String']>
  /** 종료일 */
  endedAt?: InputMaybe<Scalars['String']>
  /** 설정 키 */
  key: Scalars['String']
  /** 시작일 */
  startedAt?: InputMaybe<Scalars['String']>
  /** 설정 값 */
  value: Scalars['String']
}

export type ServerConfigType = Node & {
  __typename?: 'ServerConfigType'
  /** 설정 타입 */
  configType?: Maybe<Scalars['String']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 설명 */
  description?: Maybe<Scalars['String']>
  /** 종료일 */
  endedAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 설정 키 */
  key: Scalars['String']
  /** 시작일 */
  startedAt?: Maybe<Scalars['DateTime']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 설정 값 */
  value: Scalars['JSONString']
}

export type ServerConfigTypeConnection = {
  __typename?: 'ServerConfigTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ServerConfigTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ServerConfigType` and its cursor. */
export type ServerConfigTypeEdge = {
  __typename?: 'ServerConfigTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ServerConfigType>
}

/** An enumeration. */
export enum ServiceQnaAnsweredUserType {
  /** ADMIN */
  Admin = 'ADMIN',
  /** NONE */
  None = 'NONE',
  /** SELLER */
  Seller = 'SELLER',
}

/** An enumeration. */
export enum ServiceQnaCategory {
  /** ETC */
  Etc = 'ETC',
  /** EVENT_PLAN */
  EventPlan = 'EVENT_PLAN',
  /** MEMBER_INFO */
  MemberInfo = 'MEMBER_INFO',
  /** ORDER */
  Order = 'ORDER',
  /** ORDER_ETC */
  OrderEtc = 'ORDER_ETC',
  /** REFUND */
  Refund = 'REFUND',
  /** SHIPPING */
  Shipping = 'SHIPPING',
}

export type ServiceQnaImageType = Node & {
  __typename?: 'ServiceQnaImageType'
  /** The ID of the object. */
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  /** 서비스 문의 이미지들 */
  serviceQna?: Maybe<ServiceQnaType>
  thumbnail?: Maybe<Scalars['String']>
}

export type ServiceQnaImageTypeThumbnailArgs = {
  serviceQnaImageType?: InputMaybe<Scalars['String']>
}

export type ServiceQnaImageTypeConnection = {
  __typename?: 'ServiceQnaImageTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ServiceQnaImageTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ServiceQnaImageType` and its cursor. */
export type ServiceQnaImageTypeEdge = {
  __typename?: 'ServiceQnaImageTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ServiceQnaImageType>
}

/** An enumeration. */
export enum ServiceQnaQuestionType {
  /** ORDER */
  Order = 'ORDER',
  /** SERVICE */
  Service = 'SERVICE',
}

export type ServiceQnaType = Node & {
  __typename?: 'ServiceQnaType'
  /** 답변 내용 */
  answerBody?: Maybe<Scalars['String']>
  /** 답변일 */
  answeredAt?: Maybe<Scalars['DateTime']>
  /** 답변자 */
  answeredUser?: Maybe<UserType>
  /** 답변 단 사용자 타입 (SELLER/ADMIN/NONE) */
  answeredUserType: ServiceQnaAnsweredUserType
  /** 상품 옵션 타입 */
  category: ServiceQnaCategory
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 수신 email */
  incomingEmail: Scalars['String']
  isAdmin?: Maybe<Scalars['Boolean']>
  isAnswered?: Maybe<Scalars['Boolean']>
  isPhoto?: Maybe<Scalars['String']>
  /** 답변 연락처 핸드폰 번호 */
  mobileNumber?: Maybe<Scalars['String']>
  /** QnA관련 주문 */
  orderItem?: Maybe<OrderItemType>
  /** 질문 내용 */
  questionBody: Scalars['String']
  /** 상품 옵션 타입 */
  questionType: ServiceQnaQuestionType
  seller?: Maybe<SellerType>
  /** 서비스 문의 이미지들 */
  serviceQnaImages: ServiceQnaImageTypeConnection
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 질문자 */
  user?: Maybe<UserType>
}

export type ServiceQnaTypeServiceQnaImagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ServiceQnaTypeConnection = {
  __typename?: 'ServiceQnaTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ServiceQnaTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ServiceQnaType` and its cursor. */
export type ServiceQnaTypeEdge = {
  __typename?: 'ServiceQnaTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ServiceQnaType>
}

export type ShippingCompanyInput = {
  /** 아임포트 택배사 코드 */
  iamportCode?: InputMaybe<Scalars['String']>
  /** 택배사 이름 */
  name?: InputMaybe<Scalars['String']>
  /** 스윗트래커 택배사 코드 */
  sweetTrackerCode?: InputMaybe<Scalars['String']>
}

export type ShippingCompanyType = Node & {
  __typename?: 'ShippingCompanyType'
  /** 아임포트 택배사 코드 */
  iamportCode: Scalars['String']
  /** The ID of the object. */
  id: Scalars['ID']
  /** 택배회사 삭제 여부 */
  isDelete?: Maybe<Scalars['Boolean']>
  /** 택배사 이름 */
  name: Scalars['String']
  /** 배송회사 */
  shippingCompanyPresets: ShippingPresetTypeConnection
  /** 배송회사 */
  shippingCompanyShippings: ShippingTypeConnection
  /** 스윗트래커 택배사 코드 */
  sweetTrackerCode: Scalars['String']
  /** 토스페이먼츠 택배사 코드 */
  tosspaymentsCode?: Maybe<Scalars['String']>
  /** 토스페이먼츠 택배사 이름 */
  tosspaymentsName?: Maybe<Scalars['String']>
}

export type ShippingCompanyTypeShippingCompanyPresetsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ShippingCompanyTypeShippingCompanyShippingsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ShippingCompanyTypeConnection = {
  __typename?: 'ShippingCompanyTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ShippingCompanyTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ShippingCompanyType` and its cursor. */
export type ShippingCompanyTypeEdge = {
  __typename?: 'ShippingCompanyTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ShippingCompanyType>
}

export type ShippingNumberInput = {
  /** 대상 상품 주문 */
  orderItem: Scalars['ID']
  /** 택배사 */
  shippingCompany?: InputMaybe<Scalars['ID']>
  shippingNumbers: Array<InputMaybe<Scalars['String']>>
}

export type ShippingPresetInput = {
  /** 도서 산간 추가 배송비 적용 여부 */
  applyRemoteAreaFee?: InputMaybe<Scalars['Boolean']>
  /** 무로배송 기준 금액 */
  freeShippingBaseAmount?: InputMaybe<Scalars['Decimal']>
  /** 배송 가이드 */
  guide?: InputMaybe<Scalars['String']>
  /** 묶음 배송 가능 여부 */
  isPackage?: InputMaybe<Scalars['Boolean']>
  /** 배송 프리셋 이름 */
  name: Scalars['String']
  /** 배송 공지 */
  notice?: InputMaybe<Scalars['String']>
  /** 반품지 주소 */
  returnAddress: UpdateAddressInput
  /** 반품비 */
  returnFee?: InputMaybe<Scalars['Decimal']>
  /** 배송회사 */
  shippingCompany: Scalars['ID']
  /** 당일출고 마감시간 */
  shippingDeadline?: InputMaybe<Scalars['Int']>
  /** 운임 */
  shippingFee?: InputMaybe<Scalars['Decimal']>
  /** 출고지 주소 */
  warehouseAddress: UpdateAddressInput
}

export type ShippingPresetType = Node & {
  __typename?: 'ShippingPresetType'
  /** 도서 산간 추가 배송비 적용 여부 */
  applyRemoteAreaFee: Scalars['Boolean']
  /** 브랜드 */
  brandGnbs: GnbTypeConnection
  /** 무로배송 기준 금액 */
  freeShippingBaseAmount?: Maybe<Scalars['Decimal']>
  /** 배송 가이드 */
  guide: Scalars['String']
  /** The ID of the object. */
  id: Scalars['ID']
  /** 묶음 배송 가능 여부 */
  isPackage: Scalars['Boolean']
  /** 착불 불가 여부, 착불 가능일 경우 False */
  isPrepayment: Scalars['Boolean']
  /** 배송 프리셋 이름 */
  name: Scalars['String']
  /** 배송 공지 */
  notice: Scalars['String']
  /** 수거지 주소 */
  returnAddress?: Maybe<SellerAddressType>
  /** 반품비 */
  returnFee?: Maybe<Scalars['Decimal']>
  /** 배송 셀러 */
  seller?: Maybe<SellerType>
  /** 배송회사 */
  shippingCompany?: Maybe<ShippingCompanyType>
  /** 당일출고 마감시간 */
  shippingDeadline?: Maybe<Scalars['Int']>
  /** 운임 */
  shippingFee?: Maybe<Scalars['Decimal']>
  /** 상품 배송/환불 정보 */
  shippingPresetProducts: ProductTypeConnection
  /** 출고지 주소 */
  warehouseAddress?: Maybe<SellerAddressType>
}

export type ShippingPresetTypeBrandGnbsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isActive?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ShippingPresetTypeShippingPresetProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type ShippingPresetTypeConnection = {
  __typename?: 'ShippingPresetTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ShippingPresetTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ShippingPresetType` and its cursor. */
export type ShippingPresetTypeEdge = {
  __typename?: 'ShippingPresetTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ShippingPresetType>
}

/** An enumeration. */
export enum ShippingShippingType {
  /** BY_SELF */
  BySelf = 'BY_SELF',
  /** GENERAL */
  General = 'GENERAL',
  /** QUICK_SERVICE */
  QuickService = 'QUICK_SERVICE',
}

export type ShippingTrackType = {
  __typename?: 'ShippingTrackType'
  shipping?: Maybe<ShippingType>
  /** 스윗트래커 배송조회 결과 */
  shippingTrack?: Maybe<Scalars['JSONString']>
}

export type ShippingType = Node & {
  __typename?: 'ShippingType'
  /** 최종 배송비, 배송비 - 기본 할인 - 쿠폰 할인 - 마일리지 할인 */
  amount?: Maybe<Scalars['Decimal']>
  /** 배송비 - 기본 할인 - 쿠폰 할인 */
  amountWithoutMileage?: Maybe<Scalars['Decimal']>
  availableCoupons?: Maybe<CouponTypeConnection>
  /** 정산 대상 배송 정보 */
  billingitemSet: BillingItemTypeConnection
  /** 배송비 쿠폰 할인액 */
  couponDiscountPrice: Scalars['Decimal']
  /** 사용한 쿠폰 정보 */
  couponInfo?: Maybe<Scalars['JSONString']>
  /** 기본 배송비 할인액 */
  discountPrice: Scalars['Decimal']
  /** 도서산간 추가 배송비 */
  extraFee: Scalars['Decimal']
  /** 기본 배송비 */
  fee: Scalars['Decimal']
  /** 배송비 + 도서산간비 */
  feeWithExtraFee?: Maybe<Scalars['Decimal']>
  /** The ID of the object. */
  id: Scalars['ID']
  isFitpet?: Maybe<Scalars['Boolean']>
  isRefundable?: Maybe<Scalars['Boolean']>
  /** 배송 조회 가능 여부 */
  isTrackable?: Maybe<Scalars['Boolean']>
  /** 배송비 멤버쉽 할인액 */
  membershipDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 배송비 적립금 할인액 */
  mileageDiscountPrice?: Maybe<Scalars['Decimal']>
  /** 수신일 */
  receivedAt?: Maybe<Scalars['DateTime']>
  seller?: Maybe<SellerType>
  /** 발송일 */
  sentAt?: Maybe<Scalars['DateTime']>
  /** 배송회사 */
  shippingCompany?: Maybe<ShippingCompanyType>
  /** 택배사 및 배송번호 조회 */
  shippingCompanyAndNumbers?: Maybe<Scalars['JSONString']>
  /** 쿠폰 적용 배송 */
  shippingCoupons: CouponTypeConnection
  /** 배송비 적립금 할인 차감 */
  shippingMileageHistories: MileageHistoryTypeConnection
  /** 배송 번호 리스트 */
  shippingNumbers?: Maybe<Scalars['JSONString']>
  shippingOrderItems?: Maybe<OrderItemTypeConnection>
  /** 상품 배송/환불 정보 */
  shippingPreset?: Maybe<ShippingPresetType>
  /** 환불된 Shipping */
  shippingRefundShippingInfos: RefundShippingInfoTypeConnection
  /** 배송관련정보 */
  shippingReturnInfos: ReturnInfoTypeConnection
  /** 배송 방법, 택배: GENERAL, 퀵서비스: QUICK_SERVICE, 방문수령: BY_SELF */
  shippingType: ShippingShippingType
  shippingTypeText?: Maybe<Scalars['String']>
  /** 배송비 + 도서산간비, 현재 도서산간 0원이므로 fee와 같음 */
  sumFee?: Maybe<Scalars['Decimal']>
  /** 할인 제외 배송비 */
  sumFeeWithDiscount?: Maybe<Scalars['Decimal']>
  /** 배송 조회 불가 메세지 */
  untrackedReason?: Maybe<Scalars['String']>
}

export type ShippingTypeAvailableCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ShippingTypeBillingitemSetArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  confirmedAtAfter?: InputMaybe<Scalars['DateTime']>
  confirmedAtBefore?: InputMaybe<Scalars['DateTime']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeFitpetSeller?: InputMaybe<Scalars['Boolean']>
  excludeMileageBilling?: InputMaybe<Scalars['Boolean']>
  excludeSeller?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  hasSellerFee?: InputMaybe<Scalars['Boolean']>
  isSellerBilled?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItemNumber?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerBilledAtAfter?: InputMaybe<Scalars['DateTime']>
  sellerBilledAtBefore?: InputMaybe<Scalars['DateTime']>
  sellerChiefManagerName?: InputMaybe<Scalars['String']>
  sellerFeeGte?: InputMaybe<Scalars['Float']>
  sellerName?: InputMaybe<Scalars['String']>
}

export type ShippingTypeShippingCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroup?: InputMaybe<Scalars['String']>
  couponGroupName?: InputMaybe<Scalars['String']>
  couponStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  isUsed?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type ShippingTypeShippingMileageHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isMembership?: InputMaybe<Scalars['Boolean']>
  isPlus?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mileageType?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
}

export type ShippingTypeShippingOrderItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ShippingTypeShippingRefundShippingInfosArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ShippingTypeShippingReturnInfosArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ShippingTypeConnection = {
  __typename?: 'ShippingTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ShippingTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `ShippingType` and its cursor. */
export type ShippingTypeEdge = {
  __typename?: 'ShippingTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ShippingType>
}

export type ShippingsInput = {
  orderItems: Array<InputMaybe<ShippingNumberInput>>
}

export type SignOutInput = {
  /** 현재 로그인된 앱의 버전 */
  appVersion?: InputMaybe<Scalars['String']>
  /** 접속환경: WEB / APP */
  clientType?: InputMaybe<Scalars['String']>
  /** 로그인 유지 여부 */
  keepSignIn?: InputMaybe<Scalars['Boolean']>
  /** 단말의 모델 명(iPhone X 등) */
  modelNumber?: InputMaybe<Scalars['String']>
  /** OS의 버전 정보(4.4.2 등) */
  osVersion?: InputMaybe<Scalars['String']>
  /** OS의 타입: ANDROID, IOS, MOBILE, PC */
  platformType?: InputMaybe<Scalars['String']>
  /** 푸시용 토큰 */
  pushToken?: InputMaybe<Scalars['String']>
  /** 리프레쉬용 토큰 */
  refreshToken?: InputMaybe<Scalars['String']>
  /** 로그인용 토큰 */
  token: Scalars['String']
  /** Device 식별용 UUID */
  uuid: Scalars['String']
}

/** 로그아웃 */
export type SignOutMutation = {
  __typename?: 'SignOutMutation'
  signOut?: Maybe<Scalars['Boolean']>
}

export type SkuHistoryType = Node & {
  __typename?: 'SkuHistoryType'
  /** 수정 후 */
  afterChanges?: Maybe<Scalars['String']>
  /** 수정 전 */
  beforeChanges?: Maybe<Scalars['String']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 작업자 */
  fitpetAdmin?: Maybe<UserType>
  /** The ID of the object. */
  id: Scalars['ID']
  /** SKU */
  sku?: Maybe<SkuType>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type SkuHistoryTypeConnection = {
  __typename?: 'SkuHistoryTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SkuHistoryTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `SkuHistoryType` and its cursor. */
export type SkuHistoryTypeEdge = {
  __typename?: 'SkuHistoryTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SkuHistoryType>
}

export type SkuInput = {
  count: Scalars['Int']
  /** 옵션에 연결된 SKU */
  sku: Scalars['ID']
}

export type SkuType = Node & {
  __typename?: 'SkuType'
  /** 바코드 번호 */
  barcodeNumber?: Maybe<Scalars['String']>
  /** Sku code */
  code: Scalars['String']
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 등록자 */
  createdUser?: Maybe<UserType>
  /** 상품의 대표 소비자가 */
  customerPrice?: Maybe<Scalars['Decimal']>
  /** 정가-판매가 */
  discountPrice: Scalars['Decimal']
  /** The ID of the object. */
  id: Scalars['ID']
  /** 상품명 */
  name: Scalars['String']
  /** 정가/옵션가 */
  price: Scalars['Decimal']
  /** 상품 옵션 SKU들 */
  productOptions: ProductOptionTypeConnection
  /** 매입가 */
  purchasePrice: Scalars['Decimal']
  /** 사방넷 품번 코드 */
  sabangnetProductNumber?: Maybe<Scalars['String']>
  /** 배송 셀러 */
  seller?: Maybe<SellerType>
  /** 옵션에 연결된 SKU */
  skuProductOptionSkus: ProductOptionSkuTypeConnection
  /** SKU */
  skuSkuHistories: SkuHistoryTypeConnection
  /** 재고량 */
  stockQuantity: Scalars['Int']
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 최종 수정자 */
  updatedUser?: Maybe<UserType>
}

export type SkuTypeProductOptionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDelete?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  skus?: InputMaybe<Scalars['String']>
}

export type SkuTypeSkuProductOptionSkusArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type SkuTypeSkuSkuHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  sku?: InputMaybe<Scalars['String']>
}

export type SkuTypeConnection = {
  __typename?: 'SkuTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SkuTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `SkuType` and its cursor. */
export type SkuTypeEdge = {
  __typename?: 'SkuTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SkuType>
}

export type SlimBannerInfoType = {
  __typename?: 'SlimBannerInfoType'
  adId?: Maybe<Scalars['ID']>
  adName?: Maybe<Scalars['String']>
  adSlug?: Maybe<Scalars['String']>
  mobileImage?: Maybe<BannerImageType>
  pcImage?: Maybe<BannerImageType>
}

export type SocialServiceInput = {
  /** 엑세스 토큰 */
  accessToken: Scalars['String']
  /** 액세스 토큰 생존시간 (초) */
  accessTokenTtl?: InputMaybe<Scalars['Int']>
  /** WEB/APP */
  clientType?: InputMaybe<Scalars['String']>
  /** 리프레쉬 토큰 */
  refreshToken?: InputMaybe<Scalars['String']>
  /** 소셜 서비스 타입, NAVER/FACEBOOK/KAKAO/EMAIL */
  socialType: Scalars['String']
  /** 고유 ID */
  uniqueId: Scalars['String']
}

/** An enumeration. */
export enum SocialServiceSocialType {
  /** APPLE */
  Apple = 'APPLE',
  /** EMAIL */
  Email = 'EMAIL',
  /** FACEBOOK */
  Facebook = 'FACEBOOK',
  /** KAKAO */
  Kakao = 'KAKAO',
  /** NAVER */
  Naver = 'NAVER',
}

export type SocialServiceType = Node & {
  __typename?: 'SocialServiceType'
  /** 액세스 토큰 발급 일시 */
  accessTokenIssuedAt?: Maybe<Scalars['DateTime']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 마지막 로그인시간 */
  lastLoggedAt?: Maybe<Scalars['DateTime']>
  /** 소셜 서비스 타입, NAVER/FACEBOOK/KAKAO/EMAIL */
  socialType: SocialServiceSocialType
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 소셜 로그인 대상 회원 */
  user?: Maybe<UserType>
}

export type SocialServiceTypeConnection = {
  __typename?: 'SocialServiceTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SocialServiceTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `SocialServiceType` and its cursor. */
export type SocialServiceTypeEdge = {
  __typename?: 'SocialServiceTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<SocialServiceType>
}

export type SocialSigninInput = {
  device: DeviceInput
  socialService: SocialServiceInput
}

/** 소셜 회원 로그인 */
export type SocialSigninMutation = {
  __typename?: 'SocialSigninMutation'
  device?: Maybe<DeviceType>
  isReactivate?: Maybe<Scalars['Boolean']>
  prevLoggedAt?: Maybe<Scalars['DateTime']>
  user?: Maybe<UserType>
}

export type SocialSignupInput = {
  device: DeviceInput
  socialService: SocialServiceInput
  user: UserSignupInput
}

/** 소셜 회원 가입 */
export type SocialSignupMutation = {
  __typename?: 'SocialSignupMutation'
  device?: Maybe<DeviceType>
  prevLoggedAt?: Maybe<Scalars['DateTime']>
  user?: Maybe<UserType>
}

/** 복수 발송 처리 */
export type StartShippingsMutation = {
  __typename?: 'StartShippingsMutation'
  shippings?: Maybe<Array<Maybe<ShippingType>>>
}

/** An enumeration. */
export enum StatusHistoryRequestType {
  /** BRAND_REGISTRATION */
  BrandRegistration = 'BRAND_REGISTRATION',
  /** BRAND_UPDATE */
  BrandUpdate = 'BRAND_UPDATE',
  /** CAMPAIGN_REGISTRATION */
  CampaignRegistration = 'CAMPAIGN_REGISTRATION',
  /** INFLUENCER_ROLE */
  InfluencerRole = 'INFLUENCER_ROLE',
  /** PRODUCT_REGISTRATION */
  ProductRegistration = 'PRODUCT_REGISTRATION',
  /** PRODUCT_UPDATE */
  ProductUpdate = 'PRODUCT_UPDATE',
  /** SELLER_ROLE */
  SellerRole = 'SELLER_ROLE',
  /** SELLER_UPDATE */
  SellerUpdate = 'SELLER_UPDATE',
}

export type StatusHistoryType = Node & {
  __typename?: 'StatusHistoryType'
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 처리 관리자 */
  fitpetAdmin?: Maybe<UserType>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 처리 사유 */
  message?: Maybe<Scalars['String']>
  /** 요청 타입 */
  requestType: StatusHistoryRequestType
  /** 요청자 */
  requestUser?: Maybe<UserType>
  sellerChiefManager?: Maybe<UserType>
  /** 요청 원장 */
  status?: Maybe<StatusType>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 처리 상태 */
  value: StatusHistoryValue
}

export type StatusHistoryTypeConnection = {
  __typename?: 'StatusHistoryTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StatusHistoryTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `StatusHistoryType` and its cursor. */
export type StatusHistoryTypeEdge = {
  __typename?: 'StatusHistoryTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<StatusHistoryType>
}

/** An enumeration. */
export enum StatusHistoryValue {
  /** APPROVED */
  Approved = 'APPROVED',
  /** BANNED */
  Banned = 'BANNED',
  /** CANCELED */
  Canceled = 'CANCELED',
  /** DELETED */
  Deleted = 'DELETED',
  /** INCOMPLETE */
  Incomplete = 'INCOMPLETE',
  /** REJECTED */
  Rejected = 'REJECTED',
  /** REQUEST */
  Request = 'REQUEST',
  /** SUSPEND */
  Suspend = 'SUSPEND',
}

export type StatusInput = {
  message: Scalars['String']
}

/** An enumeration. */
export enum StatusRequestType {
  /** BRAND_REGISTRATION */
  BrandRegistration = 'BRAND_REGISTRATION',
  /** BRAND_UPDATE */
  BrandUpdate = 'BRAND_UPDATE',
  /** CAMPAIGN_REGISTRATION */
  CampaignRegistration = 'CAMPAIGN_REGISTRATION',
  /** INFLUENCER_ROLE */
  InfluencerRole = 'INFLUENCER_ROLE',
  /** PRODUCT_REGISTRATION */
  ProductRegistration = 'PRODUCT_REGISTRATION',
  /** PRODUCT_UPDATE */
  ProductUpdate = 'PRODUCT_UPDATE',
  /** SELLER_ROLE */
  SellerRole = 'SELLER_ROLE',
  /** SELLER_UPDATE */
  SellerUpdate = 'SELLER_UPDATE',
}

export type StatusType = Node & {
  __typename?: 'StatusType'
  brand?: Maybe<BrandType>
  brandUpdateStatus?: Maybe<BrandType>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  product?: Maybe<ProductType>
  productUpdateStatus?: Maybe<ProductType>
  recentMessage?: Maybe<Scalars['String']>
  /** 요청 타입 */
  requestType: StatusRequestType
  seller?: Maybe<SellerType>
  sellerChiefManager?: Maybe<UserType>
  sellerUpdateStatus?: Maybe<SellerType>
  /** 요청 원장 */
  statusStatusHistories: StatusHistoryTypeConnection
  text?: Maybe<Scalars['String']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 현재 진행 상태 */
  value: StatusValue
}

export type StatusTypeStatusStatusHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Scalars['String']>
  statusExclude?: InputMaybe<Scalars['String']>
}

export type StatusTypeConnection = {
  __typename?: 'StatusTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StatusTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `StatusType` and its cursor. */
export type StatusTypeEdge = {
  __typename?: 'StatusTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<StatusType>
}

/** An enumeration. */
export enum StatusValue {
  /** APPROVED */
  Approved = 'APPROVED',
  /** BANNED */
  Banned = 'BANNED',
  /** CANCELED */
  Canceled = 'CANCELED',
  /** DELETED */
  Deleted = 'DELETED',
  /** INCOMPLETE */
  Incomplete = 'INCOMPLETE',
  /** REJECTED */
  Rejected = 'REJECTED',
  /** REQUEST */
  Request = 'REQUEST',
  /** SUSPEND */
  Suspend = 'SUSPEND',
}

export type StatusesInput = {
  statuses: Array<InputMaybe<Scalars['ID']>>
}

export type StatusesWithMessageInput = {
  message: Scalars['String']
  statuses: Array<InputMaybe<Scalars['ID']>>
}

export type SubRollBannerInfoType = {
  __typename?: 'SubRollBannerInfoType'
  adId?: Maybe<Scalars['ID']>
  adName?: Maybe<Scalars['String']>
  adSlug?: Maybe<Scalars['String']>
  mobileDescription?: Maybe<Scalars['String']>
  mobileImage?: Maybe<BannerImageType>
  pcDescription?: Maybe<Scalars['String']>
  pcImage?: Maybe<BannerImageType>
  title?: Maybe<Scalars['String']>
}

export type SubscribeMembershipFamilyInput = {
  /** 멤버쉽 추천 코드 */
  code: Scalars['String']
}

/** 가족 초대 */
export type SubscribeMembershipFamilyMutation = {
  __typename?: 'SubscribeMembershipFamilyMutation'
  membership?: Maybe<MembershipType>
}

export type SubscribeMembershipInput = {
  /** 결제 카드 */
  creditCard: Scalars['ID']
  /** 다음 결제 간격(n개월) */
  payDuration?: InputMaybe<Scalars['Int']>
}

/** 멤버십 가입 */
export type SubscribeMembershipMutation = {
  __typename?: 'SubscribeMembershipMutation'
  membership?: Maybe<MembershipType>
}

/** An enumeration. */
export enum SupplTypeEnum {
  Buy = 'BUY',
  Consignment = 'CONSIGNMENT',
  None = 'NONE',
  Pb = 'PB',
}

/** 요청 스테이터스 중지 */
export type SuspendRequestMutation = {
  __typename?: 'SuspendRequestMutation'
  status?: Maybe<StatusType>
}

/** 관리자 혹은 사용자에 의해서 권한이 중지(판매 중지중 등에 사용) */
export type SuspendRequestsMutation = {
  __typename?: 'SuspendRequestsMutation'
  statuses?: Maybe<Array<Maybe<StatusType>>>
}

export type TermStatisticColumnType = {
  __typename?: 'TermStatisticColumnType'
  avgProductPrice?: Maybe<Scalars['Int']>
  guestUnitPrice?: Maybe<Scalars['Int']>
  leftUserCount?: Maybe<Scalars['Int']>
  signInUserCount?: Maybe<Scalars['Int']>
  totalAmount?: Maybe<Scalars['Int']>
  totalCouponDiscountAmount?: Maybe<Scalars['Int']>
  totalCustomerCount?: Maybe<Scalars['Int']>
  totalMileageDiscountAmount?: Maybe<Scalars['Int']>
  totalOrderCount?: Maybe<Scalars['Int']>
  totalSoldAmount?: Maybe<Scalars['Int']>
  totalSoldCount?: Maybe<Scalars['Int']>
  totalUserCount?: Maybe<Scalars['Int']>
  truncatedAt?: Maybe<Scalars['Date']>
}

export type TermStatisticColumnTypeConnection = {
  __typename?: 'TermStatisticColumnTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TermStatisticColumnTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `TermStatisticColumnType` and its cursor. */
export type TermStatisticColumnTypeEdge = {
  __typename?: 'TermStatisticColumnTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<TermStatisticColumnType>
}

export type TimeseriesValueType = {
  __typename?: 'TimeseriesValueType'
  x?: Maybe<Scalars['Date']>
  y?: Maybe<Scalars['Int']>
}

/** Category 노출여부 토글 */
export type ToggleCategoryVisibleMutation = {
  __typename?: 'ToggleCategoryVisibleMutation'
  category?: Maybe<CategoryType>
}

/** 상품 찜 여부 토글 */
export type ToggleProductLikedMutation = {
  __typename?: 'ToggleProductLikedMutation'
  product?: Maybe<ProductType>
}

export type ToggleRestockProductOptionInput = {
  productOption: Scalars['ID']
}

/** 유저가 재입고 알림 신청 생성/삭제 토글 */
export type ToggleRestockProductOptionMutation = {
  __typename?: 'ToggleRestockProductOptionMutation'
  restockProductOptionUser?: Maybe<RestockProductOptionUserType>
}

/** 리뷰 공감여부 토글 */
export type ToggleReviewLikedMutation = {
  __typename?: 'ToggleReviewLikedMutation'
  review?: Maybe<ReviewType>
}

export type TokenSigninInput = {
  device: DeviceInput
}

/** Token 로그인 */
export type TokenSigninMutation = {
  __typename?: 'TokenSigninMutation'
  device?: Maybe<DeviceType>
  prevLoggedAt?: Maybe<Scalars['DateTime']>
  user?: Maybe<UserType>
}

export type TokenTypeInput = {
  /** 토큰 타입(명사 - USER_DICT, 유사어 - SYNONYM) */
  tokenType?: InputMaybe<Scalars['String']>
}

export type TopRollBannerInfoType = {
  __typename?: 'TopRollBannerInfoType'
  adId?: Maybe<Scalars['ID']>
  adName?: Maybe<Scalars['String']>
  adSlug?: Maybe<Scalars['String']>
  benefitText?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  eventTypeText?: Maybe<Scalars['String']>
  mobileImage?: Maybe<BannerImageType>
  pcImage?: Maybe<BannerImageType>
  subjectText?: Maybe<Scalars['String']>
}

export type TotalInfluencerStatisticType = {
  __typename?: 'TotalInfluencerStatisticType'
  totalInfluencerCount?: Maybe<Scalars['Int']>
  totalSoldAmount?: Maybe<Scalars['Int']>
  totalSoldCount?: Maybe<Scalars['Int']>
}

export type TotalSalesStatisticType = {
  __typename?: 'TotalSalesStatisticType'
  avgProductPrice?: Maybe<Scalars['Int']>
  guestUnitPrice?: Maybe<Scalars['Int']>
  totalAmount?: Maybe<Scalars['Int']>
  totalCouponDiscountAmount?: Maybe<Scalars['Int']>
  totalCustomerCount?: Maybe<Scalars['Int']>
  totalMileageDiscountAmount?: Maybe<Scalars['Int']>
  totalOrderCount?: Maybe<Scalars['Int']>
  totalSoldAmount?: Maybe<Scalars['Int']>
  totalSoldCount?: Maybe<Scalars['Int']>
}

export type TotalSellerStatisticType = {
  __typename?: 'TotalSellerStatisticType'
  totalOrderCount?: Maybe<Scalars['Int']>
  totalSellerCount?: Maybe<Scalars['Int']>
  totalSoldAmount?: Maybe<Scalars['Int']>
  totalSoldCount?: Maybe<Scalars['Int']>
}

/** An enumeration. */
export enum TransactionPayAppType {
  /** 차이페이 */
  Chaipay = 'CHAIPAY',
  /** 기타 */
  Etc = 'ETC',
  /** 카카오페이 */
  Kakaopay = 'KAKAOPAY',
  /** 케이비페이 */
  Kbpay = 'KBPAY',
  /** 국민앱카드 */
  KbAppcard = 'KB_APPCARD',
  /** KPAY */
  Kpay = 'KPAY',
  /** L.pay */
  Lpay = 'LPAY',
  /** 네이버페이 */
  Naverpay = 'NAVERPAY',
  /** 없음 */
  None = 'NONE',
  /** 페이코 */
  Payco = 'PAYCO',
  /** 삼성페이(체크) */
  SamsumgPayCheck = 'SAMSUMG_PAY_CHECK',
  /** 삼성페이 */
  SamsungPay = 'SAMSUNG_PAY',
  /** 쓱베이 */
  Ssgpay = 'SSGPAY',
  /** 티머니페이 */
  Tmoneypay = 'TMONEYPAY',
  /** 토스페이 */
  Tosspay = 'TOSSPAY',
}

/** An enumeration. */
export enum TransactionPgProviderType {
  /** 알리페이 */
  Alipay = 'ALIPAY',
  /** 블루월넛 */
  Bluewalnut = 'BLUEWALNUT',
  /** 차이 간편결제 */
  Chai = 'CHAI',
  /** 다날휴대폰소액결제 */
  Danal = 'DANAL',
  /** 다날일반결제 */
  DanalTpay = 'DANAL_TPAY',
  /** 엑심베이 */
  Eximbay = 'EXIMBAY',
  /** 핏펫 */
  Fitpet = 'FITPET',
  /** 이니시스웹표준 */
  Html5Inicis = 'HTML5_INICIS',
  /** JTNet */
  Jtnet = 'JTNET',
  /** 카카오페이 */
  Kakaopay = 'KAKAOPAY',
  /** NHN KCP */
  Kcp = 'KCP',
  /** 한국정보통신 */
  Kicc = 'KICC',
  /** 모빌리언스 휴대폰소액결제 */
  Mobilians = 'MOBILIANS',
  /** 네이버페이-주문형 */
  Naverco = 'NAVERCO',
  /** 네이버페이-결제형 */
  Naverpay = 'NAVERPAY',
  /** 나이스페이 */
  Nice = 'NICE',
  /** 페이코 */
  Payco = 'PAYCO',
  /** 페이팔 */
  Paypal = 'PAYPAL',
  /** 스마일페이 */
  Smilepay = 'SMILEPAY',
  /** 시럽페이 */
  Syrup = 'SYRUP',
  /** 토스간편결제 */
  Tosspay = 'TOSSPAY',
  /** 토스페이먼츠 */
  Tosspayments = 'TOSSPAYMENTS',
  /** 토스페이먼츠 */
  Uplus = 'UPLUS',
}

/** An enumeration. */
export enum TransactionTransactionStatus {
  /** 결제 취소 */
  Cancelled = 'CANCELLED',
  /** 결제 실패 */
  Failed = 'FAILED',
  /** 결제 완료 */
  Paid = 'PAID',
  /** 결제 전 */
  Ready = 'READY',
  /** 미입금 취소 */
  UnpaidCanceled = 'UNPAID_CANCELED',
  /** 가상 계좌 입금 대기 */
  VbankReady = 'VBANK_READY',
}

export type TransactionType = Node & {
  __typename?: 'TransactionType'
  /** 결제 총액 */
  amount: Scalars['Decimal']
  /** 카드사 승인번호(신용카드결제에 한하여 제공) */
  applyNumber?: Maybe<Scalars['String']>
  /** 결제승인일시 (paid_at) */
  approvedAt?: Maybe<Scalars['DateTime']>
  approvedTransactionMembershipHistory?: Maybe<MembershipHistoryType>
  approvedTransactionOrder?: Maybe<OrderType>
  /** 예금주 */
  bankAccountHolderName?: Maybe<Scalars['String']>
  /** 입금계좌번호. 숫자 외 dash(-)또는 기타 기호가 포함되어 있을 수 있음 */
  bankAccountNumber?: Maybe<Scalars['String']>
  /** 은행 코드 (bank_code) */
  bankCode?: Maybe<Scalars['String']>
  /** 은행명 (bank_name) */
  bankName?: Maybe<Scalars['String']>
  cancelTransactions?: Maybe<CancelTransactionTypeConnection>
  /** 결제 완료 카드 할부 개월수 */
  cardQuota?: Maybe<Scalars['Int']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 결제시작시간 밀리초 */
  createdAtMillis?: Maybe<Scalars['String']>
  /** 지불 플랫폼, PC/MOBILE */
  device?: Maybe<Scalars['String']>
  /** 결제 오류코드 */
  errorCode?: Maybe<Scalars['String']>
  /** 결제 오류 메세지 (error_msg) */
  errorMessage?: Maybe<Scalars['String']>
  /** 관리자 결제 처리 시 담당자 */
  fitpetAdmin?: Maybe<UserType>
  /** 아임포트 고유 결제번호 */
  iamportUid?: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 에스크로 여부 (escrow) */
  isEscrow: Scalars['Boolean']
  /** 멤버쉽 히스토리 */
  membershipHistory?: Maybe<MembershipHistoryType>
  /** 가맹점 식별코드 */
  merchantCode?: Maybe<Scalars['String']>
  /** 가맹점 주문고유번호 */
  merchantUid: Scalars['String']
  /** 상품명 */
  name: Scalars['String']
  /** 주문 */
  order?: Maybe<OrderType>
  /** 결제 시 사용한 간편결제 앱 */
  payAppType: TransactionPayAppType
  payMethodType?: Maybe<Scalars['String']>
  paymentText?: Maybe<Scalars['String']>
  /** PG사 고유 승인번호 */
  pgApprovedTid?: Maybe<Scalars['String']>
  /** 결제승인/시도된 PG사 (pg_provider) */
  pgProviderType: TransactionPgProviderType
  pgProviderTypeText?: Maybe<Scalars['String']>
  /** PG사 고유 결제번호 */
  pgTid?: Maybe<Scalars['String']>
  /** PG사에서 발행되는 거래 매출전표 URL. 전달되는 URL을 클릭하면 매출전표 확인가능 */
  receiptUrl?: Maybe<Scalars['String']>
  /** 결제 응답 */
  responseData?: Maybe<Scalars['JSONString']>
  /** 결제시작일시 */
  startedAt?: Maybe<Scalars['DateTime']>
  /** amount 중 면세공급가액에 해당하는 금액 (tax_free) */
  taxFreeAmount: Scalars['Decimal']
  /** Toss 결제 토큰 */
  tossPayToken?: Maybe<Scalars['String']>
  /** 취소할 원 트랜잭션 */
  transactionCancelTransactions: CancelTransactionTypeConnection
  /** READY(미결제), PAID(결제완료), FAILED(결제실패), CANCELLED(취소됨), UNPAID_CANCELED(가상계좌 미입금 취소) (status) */
  transactionStatus: TransactionTransactionStatus
  transactionStatusText?: Maybe<Scalars['String']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 가상계좌 예금주. 계약된 사업자명으로 표시됨, 단, 일부 PG사의 경우 null을 반환하므로 자체 처리 필요 (vbank_holder) */
  vbankAccountHolderName?: Maybe<Scalars['String']>
  /** 가상계좌 입금계좌번호. PG사로부터 전달된 정보 그대로 제공하므로 숫자 외 dash(-)또는 기타 기호가 포함되어 있을 수 있음 (vbank_num) */
  vbankAccountNumber?: Maybe<Scalars['String']>
  /** 가상계좌 은행 코드 (vbank_code) */
  vbankBankCode?: Maybe<Scalars['String']>
  /** 가상계좌 은행명 (vbank_name) */
  vbankBankName?: Maybe<Scalars['String']>
  /** 가상계좌 입금기한 (vbank_date) */
  vbankEndedAt?: Maybe<Scalars['DateTime']>
  /** 가상계좌 번호 발급일시 */
  vbankIssuedAt?: Maybe<Scalars['DateTime']>
  /** 가상계좌 웹훅 응답 */
  webhookData?: Maybe<Scalars['JSONString']>
}

export type TransactionTypeCancelTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type TransactionTypeTransactionCancelTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type TransactionTypeConnection = {
  __typename?: 'TransactionTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TransactionTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `TransactionType` and its cursor. */
export type TransactionTypeEdge = {
  __typename?: 'TransactionTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<TransactionType>
}

export type TypedBannerInfoType = {
  __typename?: 'TypedBannerInfoType'
  adId?: Maybe<Scalars['ID']>
  adName?: Maybe<Scalars['String']>
  adSlug?: Maybe<Scalars['String']>
  mobileImage?: Maybe<CachedBannerImageType>
}

export type TypedBannerType = {
  __typename?: 'TypedBannerType'
  bannerInfo?: Maybe<TypedBannerInfoType>
  bannerType?: Maybe<Scalars['String']>
  displayOrder?: Maybe<Scalars['Int']>
  endedAt?: Maybe<Scalars['DateTime']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  rawId?: Maybe<Scalars['Int']>
  startedAt?: Maybe<Scalars['DateTime']>
}

export type TypedBestReviewType = {
  __typename?: 'TypedBestReviewType'
  collectionType?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  displayProductInfos?: Maybe<Array<Maybe<TypedProductInfoType>>>
  endedAt?: Maybe<Scalars['DateTime']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  rawId?: Maybe<Scalars['Int']>
  startedAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type TypedBrandType = {
  __typename?: 'TypedBrandType'
  id?: Maybe<Scalars['String']>
  listImage?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  rawId?: Maybe<Scalars['Int']>
}

export type TypedCategoryType = {
  __typename?: 'TypedCategoryType'
  code?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  displayOrder?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['String']>
  isVisible?: Maybe<Scalars['Boolean']>
  listImage?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  parentCategoryId?: Maybe<Scalars['Int']>
  text?: Maybe<Scalars['String']>
}

export type TypedDisplayCollectionType = {
  __typename?: 'TypedDisplayCollectionType'
  backgroundImage?: Maybe<Scalars['String']>
  collectionType?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  displayProductInfos?: Maybe<Array<Maybe<TypedProductInfoType>>>
  endedAt?: Maybe<Scalars['DateTime']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type TypedProductInfoType = {
  __typename?: 'TypedProductInfoType'
  id?: Maybe<Scalars['String']>
  product?: Maybe<TypedProductType>
  promotionEndedAt?: Maybe<Scalars['DateTime']>
  promotionStartedAt?: Maybe<Scalars['DateTime']>
}

export type TypedProductPromotionType = {
  __typename?: 'TypedProductPromotionType'
  id?: Maybe<Scalars['String']>
  productPromotionType?: Maybe<Scalars['String']>
}

export type TypedProductType = {
  __typename?: 'TypedProductType'
  averageReviewScore?: Maybe<Scalars['Float']>
  bestReview?: Maybe<Scalars['String']>
  /** 브랜드 정보 */
  brand?: Maybe<TypedBrandType>
  createdAt?: Maybe<Scalars['DateTime']>
  customerPrice?: Maybe<Scalars['Int']>
  discountPrice?: Maybe<Scalars['Float']>
  discountRate?: Maybe<Scalars['Float']>
  finalDiscountRate?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  isImminentSoldOut?: Maybe<Scalars['Boolean']>
  isRealSoldOut?: Maybe<Scalars['Boolean']>
  isShown?: Maybe<Scalars['Boolean']>
  isTimeSale?: Maybe<Scalars['Boolean']>
  mainImage?: Maybe<CachedProductMainImage>
  maxProductOptionSkuCount?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  pricePerSku?: Maybe<Scalars['Int']>
  productNumber?: Maybe<Scalars['Int']>
  productPromotion?: Maybe<TypedProductPromotionType>
  productPromotionCustomerPrice?: Maybe<Scalars['Int']>
  productPromotionDiscountRate?: Maybe<Scalars['Int']>
  productPromotionEndedAt?: Maybe<Scalars['DateTime']>
  productPromotionStartedAt?: Maybe<Scalars['DateTime']>
  productRealId?: Maybe<Scalars['Int']>
  reviewCount?: Maybe<Scalars['Int']>
  /** 공급 타입 */
  supplyType?: Maybe<SupplTypeEnum>
}

export type TypedRecommendedRelatedProductType = {
  __typename?: 'TypedRecommendedRelatedProductType'
  averageReviewScore?: Maybe<Scalars['Float']>
  bestReview?: Maybe<Scalars['String']>
  /** 브랜드 정보 */
  brand?: Maybe<TypedBrandType>
  categories?: Maybe<Array<Maybe<TypedCategoryType>>>
  createdAt?: Maybe<Scalars['DateTime']>
  customerPrice?: Maybe<Scalars['Int']>
  discountPrice?: Maybe<Scalars['Float']>
  discountRate?: Maybe<Scalars['Float']>
  finalDiscountRate?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  isImminentSoldOut?: Maybe<Scalars['Boolean']>
  isRealSoldOut?: Maybe<Scalars['Boolean']>
  isShown?: Maybe<Scalars['Boolean']>
  isTimeSale?: Maybe<Scalars['Boolean']>
  mainImage?: Maybe<CachedProductMainImage>
  maxProductOptionSkuCount?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Int']>
  pricePerSku?: Maybe<Scalars['Int']>
  productNumber?: Maybe<Scalars['Int']>
  productPromotion?: Maybe<TypedProductPromotionType>
  productPromotionCustomerPrice?: Maybe<Scalars['Int']>
  productPromotionDiscountRate?: Maybe<Scalars['Int']>
  productPromotionEndedAt?: Maybe<Scalars['DateTime']>
  productPromotionStartedAt?: Maybe<Scalars['DateTime']>
  productRealId?: Maybe<Scalars['Int']>
  reviewCount?: Maybe<Scalars['Int']>
  /** 공급 타입 */
  supplyType?: Maybe<SupplTypeEnum>
}

export type TypedWeeklyBestCollectionType = {
  __typename?: 'TypedWeeklyBestCollectionType'
  collectionType?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  displayProductInfos?: Maybe<Array<Maybe<TypedProductInfoType>>>
  endedAt?: Maybe<Scalars['DateTime']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  rawId?: Maybe<Scalars['Int']>
  startedAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

/** 가족 멤버쉽 구독 해제 */
export type UnsubscribeMembershipFamilyMutation = {
  __typename?: 'UnsubscribeMembershipFamilyMutation'
  membership?: Maybe<MembershipType>
}

/** 멤버쉽 구독 취소 (정기 결제 해지) */
export type UnsubscribeMembershipMutation = {
  __typename?: 'UnsubscribeMembershipMutation'
  membership?: Maybe<MembershipType>
}

export type UpdateAdInput = {
  /** 프로모션 종류, NORMAL: 일반, DEAL_100: 100원딜, DEAL_990: 990원딜 */
  adType?: InputMaybe<Scalars['String']>
  /** 설명 */
  description?: InputMaybe<Scalars['String']>
  /** 종료일 */
  endedAt?: InputMaybe<Scalars['DateTime']>
  /** 이미지들 */
  images?: InputMaybe<Array<InputMaybe<AdImageInput>>>
  /** 노출 여부 */
  isShown?: InputMaybe<Scalars['Boolean']>
  /** 프로모션명 */
  name?: InputMaybe<Scalars['String']>
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType?: InputMaybe<Scalars['String']>
  /** 프로모션 Url 주소 slug */
  slug?: InputMaybe<Scalars['String']>
  /** 시작일 */
  startedAt?: InputMaybe<Scalars['DateTime']>
  /** 배경색 등 스타일 정보 */
  style?: InputMaybe<Scalars['JSONString']>
}

/** Ad 생성 */
export type UpdateAdMutation = {
  __typename?: 'UpdateAdMutation'
  ad?: Maybe<AdType>
}

export type UpdateAddressInput = {
  /** 기본 주소 */
  basicAddress?: InputMaybe<Scalars['String']>
  /** 상세 주소 */
  detailedAddress?: InputMaybe<Scalars['String']>
  /** 우편번호 */
  postNumber?: InputMaybe<Scalars['String']>
}

export type UpdateAgreeTermsInput = {
  /** 위치 정보 기반 서비스 이용 동의 */
  agreeLocationBasedService?: InputMaybe<Scalars['Boolean']>
  /** 이벤트 및 혜택 안내 동의 여부 */
  agreeMarketing?: InputMaybe<Scalars['Boolean']>
  /** 병원 알림 허용 여부 */
  agreeMarketingHospital?: InputMaybe<Scalars['Boolean']>
  /** 플레이 알림 허용 여부 */
  agreeMarketingPlay?: InputMaybe<Scalars['Boolean']>
  /** 야간 마케팅 알림 수신 동의 여부 */
  agreeNightMarketing?: InputMaybe<Scalars['Boolean']>
  /** 개인정보 수집 및 이용 동의 */
  agreePersonalInfoCollection?: InputMaybe<Scalars['Boolean']>
  /** 홍보 및 마케팅 동의 여부 */
  agreePrMarketing?: InputMaybe<Scalars['Boolean']>
  /** 서비스 알림 허용 여부 */
  agreeServicePushAllowed?: InputMaybe<Scalars['Boolean']>
  /** 제 3자 정보 제공 동의 */
  agreeThirdPartyProvision?: InputMaybe<Scalars['Boolean']>
}

/** 추가 약관 동의 */
export type UpdateAgreeTermsMutation = {
  __typename?: 'UpdateAgreeTermsMutation'
  user?: Maybe<UserType>
}

export type UpdateAsInfoInput = {
  /** AS 전화번호 이름 */
  name?: InputMaybe<Scalars['String']>
  /** 셀러 */
  seller?: InputMaybe<Scalars['ID']>
  /** AS 전화번호 */
  telephoneNumber?: InputMaybe<Scalars['String']>
}

/** AS 정보 등록 */
export type UpdateAsInfoMutation = {
  __typename?: 'UpdateAsInfoMutation'
  asInfo?: Maybe<AsInfoType>
}

/** brand 수정 - 관리자 */
export type UpdateBrandByFitpetAdminMutation = {
  __typename?: 'UpdateBrandByFitpetAdminMutation'
  brand?: Maybe<BrandType>
}

/** 사용자에 의한 brand 수정 요청 */
export type UpdateBrandBySellerMutation = {
  __typename?: 'UpdateBrandBySellerMutation'
  brand?: Maybe<BrandType>
}

export type UpdateCartItemInput = {
  /** 장바구니에 담을 상품 아이템 */
  productOption?: InputMaybe<Scalars['ID']>
  /** 수량 */
  quantity: Scalars['Int']
}

/** 장바구니 아이템 수정 (수량) */
export type UpdateCartItemMutation = {
  __typename?: 'UpdateCartItemMutation'
  cartItem?: Maybe<CartItemType>
}

/** Category 이미지 수정 */
export type UpdateCategoryImageMutation = {
  __typename?: 'UpdateCategoryImageMutation'
  category?: Maybe<CategoryType>
}

/** Category 수정 */
export type UpdateCategoryMutation = {
  __typename?: 'UpdateCategoryMutation'
  category?: Maybe<CategoryType>
}

/** Category slug 수정 */
export type UpdateCategorySlugMutation = {
  __typename?: 'UpdateCategorySlugMutation'
  category?: Maybe<CategoryType>
}

/** 쿠폰 정보 수정 */
export type UpdateCouponGroupMutation = {
  __typename?: 'UpdateCouponGroupMutation'
  couponGroup?: Maybe<CouponGroupType>
}

export type UpdateCouponGroupStatusInput = {
  couponGroupStatus: Scalars['String']
}

/** 쿠폰 정보 수정 */
export type UpdateCouponGroupStatusMutation = {
  __typename?: 'UpdateCouponGroupStatusMutation'
  couponGroup?: Maybe<CouponGroupType>
}

/** 고객 등급 수정 */
export type UpdateCustomerGradeMutation = {
  __typename?: 'UpdateCustomerGradeMutation'
  customerGrade?: Maybe<CustomerGradeType>
}

export type UpdateCustomerTemplateAddressInput = {
  /** 기본 주소 */
  basicAddress?: InputMaybe<Scalars['String']>
  /** 상세 주소 */
  detailedAddress?: InputMaybe<Scalars['String']>
  /** 기본 주소지 여부 */
  isDefault?: InputMaybe<Scalars['Boolean']>
  /** 고객 핸드폰 번호 */
  mobileNumber?: InputMaybe<Scalars['String']>
  /** 고객 이름 */
  name?: InputMaybe<Scalars['String']>
  /** 우편번호 */
  postNumber?: InputMaybe<Scalars['String']>
  /** 프리셋 이름 */
  templateName?: InputMaybe<Scalars['String']>
}

/** 사용자 템플릿 주소 수정 */
export type UpdateCustomerTemplateAddressMutation = {
  __typename?: 'UpdateCustomerTemplateAddressMutation'
  customerTemplateAddress?: Maybe<CustomerTemplateAddressType>
}

/** Display Collection 수정시 - DisplayProductInfo & ProductPromotion Datetime Sync 동기화 */
export type UpdateDateOfDisplayCollectionMutation = {
  __typename?: 'UpdateDateOfDisplayCollectionMutation'
  displayCollection?: Maybe<DisplayCollectionType>
}

export type UpdateDisplayCollectionInput = {
  /** 이미지 */
  backgroundImage?: InputMaybe<Scalars['ImageUpload']>
  /** 콜렉션 종류, BEST: 베스트, AD: 이벤트용, TODAY_SPECIAL_PRICE: Today 특가, COVERFLOW: 커버플로우, NORMAL: 일반, BEST_REVIEW: 리뷰좋은상품, MAGAZINE: 매거진이미지, MAGAZINE_BACKGROUND: 매거진 배경이미지 */
  collectionType?: InputMaybe<Scalars['String']>
  /** 설명 */
  description?: InputMaybe<Scalars['String']>
  /** 종료일 */
  endedAt?: InputMaybe<Scalars['DateTime']>
  /** 노출 여부 */
  isShown?: InputMaybe<Scalars['Boolean']>
  /** 콜렉션명 */
  name?: InputMaybe<Scalars['String']>
  /** 동물 타입, DOG(개), CAT(고양이), ALL(모두) */
  petType?: InputMaybe<Scalars['String']>
  /** 시작일 */
  startedAt?: InputMaybe<Scalars['DateTime']>
  /** 콜렉션 탭 이름(기획전페이지 전용) */
  tabName?: InputMaybe<Scalars['String']>
}

/** Display Collection 수정 */
export type UpdateDisplayCollectionMutation = {
  __typename?: 'UpdateDisplayCollectionMutation'
  displayCollection?: Maybe<DisplayCollectionType>
}

/** 배너 디스플레이 순서 변경 */
export type UpdateDisplayOrderForBannerMutation = {
  __typename?: 'UpdateDisplayOrderForBannerMutation'
  displayOrder?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['ID']>
}

export type UpdateDisplayOrderInput = {
  displayOrder: Scalars['Int']
}

/** 디스플레이 순서 변경 */
export type UpdateDisplayOrderMutation = {
  __typename?: 'UpdateDisplayOrderMutation'
  displayOrder?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['ID']>
}

export type UpdateDisplayPetTypeInput = {
  /** Display용 동물 타입, DOG(개), CAT(고양이) */
  displayPetType: Scalars['String']
}

/** 펫 타입 변경 */
export type UpdateDisplayPetTypeMutation = {
  __typename?: 'UpdateDisplayPetTypeMutation'
  user?: Maybe<UserType>
}

export type UpdateDisplayProductInfoInput = {
  /** 종료일 */
  endedAt?: InputMaybe<Scalars['DateTime']>
  /** 시작일 */
  startedAt?: InputMaybe<Scalars['DateTime']>
}

/** DisplayProductInfo 수정 */
export type UpdateDisplayProductInfoMutation = {
  __typename?: 'UpdateDisplayProductInfoMutation'
  displayProductInfo?: Maybe<DisplayProductInfoType>
}

/** 홈 하단 띠 배너 수정 */
export type UpdateHomeBottomBannerMutation = {
  __typename?: 'UpdateHomeBottomBannerMutation'
  banner?: Maybe<BannerType>
}

/** 홈 중단 띠 배너 수정 */
export type UpdateHomeMiddleBannerMutation = {
  __typename?: 'UpdateHomeMiddleBannerMutation'
  banner?: Maybe<BannerType>
}

/** 멤버쉽 결제 수단 변경 */
export type UpdateMembershipCreditCardMutation = {
  __typename?: 'UpdateMembershipCreditCardMutation'
  membership?: Maybe<MembershipType>
}

/** 엑셀 파일로 적립금 반영 */
export type UpdateMileageByExcelMutation = {
  __typename?: 'UpdateMileageByExcelMutation'
  /** 성공한 갯수 */
  count?: Maybe<Scalars['Int']>
  /** 실패 사유들 */
  failedReasons?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
  /** Task로 전환 여부 */
  isTask?: Maybe<Scalars['Boolean']>
}

/** 적립금 설정 변경 */
export type UpdateMileageConfigMutation = {
  __typename?: 'UpdateMileageConfigMutation'
  serverConfigs?: Maybe<Array<Maybe<ServerConfigType>>>
}

/** 관리자 적립금 변경 */
export type UpdateMileageMutation = {
  __typename?: 'UpdateMileageMutation'
  mileage?: Maybe<MileageType>
}

/** 최소 앱 버전 변경 */
export type UpdateMinAppVersionMutation = {
  __typename?: 'UpdateMinAppVersionMutation'
  minAppVersion?: Maybe<MinAppVersionType>
}

/** 핸드폰 번호 수정 */
export type UpdateMobileNumberMutation = {
  __typename?: 'UpdateMobileNumberMutation'
  user?: Maybe<UserType>
}

/** 홈 멀티라인 배너 수정 */
export type UpdateMultiLineBannerMutation = {
  __typename?: 'UpdateMultiLineBannerMutation'
  banner?: Maybe<BannerType>
}

/** 공지 사항 수정 */
export type UpdateNoticeMutation = {
  __typename?: 'UpdateNoticeMutation'
  notice?: Maybe<NoticeType>
}

export type UpdateOrderCustomerAddressInput = {
  /** 기본 주소 */
  basicAddress?: InputMaybe<Scalars['String']>
  /** 상세 주소 */
  detailedAddress?: InputMaybe<Scalars['String']>
  /** 고객 핸드폰 번호 */
  mobileNumber?: InputMaybe<Scalars['String']>
  /** 고객 이름 */
  name?: InputMaybe<Scalars['String']>
  /** 우편번호 */
  postNumber?: InputMaybe<Scalars['String']>
}

/** 사용자 배송 주소 수정 */
export type UpdateOrderCustomerAddressMutation = {
  __typename?: 'UpdateOrderCustomerAddressMutation'
  order?: Maybe<OrderType>
}

export type UpdateOrderItemAdminNoteInput = {
  /** 관리자 메모 */
  note: Scalars['String']
}

/** CS용 관리자 메모 수정 */
export type UpdateOrderItemAdminNoteMutation = {
  __typename?: 'UpdateOrderItemAdminNoteMutation'
  orderItemAdminNote?: Maybe<OrderItemAdminNoteType>
}

/** 상품 수정 - 관리자 */
export type UpdateProductByFitpetAdminMutation = {
  __typename?: 'UpdateProductByFitpetAdminMutation'
  product?: Maybe<ProductType>
}

/** 상품 수정 (신청) - 셀러 */
export type UpdateProductBySellerMutation = {
  __typename?: 'UpdateProductBySellerMutation'
  product?: Maybe<ProductType>
}

/** 엑셀 파일로 상품 마일리지 적립율 변경 */
export type UpdateProductMileagesByExcelMutation = {
  __typename?: 'UpdateProductMileagesByExcelMutation'
  /** 성공한 갯수 */
  count?: Maybe<Scalars['Int']>
  /** 실패 사유들 */
  failedReasons?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
  /** Task로 전환 여부 */
  isTask?: Maybe<Scalars['Boolean']>
}

export type UpdateProductPromotionInput = {
  /** 구매 확정 시 적립율, 0.xx로 표기. */
  confirmMileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 프로모션 설명 (생략 가능) */
  description?: InputMaybe<Scalars['String']>
  /** 할인 가격 */
  discountPrice?: InputMaybe<Scalars['Decimal']>
  /** 할인 비율(정률), 0.xx로 기입 */
  discountRate?: InputMaybe<Scalars['Float']>
  /** 화면표시 기간 끝 */
  displayEndedAt?: InputMaybe<Scalars['DateTime']>
  /** 화면표시 기간 시작 */
  displayStartedAt?: InputMaybe<Scalars['DateTime']>
  /** 종료일 */
  endedAt?: InputMaybe<Scalars['DateTime']>
  /** 핏펫 수수료 */
  fitpetFeeRate?: InputMaybe<Scalars['Float']>
  /** 이미지 리뷰 작성 시 적립율, 0.xx로 표기. */
  imageReviewMileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 기본 핏펫 수수료 사용여부 */
  isDefaultFitpetFeeRate?: InputMaybe<Scalars['Boolean']>
  /** 기본 적립금 적립률 사용여부 */
  isDefaultMileageRewardRate?: InputMaybe<Scalars['Boolean']>
  /** 최대 주문 수량 */
  maxOrderCount?: InputMaybe<Scalars['Int']>
  /** 적립율, 0.xx로 표기. null일경우 ServerConfig 따름 */
  mileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 최소 주문금액 */
  minOrderAmount?: InputMaybe<Scalars['Decimal']>
  /** 할인 이름 */
  name?: InputMaybe<Scalars['String']>
  /** 리뷰 작성 시 적립율, 0.xx로 표기. */
  reviewMileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 시작일 */
  startedAt?: InputMaybe<Scalars['DateTime']>
}

/** 상품 할인 수정 */
export type UpdateProductPromotionMutation = {
  __typename?: 'UpdateProductPromotionMutation'
  productPromotion?: Maybe<ProductPromotionType>
}

export type UpdateProductPromotionsInput = {
  /** 구매 확정 시 적립율, 0.xx로 표기. */
  confirmMileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 프로모션 설명 (생략 가능) */
  description?: InputMaybe<Scalars['String']>
  /** 할인 가격 */
  discountPrice?: InputMaybe<Scalars['Decimal']>
  /** 할인 비율(정률), 0.xx로 기입 */
  discountRate?: InputMaybe<Scalars['Float']>
  /** 화면표시 기간 끝 */
  displayEndedAt?: InputMaybe<Scalars['DateTime']>
  /** 화면표시 기간 시작 */
  displayStartedAt?: InputMaybe<Scalars['DateTime']>
  /** 종료일 */
  endedAt?: InputMaybe<Scalars['DateTime']>
  /** 핏펫 수수료 */
  fitpetFeeRate?: InputMaybe<Scalars['Float']>
  ids: Array<InputMaybe<Scalars['ID']>>
  /** 이미지 리뷰 작성 시 적립율, 0.xx로 표기. */
  imageReviewMileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 기본 핏펫 수수료 사용여부 */
  isDefaultFitpetFeeRate?: InputMaybe<Scalars['Boolean']>
  /** 기본 적립금 적립률 사용여부 */
  isDefaultMileageRewardRate?: InputMaybe<Scalars['Boolean']>
  /** 최대 주문 수량 */
  maxOrderCount?: InputMaybe<Scalars['Int']>
  /** 적립율, 0.xx로 표기. null일경우 ServerConfig 따름 */
  mileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 최소 주문금액 */
  minOrderAmount?: InputMaybe<Scalars['Decimal']>
  /** 할인 이름 */
  name?: InputMaybe<Scalars['String']>
  /** 리뷰 작성 시 적립율, 0.xx로 표기. */
  reviewMileageRewardRate?: InputMaybe<Scalars['Float']>
  /** 시작일 */
  startedAt?: InputMaybe<Scalars['DateTime']>
}

/** Product Promotions 복수 변경 */
export type UpdateProductPromotionsMutation = {
  __typename?: 'UpdateProductPromotionsMutation'
  /** Returns how many objects were affected. */
  count: Scalars['Int']
}

/** 엑셀 파일로 상품 연관검색어 업데이트 */
export type UpdateProductSearchKeywordsByExcelMutation = {
  __typename?: 'UpdateProductSearchKeywordsByExcelMutation'
  /** 성공한 갯수 */
  count?: Maybe<Scalars['Int']>
  /** 실패 사유들 */
  failedReasons?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
  /** Task로 전환 여부 */
  isTask?: Maybe<Scalars['Boolean']>
}

/** 상품 상세 상단 띠 배너 수정 */
export type UpdateProductTopBannerMutation = {
  __typename?: 'UpdateProductTopBannerMutation'
  banner?: Maybe<BannerType>
}

/** 상품 판매, 노출 일괄 변경 */
export type UpdateProductsSellingMutation = {
  __typename?: 'UpdateProductsSellingMutation'
  products?: Maybe<Array<Maybe<ProductType>>>
}

/** 상품들 품절 설정 상태 변경 */
export type UpdateProductsSoldOutMutation = {
  __typename?: 'UpdateProductsSoldOutMutation'
  products?: Maybe<Array<Maybe<ProductType>>>
}

/** Push 수정 */
export type UpdatePushMutation = {
  __typename?: 'UpdatePushMutation'
  informPushQueue?: Maybe<InformPushQueueType>
}

/** 고객에게 등록된 환불 계좌 수정 */
export type UpdateRefundAccountMutation = {
  __typename?: 'UpdateRefundAccountMutation'
  user?: Maybe<UserType>
}

/** 도서산간지역 수정 */
export type UpdateRemoteAreaMutation = {
  __typename?: 'UpdateRemoteAreaMutation'
  remoteArea?: Maybe<RemoteAreaType>
}

export type UpdateReturnInfoCustomerAddressInput = {
  /** 기본 주소 */
  basicAddress: Scalars['String']
  /** 상세 주소 */
  detailedAddress: Scalars['String']
  /** 고객 핸드폰 번호 */
  mobileNumber: Scalars['String']
  /** 고객 이름 */
  name: Scalars['String']
  /** 우편번호 */
  postNumber: Scalars['String']
}

/** 반품/교환 요청 주소 수정 */
export type UpdateReturnInfoCustomerAddressMutation = {
  __typename?: 'UpdateReturnInfoCustomerAddressMutation'
  returnInfo?: Maybe<ReturnInfoType>
}

/** 리뷰 수정 */
export type UpdateReviewByFitpetAdminMutation = {
  __typename?: 'UpdateReviewByFitpetAdminMutation'
  review?: Maybe<ReviewType>
}

export type UpdateReviewGroupProductsInput = {
  /** 상품명 */
  name?: InputMaybe<Scalars['String']>
  /** 리뷰그룹에 지정할 상품 */
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
}

/** 리뷰그룹 이름변경 및 상품 지정 */
export type UpdateReviewGroupProductsMutation = {
  __typename?: 'UpdateReviewGroupProductsMutation'
  reviewGroup?: Maybe<ReviewGroupType>
}

export type UpdateReviewInput = {
  /** 답변 내용 */
  answerBody?: InputMaybe<Scalars['String']>
  /** 리뷰 내용 */
  reviewBody?: InputMaybe<Scalars['String']>
  reviewImages?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>
  /** 평점 */
  score?: InputMaybe<Scalars['Int']>
  /** 관리자가 부여하는 가중치로 50이 기본 값. 높을 수록 높은 우선 순위임 */
  weight?: InputMaybe<Scalars['Int']>
}

/** 리뷰 수정 */
export type UpdateReviewMutation = {
  __typename?: 'UpdateReviewMutation'
  review?: Maybe<ReviewType>
}

export type UpdateSearchTextInput = {
  /** 검색 placeholder 문구 */
  text: Scalars['String']
}

/** 검색 Placeholder Text 문구 수정 */
export type UpdateSearchTextMutation = {
  __typename?: 'UpdateSearchTextMutation'
  message?: Maybe<Scalars['String']>
}

export type UpdateSellerByFitpetAdminInput = {
  /** 예금주명 */
  accountHolderName?: InputMaybe<Scalars['String']>
  /** 정산 담당자명 */
  accountManagerName?: InputMaybe<Scalars['String']>
  /** 정산 담당자 연락처 */
  accountManagerTelephoneNumber?: InputMaybe<Scalars['String']>
  /** 계좌번호 */
  accountNumber?: InputMaybe<Scalars['String']>
  /** 판매대행신고여부동의일시 */
  agentReportedAt?: InputMaybe<Scalars['DateTime']>
  /** AS 전화번호 */
  asTelephoneNumber: Scalars['String']
  /** 통장 사본 */
  bankBookImage?: InputMaybe<Scalars['Upload']>
  /** 은행 코드 */
  bankCode?: InputMaybe<Scalars['String']>
  /** 사업장 기본주소 */
  basicAddress: Scalars['String']
  /** 업태 */
  businessCondition: Scalars['String']
  /** 종목 */
  businessItem: Scalars['String']
  /** 사업자등록증 */
  businessRegistration?: InputMaybe<Scalars['Upload']>
  /** 사업자 번호 */
  businessRegistrationNumber: Scalars['String']
  /** 회사명 */
  companyName: Scalars['String']
  /** 대표자 이름 */
  companyOwnerName: Scalars['String']
  /** 사업장 상세주소 */
  detailedAddress: Scalars['String']
  /** 핏펫 수수료 */
  fitpetFeeRate: Scalars['Float']
  /** 판매대행신고여부 */
  isAgentReported: Scalars['Boolean']
  /** 통신판매업신고번호 */
  mailOrderReportNumber?: InputMaybe<Scalars['String']>
  /** 통신판매업신고증 */
  mailOrderReportRegistration?: InputMaybe<Scalars['Upload']>
  /** 알림을 수신받을 이메일 */
  notificationEmail?: InputMaybe<Scalars['String']>
  /** 알림 수신받을 담당자 명 */
  notificationManagerName?: InputMaybe<Scalars['String']>
  /** 알림톡 수신받을 핸드폰번호 */
  notificationMobileNumber?: InputMaybe<Scalars['String']>
  /** 사업장 우편번호 */
  postNumber: Scalars['String']
  /** 교환/환불 안내 */
  returnExchangeGuide?: InputMaybe<Scalars['String']>
  /** 셀러샵 이름 */
  shopName: Scalars['String']
  /** 세금계산서 발급용 이메일 */
  taxEmail?: InputMaybe<Scalars['String']>
  /** 회사 대표 전화번호 */
  telephoneNumber: Scalars['String']
}

/** seller 수정 - 관리자 */
export type UpdateSellerByFitpetAdminMutation = {
  __typename?: 'UpdateSellerByFitpetAdminMutation'
  seller?: Maybe<SellerType>
}

/** 사용자에 의한 seller 수정 요청 */
export type UpdateSellerByUserRequestMutation = {
  __typename?: 'UpdateSellerByUserRequestMutation'
  seller?: Maybe<SellerType>
}

export type UpdateSellerInput = {
  /** 예금주명 */
  accountHolderName?: InputMaybe<Scalars['String']>
  /** 정산 담당자명 */
  accountManagerName?: InputMaybe<Scalars['String']>
  /** 정산 담당자 연락처 */
  accountManagerTelephoneNumber?: InputMaybe<Scalars['String']>
  /** 계좌번호 */
  accountNumber?: InputMaybe<Scalars['String']>
  /** AS 전화번호 */
  asTelephoneNumber: Scalars['String']
  /** 통장 사본 */
  bankBookImage?: InputMaybe<Scalars['Upload']>
  /** 은행 코드 */
  bankCode?: InputMaybe<Scalars['String']>
  /** 사업장 기본주소 */
  basicAddress: Scalars['String']
  /** 업태 */
  businessCondition: Scalars['String']
  /** 종목 */
  businessItem: Scalars['String']
  /** 사업자등록증 */
  businessRegistration?: InputMaybe<Scalars['Upload']>
  /** 사업자 번호 */
  businessRegistrationNumber: Scalars['String']
  /** 회사명 */
  companyName: Scalars['String']
  /** 대표자 이름 */
  companyOwnerName: Scalars['String']
  /** 사업장 상세주소 */
  detailedAddress: Scalars['String']
  /** 통신판매업신고번호 */
  mailOrderReportNumber?: InputMaybe<Scalars['String']>
  /** 통신판매업신고증 */
  mailOrderReportRegistration?: InputMaybe<Scalars['Upload']>
  /** 사업장 우편번호 */
  postNumber: Scalars['String']
  /** 교환/환불 안내 */
  returnExchangeGuide?: InputMaybe<Scalars['String']>
  /** 셀러샵 이름 */
  shopName: Scalars['String']
  /** 세금계산서 발급용 이메일 */
  taxEmail?: InputMaybe<Scalars['String']>
  /** 회사 대표 전화번호 */
  telephoneNumber: Scalars['String']
}

/** 셀러 운영 담당 정보 수정 */
export type UpdateSellerNotificationMutation = {
  __typename?: 'UpdateSellerNotificationMutation'
  seller?: Maybe<SellerType>
}

export type UpdateSellerTemplateAddressInput = {
  /** 기본 주소 */
  basicAddress?: InputMaybe<Scalars['String']>
  /** 상세 주소 */
  detailedAddress?: InputMaybe<Scalars['String']>
  /** 기본 주소지 여부 */
  isDefault?: InputMaybe<Scalars['Boolean']>
  /** 우편번호 */
  postNumber?: InputMaybe<Scalars['String']>
  /** 셀러 */
  seller?: InputMaybe<Scalars['ID']>
}

/** 셀러 템플릿 주소 수정 */
export type UpdateSellerTemplateAddressMutation = {
  __typename?: 'UpdateSellerTemplateAddressMutation'
  sellerTemplateAddress?: Maybe<SellerTemplateAddressType>
}

/** 설정 수정 */
export type UpdateServerConfigMutation = {
  __typename?: 'UpdateServerConfigMutation'
  serverConfig?: Maybe<ServerConfigType>
}

/** 엑셀 파일로 송장 번호 업데이트 */
export type UpdateShippingByExcelMutation = {
  __typename?: 'UpdateShippingByExcelMutation'
  /** 성공한 갯수 */
  count?: Maybe<Scalars['Int']>
  /** 실패 사유들 */
  failedReasons?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
  /** Task로 전환 여부 */
  isTask?: Maybe<Scalars['Boolean']>
}

/** 배송 회사 정보 수정 */
export type UpdateShippingCompanyMutation = {
  __typename?: 'UpdateShippingCompanyMutation'
  shippingCompany?: Maybe<ShippingCompanyType>
}

export type UpdateShippingPresetInput = {
  /** 도서 산간 추가 배송비 적용 여부 */
  applyRemoteAreaFee?: InputMaybe<Scalars['Boolean']>
  /** 무로배송 기준 금액 */
  freeShippingBaseAmount?: InputMaybe<Scalars['Decimal']>
  /** 배송 가이드 */
  guide?: InputMaybe<Scalars['String']>
  /** 묶음 배송 가능 여부 */
  isPackage?: InputMaybe<Scalars['Boolean']>
  /** 배송 프리셋 이름 */
  name: Scalars['String']
  /** 배송 공지 */
  notice?: InputMaybe<Scalars['String']>
  /** 반품지 주소 */
  returnAddress: UpdateAddressInput
  /** 반품비 */
  returnFee?: InputMaybe<Scalars['Decimal']>
  /** 배송 셀러 */
  seller: Scalars['ID']
  /** 배송회사 */
  shippingCompany: Scalars['ID']
  /** 당일출고 마감시간 */
  shippingDeadline?: InputMaybe<Scalars['Int']>
  /** 운임 */
  shippingFee?: InputMaybe<Scalars['Decimal']>
  /** 출고지 주소 */
  warehouseAddress: UpdateAddressInput
}

/** 배송 프리셋 정보 수정 */
export type UpdateShippingPresetMutation = {
  __typename?: 'UpdateShippingPresetMutation'
  shippingPreset?: Maybe<ShippingPresetType>
}

/** 배송 정보 복수 수정 */
export type UpdateShippingsMutation = {
  __typename?: 'UpdateShippingsMutation'
  shippings?: Maybe<Array<Maybe<ShippingType>>>
}

export type UpdateSkuInput = {
  /** 상품명 */
  name: Scalars['String']
  /** 재고량 */
  stockQuantity: Scalars['Int']
}

/** SKU 재고 수정 */
export type UpdateSkuMutation = {
  __typename?: 'UpdateSkuMutation'
  sku?: Maybe<SkuType>
}

/** Slim 배너 수정 */
export type UpdateSlimBannerMutation = {
  __typename?: 'UpdateSlimBannerMutation'
  banner?: Maybe<BannerType>
}

export type UpdateTestUserInput = {
  userName: Scalars['String']
}

/** 테스트 유저 등록 */
export type UpdateTestUserMutation = {
  __typename?: 'UpdateTestUserMutation'
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** 상단 롤 배너 수정 */
export type UpdateTopRollBannerMutation = {
  __typename?: 'UpdateTopRollBannerMutation'
  banner?: Maybe<BannerType>
}

export type UpdateUserByCustomerInput = {
  /** 이벤트 및 혜택 안내 동의 여부 */
  agreeMarketing?: InputMaybe<Scalars['Boolean']>
  /** 병원 알림 허용 여부 */
  agreeMarketingHospital?: InputMaybe<Scalars['Boolean']>
  /** 플레이 알림 허용 여부 */
  agreeMarketingPlay?: InputMaybe<Scalars['Boolean']>
  /** 야간 마케팅 알림 수신 동의 여부 */
  agreeNightMarketing?: InputMaybe<Scalars['Boolean']>
  /** 개인정보 수집 및 이용 동의 */
  agreePersonalInfoCollection?: InputMaybe<Scalars['Boolean']>
  /** 홍보 및 마케팅 동의 여부 */
  agreePrMarketing?: InputMaybe<Scalars['Boolean']>
  /** 서비스 알림 허용 여부 */
  agreeServicePushAllowed?: InputMaybe<Scalars['Boolean']>
  /** 생일 */
  birthday?: InputMaybe<Scalars['Date']>
  /** 성별, MALE(남성), FEMALE(여성), UNKNOWN(알 수 없음) */
  gender?: InputMaybe<Scalars['String']>
  /** 사용자 이름 */
  name?: InputMaybe<Scalars['String']>
  newPassword?: InputMaybe<Scalars['String']>
  userCertification?: InputMaybe<Scalars['ID']>
}

/** 사용자에 의한 유저 정보 수정 */
export type UpdateUserByCustomerMutation = {
  __typename?: 'UpdateUserByCustomerMutation'
  user?: Maybe<UserType>
}

export type UpdateUserByFitpetAdminInput = {
  /** 위치 정보 기반 서비스 이용 동의 */
  agreeLocationBasedService?: InputMaybe<Scalars['Boolean']>
  /** 제 3자 정보 제공 동의 */
  agreeThirdPartyProvision?: InputMaybe<Scalars['Boolean']>
  /** 생일 */
  birthday?: InputMaybe<Scalars['Date']>
  /** 성별, MALE(남성), FEMALE(여성), UNKNOWN(알 수 없음) */
  gender?: InputMaybe<Scalars['String']>
  /** 사용자 등급 */
  grade?: InputMaybe<Scalars['ID']>
  /** 사용자 핸드폰 번호 */
  mobileNumber?: InputMaybe<Scalars['String']>
  /** 사용자 이름 */
  name?: InputMaybe<Scalars['String']>
  newPassword?: InputMaybe<Scalars['String']>
  /** 사용자 이메일 주소 */
  username?: InputMaybe<Scalars['String']>
}

/** 관리자에 의한 유저 정보 수정 */
export type UpdateUserByFitpetAdminMutation = {
  __typename?: 'UpdateUserByFitpetAdminMutation'
  user?: Maybe<UserType>
}

export type UpdateUserPasswordByFitpetAdminInput = {
  newPassword: Scalars['String']
  oldPassword: Scalars['String']
}

/** 관리자에 의한 비밀번호 변경 */
export type UpdateUserPasswordByFitpetAdminMutation = {
  __typename?: 'UpdateUserPasswordByFitpetAdminMutation'
  user?: Maybe<UserType>
}

export type UpdatedBrandType = {
  __typename?: 'UpdatedBrandType'
  backgroundImage?: Maybe<Scalars['String']>
  brand?: Maybe<BrandType>
  listImage?: Maybe<Scalars['String']>
}

export type UpdatedProductOptionTypeConnection = {
  __typename?: 'UpdatedProductOptionTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UpdatedProductOptionTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `UpdatedProductOptionType` and its cursor. */
export type UpdatedProductOptionTypeEdge = {
  __typename?: 'UpdatedProductOptionTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<ProductOptionType>
}

export type UpdatedProductType = {
  __typename?: 'UpdatedProductType'
  product?: Maybe<ProductType>
  productImages?: Maybe<Array<Maybe<ProductImageType>>>
  productOptions?: Maybe<UpdatedProductOptionTypeConnection>
  statusValue?: Maybe<Scalars['String']>
}

export type UpdatedProductTypeProductOptionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['GenericScalar']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  pageInfo?: InputMaybe<PageInfoInputType>
}

export type UpdatedSellerType = {
  __typename?: 'UpdatedSellerType'
  asInfo?: Maybe<AsInfoType>
  bankBookImage?: Maybe<Scalars['String']>
  businessRegistration?: Maybe<Scalars['String']>
  mailOrderReportRegistration?: Maybe<Scalars['String']>
  seller?: Maybe<SellerType>
}

export type UploadSearchTokenInput = {
  /** 업데이트할 명사 사전 파일 */
  synonym?: InputMaybe<Scalars['Upload']>
  /** 업데이트할 명사 사전 파일 */
  userdict?: InputMaybe<Scalars['Upload']>
}

/** 검색사전 S3 Upload 및 패키징 */
export type UploadSearchTokenMutation = {
  __typename?: 'UploadSearchTokenMutation'
  /** 성공여부 */
  isSuccess?: Maybe<Scalars['Boolean']>
}

/** An enumeration. */
export enum UserCertificationCertType {
  /** CHANGE_MOBILE_NUMBER */
  ChangeMobileNumber = 'CHANGE_MOBILE_NUMBER',
  /** FIND_ID_BY_MOBILE_NUMBER */
  FindIdByMobileNumber = 'FIND_ID_BY_MOBILE_NUMBER',
  /** NEW_USER */
  NewUser = 'NEW_USER',
  /** RESET_PASSWORD_BY_EMAIL */
  ResetPasswordByEmail = 'RESET_PASSWORD_BY_EMAIL',
  /** RESET_PASSWORD_BY_MOBILE_NUMBER */
  ResetPasswordByMobileNumber = 'RESET_PASSWORD_BY_MOBILE_NUMBER',
}

export type UserCertificationConfirmInput = {
  /** 인증번호 */
  confirmNumber: Scalars['String']
  userCertification: Scalars['ID']
}

export type UserCertificationType = Node & {
  __typename?: 'UserCertificationType'
  /** 신규회원가입: CERT_TYPE_NEW_USER, 핸드폰 번호로 비번찾기: CERT_TYPE_RESET_PASSWORD_BY_MOBILE_NUMBER, 이메일로 비번찾기: CERT_TYPE_RESET_PASSWORD_BY_EMAIL, 핸드폰 번호 변경: CERT_TYPE_CHANGE_MOBILE_NUMBER, 전화번호로 아이디 찾기: CERT_TYPE_FIND_ID_BY_MOBILE_NUMBER */
  certType: UserCertificationCertType
  /** 휴대폰 번호 인증일 */
  confirmedAt?: Maybe<Scalars['DateTime']>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 사용자 이메일 */
  email?: Maybe<Scalars['String']>
  /** 인증 만료 시간 */
  expiredAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 사용자 핸드폰 번호 */
  mobileNumber?: Maybe<Scalars['String']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type UserCountType = {
  __typename?: 'UserCountType'
  count?: Maybe<Scalars['Int']>
}

/** An enumeration. */
export enum UserDisplayPetType {
  /** CAT */
  Cat = 'CAT',
  /** DOG */
  Dog = 'DOG',
}

/** An enumeration. */
export enum UserGender {
  /** FEMALE */
  Female = 'FEMALE',
  /** MALE */
  Male = 'MALE',
  /** UNKNOWN */
  Unknown = 'UNKNOWN',
}

export type UserInfoAccountType = {
  __typename?: 'UserInfoAccountType'
  createdAt?: Maybe<Scalars['DateTime']>
  lastLoggedAt?: Maybe<Scalars['DateTime']>
  loginAccountType?: Maybe<Scalars['String']>
}

export type UserInfoType = {
  __typename?: 'UserInfoType'
  accounts?: Maybe<Array<Maybe<UserInfoAccountType>>>
  /** **처리된 Email 정보 */
  email?: Maybe<Scalars['String']>
  /** 사용자 GID */
  id?: Maybe<Scalars['ID']>
  /** 사용자 타입 */
  userStatus?: Maybe<Scalars['String']>
}

/** An enumeration. */
export enum UserJoinedPlatform {
  /** ANDROID */
  Android = 'ANDROID',
  /** IOS */
  Ios = 'IOS',
  /** MOBILE */
  Mobile = 'MOBILE',
  /** PC */
  Pc = 'PC',
}

export type UserSigninInput = {
  email: Scalars['String']
  password: Scalars['String']
}

export type UserSignupInput = {
  /** 이벤트 및 혜택 안내 동의 여부 */
  agreeMarketing?: InputMaybe<Scalars['Boolean']>
  /** 병원 알림 허용 여부 */
  agreeMarketingHospital?: InputMaybe<Scalars['Boolean']>
  /** 플레이 알림 허용 여부 */
  agreeMarketingPlay?: InputMaybe<Scalars['Boolean']>
  /** 야간 마케팅 알림 수신 동의 여부 */
  agreeNightMarketing?: InputMaybe<Scalars['Boolean']>
  /** 개인정보 수집 및 이용 동의 */
  agreePersonalInfoCollection?: InputMaybe<Scalars['Boolean']>
  /** 홍보 및 마케팅 동의 여부 */
  agreePrMarketing?: InputMaybe<Scalars['Boolean']>
  /** 서비스 알림 허용 여부 */
  agreeServicePushAllowed?: InputMaybe<Scalars['Boolean']>
  /** 생일 */
  birthday?: InputMaybe<Scalars['Date']>
  email: Scalars['String']
  /** 성별, MALE(남성), FEMALE(여성), UNKNOWN(알 수 없음) */
  gender?: InputMaybe<Scalars['String']>
  /** 추천인 코드 */
  invitationCode?: InputMaybe<Scalars['String']>
  /** 사용자 이름 */
  name: Scalars['String']
  /** 인증번호 GID */
  userCertification?: InputMaybe<Scalars['ID']>
}

export type UserSubscriptionType = Node & {
  __typename?: 'UserSubscriptionType'
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 종료일 */
  endedAt?: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 시작일 */
  startedAt?: Maybe<Scalars['DateTime']>
  subscriptionInfo: Scalars['JSONString']
  /** 구독 상태 */
  subscriptionStatus: Scalars['String']
  /** 총 회차 */
  totalRound: Scalars['Int']
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 구독 사용자 */
  user?: Maybe<UserType>
}

export type UserSubscriptionTypeConnection = {
  __typename?: 'UserSubscriptionTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserSubscriptionTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `UserSubscriptionType` and its cursor. */
export type UserSubscriptionTypeEdge = {
  __typename?: 'UserSubscriptionTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<UserSubscriptionType>
}

export type UserType = Node & {
  __typename?: 'UserType'
  accountHolderName?: Maybe<Scalars['String']>
  accountNumber?: Maybe<Scalars['String']>
  /** 휴면해제일 */
  activatedAt?: Maybe<Scalars['DateTime']>
  /** 등록한 관리자 */
  adminCustomerGrade: CustomerGradeTypeConnection
  /** 위치 정보 기반 서비스 이용 동의 */
  agreeLocationBasedService: Scalars['Boolean']
  /** 위치 정보 기반 서비스 이용 동의/거부 일자 */
  agreeLocationBasedServiceAt?: Maybe<Scalars['DateTime']>
  /** 이벤트 및 혜택 안내 동의 여부 */
  agreeMarketing: Scalars['Boolean']
  /** 이벤트 및 혜택 안내 동의/거부 일자 */
  agreeMarketingAt?: Maybe<Scalars['DateTime']>
  /** 병원 알림 허용 여부 */
  agreeMarketingHospital: Scalars['Boolean']
  /** 병원 알림 허용 동의/거부 일자 */
  agreeMarketingHospitalAt?: Maybe<Scalars['DateTime']>
  /** 플레이 알림 허용 여부 */
  agreeMarketingPlay: Scalars['Boolean']
  /** 플레이 알림 허용 동의/거부 일자 */
  agreeMarketingPlayAt?: Maybe<Scalars['DateTime']>
  /** 야간 마케팅 알림 수신 동의 여부 */
  agreeNightMarketing: Scalars['Boolean']
  /** 야간 마케팅 알림 수신 동의 일자 */
  agreeNightMarketingAt?: Maybe<Scalars['DateTime']>
  /** 개인정보 수집 및 이용 동의 */
  agreePersonalInfoCollection: Scalars['Boolean']
  /** 개인정보 수집 및 이용 동의/거부 일자 */
  agreePersonalInfoCollectionAt?: Maybe<Scalars['DateTime']>
  /** 홍보 및 마케팅 동의 여부 */
  agreePrMarketing: Scalars['Boolean']
  /** 홍보 및 마케팅 동의/거부 일자 */
  agreePrMarketingAt?: Maybe<Scalars['DateTime']>
  /** 서비스 알림 허용 여부 */
  agreeServicePushAllowed: Scalars['Boolean']
  /** 서비스 알림 허용 동의/거부 일자 */
  agreeServicePushAllowedAt?: Maybe<Scalars['DateTime']>
  /** 제 3자 정보 제공 동의 */
  agreeThirdPartyProvision: Scalars['Boolean']
  /** 제 3자 정보 제공 동의/거부 일자 */
  agreeThirdPartyProvisionAt?: Maybe<Scalars['DateTime']>
  /** 답변자 */
  answeredUserProductQnas: ProductQnaTypeConnection
  /** 답변자 */
  answeredUserReviews: ReviewTypeConnection
  /** 답변자 */
  answeredUserServiceQnas: ServiceQnaTypeConnection
  /** user의 승인된 seller 수 */
  approvalSellerCount?: Maybe<Scalars['Int']>
  /** 은행 코드 */
  bankCode?: Maybe<Scalars['String']>
  /** 은행 이름 */
  bankName?: Maybe<Scalars['String']>
  /** 생일 */
  birthday?: Maybe<Scalars['Date']>
  /** 100원 딜 구매 여부 */
  boughtDeal100: Scalars['Boolean']
  /** 100원딜 구매 가능 여부 */
  canBuyDeal100?: Maybe<Scalars['Boolean']>
  /** 멤버쉽 가엽 가능 여부 */
  canSubscribeMembership?: Maybe<Scalars['Boolean']>
  cartItemCount?: Maybe<Scalars['Int']>
  /** 회원 */
  cartItemHistories: CartItemHistoryTypeConnection
  /** 장바구니 상품 quantity 총합 */
  cartItemQuantity?: Maybe<Scalars['Int']>
  /** 회원 */
  cartItems: CartItemTypeConnection
  commerceUser?: Maybe<CommerceUserType>
  commerceuser?: Maybe<CommerceUserType>
  couponCount?: Maybe<Scalars['Int']>
  coupons?: Maybe<CouponTypeConnection>
  /** 생성일 */
  createdAt?: Maybe<Scalars['DateTime']>
  /** 등록자 */
  createdUserSkus: SkuTypeConnection
  customerTemplateAddresses?: Maybe<CustomerTemplateAddressTypeConnection>
  dateJoined: Scalars['DateTime']
  /** Display용 동물 타입, DOG(개), CAT(고양이) */
  displayPetType: UserDisplayPetType
  email: Scalars['String']
  firstName: Scalars['String']
  /** 발급자 */
  fitpetAdminCouponGroups: CouponGroupTypeConnection
  /** 발급자 */
  fitpetAdminCoupons: CouponTypeConnection
  /** 발송 담당자 */
  fitpetAdminInformPushQueues: InformPushQueueTypeConnection
  /** 최종 처리 Fitpet Admin */
  fitpetAdminMileageHistories: MileageHistoryTypeConnection
  /** 작성한 관리자 */
  fitpetAdminOrderItemAdminNotes: OrderItemAdminNoteTypeConnection
  /** 작업자 */
  fitpetAdminSkuHistories: SkuHistoryTypeConnection
  /** 처리 관리자 */
  fitpetAdminStatusHistories: StatusHistoryTypeConnection
  /** 성별, MALE(남성), FEMALE(여성), UNKNOWN(알 수 없음) */
  gender: UserGender
  /** 기존 고도몰 아이디 */
  godoId?: Maybe<Scalars['String']>
  /** 사용자 등급 */
  grade?: Maybe<CustomerGradeType>
  /** 등급 수정일 */
  gradeUpdatedAt?: Maybe<Scalars['DateTime']>
  hasEmailAccount?: Maybe<Scalars['Boolean']>
  /** 멤버쉽 이전 가입 여부, 현재 가입 한 상태여도 true */
  hasMembership?: Maybe<Scalars['Boolean']>
  /** The ID of the object. */
  id: Scalars['ID']
  /** 휴면일 */
  inactivatedAt?: Maybe<Scalars['DateTime']>
  inactiveUser?: Maybe<InactiveUserType>
  inactiveuser?: Maybe<InactiveUserType>
  /** 추천인 코드 */
  invitationCode: Scalars['String']
  /** 누적 초대 쿠폰 금액 */
  inviteAccumulateCouponAmount?: Maybe<Scalars['Int']>
  /** 나를 추천인으로 가입한 회원 수 */
  inviteCount: Scalars['Int']
  /** 추천인 */
  inviteUser?: Maybe<UserType>
  /** 첫 구매한 초대된 유저 수 */
  invitedFirstOrderUserCount?: Maybe<Scalars['Int']>
  invitedUser?: Maybe<UserTypeConnection>
  /** 이 사용자가 활성화되어 있는지를 나타냅니다. 계정을 삭제하는 대신 이것을 선택 해제하세요. */
  isActive: Scalars['Boolean']
  /** 이번달 쿠폰팩 다운로드 여부 */
  isAppCouponDownload?: Maybe<Scalars['Boolean']>
  /** 차단 여부 */
  isBlocked: Scalars['Boolean']
  isInfluencer?: Maybe<Scalars['Boolean']>
  /** 현재 멤버쉽 가입 여부 */
  isMembership?: Maybe<Scalars['Boolean']>
  isSeller?: Maybe<Scalars['Boolean']>
  /** 사용자가 관리사이트에 로그인이 가능한지를 나타냅니다. */
  isStaff: Scalars['Boolean']
  /** 해당 사용자에게 모든 권한을 허가합니다. */
  isSuperuser: Scalars['Boolean']
  /** 테스트 유저 여부 */
  isTest?: Maybe<Scalars['Boolean']>
  /** 마일리지 전액 사용 여부 */
  isUseAllMileage?: Maybe<Scalars['Boolean']>
  /** PC, Mobile, Android, iOS */
  joinedPlatform: UserJoinedPlatform
  lastLogin?: Maybe<Scalars['DateTime']>
  lastName: Scalars['String']
  /** 마지막 구매일 */
  lastOrderedAt?: Maybe<Scalars['DateTime']>
  /** 마지막 로그인 실패 시간 */
  lastSignInFailedAt?: Maybe<Scalars['DateTime']>
  /** 탈퇴일 */
  leftAt?: Maybe<Scalars['DateTime']>
  /** 고객님 의견 */
  leftMessage?: Maybe<Scalars['String']>
  /** 탈퇴 사유 */
  leftReason?: Maybe<Scalars['String']>
  /** 찜한 상품 갯수 */
  likedCount?: Maybe<Scalars['Int']>
  likedReviews: ReviewTypeConnection
  /** 현재 사용자 멤버쉽 */
  membership?: Maybe<MembershipType>
  /** 멤버쉽 누적 혜택 */
  membershipAccumulatedBenefit?: Maybe<Scalars['Int']>
  /** 멤버쉽 누적 마일리지 */
  membershipAccumulatedMileage?: Maybe<Scalars['Int']>
  /** 멤버쉽 구독 횟수 */
  membershipCount?: Maybe<Scalars['Int']>
  /** 멤버쉽 대표 및 가족 정보 */
  membershipFamilies?: Maybe<MembershipFamilyConnection>
  /** 멈버쉽 최초 가입일 */
  membershipFirstSubscribedAt?: Maybe<Scalars['DateTime']>
  /** 멤버쉽 최근 해지일 */
  membershipLastUnsubscribedAt?: Maybe<Scalars['DateTime']>
  /** 멤버쉽 상태, NONE: 미가입, OWNER: 대표, FAMILY: 가족 */
  membershipStatus?: Maybe<Scalars['String']>
  /** 적립금 */
  mileage?: Maybe<MileageType>
  mileagePoint?: Maybe<Scalars['Int']>
  /** 전화번호 */
  mobileNumber?: Maybe<Scalars['String']>
  /** 사용자 이름 */
  name: Scalars['String']
  /** 현재 진행중인 주문 갯수 */
  ongoingOrderCount?: Maybe<Scalars['Int']>
  orderAmount?: Maybe<Scalars['Decimal']>
  orderCount?: Maybe<Scalars['Int']>
  /** 유저 아이디 */
  participants: ParticipantResultsTypeConnection
  /** 마지막 비밀번호 변경일 */
  passwordUpdatedAt?: Maybe<Scalars['DateTime']>
  /** 마지막 결제 수단 */
  payMethodType?: Maybe<Scalars['String']>
  recentOrderCustomerAddress?: Maybe<CustomerAddressType>
  /** 요청자 */
  requestUserStatusHistories: StatusHistoryTypeConnection
  reviewCount?: Maybe<Scalars['Int']>
  /** user의 seller 수 */
  sellerCount?: Maybe<Scalars['Int']>
  sellers?: Maybe<SellerTypeConnection>
  /** 로그인 횟수 */
  signInCount: Scalars['Int']
  /** 로그인 실패 횟수 */
  signInFailCount: Scalars['Int']
  /** 7일 후 소멸 예상 적립금 */
  unusedMileage?: Maybe<Scalars['Int']>
  /** 정보 변경 요청 승인일 */
  updateApprovedAt?: Maybe<Scalars['DateTime']>
  /** 수정일 */
  updatedAt?: Maybe<Scalars['DateTime']>
  /** 최종 수정자 */
  updatedUserSkus: SkuTypeConnection
  /** 발급 대상 사용자 */
  userCoupons: CouponTypeConnection
  userDevices?: Maybe<DeviceTypeConnection>
  /** 찜한 사용자 */
  userLikedProducts: LikedProductTypeConnection
  /** 멤버쉽 사용자 */
  userMembershipBenefitMonthlies: MembershipBenefitMonthlyTypeConnection
  /** 멤버쉽 사용자 */
  userMemberships: MembershipTypeConnection
  /** 작성자 */
  userNotice: NoticeTypeConnection
  /** 질문자 */
  userProductQnas: ProductQnaTypeConnection
  /** 발송 대상 사용자 */
  userPushUserHistories: PushUserHistoryTypeConnection
  userRestocks?: Maybe<RestockProductOptionUserTypeConnection>
  /** 구매자 */
  userReviews: ReviewTypeConnection
  /** 셀러 매니저인 사용자 */
  userSellerUsers: SellerUserTypeConnection
  /** 질문자 */
  userServiceQnas: ServiceQnaTypeConnection
  /** 소셜 로그인 대상 회원 */
  userSocialServices: SocialServiceTypeConnection
  /** 사용자 상태, ACTIVE(일반), INACTIVATED(휴면), LEFT(탈퇴), ADMIN_LEFT(관리자가 탈퇴) */
  userStatus: UserUserStatus
  userStatusText?: Maybe<Scalars['String']>
  /** 구독 사용자 */
  userSubscriptions: UserSubscriptionTypeConnection
  /** 사용자 이메일 주소 */
  username: Scalars['String']
  /** 7일 내 만료 쿠폰 존재 여부 */
  willExpiredCouponIn7Days?: Maybe<Scalars['Boolean']>
  /** 7일 내 만료 마일리지 존재 여부 */
  willExpiredMileageIn7Days?: Maybe<Scalars['Boolean']>
  /** 30일이내 소멸예정 마일리지 */
  willExpiredMileageIn30Days?: Maybe<Scalars['Int']>
  /** 사용자 찜한 브랜드 */
  wishedBrands: BrandTypeConnection
  /** 사용자 찜한 상품 */
  wishedProducts: ProductTypeConnection
  /** 작성 가능한 리뷰 갯수 */
  writableReviewCount?: Maybe<Scalars['Int']>
}

export type UserTypeAdminCustomerGradeArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
}

export type UserTypeAnsweredUserProductQnasArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type UserTypeAnsweredUserReviewsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  bestAt?: InputMaybe<Scalars['Date']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isPhotoReview?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  reviewBody?: InputMaybe<Scalars['String']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type UserTypeAnsweredUserServiceQnasArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItem?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  questionType?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type UserTypeCartItemHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserTypeCartItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserTypeCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserTypeCreatedUserSkusArgs = {
  after?: InputMaybe<Scalars['String']>
  barcodeNumber?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeSkus?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
}

export type UserTypeCustomerTemplateAddressesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserTypeFitpetAdminCouponGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroupStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  coverageOsType?: InputMaybe<Scalars['String']>
  coverageType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  eventType?: InputMaybe<Scalars['String']>
  excludeIssueType?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDiscountVisibleBeforeDownload?: InputMaybe<Scalars['Boolean']>
  isIssuing?: InputMaybe<Scalars['Boolean']>
  issueStatus?: InputMaybe<Scalars['String']>
  issueType?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  usingEndedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingEndedAtBefore?: InputMaybe<Scalars['DateTime']>
  usingPeriod?: InputMaybe<Scalars['Float']>
  usingStartedAtAfter?: InputMaybe<Scalars['DateTime']>
  usingStartedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type UserTypeFitpetAdminCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroup?: InputMaybe<Scalars['String']>
  couponGroupName?: InputMaybe<Scalars['String']>
  couponStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  isUsed?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type UserTypeFitpetAdminInformPushQueuesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  grades?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  pushQueueStatus?: InputMaybe<Scalars['String']>
  pushType?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  sendAtAfter?: InputMaybe<Scalars['DateTime']>
  sendAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type UserTypeFitpetAdminMileageHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isMembership?: InputMaybe<Scalars['Boolean']>
  isPlus?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mileageType?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
}

export type UserTypeFitpetAdminOrderItemAdminNotesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserTypeFitpetAdminSkuHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  sku?: InputMaybe<Scalars['String']>
}

export type UserTypeFitpetAdminStatusHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Scalars['String']>
  statusExclude?: InputMaybe<Scalars['String']>
}

export type UserTypeInvitedUserArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserTypeLikedReviewsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  bestAt?: InputMaybe<Scalars['Date']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isPhotoReview?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  reviewBody?: InputMaybe<Scalars['String']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type UserTypeMembershipFamiliesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type UserTypeParticipantsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserTypeRequestUserStatusHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Scalars['String']>
  statusExclude?: InputMaybe<Scalars['String']>
}

export type UserTypeSellersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UserTypeUpdatedUserSkusArgs = {
  after?: InputMaybe<Scalars['String']>
  barcodeNumber?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeSkus?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
}

export type UserTypeUserCouponsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  code?: InputMaybe<Scalars['String']>
  couponGroup?: InputMaybe<Scalars['String']>
  couponGroupName?: InputMaybe<Scalars['String']>
  couponStatus?: InputMaybe<Scalars['String']>
  couponType?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  grade?: InputMaybe<Scalars['String']>
  isUsed?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userMobileNumber?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type UserTypeUserDevicesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserTypeUserLikedProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserTypeUserMembershipBenefitMonthliesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
}

export type UserTypeUserMembershipsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserTypeUserNoticeArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  body?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  kind?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  receiverType?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
}

export type UserTypeUserProductQnasArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type UserTypeUserPushUserHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isSuccess?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
}

export type UserTypeUserRestocksArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserTypeUserReviewsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  bestAt?: InputMaybe<Scalars['Date']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isPhotoReview?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  reviewBody?: InputMaybe<Scalars['String']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userEmail?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type UserTypeUserSellerUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  seller?: InputMaybe<Scalars['String']>
  shopName?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
}

export type UserTypeUserServiceQnasArgs = {
  after?: InputMaybe<Scalars['String']>
  answerBody?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  first?: InputMaybe<Scalars['Int']>
  isAnswered?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  mobileNumber?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  orderItem?: InputMaybe<Scalars['String']>
  orderNumber?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  questionBody?: InputMaybe<Scalars['String']>
  questionType?: InputMaybe<Scalars['String']>
  seller?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['String']>
  userName?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type UserTypeUserSocialServicesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  socialType?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Scalars['ID']>
}

export type UserTypeUserSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Scalars['String']>
}

export type UserTypeWishedBrandsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeBrands?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  firstLetter?: InputMaybe<Scalars['String']>
  isApproved?: InputMaybe<Scalars['String']>
  last?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  seller?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
}

export type UserTypeWishedProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  approvedAtAfter?: InputMaybe<Scalars['DateTime']>
  approvedAtBefore?: InputMaybe<Scalars['DateTime']>
  before?: InputMaybe<Scalars['String']>
  brandName?: InputMaybe<Scalars['String']>
  brands?: InputMaybe<Scalars['String']>
  bundledProducts?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  categoryName?: InputMaybe<Scalars['String']>
  createdAtAfter?: InputMaybe<Scalars['DateTime']>
  createdAtBefore?: InputMaybe<Scalars['DateTime']>
  deletedAtAfter?: InputMaybe<Scalars['DateTime']>
  deletedAtBefore?: InputMaybe<Scalars['DateTime']>
  endedAtAfter?: InputMaybe<Scalars['DateTime']>
  endedAtBefore?: InputMaybe<Scalars['DateTime']>
  excludeProducts?: InputMaybe<Scalars['String']>
  excludeUnsearchable?: InputMaybe<Scalars['Boolean']>
  first?: InputMaybe<Scalars['Int']>
  hasOption?: InputMaybe<Scalars['Boolean']>
  isApproved?: InputMaybe<Scalars['Boolean']>
  isRange?: InputMaybe<Scalars['Boolean']>
  isRealSoldOut?: InputMaybe<Scalars['Boolean']>
  isSale?: InputMaybe<Scalars['Boolean']>
  isSellingEnded?: InputMaybe<Scalars['Boolean']>
  isSellingStarted?: InputMaybe<Scalars['Boolean']>
  isShown?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  modifiedAtAfter?: InputMaybe<Scalars['DateTime']>
  modifiedAtBefore?: InputMaybe<Scalars['DateTime']>
  name?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  petType?: InputMaybe<Scalars['String']>
  product?: InputMaybe<Scalars['String']>
  productCode?: InputMaybe<Scalars['String']>
  productName?: InputMaybe<Scalars['String']>
  productNumber?: InputMaybe<Scalars['String']>
  productNumbers?: InputMaybe<Scalars['String']>
  productPromotion?: InputMaybe<Scalars['String']>
  products?: InputMaybe<Scalars['String']>
  requestedAtAfter?: InputMaybe<Scalars['DateTime']>
  requestedAtBefore?: InputMaybe<Scalars['DateTime']>
  reviewGroup?: InputMaybe<Scalars['String']>
  reviewGroupName?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  searchedProducts?: InputMaybe<Scalars['String']>
  sellerName?: InputMaybe<Scalars['String']>
  sellers?: InputMaybe<Scalars['String']>
  skuCode?: InputMaybe<Scalars['String']>
  startedAtAfter?: InputMaybe<Scalars['DateTime']>
  startedAtBefore?: InputMaybe<Scalars['DateTime']>
  status?: InputMaybe<Scalars['String']>
  subCategory?: InputMaybe<Scalars['String']>
  supplyType?: InputMaybe<Scalars['String']>
  updateApprovedAtAfter?: InputMaybe<Scalars['DateTime']>
  updateApprovedAtBefore?: InputMaybe<Scalars['DateTime']>
}

export type UserTypeConnection = {
  __typename?: 'UserTypeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserTypeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['Int']>
}

/** A Relay edge containing a `UserType` and its cursor. */
export type UserTypeEdge = {
  __typename?: 'UserTypeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node?: Maybe<UserType>
}

/** An enumeration. */
export enum UserUserStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** ADMIN_LEFT */
  AdminLeft = 'ADMIN_LEFT',
  /** INACTIVATED */
  Inactivated = 'INACTIVATED',
  /** LEFT */
  Left = 'LEFT',
}

export type WeeklyBestBannerInfoType = {
  __typename?: 'WeeklyBestBannerInfoType'
  adId?: Maybe<Scalars['ID']>
  adName?: Maybe<Scalars['String']>
  adSlug?: Maybe<Scalars['String']>
  kindText?: Maybe<Scalars['String']>
  mobileImage?: Maybe<BannerImageType>
  pcImage?: Maybe<BannerImageType>
}

export type WishBrandByCustomerInput = {
  brand: Scalars['ID']
  isWish: Scalars['Boolean']
}

/** 사용자 브랜드 찜/찜취소 */
export type WishBrandByCustomerMutation = {
  __typename?: 'WishBrandByCustomerMutation'
  user?: Maybe<UserType>
}

export type WishProductByCustomerInput = {
  isWish: Scalars['Boolean']
  product: Scalars['ID']
}

/** 사용자 프로덕트 찜/찜취소 */
export type WishProductByCustomerMutation = {
  __typename?: 'WishProductByCustomerMutation'
  user?: Maybe<UserType>
}
