import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import ROUTES from '@/constants/legacy/constRoutes'
import USER_QUERY_GQLS from '@/containers/gqls/accounts/user/queries'
import MUTATION_VARIABLES from '@/containers/gqls/base/mutation_variables'
import { useApiMutation, useCustomRouter, useModal } from '@/containers/hooks/index'
import { UseModalProps } from '@/containers/hooks/useModal'
import { IModelRestockProductOptionUser } from '@/containers/models/modelRestockProductOptionUser'
import { UserContainer } from '@/containers/users/UserContainer'
import { doSetRestockProductOptions } from '@/stores/reduxData'
import { doSetGlobalNoticeModal, doSetGlobalToastContent, doSetToggleModalLogin } from '@/stores/reduxUI'
import { RootState } from '@/stores/store'
import { Bridge } from '@/utils/bridge/bridge'
import { getResultFromData } from '@/utils/utilApi'
import appBridgeProvider, { appBridgeLogin, isInAppFlag } from '@/utils/utilBridge'
import { deepcopy } from '@/utils/utilData'

type UseRestockProductOptionType = {
  isRequestedRestockProductOption: (productOptionId?: string) => boolean
  addRestockProductOption: (productOptionId?: string) => Promise<boolean>
  removeRestockProductOption: (productOptionId?: string) => Promise<boolean>
  requestRestockProductOption: (
    isAdditional: boolean,
    productOptionId: string,
    tracker: () => void
  ) => Promise<boolean | undefined>
  restockProductOptionAddedProps: UseModalProps<any>
}

const useRestockProductOption = (): UseRestockProductOptionType => {
  const dispatch = useDispatch()

  const { me } = UserContainer.useContainer()
  const bridgeLogin = appBridgeLogin()
  const { push, asPath } = useCustomRouter()
  const { useModalProps: restockProductOptionAddedProps } = useModal()

  const restockProductOptionsRedux: string[] | undefined = useSelector(
    (state: RootState) => state.reduxDataReducers.restockProductOptionIds
  )
  const [_restockProductOptionIds, _setRestockProductOptionIds] = useState<string[] | undefined>(
    restockProductOptionsRedux
  )

  const [userRestocksQuery] = useLazyQuery(USER_QUERY_GQLS.USER_RESTOCKS, {
    fetchPolicy: 'no-cache',
    onCompleted: (userForRestock) => {
      if (userForRestock) {
        const userRestocks = getResultFromData(userForRestock?.user)?.userRestocks
          .data as IModelRestockProductOptionUser[]
        if (userRestocks) {
          const userRestocksIds = userRestocks.map(
            (restockProductOptionUser: IModelRestockProductOptionUser) => restockProductOptionUser.productOption.id
          )
          _setRestockProductOptionIds(userRestocksIds)
          _setRestockProductOptionsRedux(userRestocksIds)
        }
      }
    },
  })

  useEffect(() => {
    if (me?.id) {
      if (restockProductOptionsRedux) {
        _setRestockProductOptionIds(restockProductOptionsRedux)
      } else {
        userRestocksQuery({ variables: { id: me.id } })
      }
    } else {
      _setRestockProductOptionIds(undefined)
      _clearRestockProductOptionsRedux()
    }
  }, [me?.id])

  const _addRestockProductOption = (productOptionId: string) => {
    const newRestockProductOptionIds = deepcopy(_restockProductOptionIds) || []
    newRestockProductOptionIds.push(productOptionId)
    _setRestockProductOptionIds(newRestockProductOptionIds)
    _setRestockProductOptionsRedux(newRestockProductOptionIds)
  }

  const _removeRestockProductOption = (productOptionId: string) => {
    const newRestockProductOptionIds =
      deepcopy(_restockProductOptionIds)?.filter((restockProductOptionId: string) => {
        return restockProductOptionId !== productOptionId
      }) || []
    _setRestockProductOptionIds(newRestockProductOptionIds)
    _setRestockProductOptionsRedux(newRestockProductOptionIds)
  }

  const [toggleRestockProductOptionMutation] = useApiMutation('toggleRestockProductOption')

  const isRequestedRestockProductOption = (productOptionId?: string): boolean => {
    if (!productOptionId) {
      return false
    }
    const result = _restockProductOptionIds?.find(
      (restockProductOption: string) => restockProductOption === productOptionId
    )
    return result !== undefined
  }

  const _toggleRestockProductOption = async (productOptionId: string): Promise<boolean> => {
    const variables = MUTATION_VARIABLES.TOGGLE_RESTOCK_PRODUCT_OPTION({ productOption: productOptionId })
    // @ts-ignore
    const { data, errors } = await toggleRestockProductOptionMutation({ variables })
    if (errors) {
      throw new Error(errors?.message)
    }
    const v: IModelRestockProductOptionUser = getResultFromData(data?.toggleRestockProductOption)
      ?.restockProductOptionUser.data
    return v.productOption.id !== undefined
  }

  const addRestockProductOption = async (productOptionId?: string): Promise<boolean> => {
    if (!productOptionId) {
      return false
    }
    try {
      if (!(await _toggleRestockProductOption(productOptionId))) {
        return false
      }
    } catch (e) {
      return false
    }
    _addRestockProductOption(productOptionId)
    return true
  }

  const removeRestockProductOption = async (productOptionId?: string): Promise<boolean> => {
    if (!productOptionId) {
      return false
    }
    try {
      if (await _toggleRestockProductOption(productOptionId)) {
        return false
      }
    } catch (e) {
      return false
    }
    _removeRestockProductOption(productOptionId)
    return true
  }

  const _setRestockProductOptionsRedux = (restockProductOptionIds: string[]) => {
    dispatch(doSetRestockProductOptions(restockProductOptionIds))
  }
  const _clearRestockProductOptionsRedux = () => {
    dispatch(doSetRestockProductOptions(undefined))
  }

  const requestRestockProductOption = async (isAdditional: boolean, productOptionId: string, tracker: () => void) => {
    if (!!me) {
      if (!me?.mobileNumber) {
        if (isInAppFlag) {
          appBridgeProvider((bridge: Bridge) => bridge.authentication())
          window.onforeground = () => {
            if (window.getToken().userInfo.mobileNumber.length > 0) {
              bridgeLogin(window.getToken())
              window.location.reload()
            }
          }
          return
        }
        window.onforeground = null
        push({
          pathname: ROUTES.ACCOUNTS.ADD_INFO,
          query: {
            replace_url: asPath,
          },
        })
        return
      }
      if (isAdditional) {
        if (await addRestockProductOption(productOptionId)) {
          tracker()
          restockProductOptionAddedProps.showModal()
          return true
        } else {
          dispatch(
            doSetGlobalToastContent({
              content: '잠시 후 다시 시도해주세요.',
            })
          )
          return false
        }
      } else {
        dispatch(
          doSetGlobalNoticeModal({
            text: '재입고 알림을 해제하시겠어요?',
            buttonType: 'ACTION_CANCEL',
            okText: '해제하기',
            cancelText: '돌아가기',
            onOk: async () => {
              const result = await removeRestockProductOption(productOptionId)
              if (result) {
                tracker()
              } else {
                dispatch(
                  doSetGlobalToastContent({
                    content: '잠시 후 다시 시도해주세요.',
                  })
                )
              }
            },
            visible: true,
          })
        )
      }
    } else {
      dispatch(doSetToggleModalLogin(true))
    }
  }

  return {
    isRequestedRestockProductOption,
    addRestockProductOption,
    removeRestockProductOption,
    requestRestockProductOption,
    restockProductOptionAddedProps,
  }
}

export default useRestockProductOption
