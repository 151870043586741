/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Fitpet Mall
 * 핏펫몰 API 스펙

API 스펙 작성시 다음 가이드를 숙지하시기 바랍니다.

- [Open API 가이드 참고](https://swagger.io/docs/specification/about/)
- [Kotlin-Spring Open API Generator 설정 참고](https://openapi-generator.tech/docs/generators/kotlin-spring)
- REST API 디자인 가이드 참고
  - doc > guide > rest-api-design.md
- OpenAPI Spec 컨벤션 참고
  - doc > guid > openapi-spec-convention.md
- 설명(description) 작성시 마크다운([CommonMark](https://commonmark.org/help/)) 구문 참고

로컬에서 스웨거 에디터를 사용하는 방법은 다음과 같다.

```shell
docker pull swaggerapi/swagger-editor
docker run -d -p 8123:8080 swaggerapi/swagger-editor
open http://localhost:8123
```

Api 스펙을 하나의 파일로 합치려면 다음과 같이 실행한다.

```shell
# 설치
npm install -g swagger-cli

# 실행
swagger-cli bundle api-spec.yaml --outfile build/openapi.yaml --type yaml
```
 * OpenAPI spec version: 1.0.0
 */

/**
 * 메시지 알림코드

다음은 지원하는 알림코드와 메시지 형태를 보여준다. `%{...}`의 네임드 플레이스홀더는 각각의 이름을 키(key)로 하여
`alimtalkData.templateParams`에 맵 형태의 데이터로 지정해야 한다.

- `SC_0001`: 반려동물 보험 상담 대기 안내
  - ```
    #{반려동물이름} 보호자님, 안녕하세요!
우리 아이에게 딱 맞는 맞춤 보험 견적을 신청해 주셔서 감사합니다. 🐶🐱

현재 먼저 접수된 견적을 차례대로 안내 드리고 있어 시간이 소요될 수 있습니다.

보호자님께서 궁금하신 부분을 남겨주시면, 남겨주신 내용과 함께 보험 견적 상담을 진행 할게요!

펫보험 가입 완료 시 다양한 혜택도 준비되어 있으니, 조금만 기다려 주시면 펫보험 전문 상담가가 컨설팅을 도와드리겠습니다!
    ```

- `SC_0002`: 반려동물 보험 견적 신청 완료
  - ```
    #{반려동물이름} 보호자님, 안녕하세요!
우리 아이에게 딱 맞는 맞춤 보험 견적을 신청해 주셔서 감사합니다. 🐶🐱

상담 운영시간 외 시간에는 바로 안내 드리기 어려운 점 양해 부탁 드리며, 먼저 접수된 견적을 차례대로 안내 드리고 있습니다.

보호자님께서 궁금하신 부분을 남겨주시면 상담 운영 시간에 확인 후 펫보험 전문 상담가가 컨설팅을 도와드리겠습니다!

펫보험 가입 완료 시 다양한 혜택도 준비되어 있으니, 조금만 기다려 주세요.

오늘도 우리 아이와 함께 편안하고 행복한 하루 보내세요!
    ```
 */
export type AlimtalkPetInsuranceCodeEnum =
  typeof AlimtalkPetInsuranceCodeEnum[keyof typeof AlimtalkPetInsuranceCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AlimtalkPetInsuranceCodeEnum = {
  SC_0001: 'SC_0001',
  SC_0002: 'SC_0002',
} as const
