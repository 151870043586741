const GlobalTheme = {
  borderRadius: {
    size4: '0.4rem',
    size8: '0.8rem',
    size50: '5rem',
  },
  spacing: {
    size1: '0.1rem',
    size2: '0.2rem',
    size4: '0.4rem',
    size5: '0.5rem',
    size6: '0.6rem',
    size8: '0.8rem',
    size9: '0.9rem',
    size10: '1rem',
    size12: '1.2rem',
    size13: '1.3rem',
    size14: '1.4rem',
    size16: '1.6rem',
    size18: '1.8rem',
    size20: '2rem',
    size21: '2.1rem',
    size22: '2.2rem',
    size25: '2.5rem',
    size24: '2.4rem',
    size28: '2.8rem',
    size29: '2.9rem',
    size32: '3.2rem',
    size34: '3.4rem',
    size36: '3.6rem',
    size38: '3.8rem',
    size40: '4rem',
    size44: '4.4rem',
    size46: '4.6rem',
    size48: '4.8rem',
    size54: '5.4rem',
    size56: '5.6rem',
    size60: '6rem',
    size64: '6.4rem',
    size70: '7rem',
    size80: '8rem',
    size104: '10.4rem',
    size200: '20rem',
    size233: '23.3rem',
    size320: '32rem',

    // percent
    per50: '50%',
    per100: '100%',

    // vh
    vh80: '80vh',
  },
  color: {
    // gray
    grayBlack: '#000000',
    gray900: '#1B1E21',
    gray800: '#2C3136',
    gray700: '#42494F',
    gray600: '#5A6169',
    gray500: '#727A82',
    gray400: '#8C949C',
    gray300: '#A7AEB5',
    gray200: '#C2C8CF',
    gray100: '#DFE3E8',
    gray70: '#EEF1F5',
    gray50: '#F4F7FA',
    gray30: '#FAFBFD',
    grayWhite: '#FFFFFF',

    // blue
    blue900: '#002999',
    blue800: '#0035A8',
    blue700: '#0041CC',
    blue600: '#0048E3',
    blue500: '#0050FF',
    blue400: '#4580FF',
    blue300: '#6E9EFF',
    blue200: '#8FB8FF',
    blue100: '#B2CFFF',
    blue70: '#CCDFFF',
    blue50: '#E3EDFF',
    blue30: '#EDF4FF',

    // red
    red900: '#991F25',
    red800: '#B22B32',
    red700: '#CC313E',
    red600: '#E5404E',
    red500: '#FF5967',
    red400: '#FF737E',
    red300: '#FF99A2',
    red200: '#FFB2B9',
    red100: '#FFCFD3',
    red70: '#FFDDE0',
    red50: '#FFEDEE',
    red30: '#FFF5F6',

    // yellow
    yellow900: '#997B0E',
    yellow700: '#DEB215',
    yellow500: '#FFD633',
    yellow300: '#FFE880',
    yellow100: '#FFF3B2',

    // mint
    mint900: '#137980',
    mint800: '#188E96',
    mint700: '#1DA4AD',
    mint600: '#22B0BA',
    mint500: '#31BEC8',
    mint400: '#4ED0D9',
    mint300: '#71DBE3',
    mint200: '#8EE7ED',
    mint100: '#ABF0F5',
    mint70: '#C8F7FA',
    mint50: '#E0FDFF',
    mint30: '#EDFEFF',

    // orange
    orange900: '#BD5500',
    orange800: '#CC5F00',
    orange700: '#E06E02',
    orange600: '#F28100',
    orange500: '#FF9300',
    orange400: '#FFA933',
    orange300: '#FFB44F',
    orange200: '#FFC575',
    orange100: '#FFDCAB',
    orange70: '#FFE6C4',
    orange50: '#FFEFD9',
    orange30: '#FFF7ED',

    // sns
    kakaoYellow: '#FFE812',
    naverGreen: '#1EC700',
    appleBlack: '#000000',

    // Membership TODO 임시 입니다 정해지면 다른데 넣겠습니다.
    membershipIntro: '#070d24',

    //Line
    colorLink: '#0b4ecb',

    // status
    critical: '#FF5967',
    success: '#0D9A38',
    link: '#0B4ECB',
    warning: '#DFA309',
  },
  fontSize: {
    // heading
    h1: '3rem',
    h2: '2.6rem',
    h3: '2.4rem',
    h4: '2.2rem',
    h5: '2rem',
    h6: '1.8rem',

    // body
    body1: '1.6rem',
    body2: '1.5rem',
    body3: '1.4rem',
    body4: '1.3rem',
    body5: '1.2rem',

    // caption
    caption1: '1.2rem',
    caption2: '1.1rem',
  },
  fontWeight: {
    bold: 700,
    semiBold: 600,
    medium: 500,
    regular: 400,
  },
  letterSpacing: {
    font: '-0.02em',
  },
  lineHeight: {
    // heading
    h1: '4.2rem',
    h2: '3.6rem',
    h3: '3.4rem',
    h4: '3.1rem',
    h5: '2.8rem',
    h6: '2.5rem',

    // body
    body1: '2.2rem',
    body2: '2.1rem',
    body3: '2rem',
    body4: '1.8rem',
    body5: '1.7rem',

    // caption
    caption1: '1.5rem',
    caption2: '1.4rem',
  },
  iconSize: {
    size11: '1.1rem',
    size12: '1.2rem',
    size16: '1.6rem',
    size18: '1.8rem',
    size20: '2rem',
    size24: '2.4rem',
    size32: '3.2rem',
    size42: '4.2rem',
    size48: '4.8rem',
    size50: '5rem',
    size56: '5.6rem',
    size64: '6.4rem',
    size176: '17.6rem',
  },
  deviceSize: {
    tablet: '576px',
  },
} as const

type GlobalThemeColor = keyof typeof GlobalTheme.color

const isGlobalThemeColor = (color: string): color is GlobalThemeColor => {
  return Object.keys(GlobalTheme.color).includes(color)
}

export { GlobalTheme, isGlobalThemeColor }

export type { GlobalThemeColor }
