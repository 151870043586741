import React from 'react'
import produce from 'immer'
import { createAction, handleActions } from 'redux-actions'
import { ModalButtonType } from '@/components/common/modals/ModalBase'
import { IModelReviewImage } from '@/containers/models/modelReviewImage'
import { ToastVariantKey } from '@/types/toast.type'
import { isEmpty } from '@/utils/utilCommon'

const INIT_ALL = 'ui/INIT_ALL'
const INIT_GLOBAL_TOAST_CONTENT = 'ui/INIT_GLOBAL_TOAST_CONTENT'
const INIT_GLOBAL_SNACK_BAR_CONTENT = 'ui/INIT_GLOBAL_SNACK_BAR_CONTENT'
const SET_IS_SHOW_TOP_SLIM_BANNER = 'ui/SET_IS_SHOW_TOP_SLIM_BANNER'
const SET_HEADER_TITLE = 'ui/SET_HEADER_TITLE'
const SET_GLOBAL_NOTICE_MODAL = 'ui/SET_GLOBAL_NOTICE_MODAL'
const SET_CART_ITEM_COUNT = 'ui/SET_CART_ITEM_COUNT'
const SET_TOGGLE_VISIBLE_LOADER = 'ui/TOGGLE_VISIBLE_LOADER'
const SET_TOGGLE_RELOAD_CART = 'ui/SET_TOGGLE_REFRESH_CART'
const SET_FULL_SCREEN_REVIEW_IMAGES = 'ui/SET_FULL_SCREEN_REVIEW_IMAGES'
const SET_FULL_SCREEN_REVIEW_IMAGES_V2 = 'ui/SET_FULL_SCREEN_REVIEW_IMAGES_V2'
const SET_DONOT_SHOW_POLICY_AGREE_MODAL = 'ui/SET_DONOT_SHOW_POLICY_AGREE_MODAL'
const SET_GLOBAL_TOAST_CONTENT = 'ui/SET_GLOBAL_TOAST_CONTENT'
const SET_GLOBAL_SNACK_BAR_CONTENT = 'ui/SET_GLOBAL_SNACK_BAR_CONTENT'
const SET_BEFORE_HISTORY_LENGTH = 'ui/SET_BEFORE_HISTORY_LENGTH'
const SET_IS_SHOW_SEARCHED_KEYWORDS = 'ui/SET_IS_SHOW_SEARCHED_KEYWORDS'
const SET_IS_SHOW_HEADER = 'ui/SET_IS_SHOW_HEADER'
const SET_TOGGLE_MODAL_LOGIN = 'ui/SET_TOGGLE_MODAL_LOGIN'

export const doInitAllUI = createAction(INIT_ALL)
export const doSetIsShowTopSlimBanner = createAction(SET_IS_SHOW_TOP_SLIM_BANNER, (data: boolean) => data)
export const doSetHeaderTitle = createAction(SET_HEADER_TITLE, (title: string | undefined) => title)
export const doSetGlobalNoticeModal = createAction(SET_GLOBAL_NOTICE_MODAL, (data: GlobalNoticeModalType) => data)
export const doSetCartItemCount = createAction(SET_CART_ITEM_COUNT, (data: number) => data)
export const doSetToggleVisibleLoader = createAction(SET_TOGGLE_VISIBLE_LOADER, (data: boolean) => data)
export const doSetFullScreenReviewImages = createAction(
  SET_FULL_SCREEN_REVIEW_IMAGES,
  (data: FullScreenReviewImagesType) => data
)
export const doSetFullScreenReviewImagesV2 = createAction(
  SET_FULL_SCREEN_REVIEW_IMAGES_V2,
  (data: FullScreenReviewImagesTypeV2) => data
)
export const doSetGlobalToastContent = createAction(SET_GLOBAL_TOAST_CONTENT, (data: ToastMessageType) => data)
export const doInitGlobalToastContent = createAction(INIT_GLOBAL_TOAST_CONTENT)
export const doSetGlobalSnackBarType = createAction(SET_GLOBAL_SNACK_BAR_CONTENT, (data: SnackBarType) => data)
export const doInitGlobalSnackBarContent = createAction(INIT_GLOBAL_SNACK_BAR_CONTENT)
export const doSetBeforeHistoryLength = createAction(SET_BEFORE_HISTORY_LENGTH)
export const doSetIsShowSearchedKeywords = createAction(SET_IS_SHOW_SEARCHED_KEYWORDS)
export const doSetIsShowHeader = createAction(SET_IS_SHOW_HEADER)
export const doSetToggleModalLogin = createAction(SET_TOGGLE_MODAL_LOGIN, (data: boolean) => data)

export type ToastMessageType = {
  content: React.ReactNode
  duration?: number
  onClick?: () => void
  variant?: ToastVariantKey
}

export type SnackBarType = 'addCart' | 'liked'

export type FullScreenReviewImagesType =
  | {
      reviewImages: IModelReviewImage[]
      showIndex: number
    }
  | undefined

export type FullScreenReviewImagesTypeV2 =
  | {
      reviewImages: string[]
      showIndex: number
    }
  | undefined

export type GlobalNoticeModalType = {
  visible?: boolean
  okText?: string
  cancelText?: string
  buttonType?: ModalButtonType
  text?: string
  subText?: string
  onOk?: () => void
  onCancel?: () => void
  afterClose?: () => void
  hideModal?: () => void
  notice?: string
}

type ReduxDataStateType = {
  isShowTopSlimBanner: boolean
  headerTitle: string | undefined
  globalNoticeModal: GlobalNoticeModalType
  fullScreenReviewImages: FullScreenReviewImagesType
  fullScreenReviewImagesV2: FullScreenReviewImagesTypeV2
  cartItemCount: number
  visibleLoader: boolean
  reloadCart: boolean
  doNotShowPolicyAgreeModal: boolean
  globalToastContents: ToastMessageType[]
  globalSnackBarContent: SnackBarType[]
  beforeHistoryLength: number
  isShowSearchedKeywords: boolean
  isShowHeader: boolean
  isShowModalLogin: boolean
}

const initialState: ReduxDataStateType = {
  isShowTopSlimBanner: false,
  visibleLoader: false,
  reloadCart: false,
  doNotShowPolicyAgreeModal: false,
  headerTitle: undefined,
  cartItemCount: 0,
  fullScreenReviewImages: undefined,
  fullScreenReviewImagesV2: undefined,
  globalToastContents: [] as ToastMessageType[],
  globalSnackBarContent: [],
  globalNoticeModal: {
    text: '',
    subText: '',
    visible: false,
    okText: '확인',
    cancelText: '취소',
    buttonType: 'ACTION',
    onOk: () => {},
    onCancel: () => {},
    afterClose: () => {},
    hideModal: () => {
      initialState.globalNoticeModal.visible = false
      initialState.globalNoticeModal.subText = ''
    },
  },
  beforeHistoryLength: 0,
  isShowSearchedKeywords: false,
  isShowHeader: true,
  isShowModalLogin: false,
}

export default handleActions<ReduxDataStateType, any>(
  {
    [SET_DONOT_SHOW_POLICY_AGREE_MODAL]: (state, { payload: toggle }) =>
      produce(state, (draft) => {
        draft.doNotShowPolicyAgreeModal = toggle
      }),
    [SET_IS_SHOW_TOP_SLIM_BANNER]: (state, { payload: isShowTopSlimBanner }) =>
      produce(state, (draft) => {
        draft.isShowTopSlimBanner = isShowTopSlimBanner
      }),
    [SET_TOGGLE_VISIBLE_LOADER]: (state, { payload: toggle }) =>
      produce(state, (draft) => {
        draft.visibleLoader = toggle
      }),
    [SET_TOGGLE_RELOAD_CART]: (state, { payload: toggle }) =>
      produce(state, (draft) => {
        draft.reloadCart = toggle
      }),
    [SET_HEADER_TITLE]: (state, { payload: title }) =>
      produce(state, (draft) => {
        draft.headerTitle = title
      }),
    [SET_CART_ITEM_COUNT]: (state, { payload: cartItemCount }) =>
      produce(state, (draft) => {
        draft.cartItemCount = cartItemCount
      }),
    [SET_FULL_SCREEN_REVIEW_IMAGES]: (state, { payload: fullScreenReviewImages }) =>
      produce(state, (draft) => {
        draft.fullScreenReviewImages = fullScreenReviewImages
      }),
    [SET_FULL_SCREEN_REVIEW_IMAGES_V2]: (state, { payload: fullScreenReviewImages }) =>
      produce(state, (draft) => {
        draft.fullScreenReviewImagesV2 = fullScreenReviewImages
      }),
    [SET_GLOBAL_NOTICE_MODAL]: (state, { payload: globalNoticeModal }) =>
      produce(state, (draft) => {
        const subText = !isEmpty(globalNoticeModal.subText) ? globalNoticeModal.subText : ''
        const onOk = globalNoticeModal.onOk ? globalNoticeModal.onOk : () => {}

        draft.globalNoticeModal = {
          ...initialState.globalNoticeModal,
          ...globalNoticeModal,
          subText,
          onOk,
        }
      }),
    [SET_GLOBAL_TOAST_CONTENT]: (state, { payload: globalToastContent }) =>
      produce(state, (draft) => {
        draft.globalToastContents.push(globalToastContent)
      }),
    [SET_GLOBAL_SNACK_BAR_CONTENT]: (state, { payload: globalSnackBarContent }) =>
      produce(state, (draft) => {
        draft.globalSnackBarContent = [globalSnackBarContent]
      }),
    [INIT_GLOBAL_TOAST_CONTENT]: (state) =>
      produce(state, (draft) => {
        draft.globalToastContents = []
      }),
    [INIT_GLOBAL_SNACK_BAR_CONTENT]: (state) =>
      produce(state, (draft) => {
        draft.globalSnackBarContent = []
      }),
    [SET_BEFORE_HISTORY_LENGTH]: (state, { payload: beforeHistoryLength }) =>
      produce(state, (draft) => {
        draft.beforeHistoryLength = beforeHistoryLength
      }),
    [SET_IS_SHOW_SEARCHED_KEYWORDS]: (state, { payload: isShowSearchedKeywords }) =>
      produce(state, (draft) => {
        draft.isShowSearchedKeywords = isShowSearchedKeywords
      }),
    [SET_IS_SHOW_HEADER]: (state, { payload: isShowHeader }) =>
      produce(state, (draft) => {
        draft.isShowHeader = isShowHeader
      }),
    [INIT_ALL]: (state) =>
      produce(state, (draft) => {
        draft.isShowTopSlimBanner = initialState.isShowTopSlimBanner
        draft.headerTitle = initialState.headerTitle
        draft.globalNoticeModal = initialState.globalNoticeModal
        draft.cartItemCount = initialState.cartItemCount
        // TODO typescript 오류 수정 필요
        // @ts-ignore
        draft.globalToastContents = initialState.globalToastContents
      }),
    [SET_TOGGLE_MODAL_LOGIN]: (state, { payload: toggle }) =>
      produce(state, (draft) => {
        draft.isShowModalLogin = toggle
      }),
  },
  initialState
)
