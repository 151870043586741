import { ParsedUrlQuery } from 'querystring'
import { ProductCategoryScheme, ProductDetailResponseDto, SupplyTypeEnum } from '@/apis/rest/generated/types'
import { FitpetMallEventEnum } from '@/constants/fitpet-mall-event.enum'
import { ProductLocationTrackerValue } from '@/constants/product-location.const'
import { QueryStringKeyEnum } from '@/constants/query-string-key.enum'
import { useTracker } from '@/containers/contexts/EventTrackingProvider'
import { BottomSheetEventTrackerType } from '@/types/bottom-sheet-event-tracker.type'
import { ProductTypeDefinedByFrontend } from '@/types/product-type-defined-by.frontend'
import { convertToKoreanValueFromSupplyType } from '@/utils/event-tracker/events'
import { ReferrerUtil } from '@/utils/referrer.util'
import { ProductOption } from '@/components/common/modals/bottomSheet.type'

export const useProductDetailEventTracker = () => {
  const tracker = useTracker()
  const referrer = ReferrerUtil.getReferrer()
  /**
   *
   * @description 상품 상세 페이지 진입 이벤트
   */
  const trackViewProductDetailEvent = ({
    product,
    query,
  }: {
    product: ProductDetailResponseDto
    query: ParsedUrlQuery
  }) => {
    const {
      [QueryStringKeyEnum.Location]: location,
      [QueryStringKeyEnum.PrevPath]: prevPath,
      [QueryStringKeyEnum.SectionName]: sectionName,
    } = query

    const referrerDomain = ReferrerUtil.getReferrerDomain(referrer)
    const searchQuery = ReferrerUtil.getSearchQuery(referrer)

    const properties = {
      product_id: product.id,
      product_name: product.name,
      product_is_in_stock: !product.isRealSoldOut,
      product_price: product.price,
      product_discount_rate: product.discountRate,
      product_purchase_price: product.salePrice,
      product_review_total_count: product.review.count,
      product_review_avgscore: product.review.score,
      is_timesale: product.isTimeSale,
      categoryIds: product.categories.map((category) => category.id),
      categoryNames: product.categories.map((category) => category.name),
      brand_id: product.brand.id,
      brand_name: product.brand.name,
      supply_type: convertToKoreanValueFromSupplyType(product.supplyType),
      view_at: new Date(),
      ...(referrerDomain && {
        search_engine: referrerDomain,
      }),
      ...(searchQuery && {
        search_query: searchQuery,
      }),
      ...(location && {
        location,
      }),
      ...(sectionName && {
        section_name: sectionName,
      }),
      ...(prevPath && {
        eventsale_slug: prevPath,
      }),
      is_shownprice_coupondiscounted: product.hasApplicableProductCoupon,
      is_shownprice_coupondiscount_price: product.salePriceAfterCoupon,
      thumbnail_webp: !!product.animatedMainImage,
      cum_sales_skuquantity: product.salesQuantity,
      max_mileage: product.expectedMileage,
    }

    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewProductdetail, properties)
  }

  /**
   *
   * @description 상품 상세 배송 안내 묶음배송 상품 보기 버튼 클릭 이벤트
   */
  const trackClickProductDetailBundleShippingButton = ({
    productId,
    productName,
    categories,
    price,
    salePrice,
    supplyType,
  }: {
    productId: number
    productName: string
    categories: ProductCategoryScheme[]
    price: number
    salePrice: number
    supplyType: SupplyTypeEnum
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductdetailBundleShippingButton, {
      is_fitpet_shipping: supplyType === SupplyTypeEnum.PB || supplyType === SupplyTypeEnum.BUY,
      product_id: productId,
      product_name: productName,
      categoryIds: categories.map((category) => category.id),
      categoryNames: categories.map((category) => category.name),
      product_price: price,
      product_purchase_price: salePrice,
    })
  }

  /**
   *
   * @description 상품 상세 브랜드 버튼 클릭 이벤트
   */
  const trackClickProductDetailBrandButton = ({
    brandId,
    brandName,
    productId,
    productName,
    isRealSoldOut,
    price,
    discountRate,
    salePrice,
    reviewScore,
    reviewCount,
    isTimeSale,
    categories,
    supplyType,
    listType,
    listSection,
    sectionName,
  }: {
    brandId: number
    brandName: string
    productId: number
    productName: string
    isRealSoldOut: boolean
    price: number
    discountRate: number
    salePrice: number
    reviewScore: number
    reviewCount: number
    isTimeSale: boolean
    categories: ProductCategoryScheme[]
    supplyType: SupplyTypeEnum
    listType?: 'samebrandproduct' | 'ingredient' | 'recentproduct' | 'similarproduct'
    listSection?: 'samebrand'
    sectionName?: string
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductdetailBrandButton, {
      product_id: productId,
      product_name: productName,
      product_is_in_stock: !isRealSoldOut,
      product_price: price,
      product_discount_rate: discountRate,
      product_purchase_price: salePrice,
      product_review_total_count: reviewCount,
      product_review_avgscore: reviewScore,
      is_timesale: isTimeSale,
      categoryIds: categories.map((category) => category.id),
      categoryNames: categories.map((category) => category.name),
      brand_id: brandId,
      brand_name: brandName,
      supply_type: convertToKoreanValueFromSupplyType(supplyType),
      list_type: listType,
      list_section: listSection,
      section_name: sectionName,
    })
  }

  /**
   *
   * @description 상품 상세 더보기 버튼 클릭 이벤트
   */
  const trackClickProductDetailMoreInfo = ({
    brandId,
    brandName,
    productId,
    productName,
    isRealSoldOut,
    price,
    discountRate,
    salePrice,
    reviewScore,
    reviewCount,
    isTimeSale,
    categories,
    supplyType,
  }: {
    brandId: number
    brandName: string
    productId: number
    productName: string
    isRealSoldOut: boolean
    price: number
    discountRate: number
    salePrice: number
    reviewScore: number
    reviewCount: number
    isTimeSale: boolean
    categories: ProductCategoryScheme[]
    supplyType: SupplyTypeEnum
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductdetailMoreinfo, {
      product_id: productId,
      product_name: productName,
      product_is_in_stock: !isRealSoldOut,
      product_price: price,
      product_discount_rate: discountRate,
      product_purchase_price: salePrice,
      product_review_total_count: reviewCount,
      product_review_avgscore: reviewScore,
      is_timesale: isTimeSale,
      categoryIds: categories.map((category) => category.id),
      categoryNames: categories.map((category) => category.name),
      brand_id: brandId,
      brand_name: brandName,
      supply_type: convertToKoreanValueFromSupplyType(supplyType),
    })
  }

  /**
   *
   * @description 상품 상세 리뷰 더보기 클릭 이벤트
   */
  const trackClickProductDetailMoreReviewButton = ({
    brandId,
    brandName,
    productId,
    productName,
    isRealSoldOut,
    price,
    discountRate,
    salePrice,
    reviewScore,
    reviewCount,
    isTimeSale,
    categories,
    supplyType,
  }: {
    brandId: number
    brandName: string
    productId: number
    productName: string
    isRealSoldOut: boolean
    price: number
    discountRate: number
    salePrice: number
    reviewScore: number
    reviewCount: number
    isTimeSale: boolean
    categories: ProductCategoryScheme[]
    supplyType: SupplyTypeEnum
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductdetailMorereviewButton, {
      product_id: productId,
      product_name: productName,
      product_is_in_stock: !isRealSoldOut,
      product_price: price,
      product_discount_rate: discountRate,
      product_purchase_price: salePrice,
      product_review_total_count: reviewCount,
      product_review_avgscore: reviewScore,
      is_timesale: isTimeSale,
      categoryIds: categories.map((category) => category.id),
      categoryNames: categories.map((category) => category.name),
      brand_id: brandId,
      brand_name: brandName,
      supply_type: convertToKoreanValueFromSupplyType(supplyType),
    })
  }

  /**
   *
   * @description 상품 상세 페이지 상품 리스트 조회 시
   * 1. 성분이 비슷한 상품 리스트
   */
  const trackViewProductDetailMiddleProduct = ({
    listType,
    isDirectResult,
    sectionName,
    chipName,
    categoryTypeName,
    chipListIndex,
    totalChipCount,
  }: {
    listType: string
    isDirectResult: boolean
    sectionName: string
    chipName: string
    categoryTypeName: string
    chipListIndex: number
    totalChipCount: number
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewProductdetailMiddleProduct, {
      is_direct_result: isDirectResult,
      list_type: listType,
      section_name: sectionName,
      chip_name: chipName,
      category_type_name: categoryTypeName,
      total_chip_count: totalChipCount,
      ...(totalChipCount !== 0 && {
        chip_list_index: chipListIndex,
      }),
    })
  }

  /**
   *
   * @description 상품 상세 페이지 비슷한 상품 리스트 조회 시
   */
  const trackViewProductDetailBottomProductFirst = ({ listType }: { listType: string }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewProductdetailBottomProductFirst, {
      list_type: listType,
    })
  }

  /**
   *
   * @description 상품 상세 페이지 최근 본 상품 리스트 조회 시
   */
  const trackViewProductDetailBottomProductSecond = ({ listType }: { listType: string }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewProductdetailBottomProductSecond, {
      list_type: listType,
    })
  }

  /**
   *
   * @description 상품 상세 페이지 상품 리스트 클릭 시
   */
  const trackClickProductDetailBottomProduct = ({
    productId,
    productName,
    isRealSoldOut,
    price,
    discountRate,
    salePrice,
    reviewScore,
    reviewCount,
    listType,
    listIndex,
    clickedProduct,
  }: {
    productId: number
    productName: string
    isRealSoldOut: boolean
    price: number
    discountRate: number
    salePrice: number
    reviewScore: number
    reviewCount: number
    listType: string
    listIndex: number
    clickedProduct: {
      productId: number
      productName: string
      isRealSoldOut: boolean
      price: number
      discountRate: number
      salePrice: number
      reviewScore: number
      reviewCount: number
    }
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductdetailBottomProduct, {
      list_type: listType,
      product_id: clickedProduct.productId,
      product_name: clickedProduct.productName,
      product_purchase_price: clickedProduct.salePrice,
      product_discount_rate: clickedProduct.discountRate,
      product_review_total_count: clickedProduct.reviewCount,
      product_review_avgscore: clickedProduct.reviewScore,
      list_index: listIndex,
      origin_product_discount_rate: discountRate,
      origin_product_id: productId,
      origin_product_is_in_stock: !isRealSoldOut,
      origin_product_name: productName,
      origin_product_price: price,
      origin_product_purchase_price: salePrice,
      origin_product_review_avg_score: reviewScore,
      origin_product_review_total_count: reviewCount,
      list_section: '',
    })
  }

  /**
   *
   * @description 상품 상세 페이지 상품 리스트 클릭 시
   */
  const trackClickProductDetailMiddleProduct = ({
    productId,
    productName,
    isRealSoldOut,
    price,
    discountRate,
    salePrice,
    reviewScore,
    reviewCount,
    listType,
    listSection = '',
    listIndex,
    sectionName,
    clickedProduct,
  }: {
    productId: number
    productName: string
    isRealSoldOut: boolean
    price: number
    discountRate: number
    salePrice: number
    reviewScore: number
    reviewCount: number
    listType: string
    listSection?: 'samebrand' | ''
    listIndex: number
    sectionName?: string
    clickedProduct: {
      brandId?: number
      brandName?: string
      productId: number
      productName: string
      isRealSoldOut: boolean
      price: number
      discountRate: number
      salePrice: number
      reviewScore: number
      reviewCount: number
      ingredient?: {
        chipName: string
        categoryTypeName: string
        chipListIndex: number
        totalChipCount: number
      }
    }
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductdetailMiddleProduct, {
      list_type: listType,
      product_id: clickedProduct.productId,
      product_name: clickedProduct.productName,
      product_purchase_price: clickedProduct.salePrice,
      product_discount_rate: clickedProduct.discountRate,
      product_review_total_count: clickedProduct.reviewCount,
      product_review_avgscore: clickedProduct.reviewScore,
      list_index: listIndex,
      origin_product_discount_rate: discountRate,
      origin_product_id: productId,
      origin_product_is_in_stock: !isRealSoldOut,
      origin_product_name: productName,
      origin_product_price: price,
      origin_product_purchase_price: salePrice,
      origin_product_review_avg_score: reviewScore,
      origin_product_review_total_count: reviewCount,
      list_section: listSection,
      brand_name: clickedProduct.brandName,
      brand_id: clickedProduct.brandId,
      section_name: sectionName,
      ...(clickedProduct.ingredient && {
        chip_name: clickedProduct.ingredient.chipName,
        category_type_name: clickedProduct.ingredient.categoryTypeName,
        total_chip_count: clickedProduct.ingredient.totalChipCount,
        ...(clickedProduct.ingredient.totalChipCount !== 0 && {
          chip_list_index: clickedProduct.ingredient.chipListIndex,
        }),
      }),
    })
  }

  /**
   *
   * @description 상품 상세 하단 구매하기 버튼 클릭 시
   */
  const trackClickProductDetailStartPurchaseButtonEvent = ({
    brandId,
    brandName,
    productId,
    productName,
    isRealSoldOut,
    price,
    discountRate,
    salePrice,
    reviewScore,
    reviewCount,
    isTimeSale,
    categories,
    supplyType,
  }: {
    brandId: number
    brandName: string
    productId: number
    productName: string
    isRealSoldOut: boolean
    price: number
    discountRate: number
    salePrice: number
    reviewScore: number
    reviewCount: number
    isTimeSale: boolean
    categories: ProductCategoryScheme[]
    supplyType: SupplyTypeEnum
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductdetailStartpurchaseButton, {
      product_id: productId,
      product_name: productName,
      product_is_in_stock: !isRealSoldOut,
      product_price: price,
      product_discount_rate: discountRate,
      product_purchase_price: salePrice,
      product_review_total_count: reviewCount,
      product_review_avgscore: reviewScore,
      is_timesale: isTimeSale,
      categoryIds: categories.map((category) => category.id),
      categoryNames: categories.map((category) => category.name),
      brand_id: brandId,
      brand_name: brandName,
      supply_type: convertToKoreanValueFromSupplyType(supplyType),
    })
  }

  const trackClickProductDetailAddToCartButtonEvent = ({
    product,
    productOptionIds,
    productOptionNames,
    addToCartPrice,
    trackerData,
  }: {
    product: ProductTypeDefinedByFrontend
    productOptionIds: number[]
    productOptionNames: string[]
    addToCartPrice: number
    trackerData?: BottomSheetEventTrackerType
  }) => {
    const properties = {
      add_location: trackerData?.addLocation ? ProductLocationTrackerValue[trackerData.addLocation] : undefined,
      list_index: trackerData?.listIndex,
      product_id: product.id,
      addtocart_price: addToCartPrice,
      product_name: product.name,
      supply_type: convertToKoreanValueFromSupplyType(product.supplyType),
      brand_id: product.brand.id,
      brand_name: product.brand.name,
      product_option_ids: productOptionIds,
      product_option_names: productOptionNames,
      category_ids: product.categories?.map((category) => category.id),
      category_names: product.categories?.map((category) => category.name),
      section_name: trackerData?.sectionName,
    }
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductDetailAddToCartButton, properties)
  }

  const trackClickProductDetailTab = ({
    tabName,
    productName,
    productId,
  }: {
    tabName: '상품정보' | '리뷰' | '문의' | '추천'
    productName: string
    productId: number
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductDetailTab, {
      tab_name: tabName,
      origin_product_id: productId,
      origin_product_name: productName,
    })
  }

  const trackViewProductDetailMiddleProductBrand = ({
    listType,
    listSection,
    brandName,
    brandId,
    sectionName,
  }: {
    listType: 'samebrandproduct'
    listSection: 'samebrand'
    brandName: string
    brandId: number
    sectionName: string
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewProductDetailMiddleProductBrand, {
      list_type: listType,
      list_section: listSection,
      brand_name: brandName,
      brand_id: brandId,
      section_name: sectionName,
    })
  }

  const trackClickProductDetailTag = ({
    originProductId,
    originProductName,
    tagName,
  }: {
    originProductId: number
    originProductName: string
    tagName: string
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductDetailTag, {
      origin_product_id: originProductId,
      origin_product_name: originProductName,
      tag_name: tagName,
    })
  }

  /**
   * `ProductDetailBrand` ABTest를 위해 추가.
   *
   * ABTest 끝나고 종료
   */
  const trackViewProductDetailBrand = ({
    productId,
    productName,
    brandId,
    brandName,
    supplyType,
  }: {
    productId: number
    productName: string
    brandId: number
    brandName: string
    supplyType: SupplyTypeEnum
  }) => {
    tracker.triggerCustomEvent(FitpetMallEventEnum.ViewProductDetailBrand, {
      product_id: productId,
      product_name: productName,
      brand_id: brandId,
      brand_name: brandName,
      supply_type: convertToKoreanValueFromSupplyType(supplyType),
    })
  }

  const trackClickProductDetailSingleOptionRestockButton = ({
    isRestockAlarm,
    product,
  }: {
    isRestockAlarm: boolean
    product: ProductDetailResponseDto
  }) => {
    const properties = {
      product_id: product.id,
      product_name: product.name,
      categoryIds: product.categories.map((category) => category.id),
      categoryNames: product.categories.map((category) => category.name),
      product_price: product.price,
      product_purchase_price: product.salePrice,
      product_discount_rate: product.discountRate,
      product_review_avgscore: product.review.score,
      product_review_total_count: product.review.count,
    }

    if (isRestockAlarm) {
      tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductdetailRestockButton, properties)
    } else {
      tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductdetailRestockcancelButton, properties)
    }
  }

  const trackClickProductDetailMultipleOptionRestockButton = ({
    isRestockAlarm,
    productOption,
  }: {
    isRestockAlarm: boolean
    productOption?: ProductOption
  }) => {
    if (!productOption) return

    const properties = {
      product_id: productOption.productId,
      product_option_id: productOption.productOptionId,
      product_option_name: productOption.name,
      product_option_purchase_price: productOption.salePrice,
    }

    if (isRestockAlarm) {
      tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductdetailOptionrestockButton, properties)
    } else {
      tracker.triggerCustomEvent(FitpetMallEventEnum.ClickProductdetailOptionrestockcancelButton, properties)
    }
  }

  return {
    trackViewProductDetailEvent,
    trackClickProductDetailBundleShippingButton,
    trackClickProductDetailBrandButton,
    trackClickProductDetailMoreInfo,
    trackClickProductDetailMoreReviewButton,
    trackViewProductDetailMiddleProduct,
    trackViewProductDetailBottomProductFirst,
    trackViewProductDetailBottomProductSecond,
    trackClickProductDetailBottomProduct,
    trackClickProductDetailMiddleProduct,
    trackClickProductDetailStartPurchaseButtonEvent,
    trackClickProductDetailAddToCartButtonEvent,
    trackClickProductDetailTab,
    trackViewProductDetailMiddleProductBrand,
    trackClickProductDetailTag,
    trackViewProductDetailBrand,
    trackClickProductDetailSingleOptionRestockButton,
    trackClickProductDetailMultipleOptionRestockButton,
  }
}
