/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Fitpet Mall
 * 핏펫몰 API 스펙

API 스펙 작성시 다음 가이드를 숙지하시기 바랍니다.

- [Open API 가이드 참고](https://swagger.io/docs/specification/about/)
- [Kotlin-Spring Open API Generator 설정 참고](https://openapi-generator.tech/docs/generators/kotlin-spring)
- REST API 디자인 가이드 참고
  - doc > guide > rest-api-design.md
- OpenAPI Spec 컨벤션 참고
  - doc > guid > openapi-spec-convention.md
- 설명(description) 작성시 마크다운([CommonMark](https://commonmark.org/help/)) 구문 참고

로컬에서 스웨거 에디터를 사용하는 방법은 다음과 같다.

```shell
docker pull swaggerapi/swagger-editor
docker run -d -p 8123:8080 swaggerapi/swagger-editor
open http://localhost:8123
```

Api 스펙을 하나의 파일로 합치려면 다음과 같이 실행한다.

```shell
# 설치
npm install -g swagger-cli

# 실행
swagger-cli bundle api-spec.yaml --outfile build/openapi.yaml --type yaml
```
 * OpenAPI spec version: 1.0.0
 */
import { useQuery, useMutation } from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query'
import type {
  TokenValidityResponseDto,
  BadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
  InternalServerErrorResponse,
  TokenRequestDto,
  UserInfoResponseDto,
  CountResponseDto,
  ProductsListResponseDtoV2,
  GetLikedProductsParams,
  FrequentlyBoughtSummaryResponseDto,
  SummarizeFrequentlyBoughtParams,
  FrequentlyBoughtResponseDto,
  GetFrequentlyBoughtParams,
  FrequentlyBoughtResponseDtoV2,
  GetFrequentlyBoughtV2Params,
  RecentlyBoughtResponseDto,
  GetRecentlyBoughtParams,
  FitpetPartnersResponseDto,
  FitpetPartnersRegisterResponseDto,
  RegisterPersonalFitpetPartnersRequestDto,
  RegisterBusinessFitpetPartnersRequestDto,
  FitpetPartnersCouponResponseDto,
  CreateFitpetPartnersCouponRequestDto,
  FitpetPartnersRewardResponseDto,
  FitpetPartnersTotalRewardResponseDto,
  FitpetPartnersMonthlyRewardResponseDto,
  GetFitpetPartnersMonthlyRewardParams,
  UpdateUserAgreementRequestDto,
  TextTypeBannerResponseDto,
  GetTextTypeBannersParams,
  TextTypeBannerResponseDtoV2,
  GetTextTypeBannersV2Params,
  ImageTypeBannerResponseDto,
  GetImageTypeBannersParams,
  BrandResponseDto,
  ProductsListResponseDto,
  GetProductsByBrandIdParams,
  GetProductsByBrandIdV2Params,
  AvailableMileageResponseDto,
  GetAvailableMileageBeforePaymentParams,
  EstimateOrderResponseDtoV1,
  EstimateOrderRequestDtoV1,
  NudgeCouponResponseDto,
  EstimateOrderRequestDto,
  ProductDetailResponseDto,
  GetProductDetailParams,
  PetRecommendedProductListResponseDto,
  GetPetRecommendedProductsParams,
  GetRecommendedProductsFromPersonalizeSyncParams,
  SearchProductResponseDtoV2,
  GetSearchProductsV2Params,
  SearchProductResponseDtoV3,
  GetSearchProductsV3Params,
  AvailableSkuResponseDto,
  ProductLikeResponseDto,
  ProductLikeRequestDto,
  RecentlyProductListRequestDto,
  PurchaseEstimateProductSummaryResponseDto,
  PurchaseEstimateProductRequestDto,
  DownloadableCouponListResponseDto,
  GetDownloadableProductCouponsParams,
  ClientMetaListResponseDto,
  GetClientMetaListResponseParams,
  DisplayCollectionListResponseDto,
  GetDisplayCollectionListParams,
  GetBestDisplayCollectionListParams,
  DisplayCollectionProductListResponseDtoV2,
  GetProductsByDisplayCollectionIdV2Params,
  DisplayCollectionProductListResponseDtoV3,
  GetProductsByDisplayCollectionIdV3Params,
  AdSlugDisplayCollectionProductListResponseDto,
  GetProductsByAdSlugV2Params,
  ProductsCategoryListResponseDto,
  GetProductsCategoryListParams,
  GetProductsCategoryByIdParams,
  GetProductsByCategoryIdParams,
  GetProductsByCategoryIdV2Params,
  GnbListResponseDto,
  GetGnbListParams,
  TermsListResponseDto,
  PepperBankCouponResponseDto,
  FileUploadRequestDto,
  DownloadFileParams,
  ABTestGroupListResponseDto,
  GetTestGroupsParams,
  ABTestGroupListResponseDtoV2,
  GetTestGroupsV2Params,
  EstimateCartResponseDto,
  EstimateCartRequestDto,
  DeleteCartItemRequestDto,
  CartResponseDto,
  PutCartItemRequestDto,
  CartItemResponseDto,
  PatchProductOptionIdOfCartItemRequestDto,
  HomeSectionListResponseDto,
  GetHomeSectionsParams,
  ChannelTalkRequestDto,
  ChannelTalkWebHookParams,
} from './types'
import { customAxiosInstance } from '../../../plugins/rest-api-v4'
import type { ErrorType } from '../../../plugins/rest-api-v4'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

/**
 * 인증토큰 밸리데이션
 * @summary 인증토큰 밸리데이션
 */
export const validateAuthToken = (
  tokenRequestDto: TokenRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<TokenValidityResponseDto>(
    {
      url: `/v1/auth/token/validate`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: tokenRequestDto,
    },
    options
  )
}

export const getValidateAuthTokenMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateAuthToken>>,
    TError,
    { data: TokenRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof validateAuthToken>>, TError, { data: TokenRequestDto }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof validateAuthToken>>, { data: TokenRequestDto }> = (
    props
  ) => {
    const { data } = props ?? {}

    return validateAuthToken(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ValidateAuthTokenMutationResult = NonNullable<Awaited<ReturnType<typeof validateAuthToken>>>
export type ValidateAuthTokenMutationBody = TokenRequestDto
export type ValidateAuthTokenMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 인증토큰 밸리데이션
 */
export const useValidateAuthToken = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateAuthToken>>,
    TError,
    { data: TokenRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getValidateAuthTokenMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 로그인 사용자 정보
 * @summary 로그인 사용자 정보
 */
export const getSignInUserInfo = (options?: SecondParameter<typeof customAxiosInstance>, signal?: AbortSignal) => {
  return customAxiosInstance<UserInfoResponseDto>({ url: `/v1/users/me`, method: 'get', signal }, options)
}

export const getGetSignInUserInfoQueryKey = () => [`/v1/users/me`] as const

export const getGetSignInUserInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getSignInUserInfo>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSignInUserInfo>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof getSignInUserInfo>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSignInUserInfoQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSignInUserInfo>>> = ({ signal }) =>
    getSignInUserInfo(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetSignInUserInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getSignInUserInfo>>>
export type GetSignInUserInfoQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 로그인 사용자 정보
 */
export const useGetSignInUserInfo = <
  TData = Awaited<ReturnType<typeof getSignInUserInfo>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSignInUserInfo>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSignInUserInfoQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사용자가 찜한 상품 전체 개수
 * @summary 사용자가 찜한 상품 전체 개수
 */
export const countWishlist = (options?: SecondParameter<typeof customAxiosInstance>, signal?: AbortSignal) => {
  return customAxiosInstance<CountResponseDto>({ url: `/v1/users/me/wishlists/count`, method: 'get', signal }, options)
}

export const getCountWishlistQueryKey = () => [`/v1/users/me/wishlists/count`] as const

export const getCountWishlistQueryOptions = <
  TData = Awaited<ReturnType<typeof countWishlist>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof countWishlist>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof countWishlist>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCountWishlistQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof countWishlist>>> = ({ signal }) =>
    countWishlist(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type CountWishlistQueryResult = NonNullable<Awaited<ReturnType<typeof countWishlist>>>
export type CountWishlistQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 사용자가 찜한 상품 전체 개수
 */
export const useCountWishlist = <
  TData = Awaited<ReturnType<typeof countWishlist>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof countWishlist>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCountWishlistQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * # 셜명

찜한 상품 목록을 반환한다.
 * @summary 찜한 상품 목록
 */
export const getLikedProducts = (
  params: GetLikedProductsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ProductsListResponseDtoV2>(
    { url: `/v1/users/me/wishlists/products`, method: 'get', params, signal },
    options
  )
}

export const getGetLikedProductsQueryKey = (params: GetLikedProductsParams) =>
  [`/v1/users/me/wishlists/products`, ...(params ? [params] : [])] as const

export const getGetLikedProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof getLikedProducts>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetLikedProductsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getLikedProducts>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getLikedProducts>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLikedProductsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLikedProducts>>> = ({ signal }) =>
    getLikedProducts(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetLikedProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getLikedProducts>>>
export type GetLikedProductsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 찜한 상품 목록
 */
export const useGetLikedProducts = <
  TData = Awaited<ReturnType<typeof getLikedProducts>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetLikedProductsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getLikedProducts>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLikedProductsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사용자 장바구니에 담긴 전체 항목 개수
 * @summary 사용자 장바구니에 담긴 전체 항목 개수
 */
export const countCart = (options?: SecondParameter<typeof customAxiosInstance>, signal?: AbortSignal) => {
  return customAxiosInstance<CountResponseDto>({ url: `/v1/users/me/carts/count`, method: 'get', signal }, options)
}

export const getCountCartQueryKey = () => [`/v1/users/me/carts/count`] as const

export const getCountCartQueryOptions = <
  TData = Awaited<ReturnType<typeof countCart>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof countCart>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof countCart>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCountCartQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof countCart>>> = ({ signal }) =>
    countCart(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type CountCartQueryResult = NonNullable<Awaited<ReturnType<typeof countCart>>>
export type CountCartQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 사용자 장바구니에 담긴 전체 항목 개수
 */
export const useCountCart = <
  TData = Awaited<ReturnType<typeof countCart>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof countCart>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCountCartQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사용자 장바구니에 담긴 전체 항목의 총수량
 * @summary 사용자 장바구니에 담긴 전체 항목의 총수량
 */
export const sumCartQuantity = (options?: SecondParameter<typeof customAxiosInstance>, signal?: AbortSignal) => {
  return customAxiosInstance<CountResponseDto>(
    { url: `/v1/users/me/carts/sum-quantity`, method: 'get', signal },
    options
  )
}

export const getSumCartQuantityQueryKey = () => [`/v1/users/me/carts/sum-quantity`] as const

export const getSumCartQuantityQueryOptions = <
  TData = Awaited<ReturnType<typeof sumCartQuantity>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof sumCartQuantity>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof sumCartQuantity>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSumCartQuantityQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sumCartQuantity>>> = ({ signal }) =>
    sumCartQuantity(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type SumCartQuantityQueryResult = NonNullable<Awaited<ReturnType<typeof sumCartQuantity>>>
export type SumCartQuantityQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 사용자 장바구니에 담긴 전체 항목의 총수량
 */
export const useSumCartQuantity = <
  TData = Awaited<ReturnType<typeof sumCartQuantity>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof sumCartQuantity>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSumCartQuantityQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사용자가 자주 구매한 상품 전체 항목 개수
 * @summary 사용자가 자주 구매한 상품 전체 항목 개수
 */
export const countFrequentlyBought = (options?: SecondParameter<typeof customAxiosInstance>, signal?: AbortSignal) => {
  return customAxiosInstance<CountResponseDto>(
    { url: `/v1/users/me/frequently-bought/count`, method: 'get', signal },
    options
  )
}

export const getCountFrequentlyBoughtQueryKey = () => [`/v1/users/me/frequently-bought/count`] as const

export const getCountFrequentlyBoughtQueryOptions = <
  TData = Awaited<ReturnType<typeof countFrequentlyBought>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof countFrequentlyBought>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof countFrequentlyBought>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCountFrequentlyBoughtQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof countFrequentlyBought>>> = ({ signal }) =>
    countFrequentlyBought(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type CountFrequentlyBoughtQueryResult = NonNullable<Awaited<ReturnType<typeof countFrequentlyBought>>>
export type CountFrequentlyBoughtQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 사용자가 자주 구매한 상품 전체 항목 개수
 */
export const useCountFrequentlyBought = <
  TData = Awaited<ReturnType<typeof countFrequentlyBought>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof countFrequentlyBought>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCountFrequentlyBoughtQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사용자가 자주 구매한 상품 요약 목록 조회
 * @summary 사용자가 자주 구매한 상품 요약 목록 조회
 */
export const summarizeFrequentlyBought = (
  params?: SummarizeFrequentlyBoughtParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<FrequentlyBoughtSummaryResponseDto>(
    { url: `/v1/users/me/frequently-bought/summary`, method: 'get', params, signal },
    options
  )
}

export const getSummarizeFrequentlyBoughtQueryKey = (params?: SummarizeFrequentlyBoughtParams) =>
  [`/v1/users/me/frequently-bought/summary`, ...(params ? [params] : [])] as const

export const getSummarizeFrequentlyBoughtQueryOptions = <
  TData = Awaited<ReturnType<typeof summarizeFrequentlyBought>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: SummarizeFrequentlyBoughtParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof summarizeFrequentlyBought>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof summarizeFrequentlyBought>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSummarizeFrequentlyBoughtQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof summarizeFrequentlyBought>>> = ({ signal }) =>
    summarizeFrequentlyBought(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type SummarizeFrequentlyBoughtQueryResult = NonNullable<Awaited<ReturnType<typeof summarizeFrequentlyBought>>>
export type SummarizeFrequentlyBoughtQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 사용자가 자주 구매한 상품 요약 목록 조회
 */
export const useSummarizeFrequentlyBought = <
  TData = Awaited<ReturnType<typeof summarizeFrequentlyBought>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: SummarizeFrequentlyBoughtParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof summarizeFrequentlyBought>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSummarizeFrequentlyBoughtQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사용자가 자주 구매한 상품 목록 조회
 * @summary 사용자가 자주 구매한 상품 목록 조회
 */
export const getFrequentlyBought = (
  params?: GetFrequentlyBoughtParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<FrequentlyBoughtResponseDto>(
    { url: `/v1/users/me/frequently-bought`, method: 'get', params, signal },
    options
  )
}

export const getGetFrequentlyBoughtQueryKey = (params?: GetFrequentlyBoughtParams) =>
  [`/v1/users/me/frequently-bought`, ...(params ? [params] : [])] as const

export const getGetFrequentlyBoughtQueryOptions = <
  TData = Awaited<ReturnType<typeof getFrequentlyBought>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetFrequentlyBoughtParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFrequentlyBought>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getFrequentlyBought>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFrequentlyBoughtQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFrequentlyBought>>> = ({ signal }) =>
    getFrequentlyBought(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetFrequentlyBoughtQueryResult = NonNullable<Awaited<ReturnType<typeof getFrequentlyBought>>>
export type GetFrequentlyBoughtQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 사용자가 자주 구매한 상품 목록 조회
 */
export const useGetFrequentlyBought = <
  TData = Awaited<ReturnType<typeof getFrequentlyBought>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetFrequentlyBoughtParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFrequentlyBought>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFrequentlyBoughtQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사용자가 자주 구매한 상품 목록 조회 (상품 쿠폰 적용 반영)
 * @summary 사용자가 자주 구매한 상품 목록 조회 (상품 쿠폰 적용 반영)
 */
export const getFrequentlyBoughtV2 = (
  params?: GetFrequentlyBoughtV2Params,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<FrequentlyBoughtResponseDtoV2>(
    { url: `/v2/users/me/frequently-bought`, method: 'get', params, signal },
    options
  )
}

export const getGetFrequentlyBoughtV2QueryKey = (params?: GetFrequentlyBoughtV2Params) =>
  [`/v2/users/me/frequently-bought`, ...(params ? [params] : [])] as const

export const getGetFrequentlyBoughtV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getFrequentlyBoughtV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetFrequentlyBoughtV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFrequentlyBoughtV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getFrequentlyBoughtV2>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFrequentlyBoughtV2QueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFrequentlyBoughtV2>>> = ({ signal }) =>
    getFrequentlyBoughtV2(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetFrequentlyBoughtV2QueryResult = NonNullable<Awaited<ReturnType<typeof getFrequentlyBoughtV2>>>
export type GetFrequentlyBoughtV2QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 사용자가 자주 구매한 상품 목록 조회 (상품 쿠폰 적용 반영)
 */
export const useGetFrequentlyBoughtV2 = <
  TData = Awaited<ReturnType<typeof getFrequentlyBoughtV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetFrequentlyBoughtV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFrequentlyBoughtV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFrequentlyBoughtV2QueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 사용자가 최근 구매한 상품 목록 조회
 * @summary 사용자가 최근 구매한 상품 목록 조회
 */
export const getRecentlyBought = (
  params?: GetRecentlyBoughtParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<RecentlyBoughtResponseDto>(
    { url: `/v1/users/me/recently-bought`, method: 'get', params, signal },
    options
  )
}

export const getGetRecentlyBoughtQueryKey = (params?: GetRecentlyBoughtParams) =>
  [`/v1/users/me/recently-bought`, ...(params ? [params] : [])] as const

export const getGetRecentlyBoughtQueryOptions = <
  TData = Awaited<ReturnType<typeof getRecentlyBought>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetRecentlyBoughtParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRecentlyBought>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getRecentlyBought>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRecentlyBoughtQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecentlyBought>>> = ({ signal }) =>
    getRecentlyBought(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetRecentlyBoughtQueryResult = NonNullable<Awaited<ReturnType<typeof getRecentlyBought>>>
export type GetRecentlyBoughtQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 사용자가 최근 구매한 상품 목록 조회
 */
export const useGetRecentlyBought = <
  TData = Awaited<ReturnType<typeof getRecentlyBought>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetRecentlyBoughtParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRecentlyBought>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRecentlyBoughtQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 핏펫파트너스 정보를 조회한다.

400 오류는 다음과 같은 하위 오류코드가 포함될 수 있다.

- `FITPET_PARTNERS_PARTNER_NOT_FOUND`

 * @summary 핏펫파트너스 정보 조회
 */
export const getFitpetPartners = (options?: SecondParameter<typeof customAxiosInstance>, signal?: AbortSignal) => {
  return customAxiosInstance<FitpetPartnersResponseDto>(
    { url: `/v1/users/me/fitpet-partners`, method: 'get', signal },
    options
  )
}

export const getGetFitpetPartnersQueryKey = () => [`/v1/users/me/fitpet-partners`] as const

export const getGetFitpetPartnersQueryOptions = <
  TData = Awaited<ReturnType<typeof getFitpetPartners>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartners>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartners>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFitpetPartnersQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFitpetPartners>>> = ({ signal }) =>
    getFitpetPartners(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetFitpetPartnersQueryResult = NonNullable<Awaited<ReturnType<typeof getFitpetPartners>>>
export type GetFitpetPartnersQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 핏펫파트너스 정보 조회
 */
export const useGetFitpetPartners = <
  TData = Awaited<ReturnType<typeof getFitpetPartners>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartners>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFitpetPartnersQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 핏펫파트너스 등록 가능 여부 확인
 * @summary 핏펫파트너스 등록 가능 여부 확인
 */
export const canRegisterFitpetPartners = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<boolean>(
    { url: `/v1/users/me/fitpet-partners/can-register`, method: 'get', signal },
    options
  )
}

export const getCanRegisterFitpetPartnersQueryKey = () => [`/v1/users/me/fitpet-partners/can-register`] as const

export const getCanRegisterFitpetPartnersQueryOptions = <
  TData = Awaited<ReturnType<typeof canRegisterFitpetPartners>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof canRegisterFitpetPartners>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof canRegisterFitpetPartners>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCanRegisterFitpetPartnersQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof canRegisterFitpetPartners>>> = ({ signal }) =>
    canRegisterFitpetPartners(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type CanRegisterFitpetPartnersQueryResult = NonNullable<Awaited<ReturnType<typeof canRegisterFitpetPartners>>>
export type CanRegisterFitpetPartnersQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 핏펫파트너스 등록 가능 여부 확인
 */
export const useCanRegisterFitpetPartners = <
  TData = Awaited<ReturnType<typeof canRegisterFitpetPartners>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof canRegisterFitpetPartners>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCanRegisterFitpetPartnersQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 개인 핏펫파트너스 정보를 등록한다.

400 오류는 다음과 같은 하위 오류코드가 포함될 수 있다.

- `FITPET_PARTNERS_PARTNER_DUPLICATED`
- `USER_NOT_FOUND`

 * @summary 개인 핏펫파트너스 정보 등록
 */
export const registerPersonalFitpetPartners = (
  registerPersonalFitpetPartnersRequestDto: RegisterPersonalFitpetPartnersRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<FitpetPartnersRegisterResponseDto>(
    {
      url: `/v1/users/me/fitpet-partners/register-personal`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: registerPersonalFitpetPartnersRequestDto,
    },
    options
  )
}

export const getRegisterPersonalFitpetPartnersMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerPersonalFitpetPartners>>,
    TError,
    { data: RegisterPersonalFitpetPartnersRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof registerPersonalFitpetPartners>>,
  TError,
  { data: RegisterPersonalFitpetPartnersRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registerPersonalFitpetPartners>>,
    { data: RegisterPersonalFitpetPartnersRequestDto }
  > = (props) => {
    const { data } = props ?? {}

    return registerPersonalFitpetPartners(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RegisterPersonalFitpetPartnersMutationResult = NonNullable<
  Awaited<ReturnType<typeof registerPersonalFitpetPartners>>
>
export type RegisterPersonalFitpetPartnersMutationBody = RegisterPersonalFitpetPartnersRequestDto
export type RegisterPersonalFitpetPartnersMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 개인 핏펫파트너스 정보 등록
 */
export const useRegisterPersonalFitpetPartners = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerPersonalFitpetPartners>>,
    TError,
    { data: RegisterPersonalFitpetPartnersRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getRegisterPersonalFitpetPartnersMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 사업자 핏펫파트너스 정보를 등록한다.

400 오류는 다음과 같은 하위 오류코드가 포함될 수 있다.

- `FITPET_PARTNERS_PARTNER_DUPLICATED`
- `USER_NOT_FOUND`

 * @summary 사업자 핏펫파트너스 정보 등록
 */
export const registerBusinessFitpetPartners = (
  registerBusinessFitpetPartnersRequestDto: RegisterBusinessFitpetPartnersRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<FitpetPartnersRegisterResponseDto>(
    {
      url: `/v1/users/me/fitpet-partners/register-business`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: registerBusinessFitpetPartnersRequestDto,
    },
    options
  )
}

export const getRegisterBusinessFitpetPartnersMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerBusinessFitpetPartners>>,
    TError,
    { data: RegisterBusinessFitpetPartnersRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof registerBusinessFitpetPartners>>,
  TError,
  { data: RegisterBusinessFitpetPartnersRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registerBusinessFitpetPartners>>,
    { data: RegisterBusinessFitpetPartnersRequestDto }
  > = (props) => {
    const { data } = props ?? {}

    return registerBusinessFitpetPartners(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type RegisterBusinessFitpetPartnersMutationResult = NonNullable<
  Awaited<ReturnType<typeof registerBusinessFitpetPartners>>
>
export type RegisterBusinessFitpetPartnersMutationBody = RegisterBusinessFitpetPartnersRequestDto
export type RegisterBusinessFitpetPartnersMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 사업자 핏펫파트너스 정보 등록
 */
export const useRegisterBusinessFitpetPartners = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerBusinessFitpetPartners>>,
    TError,
    { data: RegisterBusinessFitpetPartnersRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getRegisterBusinessFitpetPartnersMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 키워드 쿠폰 조회
 * @summary 키워드 쿠폰 조회
 */
export const getFitpetPartnersCoupons = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<FitpetPartnersCouponResponseDto[]>(
    { url: `/v1/users/me/fitpet-partners/keyword-coupons`, method: 'get', signal },
    options
  )
}

export const getGetFitpetPartnersCouponsQueryKey = () => [`/v1/users/me/fitpet-partners/keyword-coupons`] as const

export const getGetFitpetPartnersCouponsQueryOptions = <
  TData = Awaited<ReturnType<typeof getFitpetPartnersCoupons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartnersCoupons>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartnersCoupons>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFitpetPartnersCouponsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFitpetPartnersCoupons>>> = ({ signal }) =>
    getFitpetPartnersCoupons(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetFitpetPartnersCouponsQueryResult = NonNullable<Awaited<ReturnType<typeof getFitpetPartnersCoupons>>>
export type GetFitpetPartnersCouponsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 키워드 쿠폰 조회
 */
export const useGetFitpetPartnersCoupons = <
  TData = Awaited<ReturnType<typeof getFitpetPartnersCoupons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartnersCoupons>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFitpetPartnersCouponsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 키워드 쿠폰을 생성한다.

400 오류는 다음과 같은 하위 오류코드가 포함될 수 있다.

- `CAN_NOT_CREATE_KEYWORD_COUPON`
- `KEYWORD_COUPON_DUPLICATED`
- `FITPET_PARTNERS_PARTNER_NOT_FOUND`

 * @summary 키워드 쿠폰 생성
 */
export const createFitpetPartnersCoupon = (
  createFitpetPartnersCouponRequestDto: CreateFitpetPartnersCouponRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<FitpetPartnersCouponResponseDto>(
    {
      url: `/v1/users/me/fitpet-partners/keyword-coupons`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createFitpetPartnersCouponRequestDto,
    },
    options
  )
}

export const getCreateFitpetPartnersCouponMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFitpetPartnersCoupon>>,
    TError,
    { data: CreateFitpetPartnersCouponRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof createFitpetPartnersCoupon>>,
  TError,
  { data: CreateFitpetPartnersCouponRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createFitpetPartnersCoupon>>,
    { data: CreateFitpetPartnersCouponRequestDto }
  > = (props) => {
    const { data } = props ?? {}

    return createFitpetPartnersCoupon(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateFitpetPartnersCouponMutationResult = NonNullable<
  Awaited<ReturnType<typeof createFitpetPartnersCoupon>>
>
export type CreateFitpetPartnersCouponMutationBody = CreateFitpetPartnersCouponRequestDto
export type CreateFitpetPartnersCouponMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 키워드 쿠폰 생성
 */
export const useCreateFitpetPartnersCoupon = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFitpetPartnersCoupon>>,
    TError,
    { data: CreateFitpetPartnersCouponRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getCreateFitpetPartnersCouponMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 핏펫 파트너스 리워드 조회
 * @summary 핏펫 파트너스 리워드 조회
 */
export const getFitpetPartnersReward = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<FitpetPartnersRewardResponseDto>(
    { url: `/v1/users/me/fitpet-partners/reward`, method: 'get', signal },
    options
  )
}

export const getGetFitpetPartnersRewardQueryKey = () => [`/v1/users/me/fitpet-partners/reward`] as const

export const getGetFitpetPartnersRewardQueryOptions = <
  TData = Awaited<ReturnType<typeof getFitpetPartnersReward>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartnersReward>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartnersReward>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFitpetPartnersRewardQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFitpetPartnersReward>>> = ({ signal }) =>
    getFitpetPartnersReward(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetFitpetPartnersRewardQueryResult = NonNullable<Awaited<ReturnType<typeof getFitpetPartnersReward>>>
export type GetFitpetPartnersRewardQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 핏펫 파트너스 리워드 조회
 */
export const useGetFitpetPartnersReward = <
  TData = Awaited<ReturnType<typeof getFitpetPartnersReward>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartnersReward>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFitpetPartnersRewardQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 핏펫 파트너스 전체 리워드 조회
 * @summary 핏펫 파트너스 전체 리워드 조회
 */
export const getFitpetPartnersTotalReward = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<FitpetPartnersTotalRewardResponseDto>(
    { url: `/v1/users/me/fitpet-partners/reward/total`, method: 'get', signal },
    options
  )
}

export const getGetFitpetPartnersTotalRewardQueryKey = () => [`/v1/users/me/fitpet-partners/reward/total`] as const

export const getGetFitpetPartnersTotalRewardQueryOptions = <
  TData = Awaited<ReturnType<typeof getFitpetPartnersTotalReward>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartnersTotalReward>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartnersTotalReward>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFitpetPartnersTotalRewardQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFitpetPartnersTotalReward>>> = ({ signal }) =>
    getFitpetPartnersTotalReward(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetFitpetPartnersTotalRewardQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFitpetPartnersTotalReward>>
>
export type GetFitpetPartnersTotalRewardQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 핏펫 파트너스 전체 리워드 조회
 */
export const useGetFitpetPartnersTotalReward = <
  TData = Awaited<ReturnType<typeof getFitpetPartnersTotalReward>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartnersTotalReward>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFitpetPartnersTotalRewardQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 핏펫 파트너스 월별 리워드를 조회한다.

400 오류는 다음과 같은 하위 오류코드가 포함될 수 있다.

- `FITPET_PARTNERS_PARTNER_NOT_FOUND`

 * @summary 핏펫 파트너스 월별 리워드 조회
 */
export const getFitpetPartnersMonthlyReward = (
  params: GetFitpetPartnersMonthlyRewardParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<FitpetPartnersMonthlyRewardResponseDto>(
    { url: `/v1/users/me/fitpet-partners/reward/monthly`, method: 'get', params, signal },
    options
  )
}

export const getGetFitpetPartnersMonthlyRewardQueryKey = (params: GetFitpetPartnersMonthlyRewardParams) =>
  [`/v1/users/me/fitpet-partners/reward/monthly`, ...(params ? [params] : [])] as const

export const getGetFitpetPartnersMonthlyRewardQueryOptions = <
  TData = Awaited<ReturnType<typeof getFitpetPartnersMonthlyReward>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetFitpetPartnersMonthlyRewardParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartnersMonthlyReward>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartnersMonthlyReward>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFitpetPartnersMonthlyRewardQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFitpetPartnersMonthlyReward>>> = ({ signal }) =>
    getFitpetPartnersMonthlyReward(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetFitpetPartnersMonthlyRewardQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFitpetPartnersMonthlyReward>>
>
export type GetFitpetPartnersMonthlyRewardQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 핏펫 파트너스 월별 리워드 조회
 */
export const useGetFitpetPartnersMonthlyReward = <
  TData = Awaited<ReturnType<typeof getFitpetPartnersMonthlyReward>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetFitpetPartnersMonthlyRewardParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFitpetPartnersMonthlyReward>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFitpetPartnersMonthlyRewardQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 로그인 사용자 마케팅 수신여부 토글
 * @summary 로그인 사용자 마케팅 수신여부 토글
 */
export const updateUserAgreement = (
  updateUserAgreementRequestDto: UpdateUserAgreementRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<UserInfoResponseDto>(
    {
      url: `/v1/users/me/agreement`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: updateUserAgreementRequestDto,
    },
    options
  )
}

export const getUpdateUserAgreementMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserAgreement>>,
    TError,
    { data: UpdateUserAgreementRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserAgreement>>,
  TError,
  { data: UpdateUserAgreementRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserAgreement>>,
    { data: UpdateUserAgreementRequestDto }
  > = (props) => {
    const { data } = props ?? {}

    return updateUserAgreement(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateUserAgreementMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserAgreement>>>
export type UpdateUserAgreementMutationBody = UpdateUserAgreementRequestDto
export type UpdateUserAgreementMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 로그인 사용자 마케팅 수신여부 토글
 */
export const useUpdateUserAgreement = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserAgreement>>,
    TError,
    { data: UpdateUserAgreementRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getUpdateUserAgreementMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * # 설명

배너 구좌에 해당하는 텍스트 유형 배너 목록 조회. 리뷰 작성 배너인지 여부는 `tags` 가 `WRITE_REVIEW` 항목을 포함하는지 체크하면 된다.
리뷰 작성 배너의 경우 로그인한 사용자에게만 노출된다. 따라서 로그인한 사용자의 경우 호출할 때 인증 정보를 넘겨줘야 한다.

# 변경점

_없음_

## 제거 가능 시점

2주동안 호출한 이력이 없을 경우 제거한다.

# 참고

- 이전 버전: _없음_
- 다음 버전: `/v2/banners/text-type`
 * @deprecated
 * @summary 텍스트 유형 배너 목록 조회
 */
export const getTextTypeBanners = (
  params: GetTextTypeBannersParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<TextTypeBannerResponseDto[]>(
    { url: `/v1/banners/text-type`, method: 'get', params, signal },
    options
  )
}

export const getGetTextTypeBannersQueryKey = (params: GetTextTypeBannersParams) =>
  [`/v1/banners/text-type`, ...(params ? [params] : [])] as const

export const getGetTextTypeBannersQueryOptions = <
  TData = Awaited<ReturnType<typeof getTextTypeBanners>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetTextTypeBannersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTextTypeBanners>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getTextTypeBanners>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTextTypeBannersQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTextTypeBanners>>> = ({ signal }) =>
    getTextTypeBanners(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetTextTypeBannersQueryResult = NonNullable<Awaited<ReturnType<typeof getTextTypeBanners>>>
export type GetTextTypeBannersQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @deprecated
 * @summary 텍스트 유형 배너 목록 조회
 */
export const useGetTextTypeBanners = <
  TData = Awaited<ReturnType<typeof getTextTypeBanners>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetTextTypeBannersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTextTypeBanners>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTextTypeBannersQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * # 설명

배너 구좌에 해당하는 텍스트 유형 배너 목록 조회. 리뷰 작성 배너인지 여부는 `tags` 가 `WRITE_REVIEW` 항목을 포함하는지 체크하면 된다.
리뷰 작성 배너의 경우 로그인한 사용자에게만 노출된다. 따라서 로그인한 사용자의 경우 호출할 때 인증 정보를 넘겨줘야 한다.

# 변경점
## 마이페이지 배너 구좌 추가

`MY_PAGE` 마이페이지 배너 구좌가 추가되었다.

## 응답 구조

주요 필드의 변경점은 다음과 같다.

- `sideImageUrl`: 측면 이미지 URL 추가

# 참고

- 이전 버전: `/v1/banners/text-type`
- 다음 버전: _없음_
 * @summary 텍스트 유형 배너 목록 조회
 */
export const getTextTypeBannersV2 = (
  params: GetTextTypeBannersV2Params,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<TextTypeBannerResponseDtoV2[]>(
    { url: `/v2/banners/text-type`, method: 'get', params, signal },
    options
  )
}

export const getGetTextTypeBannersV2QueryKey = (params: GetTextTypeBannersV2Params) =>
  [`/v2/banners/text-type`, ...(params ? [params] : [])] as const

export const getGetTextTypeBannersV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getTextTypeBannersV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetTextTypeBannersV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTextTypeBannersV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getTextTypeBannersV2>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTextTypeBannersV2QueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTextTypeBannersV2>>> = ({ signal }) =>
    getTextTypeBannersV2(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetTextTypeBannersV2QueryResult = NonNullable<Awaited<ReturnType<typeof getTextTypeBannersV2>>>
export type GetTextTypeBannersV2QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 텍스트 유형 배너 목록 조회
 */
export const useGetTextTypeBannersV2 = <
  TData = Awaited<ReturnType<typeof getTextTypeBannersV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetTextTypeBannersV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTextTypeBannersV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTextTypeBannersV2QueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 배너 구좌에 해당하는 이미지 유형 배너 목록 조회
 * @summary 이미지 유형 배너 목록 조회
 */
export const getImageTypeBanners = (
  params: GetImageTypeBannersParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ImageTypeBannerResponseDto[]>(
    { url: `/v1/banners/image-type`, method: 'get', params, signal },
    options
  )
}

export const getGetImageTypeBannersQueryKey = (params: GetImageTypeBannersParams) =>
  [`/v1/banners/image-type`, ...(params ? [params] : [])] as const

export const getGetImageTypeBannersQueryOptions = <
  TData = Awaited<ReturnType<typeof getImageTypeBanners>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetImageTypeBannersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getImageTypeBanners>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getImageTypeBanners>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetImageTypeBannersQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getImageTypeBanners>>> = ({ signal }) =>
    getImageTypeBanners(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetImageTypeBannersQueryResult = NonNullable<Awaited<ReturnType<typeof getImageTypeBanners>>>
export type GetImageTypeBannersQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 이미지 유형 배너 목록 조회
 */
export const useGetImageTypeBanners = <
  TData = Awaited<ReturnType<typeof getImageTypeBanners>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetImageTypeBannersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getImageTypeBanners>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetImageTypeBannersQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 브랜드 조회
 * @summary 브랜드 조회
 */
export const getBrandById = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<BrandResponseDto>({ url: `/v1/brands/${id}`, method: 'get', signal }, options)
}

export const getGetBrandByIdQueryKey = (id: number) => [`/v1/brands/${id}`] as const

export const getGetBrandByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getBrandById>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBrandById>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getBrandById>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetBrandByIdQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBrandById>>> = ({ signal }) =>
    getBrandById(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetBrandByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getBrandById>>>
export type GetBrandByIdQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 브랜드 조회
 */
export const useGetBrandById = <
  TData = Awaited<ReturnType<typeof getBrandById>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBrandById>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBrandByIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * # 설명

브랜드별 상품 목록을 조회한다.

# 변경점

_없음_

## 제거 가능 시점

2주동안 호출한 이력이 없을 경우 제거한다.

# 참고

- 이전 버전: _없음_
- 다음 버전: `/v2/brands/{id}/products`
 * @summary 브랜드별 상품 목록
 */
export const getProductsByBrandId = (
  id: number,
  params: GetProductsByBrandIdParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ProductsListResponseDto>(
    { url: `/v1/brands/${id}/products`, method: 'get', params, signal },
    options
  )
}

export const getGetProductsByBrandIdQueryKey = (id: number, params: GetProductsByBrandIdParams) =>
  [`/v1/brands/${id}/products`, ...(params ? [params] : [])] as const

export const getGetProductsByBrandIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsByBrandId>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params: GetProductsByBrandIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByBrandId>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getProductsByBrandId>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductsByBrandIdQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsByBrandId>>> = ({ signal }) =>
    getProductsByBrandId(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetProductsByBrandIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProductsByBrandId>>>
export type GetProductsByBrandIdQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 브랜드별 상품 목록
 */
export const useGetProductsByBrandId = <
  TData = Awaited<ReturnType<typeof getProductsByBrandId>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params: GetProductsByBrandIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByBrandId>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsByBrandIdQueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * # 설명

브랜드별 상품 목록을 조회한다.

# 변경점
## 역정규화 값 사용

상품 정보를 역정규화된 값을 사용하도록 변경되었다

## 상품 쿠폰 할인가 노출

상품 쿠폰 할인가가 추가되었다

# 참고

- 이전 버전: `/v1/brands/{id}/products`
- 다음 버전: _없음_
 * @summary 브랜드별 상품 목록
 */
export const getProductsByBrandIdV2 = (
  id: number,
  params: GetProductsByBrandIdV2Params,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ProductsListResponseDtoV2>(
    { url: `/v2/brands/${id}/products`, method: 'get', params, signal },
    options
  )
}

export const getGetProductsByBrandIdV2QueryKey = (id: number, params: GetProductsByBrandIdV2Params) =>
  [`/v2/brands/${id}/products`, ...(params ? [params] : [])] as const

export const getGetProductsByBrandIdV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsByBrandIdV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params: GetProductsByBrandIdV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByBrandIdV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getProductsByBrandIdV2>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductsByBrandIdV2QueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsByBrandIdV2>>> = ({ signal }) =>
    getProductsByBrandIdV2(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetProductsByBrandIdV2QueryResult = NonNullable<Awaited<ReturnType<typeof getProductsByBrandIdV2>>>
export type GetProductsByBrandIdV2QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 브랜드별 상품 목록
 */
export const useGetProductsByBrandIdV2 = <
  TData = Awaited<ReturnType<typeof getProductsByBrandIdV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params: GetProductsByBrandIdV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByBrandIdV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsByBrandIdV2QueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 주문시 결제 금액에 대한 유저의 최대 사용 가능한 적립금 금액
 * @deprecated
 * @summary 주문시 최대 사용 가능한 적립금
 */
export const getAvailableMileageBeforePayment = (
  params: GetAvailableMileageBeforePaymentParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<AvailableMileageResponseDto>(
    { url: `/v1/orders/before-payment/available-mileage`, method: 'get', params, signal },
    options
  )
}

export const getGetAvailableMileageBeforePaymentQueryKey = (params: GetAvailableMileageBeforePaymentParams) =>
  [`/v1/orders/before-payment/available-mileage`, ...(params ? [params] : [])] as const

export const getGetAvailableMileageBeforePaymentQueryOptions = <
  TData = Awaited<ReturnType<typeof getAvailableMileageBeforePayment>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetAvailableMileageBeforePaymentParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAvailableMileageBeforePayment>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getAvailableMileageBeforePayment>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAvailableMileageBeforePaymentQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAvailableMileageBeforePayment>>> = ({ signal }) =>
    getAvailableMileageBeforePayment(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetAvailableMileageBeforePaymentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAvailableMileageBeforePayment>>
>
export type GetAvailableMileageBeforePaymentQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @deprecated
 * @summary 주문시 최대 사용 가능한 적립금
 */
export const useGetAvailableMileageBeforePayment = <
  TData = Awaited<ReturnType<typeof getAvailableMileageBeforePayment>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetAvailableMileageBeforePaymentParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAvailableMileageBeforePayment>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAvailableMileageBeforePaymentQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 주문 결제금액 정보 산정
 * @summary 주문 결제금액 정보 산정
 */
export const estimateOrder = (
  estimateOrderRequestDtoV1: EstimateOrderRequestDtoV1,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<EstimateOrderResponseDtoV1>(
    {
      url: `/v1/orders/estimate-order`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: estimateOrderRequestDtoV1,
    },
    options
  )
}

export const getEstimateOrderMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof estimateOrder>>,
    TError,
    { data: EstimateOrderRequestDtoV1 },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof estimateOrder>>,
  TError,
  { data: EstimateOrderRequestDtoV1 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof estimateOrder>>, { data: EstimateOrderRequestDtoV1 }> = (
    props
  ) => {
    const { data } = props ?? {}

    return estimateOrder(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type EstimateOrderMutationResult = NonNullable<Awaited<ReturnType<typeof estimateOrder>>>
export type EstimateOrderMutationBody = EstimateOrderRequestDtoV1
export type EstimateOrderMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 주문 결제금액 정보 산정
 */
export const useEstimateOrder = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof estimateOrder>>,
    TError,
    { data: EstimateOrderRequestDtoV1 },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getEstimateOrderMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 주문 결제 금액 정보를 장바구니 정보로 산정하여 넛징 쿠폰을 조회합니다.
 * @deprecated
 * @summary 주문 넛징 쿠폰 조회
 */
export const getNudgeCoupon = (
  estimateOrderRequestDto: EstimateOrderRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<NudgeCouponResponseDto>(
    {
      url: `/v1/orders/nudge-coupon`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: estimateOrderRequestDto,
    },
    options
  )
}

export const getGetNudgeCouponMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getNudgeCoupon>>,
    TError,
    { data: EstimateOrderRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof getNudgeCoupon>>,
  TError,
  { data: EstimateOrderRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof getNudgeCoupon>>, { data: EstimateOrderRequestDto }> = (
    props
  ) => {
    const { data } = props ?? {}

    return getNudgeCoupon(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetNudgeCouponMutationResult = NonNullable<Awaited<ReturnType<typeof getNudgeCoupon>>>
export type GetNudgeCouponMutationBody = EstimateOrderRequestDto
export type GetNudgeCouponMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @deprecated
 * @summary 주문 넛징 쿠폰 조회
 */
export const useGetNudgeCoupon = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getNudgeCoupon>>,
    TError,
    { data: EstimateOrderRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getGetNudgeCouponMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 상세
 * @summary 상품 상세
 */
export const getProductDetail = (
  id: number,
  params?: GetProductDetailParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ProductDetailResponseDto>(
    { url: `/v1/products/${id}`, method: 'get', params, signal },
    options
  )
}

export const getGetProductDetailQueryKey = (id: number, params?: GetProductDetailParams) =>
  [`/v1/products/${id}`, ...(params ? [params] : [])] as const

export const getGetProductDetailQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductDetail>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params?: GetProductDetailParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductDetail>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getProductDetail>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductDetailQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductDetail>>> = ({ signal }) =>
    getProductDetail(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetProductDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getProductDetail>>>
export type GetProductDetailQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 상품 상세
 */
export const useGetProductDetail = <
  TData = Awaited<ReturnType<typeof getProductDetail>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params?: GetProductDetailParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductDetail>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductDetailQueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 상품 추천, 유저의 펫중 랜덤으로 1개의 펫에 대한 추천 상품 목록 리턴한다.
 * @summary 상품 추천, 펫별
 */
export const getPetRecommendedProducts = (
  params?: GetPetRecommendedProductsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<PetRecommendedProductListResponseDto>(
    { url: `/v1/products/recommended/pet`, method: 'get', params, signal },
    options
  )
}

export const getGetPetRecommendedProductsQueryKey = (params?: GetPetRecommendedProductsParams) =>
  [`/v1/products/recommended/pet`, ...(params ? [params] : [])] as const

export const getGetPetRecommendedProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPetRecommendedProducts>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetPetRecommendedProductsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPetRecommendedProducts>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getPetRecommendedProducts>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPetRecommendedProductsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPetRecommendedProducts>>> = ({ signal }) =>
    getPetRecommendedProducts(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetPetRecommendedProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getPetRecommendedProducts>>>
export type GetPetRecommendedProductsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 상품 추천, 펫별
 */
export const useGetPetRecommendedProducts = <
  TData = Awaited<ReturnType<typeof getPetRecommendedProducts>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetPetRecommendedProductsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPetRecommendedProducts>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPetRecommendedProductsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Title : {반려동물명}에게 이 상품 어때요?

강아지/고양이 토글 관계없이 등록된 반려동물 중 한마리의 반려동물 이름 무작위 랜덤 노출

A유저(강아지a, 강아지b)의 경우,
강아지/고양이 토글 관계없이 개인화 추천 섹션에서 ‘강아지a’ 혹은 ‘강아지b’ 중 무작위로 노출한다

B유저(강아지a, 강아지b, 고양이a, 고양이b)의 경우,
강아지 토글로 view중일 때 ‘강아지a’ 혹은 ‘강아지b’ 중 무작위로 노출한다
고양이 토글로 view중일 때 ‘고양이a’ 혹은 '고양이b’ 중 무작위로 노출한다

C유저(고양이a, 고양이b)의 경우
강아지/고양이 토글 관계없이 개인화 추천 섹션에서  ‘고양이a’ 혹은 ‘고양이b’ 이름을 무작위로 노출한다

refresh 시 반려동물명 무작위 랜덤 노출한다(상품 또한 노출된 반려동물에 맞는 상품으로 노출)
 * @summary 펫별 상품 추천 by aws recipe
 */
export const getRecommendedProductsFromPersonalizeSync = (
  params: GetRecommendedProductsFromPersonalizeSyncParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<PetRecommendedProductListResponseDto>(
    { url: `/v1/products/recommended/personalize/pet`, method: 'get', params, signal },
    options
  )
}

export const getGetRecommendedProductsFromPersonalizeSyncQueryKey = (
  params: GetRecommendedProductsFromPersonalizeSyncParams
) => [`/v1/products/recommended/personalize/pet`, ...(params ? [params] : [])] as const

export const getGetRecommendedProductsFromPersonalizeSyncQueryOptions = <
  TData = Awaited<ReturnType<typeof getRecommendedProductsFromPersonalizeSync>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetRecommendedProductsFromPersonalizeSyncParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRecommendedProductsFromPersonalizeSync>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getRecommendedProductsFromPersonalizeSync>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRecommendedProductsFromPersonalizeSyncQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecommendedProductsFromPersonalizeSync>>> = ({ signal }) =>
    getRecommendedProductsFromPersonalizeSync(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetRecommendedProductsFromPersonalizeSyncQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRecommendedProductsFromPersonalizeSync>>
>
export type GetRecommendedProductsFromPersonalizeSyncQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 펫별 상품 추천 by aws recipe
 */
export const useGetRecommendedProductsFromPersonalizeSync = <
  TData = Awaited<ReturnType<typeof getRecommendedProductsFromPersonalizeSync>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetRecommendedProductsFromPersonalizeSyncParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRecommendedProductsFromPersonalizeSync>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRecommendedProductsFromPersonalizeSyncQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * # 셜명

검색어가 있는 경우, 검색어에 해당하는 상품 목록을 반환한다.

# 변경점
## sortBy 파라미터

네이밍 통일로 인해 `sortBy` 파라미터의 값이 변경되었다. 이전 버전에서 사용하던 값들은 각각 다음과 같이 변경되었다.

- `NEWEST` -> `CREATED`
- `BEST_SELLING` -> `SALES_SCORE`
- `PRICE` -> `SALE_PRICE`
- `RECOMMENDED` -> `MD_RECOMMENDED`

## 페이징 파라미터

페이징 파라미터 네이밍이 변경되었다.

- `limit` -> `size`
- `offset` -> `page`

## 응답 구조

`products[].` 이하의 주요 필드의 변경점은 다음과 같다.

- `customerPrice` -> `defaultOption.salePrice`
- `promotionCustomerPrice` -> `salePrice`
- `finalDiscountRate` -> `discountRate`
- `averageReviewScore` -> `review.score`
- `reviewCount` -> `review.count`
- `imageUrl` -> `mainImage.path`

## 제거 가능 시점

2주동안 호출한 이력이 없을 경우 제거한다.

# 참고

- 이전 버전: _제거_
- 다음 버전: `/v3/products/search`
 * @summary 상품 검색
 */
export const getSearchProductsV2 = (
  params: GetSearchProductsV2Params,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<SearchProductResponseDtoV2>(
    { url: `/v2/products/search`, method: 'get', params, signal },
    options
  )
}

export const getGetSearchProductsV2QueryKey = (params: GetSearchProductsV2Params) =>
  [`/v2/products/search`, ...(params ? [params] : [])] as const

export const getGetSearchProductsV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getSearchProductsV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetSearchProductsV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSearchProductsV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getSearchProductsV2>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSearchProductsV2QueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchProductsV2>>> = ({ signal }) =>
    getSearchProductsV2(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetSearchProductsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getSearchProductsV2>>>
export type GetSearchProductsV2QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 상품 검색
 */
export const useGetSearchProductsV2 = <
  TData = Awaited<ReturnType<typeof getSearchProductsV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetSearchProductsV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSearchProductsV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSearchProductsV2QueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * # 셜명

검색어가 있는 경우, 검색어에 해당하는 상품 목록을 반환한다.

# 변경점

## 역정규화 값 사용

상품 정보를 역정규화된 값을 사용하도록 변경되었다

## 상품 쿠폰 할인가 노출

상품 쿠폰 할인가가 추가되었다

# 참고

- 이전 버전: `/v2/products/search`
- 다음 버전: _없음_
 * @summary 상품 검색
 */
export const getSearchProductsV3 = (
  params: GetSearchProductsV3Params,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<SearchProductResponseDtoV3>(
    { url: `/v3/products/search`, method: 'get', params, signal },
    options
  )
}

export const getGetSearchProductsV3QueryKey = (params: GetSearchProductsV3Params) =>
  [`/v3/products/search`, ...(params ? [params] : [])] as const

export const getGetSearchProductsV3QueryOptions = <
  TData = Awaited<ReturnType<typeof getSearchProductsV3>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetSearchProductsV3Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSearchProductsV3>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getSearchProductsV3>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSearchProductsV3QueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchProductsV3>>> = ({ signal }) =>
    getSearchProductsV3(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetSearchProductsV3QueryResult = NonNullable<Awaited<ReturnType<typeof getSearchProductsV3>>>
export type GetSearchProductsV3QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 상품 검색
 */
export const useGetSearchProductsV3 = <
  TData = Awaited<ReturnType<typeof getSearchProductsV3>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetSearchProductsV3Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSearchProductsV3>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSearchProductsV3QueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * SKU별 구매가능한 수량, 장바구니 포함 여부를 조회한다.
 * @summary SKU별 구매가능한 수량, 장바구니 포함 여부 조회
 */
export const getAvailableSku = (
  id: number,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<AvailableSkuResponseDto>(
    { url: `/v1/products/${id}/available-sku`, method: 'get', signal },
    options
  )
}

export const getGetAvailableSkuQueryKey = (id: number) => [`/v1/products/${id}/available-sku`] as const

export const getGetAvailableSkuQueryOptions = <
  TData = Awaited<ReturnType<typeof getAvailableSku>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAvailableSku>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getAvailableSku>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAvailableSkuQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAvailableSku>>> = ({ signal }) =>
    getAvailableSku(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetAvailableSkuQueryResult = NonNullable<Awaited<ReturnType<typeof getAvailableSku>>>
export type GetAvailableSkuQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary SKU별 구매가능한 수량, 장바구니 포함 여부 조회
 */
export const useGetAvailableSku = <
  TData = Awaited<ReturnType<typeof getAvailableSku>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAvailableSku>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAvailableSkuQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 유저별 상품 좋아요 추가 기능을 제공한다.
 * @summary 유저별 상품 좋아요 추가 기능
 */
export const createProductLike = (
  productLikeRequestDto: ProductLikeRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<ProductLikeResponseDto>(
    {
      url: `/v1/products/product/like`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: productLikeRequestDto,
    },
    options
  )
}

export const getCreateProductLikeMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProductLike>>,
    TError,
    { data: ProductLikeRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof createProductLike>>,
  TError,
  { data: ProductLikeRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProductLike>>, { data: ProductLikeRequestDto }> = (
    props
  ) => {
    const { data } = props ?? {}

    return createProductLike(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateProductLikeMutationResult = NonNullable<Awaited<ReturnType<typeof createProductLike>>>
export type CreateProductLikeMutationBody = ProductLikeRequestDto
export type CreateProductLikeMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 유저별 상품 좋아요 추가 기능
 */
export const useCreateProductLike = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProductLike>>,
    TError,
    { data: ProductLikeRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getCreateProductLikeMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 유저별 상품 좋아요 취소 기능을 제공한다.
 * @summary 유저별 상품 좋아요 취소 기능
 */
export const deleteProductLike = (
  productLikeRequestDto: ProductLikeRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<boolean>(
    {
      url: `/v1/products/product/like`,
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
      data: productLikeRequestDto,
    },
    options
  )
}

export const getDeleteProductLikeMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProductLike>>,
    TError,
    { data: ProductLikeRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteProductLike>>,
  TError,
  { data: ProductLikeRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProductLike>>, { data: ProductLikeRequestDto }> = (
    props
  ) => {
    const { data } = props ?? {}

    return deleteProductLike(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteProductLikeMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProductLike>>>
export type DeleteProductLikeMutationBody = ProductLikeRequestDto
export type DeleteProductLikeMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 유저별 상품 좋아요 취소 기능
 */
export const useDeleteProductLike = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProductLike>>,
    TError,
    { data: ProductLikeRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getDeleteProductLikeMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * # 셜명

최근 본 상품 목록을 반환한다.
최근 본 상품 정보는 front-end에서 관리되며 Request Payload 로 전달받는다
 * @summary 최근 본 상품 목록
 */
export const getRecentlyProducts = (
  recentlyProductListRequestDto: RecentlyProductListRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<ProductsListResponseDtoV2>(
    {
      url: `/v1/products/recently/products`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: recentlyProductListRequestDto,
    },
    options
  )
}

export const getGetRecentlyProductsMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getRecentlyProducts>>,
    TError,
    { data: RecentlyProductListRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof getRecentlyProducts>>,
  TError,
  { data: RecentlyProductListRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getRecentlyProducts>>,
    { data: RecentlyProductListRequestDto }
  > = (props) => {
    const { data } = props ?? {}

    return getRecentlyProducts(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetRecentlyProductsMutationResult = NonNullable<Awaited<ReturnType<typeof getRecentlyProducts>>>
export type GetRecentlyProductsMutationBody = RecentlyProductListRequestDto
export type GetRecentlyProductsMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 최근 본 상품 목록
 */
export const useGetRecentlyProducts = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getRecentlyProducts>>,
    TError,
    { data: RecentlyProductListRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getGetRecentlyProductsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 상품 구매예상금액을 계산하여 조회한다
 * @summary 상품 구매예상금액을 계산하여 조회한다
 */
export const purchaseEstimateProduct = (
  id: number,
  purchaseEstimateProductRequestDto: PurchaseEstimateProductRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<PurchaseEstimateProductSummaryResponseDto>(
    {
      url: `/v1/products/${id}/purchase-estimate`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: purchaseEstimateProductRequestDto,
    },
    options
  )
}

export const getPurchaseEstimateProductMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof purchaseEstimateProduct>>,
    TError,
    { id: number; data: PurchaseEstimateProductRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof purchaseEstimateProduct>>,
  TError,
  { id: number; data: PurchaseEstimateProductRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof purchaseEstimateProduct>>,
    { id: number; data: PurchaseEstimateProductRequestDto }
  > = (props) => {
    const { id, data } = props ?? {}

    return purchaseEstimateProduct(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PurchaseEstimateProductMutationResult = NonNullable<Awaited<ReturnType<typeof purchaseEstimateProduct>>>
export type PurchaseEstimateProductMutationBody = PurchaseEstimateProductRequestDto
export type PurchaseEstimateProductMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 상품 구매예상금액을 계산하여 조회한다
 */
export const usePurchaseEstimateProduct = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof purchaseEstimateProduct>>,
    TError,
    { id: number; data: PurchaseEstimateProductRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getPurchaseEstimateProductMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 다운로드전 할인가 노출 상품 쿠폰을 조회합니다.
 * @summary 다운로드전 할인가 노출 상품 쿠폰 조회
 */
export const getDownloadableProductCoupons = (
  id: number,
  params?: GetDownloadableProductCouponsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<DownloadableCouponListResponseDto>(
    { url: `/v1/products/${id}/downloadable-coupon`, method: 'get', params, signal },
    options
  )
}

export const getGetDownloadableProductCouponsQueryKey = (id: number, params?: GetDownloadableProductCouponsParams) =>
  [`/v1/products/${id}/downloadable-coupon`, ...(params ? [params] : [])] as const

export const getGetDownloadableProductCouponsQueryOptions = <
  TData = Awaited<ReturnType<typeof getDownloadableProductCoupons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params?: GetDownloadableProductCouponsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDownloadableProductCoupons>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getDownloadableProductCoupons>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetDownloadableProductCouponsQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDownloadableProductCoupons>>> = ({ signal }) =>
    getDownloadableProductCoupons(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetDownloadableProductCouponsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDownloadableProductCoupons>>
>
export type GetDownloadableProductCouponsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 다운로드전 할인가 노출 상품 쿠폰 조회
 */
export const useGetDownloadableProductCoupons = <
  TData = Awaited<ReturnType<typeof getDownloadableProductCoupons>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params?: GetDownloadableProductCouponsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDownloadableProductCoupons>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDownloadableProductCouponsQueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 등록된 클라이언트 메타 리스트를 조회합니다.
 * @summary 클라이언트 메타 리스트 조회
 */
export const getClientMetaListResponse = (
  params?: GetClientMetaListResponseParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ClientMetaListResponseDto>(
    { url: `/v1/client-meta`, method: 'get', params, signal },
    options
  )
}

export const getGetClientMetaListResponseQueryKey = (params?: GetClientMetaListResponseParams) =>
  [`/v1/client-meta`, ...(params ? [params] : [])] as const

export const getGetClientMetaListResponseQueryOptions = <
  TData = Awaited<ReturnType<typeof getClientMetaListResponse>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetClientMetaListResponseParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getClientMetaListResponse>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getClientMetaListResponse>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetClientMetaListResponseQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientMetaListResponse>>> = ({ signal }) =>
    getClientMetaListResponse(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetClientMetaListResponseQueryResult = NonNullable<Awaited<ReturnType<typeof getClientMetaListResponse>>>
export type GetClientMetaListResponseQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 클라이언트 메타 리스트 조회
 */
export const useGetClientMetaListResponse = <
  TData = Awaited<ReturnType<typeof getClientMetaListResponse>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetClientMetaListResponseParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getClientMetaListResponse>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetClientMetaListResponseQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 등록된 DisplayCollection 리스트를 조회합니다.
 * @summary DisplayCollection 리스트 조회
 */
export const getDisplayCollectionList = (
  params?: GetDisplayCollectionListParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<DisplayCollectionListResponseDto>(
    { url: `/v1/display-collections`, method: 'get', params, signal },
    options
  )
}

export const getGetDisplayCollectionListQueryKey = (params?: GetDisplayCollectionListParams) =>
  [`/v1/display-collections`, ...(params ? [params] : [])] as const

export const getGetDisplayCollectionListQueryOptions = <
  TData = Awaited<ReturnType<typeof getDisplayCollectionList>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetDisplayCollectionListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDisplayCollectionList>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getDisplayCollectionList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetDisplayCollectionListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDisplayCollectionList>>> = ({ signal }) =>
    getDisplayCollectionList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetDisplayCollectionListQueryResult = NonNullable<Awaited<ReturnType<typeof getDisplayCollectionList>>>
export type GetDisplayCollectionListQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary DisplayCollection 리스트 조회
 */
export const useGetDisplayCollectionList = <
  TData = Awaited<ReturnType<typeof getDisplayCollectionList>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetDisplayCollectionListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDisplayCollectionList>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDisplayCollectionListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 등록된 Best DisplayCollection 리스트를 조회합니다.
 * @summary Best DisplayCollection 리스트 조회
 */
export const getBestDisplayCollectionList = (
  params?: GetBestDisplayCollectionListParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<DisplayCollectionListResponseDto>(
    { url: `/v1/display-collections/best`, method: 'get', params, signal },
    options
  )
}

export const getGetBestDisplayCollectionListQueryKey = (params?: GetBestDisplayCollectionListParams) =>
  [`/v1/display-collections/best`, ...(params ? [params] : [])] as const

export const getGetBestDisplayCollectionListQueryOptions = <
  TData = Awaited<ReturnType<typeof getBestDisplayCollectionList>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetBestDisplayCollectionListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBestDisplayCollectionList>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getBestDisplayCollectionList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetBestDisplayCollectionListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBestDisplayCollectionList>>> = ({ signal }) =>
    getBestDisplayCollectionList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetBestDisplayCollectionListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBestDisplayCollectionList>>
>
export type GetBestDisplayCollectionListQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary Best DisplayCollection 리스트 조회
 */
export const useGetBestDisplayCollectionList = <
  TData = Awaited<ReturnType<typeof getBestDisplayCollectionList>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetBestDisplayCollectionListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBestDisplayCollectionList>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBestDisplayCollectionListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * # 설명

이벤트(기획전) 섹션별 상품들 검색한다.

# 변경점
## sortBy 파라미터

네이밍 통일로 인해 `sortBy` 파라미터의 값이 변경되었다. 이전 버전에서 사용하던 값들은 각각 다음과 같이 변경되었다.

- MD 추천: `DISPLAY_ORDER` -> `MD_RECOMMENDED`
- 할인율: `FINAL_DISCOUNT_RATE` -> `DISCOUNT_RATE`
- 가격: `PRODUCT_PROMOTION_CUSTOMER_PRICE` -> `SALE_PRICE`

그 외에 다음값이 신규로 추가 되었다.

- 생성시기: `CREATED`
- 판매점수: `SALES_SCORE`
- 리뷰 평점: `REVIEW_SCORE`

## sortSoldOutToLast 파라미터

품절 상품을 맨마지막으로 정렬하기 위해 `sortSoldOutToLast`가 추가되었다.

## 페이징

페이징 파라미터가 추가되었다. 이전 버전에서는 `sortBy`가 `RANDOM`인 경우 `randomLimit` 파라미터로 랜덤 개수를 제한했으나
현재 버전에서는 `randomLimit`가 제거되고 `size` 파라미터를 동일한 의미로 사용한다.
`sortBy`가 `RANDOM`인 경우에는 내부적으로 `page` 파라미터를 무시하고 조회한다.

## 응답 구조

페이징이 고려되어 응답 구조가 변경되었다.

주요 필드의 변경점은 다음과 같다.

- `productId` -> `id`
- `customerPrice` -> `defaultOption.salePrice`
- `promotionCustomerPrice` -> `salePrice`
- `finalDiscountRate` -> `discountRate`
- `averageReviewScore` -> `review.score`
- `reviewCount` -> `review.count`
- `maxProductOptionSkuCount` -> `maxOptionSkuCount`
- `mainProductImage` -> `mainImage`
- `defaultProductOption` -> `defaultOption`
- `productPromotion` -> `promotion`
- `excludeSoldOut` -> `includeSoldOut`

## 제거 가능 시점

2주동안 호출한 이력이 없을 경우 제거한다.

# 참고

- 이전 버전: _제거_
- 다음 버전: _없음_
 * @summary 이벤트(기획전) 섹션별 상품들 검색
 */
export const getProductsByDisplayCollectionIdV2 = (
  id: number,
  params: GetProductsByDisplayCollectionIdV2Params,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<DisplayCollectionProductListResponseDtoV2>(
    { url: `/v2/display-collections/${id}/products`, method: 'get', params, signal },
    options
  )
}

export const getGetProductsByDisplayCollectionIdV2QueryKey = (
  id: number,
  params: GetProductsByDisplayCollectionIdV2Params
) => [`/v2/display-collections/${id}/products`, ...(params ? [params] : [])] as const

export const getGetProductsByDisplayCollectionIdV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params: GetProductsByDisplayCollectionIdV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV2>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductsByDisplayCollectionIdV2QueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV2>>> = ({ signal }) =>
    getProductsByDisplayCollectionIdV2(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetProductsByDisplayCollectionIdV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV2>>
>
export type GetProductsByDisplayCollectionIdV2QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 이벤트(기획전) 섹션별 상품들 검색
 */
export const useGetProductsByDisplayCollectionIdV2 = <
  TData = Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params: GetProductsByDisplayCollectionIdV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsByDisplayCollectionIdV2QueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * # 설명

이벤트(기획전) 섹션별 상품들 검색한다.

# 변경점

## 역정규화 값 사용

상품 정보를 역정규화된 값을 사용하도록 변경되었다

## 상품 쿠폰 할인가 노출

상품 쿠폰 할인가가 추가되었다

# 참고

- 이전 버전: `/v2/display-collections/{id}/products`
- 다음 버전: _없음_
 * @summary 이벤트(기획전) 섹션별 상품들 검색
 */
export const getProductsByDisplayCollectionIdV3 = (
  id: number,
  params: GetProductsByDisplayCollectionIdV3Params,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<DisplayCollectionProductListResponseDtoV3>(
    { url: `/v3/display-collections/${id}/products`, method: 'get', params, signal },
    options
  )
}

export const getGetProductsByDisplayCollectionIdV3QueryKey = (
  id: number,
  params: GetProductsByDisplayCollectionIdV3Params
) => [`/v3/display-collections/${id}/products`, ...(params ? [params] : [])] as const

export const getGetProductsByDisplayCollectionIdV3QueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV3>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params: GetProductsByDisplayCollectionIdV3Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV3>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV3>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductsByDisplayCollectionIdV3QueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV3>>> = ({ signal }) =>
    getProductsByDisplayCollectionIdV3(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetProductsByDisplayCollectionIdV3QueryResult = NonNullable<
  Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV3>>
>
export type GetProductsByDisplayCollectionIdV3QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 이벤트(기획전) 섹션별 상품들 검색
 */
export const useGetProductsByDisplayCollectionIdV3 = <
  TData = Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV3>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params: GetProductsByDisplayCollectionIdV3Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByDisplayCollectionIdV3>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsByDisplayCollectionIdV3QueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * # 설명

이벤트(기획전) 섹션별 상품들 검색한다.

# 변경점
## sortBy 파라미터

네이밍 통일로 인해 `sortBy` 파라미터의 값이 변경되었다. 이전 버전에서 사용하던 값들은 각각 다음과 같이 변경되었다.

- MD 추천: `DISPLAY_ORDER` -> `MD_RECOMMENDED`
- 할인율: `FINAL_DISCOUNT_RATE` -> `DISCOUNT_RATE`
- 가격: `PRODUCT_PROMOTION_CUSTOMER_PRICE` -> `SALE_PRICE`

그 외에 다음값이 신규로 추가 되었다.

- 생성시기: `CREATED`
- 판매점수: `SALES_SCORE`
- 리뷰 평점: `REVIEW_SCORE`

## sortSoldOutToLast 파라미터

품절 상품을 맨마지막으로 정렬하기 위해 `sortSoldOutToLast`가 추가되었다.

## 페이징

페이징 파라미터가 추가되었다. 이전 버전에서는 `sortBy`가 `RANDOM`인 경우 `randomLimit` 파라미터로 랜덤 개수를 제한했으나
현재 버전에서는 `randomLimit`가 제거되고 `size` 파라미터를 동일한 의미로 사용한다.
`sortBy`가 `RANDOM`인 경우에는 내부적으로 `page` 파라미터를 무시하고 조회한다.

## 응답 구조

페이징이 고려되어 응답 구조가 변경되었다.

주요 필드의 변경점은 다음과 같다.

- `productId` -> `id`
- `customerPrice` -> `defaultOption.salePrice`
- `promotionCustomerPrice` -> `salePrice`
- `finalDiscountRate` -> `discountRate`
- `averageReviewScore` -> `review.score`
- `reviewCount` -> `review.count`
- `maxProductOptionSkuCount` -> `maxOptionSkuCount`
- `mainProductImage` -> `mainImage`
- `defaultProductOption` -> `defaultOption`
- `productPromotion` -> `promotion`
- `excludeSoldOut` -> `includeSoldOut`

# 참고

- 이전 버전: _제거_
- 다음 버전: _없음_
 * @summary 이벤트(기획전) 섹션별 상품들 검색
 */
export const getProductsByAdSlugV2 = (
  slug: string,
  params: GetProductsByAdSlugV2Params,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<AdSlugDisplayCollectionProductListResponseDto>(
    { url: `/v2/display-collections/slug/${slug}/products`, method: 'get', params, signal },
    options
  )
}

export const getGetProductsByAdSlugV2QueryKey = (slug: string, params: GetProductsByAdSlugV2Params) =>
  [`/v2/display-collections/slug/${slug}/products`, ...(params ? [params] : [])] as const

export const getGetProductsByAdSlugV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsByAdSlugV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  slug: string,
  params: GetProductsByAdSlugV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByAdSlugV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getProductsByAdSlugV2>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductsByAdSlugV2QueryKey(slug, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsByAdSlugV2>>> = ({ signal }) =>
    getProductsByAdSlugV2(slug, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!slug, ...queryOptions }
}

export type GetProductsByAdSlugV2QueryResult = NonNullable<Awaited<ReturnType<typeof getProductsByAdSlugV2>>>
export type GetProductsByAdSlugV2QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 이벤트(기획전) 섹션별 상품들 검색
 */
export const useGetProductsByAdSlugV2 = <
  TData = Awaited<ReturnType<typeof getProductsByAdSlugV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  slug: string,
  params: GetProductsByAdSlugV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByAdSlugV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsByAdSlugV2QueryOptions(slug, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 등록된 ProductsCategory 리스트를 조회합니다.
 * @summary ProductsCategory 리스트 조회
 */
export const getProductsCategoryList = (
  params?: GetProductsCategoryListParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ProductsCategoryListResponseDto>(
    { url: `/v1/categories`, method: 'get', params, signal },
    options
  )
}

export const getGetProductsCategoryListQueryKey = (params?: GetProductsCategoryListParams) =>
  [`/v1/categories`, ...(params ? [params] : [])] as const

export const getGetProductsCategoryListQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsCategoryList>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetProductsCategoryListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsCategoryList>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getProductsCategoryList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductsCategoryListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsCategoryList>>> = ({ signal }) =>
    getProductsCategoryList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetProductsCategoryListQueryResult = NonNullable<Awaited<ReturnType<typeof getProductsCategoryList>>>
export type GetProductsCategoryListQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary ProductsCategory 리스트 조회
 */
export const useGetProductsCategoryList = <
  TData = Awaited<ReturnType<typeof getProductsCategoryList>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetProductsCategoryListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsCategoryList>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsCategoryListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 등록된 ProductsCategory 를 조회합니다.
 * @summary ProductsCategory 조회
 */
export const getProductsCategoryById = (
  id: number,
  params?: GetProductsCategoryByIdParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ProductsCategoryListResponseDto>(
    { url: `/v1/categories/${id}`, method: 'get', params, signal },
    options
  )
}

export const getGetProductsCategoryByIdQueryKey = (id: number, params?: GetProductsCategoryByIdParams) =>
  [`/v1/categories/${id}`, ...(params ? [params] : [])] as const

export const getGetProductsCategoryByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsCategoryById>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params?: GetProductsCategoryByIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsCategoryById>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getProductsCategoryById>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductsCategoryByIdQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsCategoryById>>> = ({ signal }) =>
    getProductsCategoryById(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetProductsCategoryByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProductsCategoryById>>>
export type GetProductsCategoryByIdQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary ProductsCategory 조회
 */
export const useGetProductsCategoryById = <
  TData = Awaited<ReturnType<typeof getProductsCategoryById>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params?: GetProductsCategoryByIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsCategoryById>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsCategoryByIdQueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * # 설명

카테고리별 상품 목록을 조회한다.

# 변경점

_없음_

## 제거 가능 시점

2주동안 호출한 이력이 없을 경우 제거한다.

# 참고

- 이전 버전: _없음_
- 다음 버전: `/v2/categories/{id}/products`
 * @summary 카테고리별 상품 목록
 */
export const getProductsByCategoryId = (
  id: number,
  params: GetProductsByCategoryIdParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ProductsListResponseDto>(
    { url: `/v1/categories/${id}/products`, method: 'get', params, signal },
    options
  )
}

export const getGetProductsByCategoryIdQueryKey = (id: number, params: GetProductsByCategoryIdParams) =>
  [`/v1/categories/${id}/products`, ...(params ? [params] : [])] as const

export const getGetProductsByCategoryIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsByCategoryId>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params: GetProductsByCategoryIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByCategoryId>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getProductsByCategoryId>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductsByCategoryIdQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsByCategoryId>>> = ({ signal }) =>
    getProductsByCategoryId(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetProductsByCategoryIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProductsByCategoryId>>>
export type GetProductsByCategoryIdQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 카테고리별 상품 목록
 */
export const useGetProductsByCategoryId = <
  TData = Awaited<ReturnType<typeof getProductsByCategoryId>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params: GetProductsByCategoryIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByCategoryId>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsByCategoryIdQueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * # 설명

카테고리별 상품 목록을 조회한다.

# 변경점

## 역정규화 값 사용

상품 정보를 역정규화된 값을 사용하도록 변경되었다

## 상품 쿠폰 할인가 노출

상품 쿠폰 할인가가 추가되었다

# 참고

- 이전 버전: `/v1/categories/{id}/products`
- 다음 버전: _없음_
 * @summary 카테고리별 상품 목록
 */
export const getProductsByCategoryIdV2 = (
  id: number,
  params: GetProductsByCategoryIdV2Params,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ProductsListResponseDtoV2>(
    { url: `/v2/categories/${id}/products`, method: 'get', params, signal },
    options
  )
}

export const getGetProductsByCategoryIdV2QueryKey = (id: number, params: GetProductsByCategoryIdV2Params) =>
  [`/v2/categories/${id}/products`, ...(params ? [params] : [])] as const

export const getGetProductsByCategoryIdV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsByCategoryIdV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params: GetProductsByCategoryIdV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByCategoryIdV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getProductsByCategoryIdV2>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductsByCategoryIdV2QueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductsByCategoryIdV2>>> = ({ signal }) =>
    getProductsByCategoryIdV2(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetProductsByCategoryIdV2QueryResult = NonNullable<Awaited<ReturnType<typeof getProductsByCategoryIdV2>>>
export type GetProductsByCategoryIdV2QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 카테고리별 상품 목록
 */
export const useGetProductsByCategoryIdV2 = <
  TData = Awaited<ReturnType<typeof getProductsByCategoryIdV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  id: number,
  params: GetProductsByCategoryIdV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductsByCategoryIdV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsByCategoryIdV2QueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 등록된 Gnb 리스트를 조회합니다.
 * @summary Gnb 리스트 조회
 */
export const getGnbList = (
  params?: GetGnbListParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GnbListResponseDto>({ url: `/v1/gnbs`, method: 'get', params, signal }, options)
}

export const getGetGnbListQueryKey = (params?: GetGnbListParams) => [`/v1/gnbs`, ...(params ? [params] : [])] as const

export const getGetGnbListQueryOptions = <
  TData = Awaited<ReturnType<typeof getGnbList>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetGnbListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getGnbList>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getGnbList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetGnbListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGnbList>>> = ({ signal }) =>
    getGnbList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetGnbListQueryResult = NonNullable<Awaited<ReturnType<typeof getGnbList>>>
export type GetGnbListQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary Gnb 리스트 조회
 */
export const useGetGnbList = <
  TData = Awaited<ReturnType<typeof getGnbList>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetGnbListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getGnbList>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGnbListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 등록된 Terms 리스트를 조회합니다.
 * @summary Terms 리스트 조회
 */
export const getTermsList = (options?: SecondParameter<typeof customAxiosInstance>, signal?: AbortSignal) => {
  return customAxiosInstance<TermsListResponseDto>({ url: `/v1/terms`, method: 'get', signal }, options)
}

export const getGetTermsListQueryKey = () => [`/v1/terms`] as const

export const getGetTermsListQueryOptions = <
  TData = Awaited<ReturnType<typeof getTermsList>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTermsList>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof getTermsList>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTermsListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTermsList>>> = ({ signal }) =>
    getTermsList(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetTermsListQueryResult = NonNullable<Awaited<ReturnType<typeof getTermsList>>>
export type GetTermsListQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary Terms 리스트 조회
 */
export const useGetTermsList = <
  TData = Awaited<ReturnType<typeof getTermsList>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTermsList>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTermsListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 이벤트 진행 여부, pepper 쿠폰 번호를 조회합니다.
 * @summary pepper 쿠폰 번호 조회
 */
export const getPepperBankCoupon = (options?: SecondParameter<typeof customAxiosInstance>, signal?: AbortSignal) => {
  return customAxiosInstance<PepperBankCouponResponseDto>(
    { url: `/v1/partner-promotions/pepperbank-coupon`, method: 'get', signal },
    options
  )
}

export const getGetPepperBankCouponQueryKey = () => [`/v1/partner-promotions/pepperbank-coupon`] as const

export const getGetPepperBankCouponQueryOptions = <
  TData = Awaited<ReturnType<typeof getPepperBankCoupon>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPepperBankCoupon>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof getPepperBankCoupon>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPepperBankCouponQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPepperBankCoupon>>> = ({ signal }) =>
    getPepperBankCoupon(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetPepperBankCouponQueryResult = NonNullable<Awaited<ReturnType<typeof getPepperBankCoupon>>>
export type GetPepperBankCouponQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary pepper 쿠폰 번호 조회
 */
export const useGetPepperBankCoupon = <
  TData = Awaited<ReturnType<typeof getPepperBankCoupon>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPepperBankCoupon>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPepperBankCouponQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 파일을 업로드한다. `fileType`이 다음에 해당하는 경우 파일을 암호화하여 저장한다.

- FITPET_PARTNERS

암호화된 파일의 디코딩은 `파일 디코딩`을 참고하기 바란다.

 * @summary 파일 업로드
 */
export const uploadFile = (
  fileUploadRequestDto: FileUploadRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formData = new FormData()
  formData.append('fileType', fileUploadRequestDto.fileType)
  formData.append('file', fileUploadRequestDto.file)

  return customAxiosInstance<string>(
    {
      url: `/v1/commons/file/upload`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export const getUploadFileMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadFile>>,
    TError,
    { data: FileUploadRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof uploadFile>>, TError, { data: FileUploadRequestDto }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadFile>>, { data: FileUploadRequestDto }> = (
    props
  ) => {
    const { data } = props ?? {}

    return uploadFile(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UploadFileMutationResult = NonNullable<Awaited<ReturnType<typeof uploadFile>>>
export type UploadFileMutationBody = FileUploadRequestDto
export type UploadFileMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 파일 업로드
 */
export const useUploadFile = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadFile>>,
    TError,
    { data: FileUploadRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getUploadFileMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 파일 다운로드
 * @summary 파일 다운로드
 */
export const downloadFile = (
  params: DownloadFileParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<string>({ url: `/v1/commons/file/download`, method: 'get', params, signal }, options)
}

export const getDownloadFileQueryKey = (params: DownloadFileParams) =>
  [`/v1/commons/file/download`, ...(params ? [params] : [])] as const

export const getDownloadFileQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadFile>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: DownloadFileParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof downloadFile>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof downloadFile>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getDownloadFileQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadFile>>> = ({ signal }) =>
    downloadFile(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type DownloadFileQueryResult = NonNullable<Awaited<ReturnType<typeof downloadFile>>>
export type DownloadFileQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 파일 다운로드
 */
export const useDownloadFile = <
  TData = Awaited<ReturnType<typeof downloadFile>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: DownloadFileParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof downloadFile>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadFileQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * # 설명

A/B 테스트 케이스 리스트 조회 합니다.

# 변경점

_없음_

## 제거 가능 시점

2주동안 호출한 이력이 없을 경우 제거한다.

# 참고

- 이전 버전: _없음_
- 다음 버전: `/v2/ab-test-groups`
 * @summary A/B 테스트 조회
 */
export const getTestGroups = (
  params?: GetTestGroupsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ABTestGroupListResponseDto>(
    { url: `/v1/ab-test-groups`, method: 'get', params, signal },
    options
  )
}

export const getGetTestGroupsQueryKey = (params?: GetTestGroupsParams) =>
  [`/v1/ab-test-groups`, ...(params ? [params] : [])] as const

export const getGetTestGroupsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTestGroups>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetTestGroupsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTestGroups>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getTestGroups>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTestGroupsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTestGroups>>> = ({ signal }) =>
    getTestGroups(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetTestGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getTestGroups>>>
export type GetTestGroupsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary A/B 테스트 조회
 */
export const useGetTestGroups = <
  TData = Awaited<ReturnType<typeof getTestGroups>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params?: GetTestGroupsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTestGroups>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTestGroupsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * # 설명

A/B 테스트 케이스 리스트를 조회 합니다.

# 변경점

- UUID 를 파라미터로 받도록 추가됨
- testCase 파라미터 제거
- AB 테스트 케이스 중 확정된 내용만 리턴

## 제거 가능 시점

_없음_

# 참고

- 이전 버전: `/v1/ab-test-groups`
- 다음 버전: `/v2/banners/text-type`
 * @summary A/B 테스트 조회
 */
export const getTestGroupsV2 = (
  params: GetTestGroupsV2Params,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ABTestGroupListResponseDtoV2>(
    { url: `/v2/ab-test-groups`, method: 'get', params, signal },
    options
  )
}

export const getGetTestGroupsV2QueryKey = (params: GetTestGroupsV2Params) =>
  [`/v2/ab-test-groups`, ...(params ? [params] : [])] as const

export const getGetTestGroupsV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getTestGroupsV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetTestGroupsV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTestGroupsV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getTestGroupsV2>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTestGroupsV2QueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTestGroupsV2>>> = ({ signal }) =>
    getTestGroupsV2(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetTestGroupsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getTestGroupsV2>>>
export type GetTestGroupsV2QueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary A/B 테스트 조회
 */
export const useGetTestGroupsV2 = <
  TData = Awaited<ReturnType<typeof getTestGroupsV2>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetTestGroupsV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTestGroupsV2>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTestGroupsV2QueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 장바구니 상품 결제 금액 정보 산정

1. cartItems == null: 최초 장바구니 접속으로 디비에 유저 장바구니 상품을 조회하여 모두 계산해서 내려줌
2. cartItems == 빈배열 == [] : 모두 체크가 안 된 케이스로 상품 정보만 구하고 결제 금액 등은 0월으로 처리
3. 그 외 경우 cartItems 의 checkedCartItemId 를 확인하여 계산
 * @summary 장바구니 상품 결제 금액 정보 산정
 */
export const estimateCart = (
  estimateCartRequestDto: EstimateCartRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<EstimateCartResponseDto>(
    {
      url: `/v1/carts/estimate-cart`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: estimateCartRequestDto,
    },
    options
  )
}

export const getEstimateCartMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof estimateCart>>,
    TError,
    { data: EstimateCartRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof estimateCart>>,
  TError,
  { data: EstimateCartRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof estimateCart>>, { data: EstimateCartRequestDto }> = (
    props
  ) => {
    const { data } = props ?? {}

    return estimateCart(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type EstimateCartMutationResult = NonNullable<Awaited<ReturnType<typeof estimateCart>>>
export type EstimateCartMutationBody = EstimateCartRequestDto
export type EstimateCartMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 장바구니 상품 결제 금액 정보 산정
 */
export const useEstimateCart = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof estimateCart>>,
    TError,
    { data: EstimateCartRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getEstimateCartMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 장바구니 상품들을 삭제합니다. (Hard Delete)
 * @summary 장바구니 상품 삭제 (InBatch)
 */
export const deleteCartItems = (
  deleteCartItemRequestDto: DeleteCartItemRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<boolean>(
    {
      url: `/v1/carts`,
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
      data: deleteCartItemRequestDto,
    },
    options
  )
}

export const getDeleteCartItemsMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCartItems>>,
    TError,
    { data: DeleteCartItemRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCartItems>>,
  TError,
  { data: DeleteCartItemRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCartItems>>,
    { data: DeleteCartItemRequestDto }
  > = (props) => {
    const { data } = props ?? {}

    return deleteCartItems(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteCartItemsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCartItems>>>
export type DeleteCartItemsMutationBody = DeleteCartItemRequestDto
export type DeleteCartItemsMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 장바구니 상품 삭제 (InBatch)
 */
export const useDeleteCartItems = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCartItems>>,
    TError,
    { data: DeleteCartItemRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getDeleteCartItemsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 장바구니 생성 혹은 수정을 합니다.
 * Insert: cartItemId 가 null 인 경우
 * Update: cartItemId 가 있는 경우
 * @summary 장바구니 생성 및 수정 (Upsert)
 */
export const putCartItems = (
  putCartItemRequestDto: PutCartItemRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<CartResponseDto>(
    { url: `/v1/carts`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: putCartItemRequestDto },
    options
  )
}

export const getPutCartItemsMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCartItems>>,
    TError,
    { data: PutCartItemRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof putCartItems>>, TError, { data: PutCartItemRequestDto }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putCartItems>>, { data: PutCartItemRequestDto }> = (
    props
  ) => {
    const { data } = props ?? {}

    return putCartItems(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutCartItemsMutationResult = NonNullable<Awaited<ReturnType<typeof putCartItems>>>
export type PutCartItemsMutationBody = PutCartItemRequestDto
export type PutCartItemsMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 장바구니 생성 및 수정 (Upsert)
 */
export const usePutCartItems = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCartItems>>,
    TError,
    { data: PutCartItemRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getPutCartItemsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 장바구니 옵션을 변경 합니다.
 * @summary 장바구니 옵션 변경
 */
export const patchProductOptionIdOfCartItem = (
  id: number,
  patchProductOptionIdOfCartItemRequestDto: PatchProductOptionIdOfCartItemRequestDto,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<CartItemResponseDto>(
    {
      url: `/v1/carts/${id}/option`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchProductOptionIdOfCartItemRequestDto,
    },
    options
  )
}

export const getPatchProductOptionIdOfCartItemMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchProductOptionIdOfCartItem>>,
    TError,
    { id: number; data: PatchProductOptionIdOfCartItemRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchProductOptionIdOfCartItem>>,
  TError,
  { id: number; data: PatchProductOptionIdOfCartItemRequestDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchProductOptionIdOfCartItem>>,
    { id: number; data: PatchProductOptionIdOfCartItemRequestDto }
  > = (props) => {
    const { id, data } = props ?? {}

    return patchProductOptionIdOfCartItem(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchProductOptionIdOfCartItemMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchProductOptionIdOfCartItem>>
>
export type PatchProductOptionIdOfCartItemMutationBody = PatchProductOptionIdOfCartItemRequestDto
export type PatchProductOptionIdOfCartItemMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 장바구니 옵션 변경
 */
export const usePatchProductOptionIdOfCartItem = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchProductOptionIdOfCartItem>>,
    TError,
    { id: number; data: PatchProductOptionIdOfCartItemRequestDto },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getPatchProductOptionIdOfCartItemMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * 홈섹션 조회 조회 합니다.
 * @summary 홈섹션 조회
 */
export const getHomeSections = (
  params: GetHomeSectionsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<HomeSectionListResponseDto>(
    { url: `/v1/home-sections`, method: 'get', params, signal },
    options
  )
}

export const getGetHomeSectionsQueryKey = (params: GetHomeSectionsParams) =>
  [`/v1/home-sections`, ...(params ? [params] : [])] as const

export const getGetHomeSectionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getHomeSections>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetHomeSectionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getHomeSections>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getHomeSections>>, TError, TData> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetHomeSectionsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHomeSections>>> = ({ signal }) =>
    getHomeSections(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type GetHomeSectionsQueryResult = NonNullable<Awaited<ReturnType<typeof getHomeSections>>>
export type GetHomeSectionsQueryError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 홈섹션 조회
 */
export const useGetHomeSections = <
  TData = Awaited<ReturnType<typeof getHomeSections>>,
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>
>(
  params: GetHomeSectionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getHomeSections>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetHomeSectionsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 추천 조회
 * @summary 추천 조회
 */
export const channelTalkWebHook = (
  channelTalkRequestDto: ChannelTalkRequestDto,
  params: ChannelTalkWebHookParams,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<boolean>(
    {
      url: `/v1/channel-talk`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: channelTalkRequestDto,
      params,
    },
    options
  )
}

export const getChannelTalkWebHookMutationOptions = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof channelTalkWebHook>>,
    TError,
    { data: ChannelTalkRequestDto; params: ChannelTalkWebHookParams },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof channelTalkWebHook>>,
  TError,
  { data: ChannelTalkRequestDto; params: ChannelTalkWebHookParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof channelTalkWebHook>>,
    { data: ChannelTalkRequestDto; params: ChannelTalkWebHookParams }
  > = (props) => {
    const { data, params } = props ?? {}

    return channelTalkWebHook(data, params, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ChannelTalkWebHookMutationResult = NonNullable<Awaited<ReturnType<typeof channelTalkWebHook>>>
export type ChannelTalkWebHookMutationBody = ChannelTalkRequestDto
export type ChannelTalkWebHookMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse
>

/**
 * @summary 추천 조회
 */
export const useChannelTalkWebHook = <
  TError = ErrorType<BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | InternalServerErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof channelTalkWebHook>>,
    TError,
    { data: ChannelTalkRequestDto; params: ChannelTalkWebHookParams },
    TContext
  >
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const mutationOptions = getChannelTalkWebHookMutationOptions(options)

  return useMutation(mutationOptions)
}
