/**
 * @desc svg 파일 추가시에 width: 100%, height: 100%, fill: currentColor 로 저장
 */
import IcAdd from '@/icons/ic_add.svg'
import IcAddSmall from '@/icons/ic_add_small.svg'
import IcArrowDownRedGradation from '@/icons/ic_arrow_down_red_gradation.svg'
import IcArrowRight from '@/icons/ic_arrow_right.svg'
import IcBuyCount from '@/icons/ic_buy_count.svg'
import IcCheckboxSmallChecked from '@/icons/ic_checkbox_small_checked.svg'
import IcCheckboxSmallUnChecked from '@/icons/ic_checkbox_small_unchecked.svg'
import IcCheckboxSmallUnCheckedDisabled from '@/icons/ic_checkbox_small_unchecked_disabled.svg'
import IcChevronDown from '@/icons/ic_chevron_down.svg'
import IcChevronRight from '@/icons/ic_chevron_right.svg'
import IcCoin from '@/icons/ic_coin.svg'
import IcCoinFlat from '@/icons/ic_coin_flat.svg'
import IcInsurance from '@/icons/ic_insurance.svg'
import IcPlus from '@/icons/ic_plus.svg'
import IcRadioDefault from '@/icons/ic_radio_default.svg'
import IcRadioSelect from '@/icons/ic_radio_select.svg'
import IcStar from '@/icons/ic_star.svg'
import IcTip from '@/icons/ic_tip.svg'
/**
 * import 순서와 동일하게 작성해주세요.
 */
export const SvgImages = {
  'ic-add': IcAdd,
  'ic-add-small': IcAddSmall,
  'ic-arrow-down-red-gradation': IcArrowDownRedGradation,
  'ic-arrow-right': IcArrowRight,
  'ic-buy-count': IcBuyCount,
  'ic-checkbox-small-checked': IcCheckboxSmallChecked,
  'ic-checkbox-small-unchecked': IcCheckboxSmallUnChecked,
  'ic-checkbox-small-unchecked-disabled': IcCheckboxSmallUnCheckedDisabled,
  'ic-chevron-down': IcChevronDown,
  'ic-chevron-right': IcChevronRight,
  'ic-coin': IcCoin,
  'ic-coin-flat': IcCoinFlat,
  'ic-insurance': IcInsurance,
  'ic-plus': IcPlus,
  'ic-radio-default': IcRadioDefault,
  'ic-radio-select': IcRadioSelect,
  'ic-star': IcStar,
  'ic-tip': IcTip,
} as const
