/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Fitpet Mall
 * 핏펫몰 API 스펙

API 스펙 작성시 다음 가이드를 숙지하시기 바랍니다.

- [Open API 가이드 참고](https://swagger.io/docs/specification/about/)
- [Kotlin-Spring Open API Generator 설정 참고](https://openapi-generator.tech/docs/generators/kotlin-spring)
- REST API 디자인 가이드 참고
  - doc > guide > rest-api-design.md
- OpenAPI Spec 컨벤션 참고
  - doc > guid > openapi-spec-convention.md
- 설명(description) 작성시 마크다운([CommonMark](https://commonmark.org/help/)) 구문 참고

로컬에서 스웨거 에디터를 사용하는 방법은 다음과 같다.

```shell
docker pull swaggerapi/swagger-editor
docker run -d -p 8123:8080 swaggerapi/swagger-editor
open http://localhost:8123
```

Api 스펙을 하나의 파일로 합치려면 다음과 같이 실행한다.

```shell
# 설치
npm install -g swagger-cli

# 실행
swagger-cli bundle api-spec.yaml --outfile build/openapi.yaml --type yaml
```
 * OpenAPI spec version: 1.0.0
 */

/**
 * 하위 오류코드

## Common
- `REQUIRED_ARGUMENTS_MISSING`: 필요한 값이 없습니다.
- `DUPLICATE_ARGUMENTS_PROVIDED`: 중복 값이 존재합니다.
- `HAS_DIFFERENT_ELEMENTS`: 서로 다른 값을 가지고 있습니다.
- `VALUE_NOT_FOUND`: 찾는 값이 없습니다.
- `INVALID_ARGUMENTS`: 올바르지 않은 값 입니다.

## User
- `USER_NOT_FOUND`: 사용자를 찾을 수 없습니다.
- `EXCEED_OWNED_MILEAGE`: 적립된 금액 이상 사용할 수 없습니다.

## Product
- `PRODUCT_ENTITY_NOT_FOUND`: 상품을 찾을 수 없습니다.
- `PRODUCT_DELETED`: 삭제된 상품입니다
- `PRODUCT_INVALID_STATUS`: 판매 상태가 아닙니다.

## Promotion
- `PROMOTION_ENTITY_NOT_FOUND`: 프로모션을 찾을 수 없습니다.
- `PROMOTION_MIN_ORDER_AMOUNT_NOT_SATISFIED`: 프로모션 최소 주문 금액을 만족하지 않습니다.

## Coupon
- `COUPON_NOT_FOUND`: 쿠폰를 찾을 수 없습니다.
- `COUPON_CAN_NOT_CONJUNCT`: 쿠폰을 같이 사용할 수 없습니다.
- `COUPON_INVALID_TYPE`: 올바르지 않은 쿠폰 유형입니다.
- `COUPON_INVALID_TARGET`: 사용할 수 없는 쿠폰입니다.
- `COUPON_CAN_USE_ONLY_MEMBERSHIP`: 멤버십이 해지되어 멤버십 전용 쿠폰을 사용할 수 없어요.
- `COUPON_INVALID_INCLUDE_COVERAGE_PRODUCT`: 쿠폰을 사용할 수 없는 상품을 포함하고 있습니다.
- `COUPON_INVALID_INCLUDE_COVERAGE_BRAND`: 쿠폰을 사용할 수 없는 브랜드를 포함하고 있습니다.
- `COUPON_INVALID_INCLUDE_COVERAGE_CATEGORY`: 쿠폰을 사용할 수 없는 카테고리를 포함하고 있습니다.
- `COUPON_INVALID_EXCLUDE_COVERAGE_PRODUCT`: 쿠폰을 사용 불가 상품을 포함하고 있습니다.
- `COUPON_INVALID_EXCLUDE_COVERAGE_BRAND`: 쿠폰을 사용 불가 브랜드를 포함하고 있습니다.
- `COUPON_INVALID_EXCLUDE_COVERAGE_CATEGORY`: 쿠폰을 사용 불가 카테고리를 포함하고 있습니다.
- `CAN_NOT_USE_COUPON`: 사용할 수 없는 쿠폰 입니다.
- `CAN_NOT_CREATE_KEYWORD_COUPON`: 키워드 쿠폰을 생성할 수 없습니다.
- `KEYWORD_COUPON_DUPLICATED`: 이미 등록된 키워드 쿠폰입니다.

## Cart
- `CART_DIFFERENT_PRODUCT`: 장바구니에 등록된 상품과 변경하려는 상품이 다릅니다.
- `CART_REGISTERED_PRODUCT_OPTION`: 이미 장바구니에 등록된 상품 옵션입니다. 수량 조절만 가능합니다.

## Order
- `INSUFFICIENT_STOCK`: 재고가 부족합니다.
- `ACTION_POWER_ORDER_QUANTITY_IS_ONE` : AI 상품의 주문 가능 수량은 1개 입니다.
- `SPECIAL_DEAL_ORDER_QUANTITY_IS_ONE`: 웰컴딜 상품의 주문 가능 수량은 1개 입니다.
- `MIN_ORDER_QUANTITY_IS_ONE`: 최소 주문 수량은 1개 입니다.
- `MAX_ORDER_QUANTITY_IS_TWENTY`: 최대 주문 수량은 20개 입니다.
- `NOT_APPROVED_PRODUCT`: 승인되지 않은 상품입니다.
- `NO_SELLER_INFORMATION`: 판매자 정보가 없습니다.
- `NO_BRAND_INFORMATION`: 브랜드 정보가 없습니다.
- `NO_SHIPPING_PRESET_INFORMATION`: 출고지 정보가 없습니다.
- `NO_CATEGORY_INFORMATION`: 카테고리 정보가 없습니다.
- `SOLD_OUT_PRODUCT`: 품절된 상품입니다.
- `NOT_ON_SALE_PRODUCT`: 판매 중인 상품이 아닙니다.
- `COUPON_UNDER_MINIMUM_AMOUNT`: 최소 사용 금액보다 적은 금액으로 쿠폰을 사용할 수 없습니다.
- `ALREADY_BOUGHT_SPECIAL_DEAL`: 이미 웰컴딜 구매 이벤트를 이용하셨습니다.

## Partners
- `FITPET_PARTNERS_PARTNER_DUPLICATED`: 이미 존재하는 핏펫파트너스 파트너입니다.
- `FITPET_PARTNERS_PARTNER_NOT_FOUND`: 핏펫파트너스 파트를 찾을 수 없습니다.
 */
export type FitpetErrorSubCodeEnum = typeof FitpetErrorSubCodeEnum[keyof typeof FitpetErrorSubCodeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FitpetErrorSubCodeEnum = {
  REQUIRED_ARGUMENTS_MISSING: 'REQUIRED_ARGUMENTS_MISSING',
  DUPLICATE_ARGUMENTS_PROVIDED: 'DUPLICATE_ARGUMENTS_PROVIDED',
  HAS_DIFFERENT_ELEMENTS: 'HAS_DIFFERENT_ELEMENTS',
  VALUE_NOT_FOUND: 'VALUE_NOT_FOUND',
  INVALID_ARGUMENTS: 'INVALID_ARGUMENTS',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  EXCEED_OWNED_MILEAGE: 'EXCEED_OWNED_MILEAGE',
  COUPON_NOT_FOUND: 'COUPON_NOT_FOUND',
  COUPON_CAN_NOT_CONJUNCT: 'COUPON_CAN_NOT_CONJUNCT',
  COUPON_INVALID_TYPE: 'COUPON_INVALID_TYPE',
  COUPON_INVALID_TARGET: 'COUPON_INVALID_TARGET',
  COUPON_CAN_USE_ONLY_MEMBERSHIP: 'COUPON_CAN_USE_ONLY_MEMBERSHIP',
  COUPON_INVALID_INCLUDE_COVERAGE_PRODUCT: 'COUPON_INVALID_INCLUDE_COVERAGE_PRODUCT',
  COUPON_INVALID_INCLUDE_COVERAGE_BRAND: 'COUPON_INVALID_INCLUDE_COVERAGE_BRAND',
  COUPON_INVALID_INCLUDE_COVERAGE_CATEGORY: 'COUPON_INVALID_INCLUDE_COVERAGE_CATEGORY',
  COUPON_INVALID_EXCLUDE_COVERAGE_PRODUCT: 'COUPON_INVALID_EXCLUDE_COVERAGE_PRODUCT',
  COUPON_INVALID_EXCLUDE_COVERAGE_BRAND: 'COUPON_INVALID_EXCLUDE_COVERAGE_BRAND',
  COUPON_INVALID_EXCLUDE_COVERAGE_CATEGORY: 'COUPON_INVALID_EXCLUDE_COVERAGE_CATEGORY',
  CAN_NOT_USE_COUPON: 'CAN_NOT_USE_COUPON',
  CAN_NOT_CREATE_KEYWORD_COUPON: 'CAN_NOT_CREATE_KEYWORD_COUPON',
  KEYWORD_COUPON_DUPLICATED: 'KEYWORD_COUPON_DUPLICATED',
  PRODUCT_ENTITY_NOT_FOUND: 'PRODUCT_ENTITY_NOT_FOUND',
  PRODUCT_DELETED: 'PRODUCT_DELETED',
  PRODUCT_INVALID_STATUS: 'PRODUCT_INVALID_STATUS',
  PROMOTION_ENTITY_NOT_FOUND: 'PROMOTION_ENTITY_NOT_FOUND',
  PROMOTION_MIN_ORDER_AMOUNT_NOT_SATISFIED: 'PROMOTION_MIN_ORDER_AMOUNT_NOT_SATISFIED',
  CART_DIFFERENT_PRODUCT: 'CART_DIFFERENT_PRODUCT',
  CART_REGISTERED_PRODUCT_OPTION: 'CART_REGISTERED_PRODUCT_OPTION',
  INSUFFICIENT_STOCK: 'INSUFFICIENT_STOCK',
  ACTION_POWER_ORDER_QUANTITY_IS_ONE: 'ACTION_POWER_ORDER_QUANTITY_IS_ONE',
  SPECIAL_DEAL_ORDER_QUANTITY_IS_ONE: 'SPECIAL_DEAL_ORDER_QUANTITY_IS_ONE',
  MIN_ORDER_QUANTITY_IS_ONE: 'MIN_ORDER_QUANTITY_IS_ONE',
  MAX_ORDER_QUANTITY_IS_TWENTY: 'MAX_ORDER_QUANTITY_IS_TWENTY',
  NOT_APPROVED_PRODUCT: 'NOT_APPROVED_PRODUCT',
  NO_SELLER_INFORMATION: 'NO_SELLER_INFORMATION',
  NO_BRAND_INFORMATION: 'NO_BRAND_INFORMATION',
  NO_SHIPPING_PRESET_INFORMATION: 'NO_SHIPPING_PRESET_INFORMATION',
  NO_CATEGORY_INFORMATION: 'NO_CATEGORY_INFORMATION',
  SOLD_OUT_PRODUCT: 'SOLD_OUT_PRODUCT',
  NOT_ON_SALE_PRODUCT: 'NOT_ON_SALE_PRODUCT',
  COUPON_UNDER_MINIMUM_AMOUNT: 'COUPON_UNDER_MINIMUM_AMOUNT',
  ALREADY_BOUGHT_SPECIAL_DEAL: 'ALREADY_BOUGHT_SPECIAL_DEAL',
  FITPET_PARTNERS_PARTNER_DUPLICATED: 'FITPET_PARTNERS_PARTNER_DUPLICATED',
  FITPET_PARTNERS_PARTNER_NOT_FOUND: 'FITPET_PARTNERS_PARTNER_NOT_FOUND',
} as const
